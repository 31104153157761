import React, { useEffect } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import PageLayout from '../../../../components/layout/PageLayout';
import AddLiquidityForm from './AddLiquidityForm';
import ChainNotValidBlockWrapper from '../../../components/ChainNotValidBlock';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import BackButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/BackButton/BackButton';
import { Heading, Text } from '../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';

const AddLiquidity: React.FC = () => {
    useEffect(() => {
        document.title = 'Liquidity | Arctic';
    }, []);
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack w="100%" mt="20px">
                            <BackButton flex={20} mr={{ base: 'auto', sm: 'unset' }} />
                            <Stack flex={80} alignItems="center" ml={{ base: '0px', sm: '-20%' }} mt={{ base: '14px', sm: 'unset' }}>
                                <Heading level={'4'}>{'Add Liquidity'}</Heading>
                                <Text variant="display" fontSize={isMobile ? '12px' : 'unset'}>
                                    Add a new position to earn transaction fees.
                                </Text>
                            </Stack>
                            <Box h="20px" />
                            <AddLiquidityForm />
                        </Stack>
                    }
                    app="trade"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={t('Add Liquidity')}
                    subtitle={t('Add a new position to earn transaction fees.')}
                    variant="secondary"
                />
            }
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack w="100%">
                            <Box h={{ base: '20px', sm: '40px' }} />
                            <AddLiquidityForm />
                        </Stack>
                    }
                    app="trade"
                />
            }
            internalWidth={{ base: '100%', sm: '700px', xl: '1150px' }}
            mobileAdapt={true}
        />
    );
};

export default AddLiquidity;
