import { HStack, Stack, VStack, IconButton, Icon, useColorMode, Switch, Center, Image, Tooltip, BoxProps } from '@chakra-ui/react';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { ToastLink, useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { CustomNumberInput } from '../../../../../iZUMi-UI-toolkit/src/components/Inputs/NumberInput/NumberInput';
import { amount2Decimal, formatNumber } from '../../../../../utils/tokenMath';
import { Text } from '../../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { RootDispatch } from '../../../../../state/store';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { i_text_copy, i_text_copy_bold, i_text_d } from '../../../../../style';
import CurrentPriceBlock from '../../../../components/CurrentPriceBlock';
import { TokenIconSymbol } from '../../../../components/TokenIconSymbol';
import { findPoolEntryByPoolKey, niZiForDesiredBoost } from '../../../../../state/models/farm/iZiSwap/dynamicRange/funcs';
import { RootState } from '../../../../../state/store';
import { isNumeric } from '../../../../../utils/valid';
import { FarmDynamicRangeiZiContractVersion, TokenSymbol } from '../../../../../types/mod';
import { identity } from '../../../../../utils/funcs';
import BigNumber from 'bignumber.js';
import useTokenEntity, { TokenEntity } from '../../../../../state/models/hooks/useTokenEntity';
import { FarmRefreshHandle } from './PoolList';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import ApproveTokenButton from '../../../../components/ApproveTokenButton';
import useDynamicRangeiZiEntity, { DynamicRangeiZiDepositParams } from '../../../../../state/models/hooks/farm/useDynamicRangeiZiEntity';
import { MiningCallbacks } from '../../../../../state/models/hooks/farm/common/callbacks';
import { PoolEntryState } from '../../../../../state/models/farm/iZiSwap/dynamicRange/types';
import { TokenIcons } from '../../../../Trade/components/TokenIcons';
import { FeeRate } from '../../../../Trade/components/FeeRate';
import { FARM_CONFIG } from '../../../../../config/bizConfig';
import { VeNFTBoostBlock } from '../../../components/VeNFTBoostBlock';
import useWindowDimensions from '../../../../../hooks/useWindowDimension';
import { useIZiToken } from '../../../../../hooks/useiZiToken';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { getChain, getTxLink } from '../../../../../config/chains';

const IconWithSymbol = TokenIconSymbol;

type AddLiquidityModalProps = {
    isOpen: boolean | any;
    onClose: () => void;
    onSuccess?: () => void;
    entry: PoolEntryState;
} & BoxProps &
    FarmRefreshHandle;

const AddLiquidityModal: React.FC<AddLiquidityModalProps> = ({
    //refreshPosition,
    refreshPoolListDataAndPosition,
    isOpen,
    onClose,
    //onSuccess,
    entry,
    ...rest
}) => {
    const toast = useCustomToast();
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const [iZiSwitch, setiZiSwitch] = useState(false);
    const { gasPrice } = useGasPrice();
    const { iZiToken } = useIZiToken();
    const isMobile = useIsMobile();

    const {
        account: accountModel,
        farmDynamicRangeiZi: { poolEntryList },
        farmDynamicRangeiZiAddLiquidity: { mintForm },
        veiZiFarmDynamicRangeiZiAddLiquidity: { mintForm: veiZiMintForm },
    } = useSelector((state: RootState) => state);

    const { account, chainId, web3 } = useWeb3WithDefault();

    const poolEntry = findPoolEntryByPoolKey(poolEntryList, mintForm.positionPoolKey);

    const dynamicRangeEntity = useDynamicRangeiZiEntity(poolEntry);

    useEffect(() => {
        if (!account || !web3 || !poolEntry.meta.positionPoolKey) {
            return;
        }
        const tokenList = [poolEntry.meta.tokenA, poolEntry.meta.tokenB];
        for (const token of tokenList) {
            const param = {
                token,
                account,
                web3,
                chainId,
                spender: poolEntry.meta.miningContract,
            } as unknown as any;
            dispatch.account.fetchTokenBalanceIfMissing(param);
            dispatch.account.fetchTokenApprovedIfMissing(param);
        }
    }, [account, poolEntry, chainId, dispatch, web3]);

    let tokenAEntity = undefined as unknown as TokenEntity;
    let tokenBEntity = undefined as unknown as TokenEntity;

    const tokenANotWrapToken = !poolEntry.meta.tokenA.wrapTokenAddress;
    const tokenBNotWrapToken = !poolEntry.meta.tokenB.wrapTokenAddress;

    console.log('+++++++++++++++++++ tokenA: ', poolEntry.meta.tokenA);
    console.log('+++++++++++++++++++ tokenB: ', poolEntry.meta.tokenB);

    const noWrapToken = tokenANotWrapToken && tokenBNotWrapToken;
    if (noWrapToken) {
        tokenAEntity = useTokenEntity(poolEntry.meta.tokenA, poolEntry.meta.miningContract);
        tokenBEntity = useTokenEntity(poolEntry.meta.tokenB, poolEntry.meta.miningContract);
    } else {
        tokenAEntity = tokenANotWrapToken
            ? useTokenEntity(poolEntry.meta.tokenA, poolEntry.meta.miningContract)
            : useTokenEntity(poolEntry.meta.tokenA, poolEntry.meta.tokenA.wrapTokenAddress, poolEntry.meta.miningContract);
        tokenBEntity = tokenBNotWrapToken
            ? useTokenEntity(poolEntry.meta.tokenB, poolEntry.meta.miningContract)
            : useTokenEntity(poolEntry.meta.tokenB, poolEntry.meta.tokenB.wrapTokenAddress, poolEntry.meta.miningContract);
    }

    console.log('tokenB: ', poolEntry.meta.tokenB.symbol, tokenBNotWrapToken);
    console.log('deposit approve: ', tokenBEntity.isDepositApproved());

    const tokeniZiEntity = useTokenEntity(iZiToken, poolEntry.meta.miningContract);

    const PricePiece = (text: any, price: any) => (
        <VStack alignItems="center" w={isChangeLine ? '50%' : 'unset'}>
            <Card variant="deep" px="10px" py="4px">
                <Text variant="caption" className={i_text_d} fontSize="16px" color={colorTheme('tertiary.900', 'tertiary.500')}>
                    {price}
                </Text>
            </Card>
            <Text variant="caption" className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.600', 'tertiary.300')}>
                {text}
            </Text>
        </VStack>
    );

    const maxPriceAByB = Math.max(poolEntry.data.priceAByBDecimal, poolEntry.data.oraclePriceAByBDecimal);
    const minPriceAByB = Math.min(poolEntry.data.priceAByBDecimal, poolEntry.data.oraclePriceAByBDecimal);

    const priceAByBDecimalLower = poolEntry.data.oraclePriceAByBDecimal / poolEntry.meta.leftRangeRatio;
    const priceAByBDecimalUpper = poolEntry.data.oraclePriceAByBDecimal * poolEntry.meta.rightRangeRatio;

    const priceDiffRate = (maxPriceAByB - minPriceAByB) / maxPriceAByB;

    const isVeiZiVersion = poolEntry.meta.contractVersion === FarmDynamicRangeiZiContractVersion.VEIZI;

    const tokenAAmountDecimal =
        poolEntry.meta.contractVersion === FarmDynamicRangeiZiContractVersion.V1
            ? mintForm.tokenAAmountDecimal
            : veiZiMintForm.tokenAAmountDecimal;

    const tokenAAmount =
        poolEntry.meta.contractVersion === FarmDynamicRangeiZiContractVersion.V1 ? mintForm.tokenAAmount : veiZiMintForm.tokenAAmount;

    const tokenBAmountDecimal =
        poolEntry.meta.contractVersion === FarmDynamicRangeiZiContractVersion.V1
            ? mintForm.tokenBAmountDecimal
            : veiZiMintForm.tokenBAmountDecimal;
    const tokenBAmount =
        poolEntry.meta.contractVersion === FarmDynamicRangeiZiContractVersion.V1 ? mintForm.tokenBAmount : veiZiMintForm.tokenBAmount;

    const tokenAAllApproved =
        tokenAEntity.isApproved(String(tokenAAmount)) && (tokenANotWrapToken ? true : tokenAEntity.isDepositApproved());
    const tokenBAllApproved =
        tokenBEntity.isApproved(String(tokenBAmount)) && (tokenBNotWrapToken ? true : tokenBEntity.isDepositApproved());

    const iZiAmountDecimal = poolEntry.meta.contractVersion === FarmDynamicRangeiZiContractVersion.V1 ? mintForm.iZiAmountDecimal : 0;
    const iZiAmount = poolEntry.meta.contractVersion === FarmDynamicRangeiZiContractVersion.V1 ? mintForm.iZiAmount : 0;

    let totaliZiAmountDecimal = iZiAmountDecimal;
    let totaliZiAmount = iZiAmount;

    if (poolEntry.meta.tokenA.symbol === TokenSymbol.IZI) {
        totaliZiAmountDecimal += tokenAAmountDecimal;
        totaliZiAmount += tokenAAmount;
    }
    if (poolEntry.meta.tokenB.symbol === TokenSymbol.IZI) {
        totaliZiAmountDecimal += tokenBAmountDecimal;
        totaliZiAmount += tokenBAmount;
    }

    const extraveiZi = Math.max(veiZiMintForm.veiZiForFullBoostDecimal - poolEntry.userData.veiZiDecimal, 0);

    const { width } = useWindowDimensions();
    const changeLineWidth = 960;
    const isChangeLine = changeLineWidth > width ? true : false;

    console.log('extraveiZi: ', extraveiZi);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '73%', lg: '744px' }}
            h="unset"
            title={t('Add liquidity')}
            {...rest}
            overflowX="hidden"
        >
            <VStack w="100%" spacing="10px" alignItems="start">
                <Card variant="deep" w="100%" h={isChangeLine ? 'unset' : '108px'} mt="-10px">
                    <Stack
                        direction={isChangeLine ? 'column' : 'row'}
                        w="100%"
                        spacing="10px"
                        p={isChangeLine ? '14px 20px 14px 20px' : '14px 20px 14px 40px'}
                        justifyContent="space-between"
                    >
                        <HStack>
                            <TokenIcons tokenA={entry.meta.tokenA} tokenB={entry.meta.tokenB} initialToggle={entry.meta.initialToggle} />
                            <FeeRate
                                tokenA={entry.meta.tokenA}
                                tokenB={entry.meta.tokenB}
                                initialToggle={entry.meta.initialToggle}
                                feeTier={entry.meta.feeTier as FeeTier}
                            />
                        </HStack>
                        <CurrentPriceBlock
                            tokenA={poolEntry.meta.tokenA}
                            tokenB={poolEntry.meta.tokenB}
                            price={poolEntry.data.priceAByBDecimal}
                            toggle={poolEntry.meta.initialToggle}
                            handleToggle={() => {
                                dispatch.farmDynamicRangeiZi.togglePoolMetaInitialToggle(poolEntry.meta.positionPoolKey);
                            }}
                            w={isChangeLine ? '100%' : '241px'}
                            mt={isChangeLine ? '40px !important' : 'unset'}
                        />
                    </Stack>
                </Card>

                <Stack direction="row" h="35px" w="150px" alignItems="center">
                    <Text color={colorTheme('tertiary.500', 'tertiary.300')} variant="caption">
                        {t('Volume')}
                    </Text>
                    <Tooltip
                        label={`Both token ${poolEntry.meta.tokenA}  and token ${poolEntry.meta.tokenB} 
                            will be added into the iZiSwap pool to earn fees based on the current price. \n 
                            The amounts required are calculated with instant price, which may have a slight difference in the actual executive process.`}
                    >
                        <IconButton
                            size="sm"
                            aria-label=""
                            variant="ghost"
                            isRound={true}
                            icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                        />
                    </Tooltip>
                </Stack>

                <Stack
                    direction={isChangeLine ? 'column' : 'row'}
                    bg={colorTheme('#FDFDFD', '#423854')}
                    pt="16px"
                    w="100%"
                    boxShadow={colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', 'undefined')}
                    borderRadius="6px"
                    px={isChangeLine ? '20px' : '30px'}
                    pb="16px"
                >
                    <VStack
                        bg={colorTheme('#fff', '#34294A')}
                        boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', '0px 0px 27px 5px rgba(29, 6, 51, 0.25)')}
                        borderRadius="6px"
                        border={colorTheme('0.5px solid #DDDAE1', '1px solid #8664CE')}
                        pb="10px"
                        w={isChangeLine ? '100%' : '270px'}
                    >
                        <Stack
                            direction={isChangeLine ? 'column' : 'row'}
                            h={isChangeLine ? 'unset' : '31px'}
                            textAlign={isChangeLine ? 'center' : 'unset'}
                            px="17px"
                            justifyContent="space-between"
                            w="100%"
                            borderBottom={colorTheme('1px solid', '0.5px solid')}
                            borderColor={colorTheme('tertiary.100', 'tertiary.600')}
                            alignItems="center"
                        >
                            <IconWithSymbol
                                justifyContent={isChangeLine ? 'center' : 'unset'}
                                token={poolEntry.meta.tokenA}
                                mt={isChangeLine ? '8px' : 'unset'}
                            />

                            <Text color="tertiary.300" variant="caption" mb={isChangeLine ? '10px !important' : 'unset'}>
                                {formatNumber(accountModel.tokenBalance[poolEntry.meta.tokenA?.symbol]?.[0] ?? 0)}
                            </Text>
                        </Stack>

                        <VStack w={isChangeLine ? '100%' : 'unset'}>
                            <CustomNumberInput
                                inputValue={Number(tokenAAmountDecimal).toFixed(4)}
                                onBlur={(value) => {
                                    if (isNumeric(value)) {
                                        if (isVeiZiVersion) {
                                            dispatch.veiZiFarmDynamicRangeiZiAddLiquidity.updateAmountADecimal({
                                                amountDecimal: value as number,
                                                positionPoolKey: veiZiMintForm.positionPoolKey,
                                                chainId,
                                            });
                                        } else {
                                            dispatch.farmDynamicRangeiZiAddLiquidity.updateAmountADecimal({
                                                amountDecimal: value as number,
                                                positionPoolKey: mintForm.positionPoolKey,
                                                chainId,
                                            });
                                        }
                                    }
                                }}
                                errorInfo={
                                    tokenAAmountDecimal <= accountModel.tokenBalance[poolEntry.meta.tokenA?.symbol]?.[0]
                                        ? ''
                                        : 'Not enough token'
                                }
                            />
                            <HStack w={isChangeLine ? '90%' : 'unset'}>
                                <CustomButton
                                    w={isChangeLine ? '50%' : '88px'}
                                    h="24px"
                                    variant="tertiary"
                                    text="Clear"
                                    fontClass={i_text_copy_bold}
                                    onClick={() => {
                                        if (isVeiZiVersion) {
                                            dispatch.veiZiFarmDynamicRangeiZiAddLiquidity.updateAmountADecimal({
                                                amountDecimal: 0,
                                                positionPoolKey: veiZiMintForm.positionPoolKey,
                                                chainId,
                                            });
                                        } else {
                                            dispatch.farmDynamicRangeiZiAddLiquidity.updateAmountADecimal({
                                                amountDecimal: 0,
                                                positionPoolKey: mintForm.positionPoolKey,
                                                chainId,
                                            });
                                        }
                                    }}
                                />
                                <CustomButton
                                    w={isChangeLine ? '50%' : '88px'}
                                    h="24px"
                                    variant="primary2"
                                    text="All"
                                    fontClass={i_text_copy_bold}
                                    onClick={() => {
                                        if (isVeiZiVersion) {
                                            dispatch.veiZiFarmDynamicRangeiZiAddLiquidity.updateAmountADecimal({
                                                amountDecimal: accountModel.tokenBalance[poolEntry.meta.tokenA.symbol]
                                                    ? accountModel.tokenBalance[poolEntry.meta.tokenA.symbol]?.[0] * FARM_CONFIG.ALL_FACTOR
                                                    : 0,
                                                positionPoolKey: veiZiMintForm.positionPoolKey,
                                                chainId,
                                            });
                                        } else {
                                            dispatch.farmDynamicRangeiZiAddLiquidity.updateAmountADecimal({
                                                amountDecimal: accountModel.tokenBalance[poolEntry.meta.tokenA.symbol]
                                                    ? accountModel.tokenBalance[poolEntry.meta.tokenA.symbol]?.[0] * FARM_CONFIG.ALL_FACTOR
                                                    : 0,
                                                positionPoolKey: mintForm.positionPoolKey,
                                                chainId,
                                            });
                                        }
                                    }}
                                />
                            </HStack>
                        </VStack>
                    </VStack>

                    <IconButton
                        size="sm"
                        transform="rotate(90deg)"
                        aria-label=""
                        variant="ghost"
                        alignSelf="center"
                        width="15px"
                        height="15px"
                        onClick={() => {}}
                        icon={<Icon as={MdAdd} boxSize="20px" />}
                    />

                    <VStack
                        bg={colorTheme('#fff', '#34294A')}
                        boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', '0px 0px 27px 5px rgba(29, 6, 51, 0.25)')}
                        borderRadius="6px"
                        border={colorTheme('0.5px solid #DDDAE1', '1px solid #8664CE')}
                        pb="10px"
                        w={isChangeLine ? '100%' : '270px'}
                    >
                        <Stack
                            direction={isChangeLine ? 'column' : 'row'}
                            h={isChangeLine ? 'unset' : '31px'}
                            textAlign={isChangeLine ? 'center' : 'unset'}
                            px="17px"
                            justifyContent="space-between"
                            w="100%"
                            borderBottom={colorTheme('1px solid', '0.5px solid')}
                            borderColor={colorTheme('tertiary.100', 'tertiary.600')}
                            alignItems="center"
                        >
                            <IconWithSymbol
                                justifyContent={isChangeLine ? 'center' : 'unset'}
                                mt={isChangeLine ? '8px' : 'unset'}
                                token={poolEntry.meta.tokenB}
                            />

                            <Text color="tertiary.300" variant="caption" mb={isChangeLine ? '10px !important' : 'unset'}>
                                {formatNumber(accountModel.tokenBalance[poolEntry.meta.tokenB.symbol]?.[0] ?? 0)}
                            </Text>
                        </Stack>

                        <VStack w={isChangeLine ? '100%' : 'unset'}>
                            <CustomNumberInput
                                inputValue={Number(tokenBAmountDecimal).toFixed(4)}
                                onBlur={(value) => {
                                    if (isVeiZiVersion) {
                                        console.log('value: ', value);
                                        dispatch.veiZiFarmDynamicRangeiZiAddLiquidity.updateAmountBDecimal({
                                            amountDecimal: value as number,
                                            positionPoolKey: veiZiMintForm.positionPoolKey,
                                            chainId,
                                        });
                                    } else {
                                        dispatch.farmDynamicRangeiZiAddLiquidity.updateAmountBDecimal({
                                            amountDecimal: value as number,
                                            positionPoolKey: mintForm.positionPoolKey,
                                            chainId,
                                        });
                                    }
                                }}
                                errorInfo={
                                    tokenBAmountDecimal <= accountModel.tokenBalance[poolEntry.meta.tokenB.symbol]?.[0]
                                        ? ''
                                        : 'Not enough token'
                                }
                            />
                            <HStack w={isChangeLine ? '90%' : 'unset'}>
                                <CustomButton
                                    w={isChangeLine ? '50%' : '88px'}
                                    h="24px"
                                    variant="tertiary"
                                    text="Clear"
                                    fontClass={i_text_copy_bold}
                                    onClick={() => {
                                        if (isVeiZiVersion) {
                                            dispatch.veiZiFarmDynamicRangeiZiAddLiquidity.updateAmountBDecimal({
                                                amountDecimal: 0,
                                                positionPoolKey: veiZiMintForm.positionPoolKey,
                                                chainId,
                                            });
                                        } else {
                                            dispatch.farmDynamicRangeiZiAddLiquidity.updateAmountBDecimal({
                                                amountDecimal: 0,
                                                positionPoolKey: mintForm.positionPoolKey,
                                                chainId,
                                            });
                                        }
                                    }}
                                />
                                <CustomButton
                                    w={isChangeLine ? '50%' : '88px'}
                                    h="24px"
                                    variant="primary2"
                                    text="All"
                                    fontClass={i_text_copy_bold}
                                    onClick={() => {
                                        if (isVeiZiVersion) {
                                            dispatch.veiZiFarmDynamicRangeiZiAddLiquidity.updateAmountBDecimal({
                                                amountDecimal: accountModel.tokenBalance[poolEntry.meta.tokenB.symbol]
                                                    ? accountModel.tokenBalance[poolEntry.meta.tokenB.symbol]?.[0] * FARM_CONFIG.ALL_FACTOR
                                                    : 0,
                                                positionPoolKey: veiZiMintForm.positionPoolKey,
                                                chainId,
                                            });
                                        } else {
                                            dispatch.farmDynamicRangeiZiAddLiquidity.updateAmountBDecimal({
                                                amountDecimal: accountModel.tokenBalance[poolEntry.meta.tokenB.symbol]
                                                    ? accountModel.tokenBalance[poolEntry.meta.tokenB.symbol]?.[0] * FARM_CONFIG.ALL_FACTOR
                                                    : 0,
                                                positionPoolKey: mintForm.positionPoolKey,
                                                chainId,
                                            });
                                        }
                                    }}
                                />
                            </HStack>
                        </VStack>
                    </VStack>
                </Stack>

                <Stack direction="row" h="35px" w="150px" alignItems="center">
                    <Text color={colorTheme('tertiary.500', 'tertiary.300')} variant="caption">
                        {t('Price Range')}
                    </Text>
                </Stack>

                <Stack
                    direction={isChangeLine ? 'column' : 'row'}
                    justifyContent="space-between"
                    alignItems="center"
                    bg={colorTheme('#fff', '#34294A')}
                    boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', 'undefined')}
                    borderRadius="6px"
                    borderColor="tertiary.100"
                    w="100%"
                    py="16px"
                    px="20px"
                >
                    <VStack w="340px" justifyContent="space-between" spacing="15px" pt="5px !important">
                        <HStack>
                            <Text variant="caption-bold" color={colorTheme('tertiary.500', 'tertiary.200')}>
                                1
                            </Text>
                            <IconWithSymbol token={poolEntry.meta.initialToggle ? poolEntry.meta.tokenB : poolEntry.meta.tokenA} />
                            <Text variant="caption-bold" color={colorTheme('tertiary.500', 'tertiary.200')}>
                                = &nbsp; ?
                            </Text>

                            <IconWithSymbol token={poolEntry.meta.initialToggle ? poolEntry.meta.tokenA : poolEntry.meta.tokenB} />
                        </HStack>
                    </VStack>
                    <HStack w={isChangeLine ? '100%' : '45%'} justifyContent="space-between" pr={isChangeLine ? 'unset' : '100px'}>
                        {PricePiece(
                            'Min Price',
                            poolEntry.meta.initialToggle
                                ? formatNumber(1 / priceAByBDecimalUpper, 2, 1 / priceAByBDecimalUpper > 1 ? 2 : 4, true)
                                : formatNumber(priceAByBDecimalLower, 2, priceAByBDecimalLower > 1 ? 2 : 4, true)
                        )}
                        {PricePiece(
                            'Max Price',
                            poolEntry.meta.initialToggle
                                ? formatNumber(1 / priceAByBDecimalLower, 2, 1 / priceAByBDecimalLower > 1 ? 2 : 4, true)
                                : formatNumber(priceAByBDecimalUpper, 2, priceAByBDecimalUpper > 1 ? 2 : 4, true)
                        )}
                    </HStack>
                </Stack>
                {poolEntry.meta.veiZiBoost && (
                    <VeNFTBoostBlock
                        entry={{
                            veiZiDecimal: poolEntry.userData.veiZiDecimal,
                            nftId: poolEntry.userData.veiZiNftId,
                        }}
                        aprRaw={veiZiMintForm.apr}
                        aprBoost={veiZiMintForm.aprBoostVeiZi}
                        extraveiZi={extraveiZi}
                    />
                )}

                {poolEntry.meta.iZiBoost && (
                    <>
                        <Stack direction="row" h="35px" w="150px" alignItems="center">
                            <HStack mt="40px !important" w="90%">
                                <HStack>
                                    <Image
                                        w="15px"
                                        h="11px"
                                        src={process.env.PUBLIC_URL + '/assets/farm/boostIcon.svg'}
                                        mr="0 !important"
                                        pr="0 !important"
                                    />
                                    <Text variant="caption-bold" color="secondary.500">
                                        {t('Boost APR')}
                                    </Text>
                                </HStack>

                                <Switch
                                    isChecked={iZiSwitch}
                                    onChange={(e) => {
                                        setiZiSwitch(e.target.checked);
                                        dispatch.farmDynamicRangeiZiAddLiquidity.updateTokeniZiAmount({
                                            amountDecimal: 0,
                                            positionPoolKey: mintForm.positionPoolKey,
                                            chainId,
                                        });
                                    }}
                                    size="md"
                                ></Switch>
                            </HStack>
                        </Stack>

                        {iZiSwitch && (
                            <Stack
                                direction={isChangeLine ? 'column' : 'row'}
                                bg={colorTheme('#FDFDFD', '#423854')}
                                pt="16px"
                                w="100%"
                                boxShadow={colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', 'undefined')}
                                borderRadius="6px"
                                px="30px"
                                pb="16px"
                                mt="25px !important"
                                alignItems="center"
                                p={isChangeLine ? '14px 20px 14px 25px' : '14px 20px 14px 40px'}
                            >
                                <VStack
                                    w={isChangeLine ? '100%' : 'unset'}
                                    alignItems={isChangeLine ? 'center' : 'start'}
                                    direction={isChangeLine ? 'column' : 'row'}
                                >
                                    <Stack direction="row" h="35px" justifyContent="space-between" alignItems="center">
                                        <Text color={colorTheme('tertiary.500', 'tertiary.300')} variant="caption">
                                            {t('Stake iZi to boost APR')}
                                        </Text>
                                        <Tooltip
                                            label={`Token ${TokenSymbol.IZI} will be locked in the mining contract and boost up to 2.5X APR.`}
                                        >
                                            <IconButton
                                                size="sm"
                                                aria-label=""
                                                variant="ghost"
                                                isRound={true}
                                                icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                                            />
                                        </Tooltip>
                                    </Stack>
                                    <Stack direction={isChangeLine ? 'column' : 'row'}>
                                        {[1.5, 2.5].map((item, index) => {
                                            return (
                                                <CustomButton
                                                    key={index}
                                                    variant="outlinePurple"
                                                    w="147px"
                                                    h="40px"
                                                    selected={false}
                                                    onClick={() => {
                                                        const boostRate = item;
                                                        const vLiquidity = mintForm.vLiquidity;
                                                        console.log('vliquidity: ', vLiquidity);
                                                        const totalVLiquidity = poolEntry.data.vLiquidity + vLiquidity;
                                                        console.log('origin vliquidity: ', poolEntry.data.vLiquidity);
                                                        console.log('total vliquidity: ', totalVLiquidity);
                                                        const totalNIZI = poolEntry.data.totalNIZI;
                                                        console.log('total nizi: ', totalNIZI);
                                                        let neededNiZi = niZiForDesiredBoost(
                                                            vLiquidity,
                                                            totalVLiquidity,
                                                            totalNIZI,
                                                            boostRate
                                                        );
                                                        if (neededNiZi < 0) {
                                                            neededNiZi = 0;
                                                        }
                                                        const neededNiZiDecimal = amount2Decimal(new BigNumber(neededNiZi), iZiToken) ?? 0;

                                                        dispatch.farmDynamicRangeiZiAddLiquidity.updateTokeniZiAmount({
                                                            amountDecimal: neededNiZiDecimal,
                                                            positionPoolKey: mintForm.positionPoolKey,
                                                            chainId,
                                                        });
                                                    }}
                                                    text={
                                                        <Text
                                                            className={i_text_copy_bold}
                                                            variant="caption"
                                                            fontSize="16px"
                                                            color={colorTheme('tertiary.800', 'tertiary.50')}
                                                        >
                                                            {item.toFixed(1)}x
                                                        </Text>
                                                    }
                                                />
                                            );
                                        })}
                                    </Stack>

                                    <VStack
                                        bg={colorTheme('#fff', '#34294A')}
                                        boxShadow={colorTheme(
                                            '0px 0px 9px 5px rgba(240, 226, 254, 0.25)',
                                            '0px 0px 27px 5px rgba(29, 6, 51, 0.25)'
                                        )}
                                        borderRadius="6px"
                                        border={colorTheme('0.5px solid #DDDAE1', '1px solid #8664CE')}
                                        pb="10px"
                                    >
                                        <Stack
                                            direction={isChangeLine ? 'column' : 'row'}
                                            h={isChangeLine ? 'unset' : '31px'}
                                            px="17px"
                                            justifyContent="space-between"
                                            w="100%"
                                            borderBottom={colorTheme('1px solid', '0.5px solid')}
                                            borderColor={colorTheme('tertiary.100', 'tertiary.600')}
                                            alignItems="center"
                                        >
                                            <IconWithSymbol mt={isChangeLine ? '10px' : 'unset'} token={iZiToken} />

                                            <Text
                                                color="tertiary.300"
                                                variant="caption"
                                                w={isChangeLine ? '100%' : 'unset'}
                                                pb={isChangeLine ? '10px' : 'unset'}
                                                textAlign={isChangeLine ? 'center' : 'unset'}
                                            >
                                                {formatNumber(accountModel.tokenBalance[TokenSymbol.IZI]?.[0] ?? 0)}
                                            </Text>
                                        </Stack>

                                        <VStack>
                                            <CustomNumberInput
                                                inputValue={Number(mintForm.iZiAmountDecimal).toFixed(4)}
                                                onBlur={(value) => {
                                                    isNumeric(value) &&
                                                        dispatch.farmDynamicRangeiZiAddLiquidity.updateTokeniZiAmount({
                                                            amountDecimal: value as number,
                                                            positionPoolKey: mintForm.positionPoolKey,
                                                            chainId,
                                                        });
                                                }}
                                                errorInfo={
                                                    mintForm.iZiAmountDecimal <= accountModel.tokenBalance[TokenSymbol.IZI]?.[0]
                                                        ? ''
                                                        : 'Not enough token'
                                                }
                                                w={isChangeLine ? '100%' : '300px'}
                                            />
                                            <HStack w={isChangeLine ? '90%' : 'unset'}>
                                                <CustomButton
                                                    w={isChangeLine ? '50%' : '88px'}
                                                    h="24px"
                                                    variant="tertiary"
                                                    text="Clear"
                                                    fontClass={i_text_copy_bold}
                                                    onClick={() => {
                                                        dispatch.farmDynamicRangeiZiAddLiquidity.updateTokeniZiAmount({
                                                            amountDecimal: 0,
                                                            positionPoolKey: mintForm.positionPoolKey,
                                                            chainId,
                                                        });
                                                    }}
                                                />
                                                <CustomButton
                                                    w={isChangeLine ? '50%' : '88px'}
                                                    h="24px"
                                                    variant="primary2"
                                                    text="All"
                                                    fontClass={i_text_copy_bold}
                                                    onClick={() => {
                                                        dispatch.farmDynamicRangeiZiAddLiquidity.updateTokeniZiAmount({
                                                            amountDecimal: accountModel.tokenBalance[TokenSymbol.IZI]
                                                                ? accountModel.tokenBalance[TokenSymbol.IZI]?.[0] * FARM_CONFIG.ALL_FACTOR
                                                                : 0,
                                                            positionPoolKey: mintForm.positionPoolKey,
                                                            chainId,
                                                        });
                                                    }}
                                                />
                                            </HStack>
                                        </VStack>
                                    </VStack>
                                </VStack>

                                <Stack w={isChangeLine ? 'unset' : '300px'}>
                                    <Center mt="20px !important">
                                        <VStack alignItems="center">
                                            <Text variant="caption" color="tertiary.400">
                                                {t('Current APR')}
                                            </Text>
                                            <Text variant="caption" color={colorTheme('tertiary.700', 'tertiary.100')} fontSize="18px">
                                                {formatNumber(mintForm.apr * 100)}%
                                            </Text>

                                            <HStack mt="16px !important" mb="12px !important">
                                                <Image h="13px" w="11px" src="/assets/farm/lightToV.svg" />
                                                <Text variant="caption" color={colorTheme('secondary.600', 'secondary.300')}>
                                                    {mintForm.apr > 0 ? formatNumber(mintForm.aprBoostIZI / mintForm.apr, 0, 2) : '1.0'}x
                                                </Text>
                                            </HStack>

                                            <Text variant="caption" color={colorTheme('secondary.600', 'secondary.300')}>
                                                Boosted APR
                                            </Text>

                                            <Text variant="caption-bold" color={colorTheme('tertiary.700', 'tertiary.100')} fontSize="24px">
                                                {formatNumber(mintForm.aprBoostIZI * 100)}%
                                            </Text>
                                        </VStack>
                                    </Center>
                                </Stack>
                            </Stack>
                        )}
                    </>
                )}

                <Stack w="100%">
                    {[
                        tokenAAllApproved,
                        tokenBAllApproved,
                        totaliZiAmount === 0 || (totaliZiAmount > 0 && tokeniZiEntity.isApproved(String(totaliZiAmount))),
                    ].every(identity) ? (
                        <>
                            <CustomButton
                                disabled={
                                    totaliZiAmountDecimal > accountModel.tokenBalance[TokenSymbol.IZI]?.[0] ||
                                    tokenAAmountDecimal > accountModel.tokenBalance[poolEntry.meta.tokenA.symbol]?.[0] ||
                                    tokenBAmountDecimal > accountModel.tokenBalance[poolEntry.meta.tokenB.symbol]?.[0] ||
                                    priceDiffRate >= 0.2
                                }
                                variant="purple"
                                text={t('Add Liquidity')}
                                fontClass={i_text_copy_bold}
                                w={isChangeLine ? '100%' : '300px'}
                                mx="auto"
                                h="51px"
                                mt="30px !important"
                                onClick={() => {
                                    const chain = getChain(chainId);
                                    const toastLink = {} as ToastLink;
                                    dynamicRangeEntity.deposit(
                                        {
                                            amount0Desired: new BigNumber(tokenAAmount).toFixed(0),
                                            amount1Desired: new BigNumber(tokenBAmount).toFixed(0),
                                            numIZI: new BigNumber(iZiAmount).toFixed(0),
                                        } as DynamicRangeiZiDepositParams,
                                        {
                                            onTransactionHash: (e: any) => {
                                                if (chain) {
                                                    toastLink.title = 'View on ' + chain.name;
                                                    toastLink.link = getTxLink(e, chain);
                                                }
                                                toast('info', t('Add Liquidity') + ': ' + e, undefined, toastLink);
                                            },
                                            then: (e: any) => {
                                                if (poolEntry.meta.tokenA.symbol !== TokenSymbol.IZI) {
                                                    tokenAEntity.handleApproveSuccess();
                                                }
                                                if (poolEntry.meta.tokenB.symbol !== TokenSymbol.IZI) {
                                                    tokenBEntity.handleApproveSuccess();
                                                }
                                                tokeniZiEntity.handleApproveSuccess();
                                                console.log(e);
                                                toast('success', t('Add Liquidity') + ' successfully', undefined, toastLink);
                                                refreshPoolListDataAndPosition?.();
                                            },
                                            catch: (e: any) => {
                                                console.log(e);
                                                toast('error', typeof e === 'string' ? e : e.message);
                                            },
                                        } as MiningCallbacks,
                                        gasPrice
                                    );
                                }}
                            />
                            {priceDiffRate >= 0.2 && (
                                <VStack
                                    bg={colorTheme('#fff', '#34294A')}
                                    boxShadow={colorTheme(
                                        '0px 0px 9px 5px rgba(240, 226, 254, 0.25)',
                                        '0px 0px 27px 5px rgba(29, 6, 51, 0.25)'
                                    )}
                                    borderRadius="6px"
                                    border={colorTheme('0.5px solid #BB420F', '1px solid #D97E7E')}
                                    pb="10px"
                                    pt="10px"
                                    px="10px"
                                    m="0"
                                >
                                    <Text variant="caption">
                                        {/* {priceDiffRate} */}
                                        The current price fluctuates sharply, please wait for the price to stabilize to avoid impermanent
                                        loss.
                                    </Text>
                                </VStack>
                            )}
                        </>
                    ) : (
                        <Stack direction={isMobile ? 'column' : 'row'} spacing="20px" mt="30px">
                            <ApproveTokenButton
                                tokenEntity={tokenAEntity}
                                hidden={tokenAEntity.isApproved(String(tokenAAmount)) || poolEntry.meta.tokenA.symbol === TokenSymbol.IZI}
                                approveAmount={String(tokenAAmount)}
                                mt="10px !important"
                                w="200px"
                                mx="auto !important"
                            />

                            {!tokenANotWrapToken && (
                                <CustomButton
                                    hidden={tokenAEntity.isDepositApproved() || !account || !poolEntry.meta.tokenA.symbol}
                                    mt="10px !important"
                                    variant="purple"
                                    text={t('Approve Deposit') + ' ' + poolEntry.meta.tokenA.symbol}
                                    w="200px"
                                    h="51px"
                                    fontSize="12px"
                                    mx="auto !important"
                                    onClick={() => {
                                        const chain = getChain(chainId);
                                        const toastLink = {} as ToastLink;
                                        tokenAEntity
                                            .handleDepositApprove()
                                            .on('transactionHash', (hash: string) => {
                                                if (chain) {
                                                    toastLink.title = 'View on ' + chain.name;
                                                    toastLink.link = getTxLink(hash, chain);
                                                }
                                                toast('info', 'Ongoing', undefined, toastLink);
                                            })
                                            .then(() => {
                                                toast('success', 'Deposit approve successfully', undefined, toastLink);
                                                tokenAEntity.handleDepositApproveSuccess();
                                            })
                                            .catch((e: any) => {
                                                console.info('error:  ', e.message);
                                            });
                                    }}
                                />
                            )}

                            <ApproveTokenButton
                                tokenEntity={tokenBEntity}
                                hidden={tokenBEntity.isApproved(String(tokenBAmount)) || poolEntry.meta.tokenB.symbol === TokenSymbol.IZI}
                                approveAmount={String(tokenBAmount)}
                                mt="10px !important"
                                w="200px"
                                mx="auto !important"
                            />

                            {!tokenBNotWrapToken && (
                                <CustomButton
                                    hidden={tokenBEntity.isDepositApproved() || !account || !poolEntry.meta.tokenB.symbol}
                                    mt="10px !important"
                                    variant="purple"
                                    text={t('Approve Deposit') + ' ' + poolEntry.meta.tokenB.symbol}
                                    w="200px"
                                    h="51px"
                                    fontSize="12px"
                                    mx="auto !important"
                                    onClick={() => {
                                        const chain = getChain(chainId);
                                        const toastLink = {} as ToastLink;
                                        tokenBEntity
                                            .handleDepositApprove()
                                            .on('transactionHash', (hash: string) => {
                                                if (chain) {
                                                    toastLink.title = 'View on ' + chain.name;
                                                    toastLink.link = getTxLink(hash, chain);
                                                }
                                                toast('info', 'Ongoing', undefined, toastLink);
                                            })
                                            .then(() => {
                                                toast('success', 'Deposit approve successfully', undefined, toastLink);
                                                tokenBEntity.handleDepositApproveSuccess();
                                            })
                                            .catch((e: any) => {
                                                console.info('error:  ', e.message);
                                            });
                                    }}
                                />
                            )}

                            {(iZiAmount > 0 ||
                                poolEntry.meta.tokenB.symbol === TokenSymbol.IZI ||
                                poolEntry.meta.tokenA.symbol === TokenSymbol.IZI) && (
                                <ApproveTokenButton
                                    tokenEntity={tokeniZiEntity}
                                    approveAmount={String(totaliZiAmount)}
                                    mt="10px !important"
                                    w="200px"
                                    mx="auto !important"
                                />
                            )}
                        </Stack>
                    )}
                </Stack>
            </VStack>
        </Modal>
    );
};

export default AddLiquidityModal;
