import React from 'react';
import { Image, Center, VStack, useColorMode, Text, BoxProps, Tooltip, HStack } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../utils/funcs';
import { i_h3 } from '../../style';
import chains from '../../config/chains';
import useIsChainValid from '../../hooks/useIsChainValid';
import { ReactElement } from 'react';

type Props = {
    app: 'all' | 'farm' | 'trade' | 'veToken' | 'pools';
    content: ReactElement;
} & BoxProps;

const ChainNotValidBlockWrapper: React.FC<Props> = (props) => {
    const { app, content, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isChainValid = useIsChainValid(app);

    return isChainValid ? (
        <>{content}</>
    ) : (
        <Center w="100%" h="400px" alignItems="center" {...rest}>
            <VStack spacing="40px" w="90%">
                <HStack spacing="20px">
                    {chains[app].map((e, i) => {
                        return (
                            <Tooltip key={i} label={e.name}>
                                <Image boxSize="40px" src={process.env.PUBLIC_URL + e.icon} borderRadius="20px" />
                            </Tooltip>
                        );
                    })}
                </HStack>
                <Text
                    className={i_h3}
                    fontSize={{ base: '12px', md: '16px', xl: '22px' }}
                    color={colorTheme('tertiary.500', 'tertiary.200')}
                >
                    Oops, the current blockchain is not supported, please to the above chains:
                </Text>
            </VStack>
        </Center>
    );
};

export default ChainNotValidBlockWrapper;
