import { Box, Flex } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useIsHomePage } from '../../App';
import './AppLayout.css';

export type AppLayoutProps = {
    top: ReactElement;
    center: ReactElement;
    bottom?: ReactElement;
};

const AppLayout: React.FC<AppLayoutProps> = (props) => (
    <Box
        className="AppLayout"
        css={{
            '&::-webkit-scrollbar': {
                display: 'none !important',
            },
        }}
    >
        <Flex
            direction="column"
            w="100%"
            h={'100%'}
            overflowY="scroll"
            bgImage={useIsHomePage() ? '/assets/home/homeBg.png' : '/assets/home/Arcticbg.png'}
            bgRepeat="no-repeat"
            bgSize="cover"
            bgPosition={useIsHomePage() ? '' : 'center'}
            bgAttachment={useIsHomePage() ? '' : 'fixed'}
        >
            {props.top}
            {props.center}
            {props.bottom}
        </Flex>
    </Box>
);

export default AppLayout;
