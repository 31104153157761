import { Flex, Text, Divider, Image, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import useWindowDimensions from '../../../../iZUMi-UI-toolkit/src/hooks/useWindowDimension';
import { a_display, a_h3, a_h5 } from '../../../../iZUMi-UI-toolkit/src/style';
import { TimeScope } from '../../../Trade/Swap/components/types';
import '../../Home.css';
import { TradingChart } from './TradingChart';
const HomePiece2: React.FC = () => {
    const [selectTimeScope, setSelectTimeScope] = useState<TimeScope>(TimeScope.day);
    const { width } = useWindowDimensions();

    return (
        <Flex w="100%" direction="column" alignItems="center" justifyContent="center" mt={{ base: '0px', sm: '50px', xl: '0px' }}>
            <Flex w={{ base: '100%', xl: '1152px' }}>
                <Image w="114px" h="97px" src="/assets/home/homePiece2/leftIcon.svg"></Image>
                <Image
                    w={{ base: '267px', sm: '440px', xl: '540px' }}
                    h="159px"
                    src="/assets/home/homePiece2/piece2Title.svg"
                    ml={{ base: '-100px', sm: '-50px' }}
                    mt="20px"
                ></Image>
            </Flex>

            <Flex
                h={{ base: 'unset', sm: 'unset', xl: '477px' }}
                mt={{ base: '32px', sm: '50px' }}
                direction={{ base: 'column', sm: 'column', xl: 'row' }}
            >
                <Card
                    flexDirection="column"
                    w={{ base: '100%', sm: '100%', xl: '364px' }}
                    bg="#260B5A"
                    px="40px"
                    pb={{ base: '36px', xl: '0px' }}
                    boxShadow="unset"
                    alignItems={{ base: 'center', xl: 'start' }}
                >
                    <Flex direction="column" alignItems={{ base: 'center', xl: 'start' }}>
                        <Text className={a_h5} color="customGreen" mt="40px" letterSpacing="0.189em">
                            TOTAL VOLUME LOCKED
                        </Text>
                        <Text className={a_h3} color="tertiary.50" mt="10px">
                            $30.12m
                        </Text>
                    </Flex>
                    <Divider my="30px"></Divider>
                    <Stack direction={{ base: 'column', sm: 'row', xl: 'column' }} spacing={{ base: '40px', xl: '20px' }}>
                        <Flex direction={'column'} alignItems={{ base: 'center', sm: 'start' }}>
                            <Text className={a_display} color="tertiary.300">
                                24hrs Trading Volume
                            </Text>
                            <Text className={a_h3} fontSize="32px" color="tertiary.50" mt={{ base: '12px', sm: '8px' }}>
                                $950k
                            </Text>
                        </Flex>
                        <Flex
                            direction={'column'}
                            mt={{ base: '37px', sm: '20px', xl: '29px' }}
                            textAlign={{ base: 'center', xl: 'start' }}
                        >
                            <Text className={a_display} color="tertiary.300">
                                Total Users
                            </Text>
                            <Text className={a_h3} fontSize="32px" color="tertiary.50" mt={{ base: '12px', sm: '8px' }}>
                                600,012
                            </Text>
                        </Flex>
                        <Flex direction={'column'} mt={{ base: '37px', sm: '20px' }} textAlign={{ base: 'center', xl: 'start' }}>
                            <Text className={a_display} color="tertiary.300">
                                Capital Efficiency
                            </Text>
                            <Text className={a_h3} fontSize="32px" color="tertiary.50" mt={{ base: '12px', sm: '8px' }}>
                                97.42%
                            </Text>
                        </Flex>
                    </Stack>
                </Card>
                <TradingChart
                    series={[
                        {
                            name: '12',
                            data: [
                                [1, 2, 4],
                                [2, 5, 2],
                            ],
                        },
                    ]}
                    timeScope={selectTimeScope}
                    setTimeScope={setSelectTimeScope}
                    w={{ base: width - 40 + 'px', sm: width - 144 + 'px', xl: '764px' }}
                    h={{ base: '522px', sm: '477px', xl: 'unset' }}
                    ml={{ base: '0px', xl: '24px' }}
                    mt={{ base: '32px', xl: '0px' }}
                    boxShadow="unset"
                ></TradingChart>
            </Flex>
        </Flex>
    );
};

export default HomePiece2;
