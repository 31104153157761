import { Flex, Circle, Divider, Text, useColorMode, useInterval, useBreakpointValue, Image } from '@chakra-ui/react';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { useCustomToast } from '../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { GameEventsTypeEnum, GameUser, getGameUser, postGameJoin } from '../../../net/iZUMi-endpoints/src/restful/izumiGame';
import { useConnectModal } from '../../../providers/ConnectProvider';
import { RootDispatch, RootState } from '../../../state/store';
import { i_text_copy_bold, i_text_d, i_text_Bold, i_text_prize_16 } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { ClaimBlock } from './ClaimBlock';
import { useTranslation } from 'react-i18next';

type UserBlockProps = {
    status: number;
    startTime: number;
    endTIme: number;
    eventId: number;
    leftTime: number;
};
export const UserBlock: React.FC<UserBlockProps> = (props) => {
    const { status, eventId, leftTime } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isTablet = useBreakpointValue({ base: false, md: true });
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));
    const { prize } = useSelector((state: RootState) => state);
    const { onOpenModal: onConnect } = useConnectModal();
    const { account } = useWeb3WithDefault();
    const toast = useCustomToast();

    const [isDisableJoin, setDisableJoin] = useState(true);
    const [isJoinSuccess, setJoinSuccess] = useState(false);
    const [userData, setUserData] = useState<GameUser>();
    const [netWorkLoading, setNetWorkLoading] = useState(true);
    const [timeLeft, setTimeLeft] = useState(leftTime);
    const minutes = useMemo(() => Math.ceil((timeLeft / 60) % 60), [timeLeft]);
    const hours = useMemo(() => Math.max(Math.floor((timeLeft / (60 * 60)) % 24), 0), [timeLeft]);
    const days = useMemo(() => Math.max(Math.floor(timeLeft / (60 * 60 * 24)), 0), [timeLeft]);
    const [runOnce, setRunOnce] = useState(true);
    useEffect(() => {
        setTimeLeft(leftTime);
        setRunOnce(true);
    }, [leftTime]);
    useInterval(() => {
        if (timeLeft === 0 && runOnce) {
            dispatch.prize.setEventState(!prize.eventStateChange);
            setRunOnce(false);
        }
        if (timeLeft > 0) {
            setRunOnce(true);
        }
        setTimeLeft(timeLeft > 0 ? timeLeft - 1 : 0);
    }, 1000);
    useEffect(() => {
        if (account) {
            setNetWorkLoading(true);
            getGameUser({
                address: account,
                id: eventId,
            })
                .then((r) => {
                    setNetWorkLoading(false);
                    setUserData(r.data.data.data);
                    //If it returns null, it means that the user has not participated in the activity
                    if (Object.keys(r.data.data.data).length !== 0) {
                        setDisableJoin(true);
                    } else {
                        if (status === GameEventsTypeEnum.UPCOMING || status === GameEventsTypeEnum.LIVE) {
                            setDisableJoin(false);
                        } else {
                            setDisableJoin(true);
                        }
                    }
                })
                .catch(() => {
                    setDisableJoin(true);
                });
        }
    }, [account, eventId, status, dispatch.prize, isJoinSuccess]);

    const ethereum = (window as WindowChain).ethereum;
    const personalSign = async () => {
        let isSign = false;
        const exampleMessage = 'To avoid digital burglars, sign below to authenticate with izumi';
        try {
            const from = account;
            const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
            if (ethereum && ethereum.request) {
                await ethereum.request({
                    method: 'personal_sign',
                    params: [msg, from, 'Example password'],
                });
                isSign = true;
                return true;
            }
        } catch (err) {
            console.error(err);
            isSign = false;
            return false;
        }
        return isSign;
    };

    const JoinText = useMemo(() => {
        if (status === GameEventsTypeEnum.ENDED || status === GameEventsTypeEnum.STOP) {
            return 'Closed';
        }
        if (isDisableJoin) {
            return 'Already Joined';
        }
        if (status === GameEventsTypeEnum.UPCOMING || status === GameEventsTypeEnum.LIVE) {
            return 'Join Now';
        } else {
            return 'Not available';
        }
    }, [status, isDisableJoin]);

    return (
        <Card
            w={{ base: '373px', md: '675px', lg: '794px' }}
            h={account ? { base: '394px', md: '240px', lg: '224px' } : { base: '220px', md: '151px', lg: '86px' }}
            mt={{ base: '0px', lg: '-43px' }}
            bgColor={colorTheme('#ffffff', '#211834')}
        >
            <Flex w="100%" h="100%" direction="column">
                <Flex
                    h={{ base: '123px', lg: '100%' }}
                    direction={{ base: 'column', md: 'row' }}
                    alignItems="center"
                    pl={{ base: '20px', lg: '40px' }}
                    pr="17px"
                >
                    <Flex mt={{ base: '20px', md: '0px' }} alignItems="center">
                        <Text w="150px" className={i_text_prize_16} color={colorTheme('secondary.500', 'secondary.400')}>
                            {status === GameEventsTypeEnum.ENDED || status === GameEventsTypeEnum.STOP
                                ? t('Event has ended')
                                : status === GameEventsTypeEnum.UPCOMING
                                ? t('Event will start at') + ' :'
                                : t('Event will end at') + ' :'}
                        </Text>
                        <Flex w="140px">
                            <Flex w="33%" direction="column" textAlign="center">
                                <Text className={i_text_Bold} fontWeight="700">
                                    {days}
                                </Text>
                                <Text className={i_text_copy_bold} color="#9B8FAE" mt="5px">
                                    {t('D')}
                                </Text>
                            </Flex>
                            <Flex w="33%" direction="column" textAlign="center">
                                <Text className={i_text_Bold} fontWeight="700">
                                    {hours}
                                </Text>
                                <Text className={i_text_copy_bold} color="#9B8FAE" mt="5px">
                                    {t('H')}
                                </Text>
                            </Flex>
                            <Flex w="33%" direction="column" textAlign="center">
                                <Text className={i_text_Bold} fontWeight="700">
                                    {minutes}
                                </Text>
                                <Text className={i_text_copy_bold} color="#9B8FAE" mt="5px">
                                    {t('M')}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    {account ? (
                        <Flex
                            className={i_text_copy_bold}
                            ml={{ base: 'unset', md: 'auto' }}
                            mr="15px"
                            mt={{ base: '10px', md: '0px' }}
                            alignItems="center"
                        >
                            {netWorkLoading ? (
                                <Flex
                                    w={{ base: '155px', md: '115px', lg: '155px' }}
                                    h="48px"
                                    mx={{ base: '15px', md: '20px', lg: '25px' }}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Image w="20px" h="20px" src="/assets/gallery/loading.gif" fallbackSrc="/assets/gallery/loading.gif" />
                                </Flex>
                            ) : (
                                <CustomButton
                                    className={i_text_copy_bold}
                                    variant="purple"
                                    w={{ base: '155px', md: '115px', lg: '155px' }}
                                    h="48px"
                                    mx={{ base: '15px', md: '20px', lg: '25px' }}
                                    text={t(JoinText)}
                                    onClick={async () => {
                                        if (await personalSign()) {
                                            postGameJoin({
                                                address: account,
                                                id: eventId,
                                            }).then((r) => {
                                                if (r.data.data.joined) {
                                                    toast('info', 'Join successfully.');
                                                    setJoinSuccess(true);
                                                    setDisableJoin(true);
                                                } else {
                                                    setJoinSuccess(false);
                                                    toast('info', 'Failed, Please check your account or network.');
                                                }
                                            });
                                        } else {
                                            toast('info', 'Failed.');
                                        }
                                    }}
                                    disabled={isDisableJoin}
                                ></CustomButton>
                            )}
                            <Flex direction="column" alignItems="center">
                                <Circle
                                    size="16px"
                                    bg={
                                        status > GameEventsTypeEnum.UPCOMING
                                            ? 'secondary.200'
                                            : status === GameEventsTypeEnum.UPCOMING
                                            ? 'secondary.500'
                                            : 'tertiary.200'
                                    }
                                    borderColor="secondary.500"
                                ></Circle>

                                <Text
                                    mt="8px"
                                    color={
                                        status > GameEventsTypeEnum.UPCOMING
                                            ? 'secondary.200'
                                            : status === GameEventsTypeEnum.UPCOMING
                                            ? 'secondary.500'
                                            : ''
                                    }
                                >
                                    {t('Entry')}
                                </Text>
                            </Flex>
                            <Divider w={{ base: '30px', md: '40px' }} mb="20px" mx="3px" borderColor="tertiary.200"></Divider>

                            <Flex direction="column" alignItems="center">
                                <Circle
                                    size="16px"
                                    bg={
                                        status > GameEventsTypeEnum.LIVE
                                            ? 'secondary.200'
                                            : status === GameEventsTypeEnum.LIVE
                                            ? 'secondary.500'
                                            : 'tertiary.200'
                                    }
                                    borderColor="secondary.500"
                                    color="secondary.500"
                                ></Circle>

                                <Text
                                    color={
                                        status > GameEventsTypeEnum.LIVE
                                            ? 'secondary.200'
                                            : status === GameEventsTypeEnum.LIVE
                                            ? 'secondary.500'
                                            : ''
                                    }
                                    mt="8px"
                                >
                                    {t('Live')}
                                </Text>
                            </Flex>

                            <Divider w={{ base: '30px', md: '40px' }} mb="20px" mx="3px" borderColor="tertiary.200"></Divider>
                            <Flex direction="column" alignItems="center">
                                <Circle
                                    size="16px"
                                    bg={status === GameEventsTypeEnum.ENDED ? 'secondary.500' : 'tertiary.200'}
                                    borderColor="secondary.500"
                                    color="secondary.500"
                                ></Circle>

                                <Text color={status === GameEventsTypeEnum.ENDED ? 'secondary.500' : ''} mt="8px">
                                    {t('End')}
                                </Text>
                            </Flex>
                        </Flex>
                    ) : (
                        <CustomButton
                            w="195px"
                            h="51px"
                            ml={{ base: 'unset', lg: 'auto' }}
                            mt={{ base: '50px', md: '16px', lg: '0px' }}
                            variant="purple"
                            className={i_text_copy_bold}
                            text="Connect Wallet"
                            onClick={onConnect as unknown as any}
                        ></CustomButton>
                    )}
                </Flex>
                {account && (
                    <Flex
                        w="100%"
                        h={{ base: '271px', lg: '139px' }}
                        direction={{ base: 'column', md: 'row' }}
                        pl={{ base: '20px', lg: '40px' }}
                        pr="17px"
                        py="15px"
                        bg={colorTheme('linear-gradient(180deg, #F1F1FF 0%, #FAFAFF 100%)', '#211834')}
                        mt={{ base: '13px', md: '0px' }}
                    >
                        <Flex w={{ base: '100%', md: '40%', lg: '47%' }} alignItems="center">
                            <Flex w={{ base: '170px', md: '140px', lg: '170px' }} direction="column">
                                <Flex direction="column" mt="8px">
                                    <Text className={i_text_prize_16} color={colorTheme('secondary.500', 'secondary.400')}>
                                        {t('My Score')} :
                                    </Text>
                                    {netWorkLoading ? (
                                        <Image
                                            boxSize="24px"
                                            mt="5px"
                                            src="/assets/gallery/loading.gif"
                                            fallbackSrc="/assets/gallery/loading.gif"
                                        />
                                    ) : (
                                        <Text className={i_text_d} h="24px" mt="5px">
                                            {userData?.score ? userData.score : '-'}
                                        </Text>
                                    )}
                                </Flex>
                                <Flex direction="column" mt="16px">
                                    <Text className={i_text_prize_16} color={colorTheme('secondary.500', 'secondary.400')}>
                                        {t('Trading Volume')}
                                    </Text>
                                    {netWorkLoading ? (
                                        <Image
                                            boxSize="24px"
                                            mt="5px"
                                            src="/assets/gallery/loading.gif"
                                            fallbackSrc="/assets/gallery/loading.gif"
                                        />
                                    ) : (
                                        <Text className={i_text_d} h="24px" mt="5px">
                                            {userData?.volume ? userData.volume : '-'}
                                        </Text>
                                    )}
                                </Flex>
                            </Flex>

                            <Flex w={{ base: '150px', md: '100px', lg: '170px' }} direction="column">
                                <Flex direction="column" mt="8px">
                                    <Text className={i_text_prize_16} color={colorTheme('secondary.500', 'secondary.400')}>
                                        {t('My Reward')}
                                    </Text>
                                    {netWorkLoading ? (
                                        <Image
                                            boxSize="24px"
                                            mt="5px"
                                            src="/assets/gallery/loading.gif"
                                            fallbackSrc="/assets/gallery/loading.gif"
                                        />
                                    ) : (
                                        <Text className={i_text_d} h="24px" mt="5px">
                                            {userData?.reward ? userData.reward : '-'}
                                        </Text>
                                    )}
                                </Flex>
                                <Flex direction="column" mt="16px">
                                    <Text className={i_text_prize_16} color={colorTheme('secondary.500', 'secondary.400')}>
                                        {t('Active Index')}
                                    </Text>
                                    {netWorkLoading ? (
                                        <Image
                                            boxSize="24px"
                                            mt="5px"
                                            src="/assets/gallery/loading.gif"
                                            fallbackSrc="/assets/gallery/loading.gif"
                                        />
                                    ) : (
                                        <Text className={i_text_d} h="24px" mt="5px">
                                            {userData?.activityIndex ? userData.activityIndex : '-'}
                                        </Text>
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                        {isTablet && (
                            <Flex h="100%" alignItems="center">
                                <Divider h="108px" orientation="vertical" border="1px solid #DDDAE1"></Divider>
                            </Flex>
                        )}
                        <ClaimBlock></ClaimBlock>
                    </Flex>
                )}
            </Flex>
        </Card>
    );
};
