import { Box, Stack } from '@chakra-ui/layout';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { MdAddChart } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Button, useColorMode, Icon, Text, HStack, Center } from '@chakra-ui/react';
import JumpButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/JumpButton/JumpButton';
import { Heading } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import SwapForm from './SwapForm';
import { i_h3, i_text_copy } from '../../../style';
import Tabs from '../components/Tabs';
import AdvanceSwap from './AdvanceSwap';
import SwapSetting from './components/SwapSetting';
import useIsMobile from '../../../hooks/useIsMobile';
import PageLayout from '../../../components/layout/PageLayout';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { RootDispatch, RootState } from '../../../state/store';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import ChainNotValidBlockWrapper from '../../components/ChainNotValidBlock';
import { getColorThemeSelector } from '../../../utils/funcs';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { LayoutStateEnum } from '../../../state/models/pageLayout/pageLayout';
import { WarningInfo } from '../../../components/WarningInfo/WarningInfo';

const Swap: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { tradeMode } = useSelector((state: RootState) => state);
    const layoutType = LayoutStateEnum.header;

    const isSimpleMode = tradeMode.isSimpleMode;
    const [showWarning, setShowWarning] = useState(true);

    const setIsSimpleMode = useCallback(
        (e: boolean) => {
            dispatch.tradeMode.setIsSimpleMode(e);
        },
        [dispatch]
    );
    const location = useLocation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const oneLineMode = useBreakpointValue({ base: false, xl: true })!;

    useEffect(() => {
        const locationState = location.state as any;
        if (locationState) {
            setIsSimpleMode(false);
        }
        document.title = 'Exchange | Arctic';
    }, []);
    const {
        tradeSwap: { swapForm },
    } = useSelector((state: RootState) => state);

    const tabValue = 'Swap';
    const handleChangeTab = (value: string) => {
        if (value === 'Limit Order') {
            history.push('/trade/limit');
        }
    };
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Center pt="7px" w="100%">
                            <SwapForm type="mobile" />
                        </Center>
                    }
                    app="trade"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                isSimpleMode ? (
                    <Box h="0px"></Box>
                ) : (
                    <PageHeader
                        headerTitle={
                            <HStack spacing="20px">
                                <Heading level="3" fontSize={oneLineMode ? '24px' : '20px'}>
                                    {t('Exchange')}
                                </Heading>
                                <Box h="60%" w="3px" bg={colorTheme('tertiary.200', 'tertiary.200')} />
                                <Text className={i_h3} fontSize={oneLineMode ? '23px' : '19px'} color={'greenLight'}>
                                    {t('Swap')}
                                </Text>
                                <CustomButton
                                    w="60px"
                                    h="25px"
                                    className={i_text_copy}
                                    fontWeight="600"
                                    bg="linear-gradient(93.67deg, rgb(50, 169, 201) -21.64%, rgb(50, 201, 165) 138.94%)"
                                    color="#FFFFFF"
                                    borderRadius="3px"
                                    onClick={() => {
                                        history.push('/pro/');
                                    }}
                                    text="Pro"
                                    leftIcon={'/assets/swap/extraLink.svg'}
                                    ml={{ base: '8px !important', lg: '14px !important' }}
                                ></CustomButton>
                            </HStack>
                        }
                        subtitle={t('Swap tokens in an instant.')}
                        element={
                            <HStack spacing="10px">
                                <SwapSetting
                                    w="34px"
                                    h="34px"
                                    exclusiveLiquidity={swapForm.exclusiveLiquidity}
                                    slippage={swapForm.slippagePercent}
                                    maxDelay={swapForm.maxDelay}
                                    quoterType={swapForm.quoterType}
                                    handleSetSlippage={dispatch.tradeSwap.setSwapFormSlippagePercent}
                                    handleSetMaxDelay={dispatch.tradeSwap.setSwapFormMaxDelay}
                                    handleSetQuoterType={dispatch.tradeSwap.setSwapFormQuoterType}
                                    handleSetExclusive={dispatch.tradeSwap.setSwapFormExclusiveLiquidity}
                                />
                                <Button
                                    w="34px"
                                    h="34px"
                                    mr="10px"
                                    borderRadius="3px"
                                    bgColor={colorTheme('#EBEAEC', '#52466A')}
                                    color={colorTheme('#52466A', '#EBEAEC')}
                                    fontSize="14px"
                                    onClick={() => setIsSimpleMode(!isSimpleMode)}
                                    p="7px"
                                >
                                    <Icon as={MdAddChart} boxSize="20px" />
                                </Button>
                                <JumpButton w="150px" h="40px" text={t('Manage Liquidity')} target="/liquidity" mr="10px" />
                                <NetworkSelect />
                            </HStack>
                        }
                    />
                )
            }
            body={
                isSimpleMode ? (
                    <ChainNotValidBlockWrapper
                        content={
                            <Center pt="56px" w="100%" mt="0px !important" alignItems="start">
                                <Tabs
                                    variant={isSimpleMode ? 'simple' : 'none'}
                                    list={[
                                        {
                                            label: t('Swap'),
                                            value: 'Swap',
                                            component: <SwapForm />,
                                        },
                                        {
                                            label: t('Limit Order'),
                                            value: 'Limit Order',
                                            component: <Box>Limit Order Component </Box>,
                                        },
                                    ]}
                                    endList={[
                                        {
                                            label: t('pro'),
                                            value: 'pro',
                                            component: (
                                                <CustomButton
                                                    w="60px"
                                                    h="25px"
                                                    className={i_text_copy}
                                                    fontWeight="600"
                                                    bg="linear-gradient(93.67deg, rgb(50, 169, 201) -21.64%, rgb(50, 201, 165) 138.94%)"
                                                    color="#FFFFFF"
                                                    borderRadius="3px"
                                                    onClick={() => {
                                                        history.push('/pro/');
                                                    }}
                                                    text="Pro"
                                                    leftIcon={'/assets/swap/extraLink.svg'}
                                                ></CustomButton>
                                            ),
                                        },

                                        {
                                            label: t('Setting'),
                                            value: 'Setting',
                                            component: (
                                                <SwapSetting
                                                    h="30px"
                                                    exclusiveLiquidity={swapForm.exclusiveLiquidity}
                                                    slippage={swapForm.slippagePercent}
                                                    maxDelay={swapForm.maxDelay}
                                                    quoterType={swapForm.quoterType}
                                                    handleSetSlippage={dispatch.tradeSwap.setSwapFormSlippagePercent}
                                                    handleSetMaxDelay={dispatch.tradeSwap.setSwapFormMaxDelay}
                                                    handleSetQuoterType={dispatch.tradeSwap.setSwapFormQuoterType}
                                                    handleSetExclusive={dispatch.tradeSwap.setSwapFormExclusiveLiquidity}
                                                />
                                            ),
                                        },
                                        {
                                            label: t('Swap'),
                                            value: 'Swap',
                                            component: (
                                                <Button
                                                    maxW="38px !important"
                                                    h="30px"
                                                    borderRadius="3px"
                                                    bgColor={colorTheme('#EBEAEC', '#52466A')}
                                                    color={colorTheme('#52466A', '#EBEAEC')}
                                                    fontSize="14px"
                                                    onClick={() => setIsSimpleMode(!isSimpleMode)}
                                                >
                                                    <Icon as={MdAddChart} boxSize="20px" />
                                                </Button>
                                            ),
                                        },
                                        {
                                            label: t('Liquidity'),
                                            value: 'Liquidity',
                                            component: <JumpButton w="100px" h="30px" text={t('Liquidity')} target="/liquidity" />,
                                        },
                                        {
                                            label: t('Network'),
                                            value: 'Network',
                                            component: (
                                                <Stack bg={colorTheme('#fbfbfb', '#140E28')} color={colorTheme('#000000', '#ffffff')}>
                                                    <NetworkSelect
                                                        buttonStyle={{ h: '30px !important' }}
                                                        rightIconStyle={{ boxSize: '20px' }}
                                                    />
                                                </Stack>
                                            ),
                                        },
                                    ]}
                                    value={tabValue}
                                    handleClick={handleChangeTab}
                                />
                            </Center>
                        }
                        app="trade"
                    />
                ) : (
                    <ChainNotValidBlockWrapper
                        content={
                            <Stack>
                                <WarningInfo isShow={showWarning} onclose={setShowWarning}></WarningInfo>
                                <AdvanceSwap tabValue="Swap"></AdvanceSwap>{' '}
                            </Stack>
                        }
                        app="trade"
                    />
                )
            }
        />
    );
};

export default Swap;
