import { createModel } from '@rematch/core';
import BigNumber from 'bignumber.js';
import produce from 'immer';
import { RootModel } from '..';
import { blocksPerDay } from '../../../config/chains';
import { tokenSymbol2token } from '../../../config/tokens';
import { VETOKEN_ADDRESS } from '../../../config/veToken/veTokenContracts';
import { NftLockedResponse } from '../../../types/abis/veToken/VeToken';
import { TokenSymbol } from '../../../types/mod';
import { decodeMethodResult, getVeTokenContract } from '../../../utils/contractHelpers';
import { amount2Decimal } from '../../../utils/tokenMath';
import { getChartTimeStamps, getTotalVeTokenCurve, getVeTokenGlobalData, getVeTokenNftCurve } from './funcs';
import { 
    InitGlobalDataParams, 
    InitManageDataParams, 
    InitUserDataParams, 
    VeTokenGlobalData, 
    VeTokenManageData, 
    VeTokenNft, 
    VeTokenState, 
    VeTokenUserData 
} from './types';

export const veToken = createModel<RootModel>()({
    state: {
        globalData: {
            stakeTokenAmount: '0',
            rewardPerBlock: '0',
            totalTokenLocked: '0',
            totalTokenLockedDecimal: 0,
            totalVeToken: '0',
            totalVeTokenDecimal: 0,
            seconds4Year: (4 * 365 + 1) * 24 * 3600,
            currentTimestamp: 0,
            curveTimestamps: [],
            totalVeTokenCurve: [],
            avgLockYear: 0,
        },
        userData: {
            nftList: [],
            totalLockedDecimal: 0,
            totalVeTokenDecimal: 0,
        },
        manageData: {
            manageNftId: '0',
            manageVeTokenCurve: [],
        }
    } as VeTokenState,
    reducers: {
        setVeTokenState: (state: VeTokenState, payload: VeTokenState) => {
            return {...state, ...payload};
        },
        setGlobalData: (state: VeTokenState, globalData: VeTokenGlobalData) => produce(state, draft => {
            draft.globalData = {...globalData};
        }),
        setUserData: (state: VeTokenState, userData: VeTokenUserData) => produce(state, draft => {
            draft.userData = {...userData};
        }),
        setManageData: (state: VeTokenState, manageData: VeTokenManageData) => produce(state, draft => {
            draft.manageData = {...manageData};
        }),
    },
    effects: (dispatch) => ({
        async initGlobalData(initGlobalDataParams: InitGlobalDataParams): Promise<void> {
            const {chainId, web3} = initGlobalDataParams;
            if (!chainId || !web3) {
                return;
            }
            const veTokenAddress = VETOKEN_ADDRESS[chainId];
            const globalData = await getVeTokenGlobalData(veTokenAddress, chainId, web3);
            const currentDate = new Date(globalData.currentTimestamp * 1000);
            const curveTimestamps = getChartTimeStamps(currentDate);
            globalData.curveTimestamps = curveTimestamps;
            globalData.avgLockYear = globalData.totalVeTokenDecimal / globalData.totalTokenLockedDecimal * 4 ?? 0;
            
            if (!veTokenAddress) {
                // not support chainId
                for (let i = 0; i < globalData.curveTimestamps.length; i ++) {
                    globalData.totalVeTokenCurve.push(0);
                }
            } else {
                globalData.totalVeTokenCurve = await getTotalVeTokenCurve(veTokenAddress, globalData.curveTimestamps, chainId, web3);
            }
            dispatch.veToken.setGlobalData(globalData);
        },
        async initUserData(initUserDataParams: InitUserDataParams, rootState): Promise<void> {
            const {chainId, web3, account} = initUserDataParams;
            if (!chainId || !web3 || !account || !rootState.veToken.globalData) {
                return;
            }
            const veTokenAddress = VETOKEN_ADDRESS[chainId];
            const veTokenContract = getVeTokenContract(veTokenAddress, web3);
            if (!veTokenContract) {
                const userData = {
                    nftList: [],
                    totalLockedDecimal: 0,
                    totalVeTokenDecimal: 0,
                } as VeTokenUserData;
                dispatch.veToken.setUserData(userData);
                return;
            }

            const veTokenNum = await veTokenContract.methods.balanceOf(account).call().then((num: number) => num);
            const veTokenIdMulticall = [];
            for (let i = 0; i < veTokenNum; i ++) {
                veTokenIdMulticall.push(veTokenContract.methods.tokenOfOwnerByIndex(account, i).encodeABI());
            }
            
            const veTokenIdRawStringListResult: string[] = await veTokenContract.methods.multicall(veTokenIdMulticall).call().then((idList: string[]) => idList);
            const veTokenIdStringList: string[] = veTokenIdRawStringListResult.map((id: string)=>new BigNumber(id).toFixed(0));

            const stakedNftBN = new BigNumber(await veTokenContract.methods.stakedNft(account).call());
            const stakedNftId = stakedNftBN.toFixed(0);
            
            if (stakedNftId !== String('0')) {
                veTokenIdStringList.push(stakedNftId);
            }
            const nftLockedMulticallData = veTokenIdStringList.map((id:string)=>veTokenContract.methods.nftLocked(id).encodeABI());
            const nftLockedResponse = await veTokenContract.methods.multicall(nftLockedMulticallData).call();
            const nftLocked: NftLockedResponse[] = nftLockedResponse.map((data:string)=>{
                const t: NftLockedResponse = decodeMethodResult(veTokenContract, 'nftLocked', data);
                return t;
            });
            const nftList: VeTokenNft[] = [];


            const currentTimestamp = rootState.veToken.globalData.currentTimestamp;
            const seconds4Year = rootState.veToken.globalData.seconds4Year;
            const blockNum4Year = blocksPerDay(chainId) * 365;

            let totalLockedDecimal = 0;
            let totalVeTokenDecimal = 0;
            for (let i = 0; i < nftLocked.length; i ++) {
                const item = nftLocked[i];
                const nftId = veTokenIdStringList[i];
                const lockAmountStr = item.amount;
                const lockAmountDecimal: number = amount2Decimal(new BigNumber(lockAmountStr), tokenSymbol2token(TokenSymbol.ARC, chainId)) ?? 0;
                const timeRemain = Math.max(0, Number(item.end) - currentTimestamp);
                const veTokenDecimal = lockAmountDecimal * timeRemain / seconds4Year;

                let pendingRewardDecimal = 0;
                const isStaked = (nftId === stakedNftId);
                let apr = 0;
                if (isStaked) {
                    const pendingReward = await veTokenContract.methods.pendingRewardOfToken(nftId).call();
                    pendingRewardDecimal = amount2Decimal(new BigNumber(pendingReward), tokenSymbol2token(TokenSymbol.ARC, chainId)) ?? 0;
                    if (rootState.veToken.globalData.stakeTokenAmount === '0') {
                        apr = 0;
                    } else {
                        apr = Number(new BigNumber(blockNum4Year).times(rootState.veToken.globalData.rewardPerBlock).div(rootState.veToken.globalData.stakeTokenAmount));
                    }
                }

                const nft = {
                    lockAmountDecimal,
                    veTokenDecimal,
                    nftId,
                    isStaked,
                    endTimestamp: Number(item.end),
                    owner: account,
                    pendingRewardDecimal,
                    apr,
                } as VeTokenNft;
                nftList.push(nft);
                totalLockedDecimal += lockAmountDecimal;
                totalVeTokenDecimal += veTokenDecimal;
            }
            const userData = {
                nftList,
                totalLockedDecimal,
                totalVeTokenDecimal,
            } as VeTokenUserData;
            dispatch.veToken.setUserData(userData);
        },
        async initManageData(initManageDataParams: InitManageDataParams, rootState): Promise<void> {
            const {chainId, web3, account, manageNftId} = initManageDataParams;
            if (!chainId || !web3 || !account || !rootState.veToken.globalData || !rootState.veToken.userData) {
                return;
            }
            const manageNft = rootState.veToken.userData.nftList.find((e)=>{return e.nftId === manageNftId;});
            if (!manageNft) {
                const manageVeTokenCurve = [];
                for (let i = 0; i < rootState.veToken.globalData.curveTimestamps.length; i ++) {
                    manageVeTokenCurve.push(0);
                }
                dispatch.veToken.setManageData({manageNftId, manageVeTokenCurve} as VeTokenManageData);
            }
            const veTokenAddress = VETOKEN_ADDRESS[chainId];
            const manageVeTokenCurve = await getVeTokenNftCurve(veTokenAddress, manageNftId, rootState.veToken.globalData.curveTimestamps, chainId, web3);
            dispatch.veToken.setManageData({manageNftId, manageVeTokenCurve} as VeTokenManageData);
        },
        async initAll(initUserDataParams: InitUserDataParams): Promise<void> {
            const {chainId, web3, account} = initUserDataParams;
            await dispatch.veToken.initGlobalData({chainId, web3} as InitGlobalDataParams);
            await dispatch.veToken.initUserData({chainId, web3, account} as InitUserDataParams);
        }
    }),
});