import { ChevronDownIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Flex, Tooltip, Text, useColorMode, Image, useDisclosure, Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { tokenSymbol2token } from '../../../config/tokens';
import { usePrizeContract } from '../../../hooks/useContracts';
import { useIZiToken } from '../../../hooks/useiZiToken';
import { usePrizeToken } from '../../../hooks/usePrizeToken';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useCustomToast } from '../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { PrizeBalanceParams, PrizeClaimParams } from '../../../state/models/prize/prize';
import { RootDispatch, RootState } from '../../../state/store';
import { i_text_copy_bold, i_text_prize_16, i_h4, i_text_copy } from '../../../style';
import { PrizeBalanceRequest } from '../../../types/abis/prize/Prize';
import { getColorThemeSelector } from '../../../utils/funcs';
import HistoryModal from '../components/HistoryModal';

export const ClaimBlock: React.FC = () => {
    const { isOpen: isHistoryOpen, onOpen: onHistoryOpen, onClose: onHistoryClose } = useDisclosure();
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const { prize } = useSelector((state: RootState) => state);
    const toast = useCustomToast();
    const { account, chainId } = useWeb3WithDefault();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const getIcon = (symbol: string) => {
        return tokenSymbol2token(symbol, chainId).icon ?? '/assets/tokens/default.svg';
    };

    const [netWorkLoading, setNetWorkLoading] = useState(true);
    const [claimed, setClaimed] = useState(false);
    const [token, setToken] = useState('iZi');
    const [tokenAddr, setTokenAddr] = useState('');
    const [decimal, setDecimal] = useState(0);

    const { iZiToken } = useIZiToken();
    const prizeContract = usePrizeContract();
    const { prizeToken } = usePrizeToken();
    const getBalanceParams = useMemo(() => {
        if (tokenAddr) {
            return {
                address: account,
                token: tokenAddr,
            } as PrizeBalanceRequest;
        } else if (iZiToken && iZiToken.address) {
            setTokenAddr(iZiToken.address);
            setDecimal(iZiToken.decimal);
            return {
                address: account,
                token: iZiToken.address,
            } as PrizeBalanceRequest;
        }
    }, [account, iZiToken, tokenAddr]);

    useEffect(() => {
        setNetWorkLoading(true);
        if (getBalanceParams?.address && getBalanceParams?.token) {
            dispatch.prize
                .getBalance({
                    ...getBalanceParams,
                    prizeContract: prizeContract,
                    decimal: decimal,
                } as PrizeBalanceParams)
                .then(() => {
                    setNetWorkLoading(false);
                });
        }
    }, [chainId, dispatch, getBalanceParams, prizeContract, claimed, decimal]);

    const claimParams = useMemo(() => {
        if (prizeToken) {
            return {
                token: tokenAddr,
                maxAmount: prize.balance,
                account: account,
                prizeContract: prizeContract,
                decimal: decimal,
            } as PrizeClaimParams;
        }
    }, [account, decimal, prize.balance, prizeContract, prizeToken, tokenAddr]);

    const handleClaim = () => {
        dispatch.prize
            .claim({
                ...claimParams,
                onGoingCallback: () => {
                    toast('info', 'Ongoing ...');
                },
            } as PrizeClaimParams)
            .then(() => {
                setClaimed(!claimed);
                toast('info', 'Successful claim');
            })
            .catch(() => {
                if (claimParams?.maxAmount === 0) {
                    toast('error', 'Prize balance is zero');
                } else {
                    toast('error', 'Claim failed');
                }
            });
    };

    return (
        <Flex
            w={{ base: '100%', md: '60%', lg: '53%' }}
            pl={{ base: '0px', md: '22px' }}
            direction="column"
            ml={{ base: 'unset', md: 'auto' }}
            justifyContent="center"
        >
            <Flex mt={{ base: '10px', md: '0px' }}>
                <Flex mt={{ base: '9px', md: '6px' }}>
                    <Text className={i_text_prize_16} color={colorTheme('secondary.500', 'secondary.400')}>
                        {t('Prize Balance')}
                    </Text>
                    <Tooltip label="Prizes from all the campaigns will be credited to this balance" fontSize="md">
                        <InfoOutlineIcon ml={{ base: '7px', md: '10px' }} />
                    </Tooltip>
                </Flex>
                <Flex w="219px" h="48px" bg={colorTheme('secondary.50', '#292343')} ml="auto" alignItems="center" justifyContent="center">
                    {netWorkLoading ? (
                        <Image w="20px" h="20px" src="/assets/gallery/loading.gif" fallbackSrc="/assets/gallery/loading.gif" />
                    ) : (
                        <>
                            <Image boxSize="30px" src={getIcon(token)}></Image>
                            <Menu>
                                {() => (
                                    <>
                                        <MenuButton
                                            as={Button}
                                            rightIcon={<ChevronDownIcon />}
                                            w="130px"
                                            className={i_h4}
                                            fontWeight="700 !important"
                                            bg="inherit"
                                            ml="5px"
                                            _hover={{ bg: 'secondary.100' }}
                                            _active={{ bg: 'secondary.100' }}
                                        >
                                            {prize.balance} {token}
                                        </MenuButton>

                                        <MenuList className={i_text_copy}>
                                            {prizeToken &&
                                                prizeToken.length > 0 &&
                                                prizeToken.map((item: TokenInfoFormatted, index: number) => {
                                                    if (item) {
                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                onClick={() => {
                                                                    setToken(item.symbol);
                                                                    setTokenAddr(item.address);
                                                                    setDecimal(item.decimal);
                                                                }}
                                                            >
                                                                {item.symbol}
                                                            </MenuItem>
                                                        );
                                                    }
                                                })}
                                        </MenuList>
                                    </>
                                )}
                            </Menu>
                        </>
                    )}
                </Flex>
            </Flex>
            <Flex mt="12px">
                <CustomButton
                    className={i_text_copy_bold}
                    variant="purple"
                    w={{ base: '180px', md: '190px' }}
                    h="48px"
                    text={t('Claim')}
                    onClick={() => {
                        handleClaim();
                    }}
                ></CustomButton>
                <CustomButton
                    className={i_text_copy_bold}
                    variant="lightPurple"
                    w="147px"
                    h="48px"
                    text={t('History')}
                    onClick={onHistoryOpen}
                    leftIcon={process.env.PUBLIC_URL + '/assets/Prize/history.svg'}
                    ml="auto"
                ></CustomButton>
            </Flex>
            <HistoryModal isOpen={isHistoryOpen} onClose={onHistoryClose}></HistoryModal>
        </Flex>
    );
};
