import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import tokenContracts from '../config/tokens';

//ABI
import erc20ABI from '../config/abi/erc20.json';

import miningFixRangeBoostV2ABI from '../config/abi/farm/UniswapV3/fixRange/MiningFixRange.json';
import miningFixRangeiZiV1ABI from '../config/abi/farm/iZiSwap/fixRange/FixRange.json';
import miningFixRangeiZiTimestampV1ABI from '../config/abi/farm/iZiSwap/fixRange/FixRangeTimestamp.json';
import miningFixRangeBoostVeiZiABI from '../config/abi/farm/UniswapV3/fixRange/MiningFixRangeBoostVeiZi.json';
import miningOneSideABI from '../config/abi/farm/UniswapV3/oneSide/MiningOneSide.json';
import miningOneSideV3ABI from '../config/abi/farm/UniswapV3/oneSide/MiningOneSideBoostV3.json';
import miningOneSideiZiV1ABI from '../config/abi/farm/iZiSwap/oneSide/OneSide.json';
import miningOneSideiZiTimestampV1ABI from '../config/abi/farm/iZiSwap/oneSide/OneSideTimestamp.json';
import miningOneSideBoostVeiZiABI from '../config/abi/farm/UniswapV3/oneSide/MiningOneSideBoostVeiZi.json';
import miningOneSideABI2Rewards from '../config/abi/farm/UniswapV3/oneSide/MiningOneSide2Rewards.json';

import miningDynamicRangeV2ABI from '../config/abi/farm/UniswapV3/dynamicRange/MiningDynamicRangeBoostV2.json';
import miningDynamicRangeVeiZiABI from '../config/abi/farm/UniswapV3/dynamicRange/MiningDynamicRangeBoostVeiZi.json';
import miningDynamicRangeiZiV1ABI from '../config/abi/farm/iZiSwap/dynamicRange/DynamicRange.json';
import miningDynamicRangeTimestampiZiV1ABI from '../config/abi/farm/iZiSwap/dynamicRange/DynamicRangeTimestamp.json';

import veiZiABI from '../config/abi/veiZi/VeiZi.json';
import veTokenABI from '../config/abi/veToken/VeToken.json';

import factoryABI from '../config/abi/UniswapV3/UniswapV3Factory.json';
import poolABI from '../config/abi/UniswapV3/UniswapV3Pool.json';
import iZiPoolABI from '../config/abi/iZiSwap/Pool.json';

import multicallABI from '../config/abi/multicall/MultiContractCall.json';
//Prize abi
import PrizeABI from '../config/abi/prize/Prize.json';

import {
    ChainId,
    FarmDynamicRangeContractVersion,
    FarmDynamicRangeiZiContractVersion,
    FarmFixRangeContractVersion,
    FarmFixRangeiZiContractVersion,
    FarmOneSideContractVersion,
    FarmOneSideiZiContractVersion,
    TokenSymbol,
} from '../types/mod';
import { FACTORY_ADDRESS } from '../config/miscellaneous/uniswapContracts';
import { Contract } from 'web3-eth-contract';
import { useWeb3WithDefault } from '../hooks/useWeb3WithDefault';
import { useMemo } from 'react';
import { PRIZE_ADDRESSES } from '../config/prize/prize';

export const getEVMContract = (abi: any, address: string, web3?: Web3): Contract => {
    return new web3!.eth.Contract(abi as unknown as AbiItem, address, {});
};

export const getEVMTokenContract = (chainId: any, token: any, type?: any, web3?: Web3) => {
    try {
        const contractAddress = tokenContracts[token as TokenSymbol]?.contracts[chainId as ChainId]?.address;
        if (!contractAddress) {
            return undefined;
        }

        return getEVMContract(erc20ABI, contractAddress, web3);
    } catch (e) {
        return undefined;
    }
};

export const getMiningFixRangeBoostV2Contract = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }
    try {
        return getEVMContract(miningFixRangeBoostV2ABI, address, web3);
    } catch (err) {
        return undefined;
    }
};

export const getMiningFixRangeiZiV1Contract = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }
    try {
        return getEVMContract(miningFixRangeiZiV1ABI, address, web3);
    } catch (err) {
        return undefined;
    }
};

export const getMiningFixRangeiZiTimestampV1Contract = (address?: string, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }
    try {
        return getEVMContract(miningFixRangeiZiTimestampV1ABI, address, web3);
    } catch (err) {
        return undefined;
    }
};

export const getMiningFixRangeBoostVeiZiContract = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }
    try {
        return getEVMContract(miningFixRangeBoostVeiZiABI, address, web3);
    } catch (err) {
        return undefined;
    }
};

export const getMiningFixRangeBoostContract = (address: string, web3: Web3, contractVersion: FarmFixRangeContractVersion): any => {
    if (contractVersion === FarmFixRangeContractVersion.V2) {
        return getMiningFixRangeBoostV2Contract(address, web3);
    } else {
        return getMiningFixRangeBoostVeiZiContract(address, web3);
    }
};

export const getMiningFixRangeiZiContract = (address: string, web3: Web3, contractVersion: FarmFixRangeiZiContractVersion): any => {
    if (contractVersion === FarmFixRangeiZiContractVersion.V1) {
        return getMiningFixRangeiZiV1Contract(address, web3);
    }
    return undefined;
};

export const getMiningFixRangeiZiTimestampContract = (
    address: string,
    web3: Web3,
    contractVersion: FarmFixRangeiZiContractVersion
): any => {
    if (contractVersion === FarmFixRangeiZiContractVersion.V1) {
        return getMiningFixRangeiZiTimestampV1Contract(address, web3);
    }
    return undefined;
};

export const useMiningFixRangeiZiContract = (miningAddress: string, contractVersion: FarmFixRangeiZiContractVersion): any => {
    const { web3 } = useWeb3WithDefault();

    const contract = useMemo(() => {
        return getMiningFixRangeiZiContract(miningAddress, web3, contractVersion);
    }, [contractVersion, miningAddress, web3]);

    return contract;
};

export const getMiningOneSideBoostV2Contract = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }

    try {
        const ret = getEVMContract(miningOneSideABI, address, web3);
        return ret;
    } catch (err) {
        return undefined;
    }
};

export const getMiningOneSideBoostV3Contract = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }

    try {
        const ret = getEVMContract(miningOneSideV3ABI, address, web3);
        return ret;
    } catch (err) {
        return undefined;
    }
};
export const getMiningOneSideBoostVeiZiContract = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }

    try {
        return getEVMContract(miningOneSideBoostVeiZiABI, address, web3);
    } catch (err) {
        return undefined;
    }
};

export const getMiningOneSideBoostContract = (address: string, web3: Web3, contractVersion: FarmOneSideContractVersion): any => {
    if (contractVersion === FarmOneSideContractVersion.V2) {
        return getMiningOneSideBoostV2Contract(address, web3);
    } else if (contractVersion === FarmOneSideContractVersion.V3) {
        return getMiningOneSideBoostV3Contract(address, web3);
    } else {
        return getMiningOneSideBoostVeiZiContract(address, web3);
    }
};

export const getMiningDynamicRangeV2Contract = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }

    try {
        return getEVMContract(miningDynamicRangeV2ABI, address, web3);
    } catch (err) {
        return undefined;
    }
};

export const getMiningDynamicRangeVeiZiContract = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }

    try {
        return getEVMContract(miningDynamicRangeVeiZiABI, address, web3);
    } catch (err) {
        console.log('veizi: ', err);
        return undefined;
    }
};

export const getMiningDynamicRangeBoostContract = (address: string, web3: Web3, contractVersion: FarmDynamicRangeContractVersion): any => {
    if (contractVersion === FarmDynamicRangeContractVersion.V2) {
        return getMiningDynamicRangeV2Contract(address, web3);
    } else {
        return getMiningDynamicRangeVeiZiContract(address, web3);
    }
};

export const getMiningDynamicRangeiZiV1Contract = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }
    try {
        return getEVMContract(miningDynamicRangeiZiV1ABI, address, web3);
    } catch (err) {
        return undefined;
    }
};

export const getMiningDynamicRangeTimestampiZiV1Contract = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }
    try {
        return getEVMContract(miningDynamicRangeTimestampiZiV1ABI, address, web3);
    } catch (err) {
        return undefined;
    }
};

export const getMiningDynamicRangeiZiBoostContract = (
    address: string,
    web3: Web3,
    contractVersion: FarmDynamicRangeiZiContractVersion
): any => {
    if (contractVersion === FarmDynamicRangeiZiContractVersion.V1) {
        return getMiningDynamicRangeiZiV1Contract(address, web3);
    }
};

export const getMiningDynamicRangeTimestampiZiBoostContract = (
    address: string,
    web3: Web3,
    contractVersion: FarmDynamicRangeiZiContractVersion
): any => {
    if (contractVersion === FarmDynamicRangeiZiContractVersion.V1) {
        return getMiningDynamicRangeTimestampiZiV1Contract(address, web3);
    }
};

export const getMiningOneSideiZiV1Contract = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }
    try {
        return getEVMContract(miningOneSideiZiV1ABI, address, web3);
    } catch (err) {
        return undefined;
    }
};

export const getMiningOneSideiZiTimestampV1Contract = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }
    try {
        return getEVMContract(miningOneSideiZiTimestampV1ABI, address, web3);
    } catch (err) {
        return undefined;
    }
};

export const getMiningOneSideiZiBoostContract = (address: string, web3: Web3, contractVersion: FarmOneSideiZiContractVersion): any => {
    if (contractVersion === FarmOneSideiZiContractVersion.V1) {
        return getMiningOneSideiZiV1Contract(address, web3);
    } else {
        return undefined;
    }
};

export const getVeiZiContract = (address?: string, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }
    const abi2 = veiZiABI.map((m) => ({ ...m }));
    try {
        const aa = getEVMContract(abi2, address, web3);
        //console.log('aa: ', aa);
        return aa;
    } catch (err) {
        return undefined;
    }
};

export const getMiningOneSideContract2Rewards = (address?: any, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }

    try {
        return getEVMContract(miningOneSideABI2Rewards, address, web3);
    } catch (err) {
        return undefined;
    }
};

export const getMiningOneSideContractByCondition = (isTwoRewards: boolean, address: string, web3: Web3): Contract => {
    return getEVMContract(isTwoRewards ? miningOneSideABI2Rewards : miningOneSideABI, address, web3);
};

export const getFactoryContract = (chainId?: ChainId, web3?: any) => {
    if (!chainId) {
        return undefined;
    }

    try {
        const address = FACTORY_ADDRESS[chainId];
        return getEVMContract(factoryABI, address, web3);
    } catch (err) {
        console.error('Failed to get factory contract', err);
        return undefined;
    }
};

export const getPositionPoolContract = (address?: any, web3?: any) => {
    if (!address || !web3) {
        return undefined;
    }

    try {
        return getEVMContract(poolABI, address, web3);
    } catch (err) {
        console.error('Failed to get pool contract', err);
        return undefined;
    }
};

export const getiZiSwapPoolContract = (address?: any, web3?: any) => {
    if (!address || !web3) {
        return undefined;
    }
    try {
        return getEVMContract(iZiPoolABI, address, web3);
    } catch (err) {
        console.error('Failed to get pool contract', err);
        return undefined;
    }
};

export function decodeMethodResult(contract: Contract, methodName: string, data: string) {
    const typeDefine = (contract as any)._jsonInterface.filter((a: any) => a['name'] === methodName)[0].outputs;
    return (contract as any)._decodeMethodReturn(typeDefine, data);
}

export async function getChainIdOfContractDeployed(contract: Contract): Promise<ChainId> {
    // TODO why
    const chainId = parseInt((contract as any).currentProvider.chainId);

    if (Number.isInteger(chainId)) {
        return chainId;
    } else {
        // TODO maybe undefined
        return (contract as any).eth.getChainId().then((r: number) => r);
    }
}

export const getMulticallContract = (address: string, web3: Web3): any => {
    if (!address || !web3) {
        return undefined;
    }
    try {
        return getEVMContract(multicallABI, address, web3);
    } catch (err) {
        return undefined;
    }
};

export const CHAIN_EIP1559_SET = new Set([ChainId.EthereumMainnet /*, ChainId.Rinkeby*/]);

// todo: complete type of fields
export interface TransactionObject {
    from?: any;
    to?: any;
    value?: any;
    gas?: any;
    gasPrice?: any;
    type?: any;
    maxFeePerGas?: string | number;
    maxPriorityFeePerGas?: any;
    accessList?: any;
    data?: any;
    nonce?: any;
    chain?: any;
    hardfork?: any;
    common?: any;
}

export const buildSendingParams = (chainId: ChainId, params: TransactionObject, gasPrice: string | number): TransactionObject => {
    if (CHAIN_EIP1559_SET.has(chainId)) {
        console.log('params: ', params);
        return params;
    }
    const newParams = { ...params } as TransactionObject;
    if (newParams.maxFeePerGas !== undefined) {
        newParams.gasPrice = newParams.maxFeePerGas;
        delete newParams.maxFeePerGas;
    } else {
        newParams.gasPrice = gasPrice;
    }
    // todo: support transform from maxPriorityFeePerGas to maxFeePerGas
    if (newParams.maxPriorityFeePerGas !== undefined) {
        delete newParams.maxPriorityFeePerGas;
    }

    console.log('new params: ', newParams);
    return newParams;
};

export const getPrizeContractByChainId = (chainId: ChainId, web3: Web3) => {
    const contractAddress = PRIZE_ADDRESSES[chainId];
    if (!contractAddress || !web3) {
        return undefined;
    }

    return getEVMContract(PrizeABI, contractAddress, web3);
};
export const getVeTokenContract = (address?: string | null | undefined, web3?: Web3) => {
    if (!address || !web3) {
        return undefined;
    }
    const abi2 = veTokenABI.map((m) => ({ ...m }));
    try {
        const aa = getEVMContract(abi2, address, web3);
        //console.log('aa: ', aa);
        return aa;
    } catch (err) {
        return undefined;
    }
};
