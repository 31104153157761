import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, Image, useColorMode, Text, Button, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, Stack } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { parentEndpoint } from '../../../net/iZUMi-endpoints/src/restful/apiEndpoints';
import { GameEvents, getGameEventById } from '../../../net/iZUMi-endpoints/src/restful/izumiGame';
import { RootDispatch, RootState } from '../../../state/store';
import { i_text_copy, i_text_prize_16 } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { EventEntry } from '../components/EventEntry';
import './FinishedBlock.css';
type FinishedBlockProps = {
    selectId: number;
    setSelectId: any;
};
export const FinishedBlock: React.FC<FinishedBlockProps> = (pros) => {
    const { selectId, setSelectId } = pros;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { prize } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));
    const {
        prize: { control },
    } = useSelector((state: RootState) => state);
    const [eventData, setEventData] = useState<GameEvents[]>([]);
    const [cardFlow, setCardFlow] = useState(false);
    const [mouseOverId, setMouseOverId] = useState(-1);

    const onCategoryChange = useCallback(
        (e: number) => {
            const control = { ...prize.control };
            control.category = e;
            dispatch.prize.setControl(control);
        },
        [dispatch.prize, prize.control]
    );

    const onEndTimeChange = useCallback(
        (e: number) => {
            const control = { ...prize.control };
            control.timeEnd = e;
            dispatch.prize.setControl(control);
        },
        [dispatch.prize, prize.control]
    );

    const endList = useMemo(() => {
        if (prize.endList && prize.endList.length > 0) {
            let resultList = [...prize.endList];
            if (control.category !== -1) {
                resultList = resultList.filter((item) => {
                    return item.type === control.category;
                });
                return resultList;
            }

            if (control.timeEnd) {
                let t = 0;
                if (control.timeEnd === 30) {
                    t = new Date().valueOf() / 1000 - 30 * 24 * 60 * 60;
                }
                if (control.timeEnd === 90) {
                    t = new Date().valueOf() / 1000 - 90 * 24 * 60 * 60;
                }

                resultList = resultList.filter((item) => {
                    return (item.endTime as unknown as number) > t;
                });
                return resultList;
            }
        }

        return prize.endList;
    }, [control.category, control.timeEnd, prize.endList]);

    useEffect(() => {
        setEventData([]);
        if (selectId !== -1) {
            getGameEventById({
                id: selectId,
            }).then((r) => {
                setEventData(r.data.data.data);
            });
        }
    }, [selectId]);
    const formatTime = (type: string, time: number) => {
        const t = new Date().valueOf() / 1000;
        if (type === 'start') {
            return Math.max(time - t, 0);
        }
        if (type === 'end') {
            return Math.max(time - t, 0);
        }
        return Math.max(time - t, 0);
    };
    return (
        <Box>
            <Flex direction="column" mt="17px" alignItems="center">
                {eventData && eventData.length > 0 && selectId >= 0 ? (
                    <EventEntry
                        status={eventData[0].status}
                        startTime={formatTime('start', eventData[0].startTime as unknown as number)}
                        endTIme={formatTime('end', eventData[0].endTime as unknown as number)}
                        eventId={eventData[0].id}
                        banner={eventData[0].banner}
                        leftTime={eventData[0].leftTime as unknown as number}
                    />
                ) : (
                    <>
                        <Image
                            w={{ base: '373px', md: '675px', lg: '1000px' }}
                            h={{ base: '190px', md: '220px', lg: '350px' }}
                            src={process.env.PUBLIC_URL + '/assets/Prize/finishBanner.svg'}
                            fallbackSrc={process.env.PUBLIC_URL + '/assets/Prize/finishBanner.svg'}
                        ></Image>
                        <Card
                            w={{ base: '373px', md: '675px', lg: '794px' }}
                            h={{ base: '150px', md: '86px' }}
                            mt={{ base: '0px', lg: '-43px' }}
                            bgColor={colorTheme('#ffffff', '#211834')}
                            alignItems="center"
                        >
                            <Stack
                                w={{ base: '270px', md: 'unset' }}
                                h="100%"
                                mt={{ base: '0px', md: '0px' }}
                                spacing={{ base: '20px', md: '100px' }}
                                justifyContent="center"
                                direction={{ base: 'column', md: 'row' }}
                            >
                                <Flex alignItems="center">
                                    <Text className={i_text_copy} color={colorTheme('tertiary.600', 'tertiary.400')} mr="22px">
                                        {t('End in')}
                                    </Text>
                                    <Menu>
                                        {() => (
                                            <>
                                                <MenuButton
                                                    as={Button}
                                                    rightIcon={<ChevronDownIcon />}
                                                    className={i_text_copy}
                                                    fontSize="13px"
                                                    w="178px"
                                                    color={colorTheme('tertiary.900', 'tertiary.300')}
                                                    ml={{ base: 'auto', md: '0px' }}
                                                >
                                                    {control.timeEnd === 0
                                                        ? t('All')
                                                        : control.timeEnd === 30
                                                        ? t('Last 30 days')
                                                        : control.timeEnd === 90
                                                        ? t('Last 90 days')
                                                        : ''}
                                                </MenuButton>
                                                <MenuList className={i_text_copy}>
                                                    <MenuItem
                                                        onClick={() => {
                                                            onEndTimeChange(0);
                                                        }}
                                                    >
                                                        {t('All')}
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            onEndTimeChange(30);
                                                        }}
                                                    >
                                                        {t('Last 30 days')}
                                                    </MenuItem>

                                                    <MenuItem
                                                        onClick={() => {
                                                            onEndTimeChange(90);
                                                        }}
                                                    >
                                                        {t('Last 90 days')}
                                                    </MenuItem>
                                                </MenuList>
                                            </>
                                        )}
                                    </Menu>
                                </Flex>
                                <Flex alignItems="center">
                                    <Text className={i_text_copy} color={colorTheme('tertiary.600', 'tertiary.400')} mr="22px">
                                        {t('Category')}
                                    </Text>
                                    <Menu>
                                        {() => (
                                            <>
                                                <MenuButton
                                                    as={Button}
                                                    rightIcon={<ChevronDownIcon />}
                                                    className={i_text_copy}
                                                    fontSize="13px"
                                                    w="178px"
                                                    color={colorTheme('tertiary.900', 'tertiary.300')}
                                                    ml={{ base: 'auto', md: '0px' }}
                                                >
                                                    {control.category === -1
                                                        ? t('All')
                                                        : control.category === 0
                                                        ? t('Weekly')
                                                        : control.category === 1
                                                        ? t('Daily')
                                                        : t('Competition')}
                                                </MenuButton>
                                                <MenuList className={i_text_copy}>
                                                    <MenuItem
                                                        onClick={() => {
                                                            onCategoryChange(-1);
                                                        }}
                                                    >
                                                        {t('All')}
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            onCategoryChange(1);
                                                        }}
                                                    >
                                                        {t('Daily')}
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            onCategoryChange(0);
                                                        }}
                                                    >
                                                        {t('Weekly')}
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => {
                                                            onCategoryChange(2);
                                                        }}
                                                    >
                                                        {t('Competition')}
                                                    </MenuItem>
                                                </MenuList>
                                            </>
                                        )}
                                    </Menu>
                                </Flex>
                            </Stack>
                        </Card>

                        <SimpleGrid
                            w={{ base: '373px', md: '675px', lg: '794px' }}
                            columns={{ base: 1, md: 2 }}
                            spacingX="20px"
                            spacingY="20px"
                        >
                            {endList.map((item, index) => {
                                return (
                                    <Card
                                        w={{ base: '100%', sm: '373px' }}
                                        h="177px"
                                        key={index}
                                        mt="30px"
                                        flexDirection="row"
                                        cursor="pointer"
                                        onMouseOver={() => {
                                            setCardFlow(true);
                                            setMouseOverId(index);
                                        }}
                                        onMouseLeave={() => {
                                            setCardFlow(false);
                                            setMouseOverId(-1);
                                        }}
                                    >
                                        <Image
                                            w="100%"
                                            h="100%"
                                            className={cardFlow && mouseOverId === index ? 'blurCard' : 'blurLeave'}
                                            src={parentEndpoint + '/game/media/' + item.banner}
                                            fallbackSrc={process.env.PUBLIC_URL + '/assets/Prize/finishBanner.svg'}
                                        ></Image>
                                        <Flex
                                            w="373px"
                                            h="177px"
                                            position="absolute"
                                            alignItems="center"
                                            justifyContent="center"
                                            opacity={cardFlow && mouseOverId === index ? 1 : 0}
                                        >
                                            <CustomButton
                                                w="130px"
                                                h="45px"
                                                className={i_text_prize_16}
                                                variant="outline"
                                                text={t('Read More')}
                                                onClick={() => {
                                                    setSelectId(item.id);
                                                    setTimeout(() => {
                                                        setMouseOverId(-1);
                                                    }, 500);
                                                }}
                                            ></CustomButton>
                                        </Flex>
                                    </Card>
                                );
                            })}
                        </SimpleGrid>
                    </>
                )}
            </Flex>
        </Box>
    );
};
