export type TimeNumberSeries = number[][];

export type NamedTimeNumberSeries = {
    name: string;
    data: TimeNumberSeries;
};

export type DataSeries = NamedTimeNumberSeries[];

export enum TimeScope {
    day = 24 * 3600 * 1000,
    week = 7 * 24 * 3600 * 1000,
    month = 31 * 24 * 3600 * 1000,
    year = 365 * 24 * 3600 * 1000,
}
