import { BoxProps, useColorMode, HStack, VStack, Text, Box, Image, Divider, Icon, Flex } from '@chakra-ui/react';
import { getColorThemeSelector, getFormatDateDiff, getHiddenAddress } from '../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy, i_h2 } from '../../../../style';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import Info from '../../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { NFTId } from '../../../../iZUMi-UI-toolkit/src/components/NFTId/NFTId';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
import DatePicker from 'react-datepicker';
import { useCallback, useState } from 'react';
import { CgCalendar } from 'react-icons/cg';
import { TokenBalanceBlock } from '../../../Trade/components/TokenBalanceBlock';
import { AmountInput } from '../../../Trade/components/AmountInput';
import { decimal2Amount, formatNumber } from '../../../../utils/tokenMath';
import { VeTokenAmount } from '../../components/VeTokenAmount';
import { LockTime } from '../../components/LockTime';
import { BsClock } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { VeTokenNft } from '../../../../state/models/veToken/types';
import { VETOKEN_ADDRESS } from '../../../../config/veToken/veTokenContracts';
import useTokenEntity from '../../../../state/models/hooks/useTokenEntity';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import BigNumber from 'bignumber.js';
import { isNumeric } from '../../../../utils/valid';
import ApproveTokenButton from '../../../components/ApproveTokenButton';
import { MiningCallbacks } from '../../../../state/models/hooks/farm/common/callbacks';
import { useARCToken } from '../../../../hooks/useARCToken';
import useVeTokenEntity from '../../../../hooks/useVeTokenEntity';

type LockMoreModalProps = {
    nftId: string;
    isOpen: boolean | any;
    onClose: () => void;
    onSuccess?: () => void;
} & BoxProps;

export const LockMoreModal: React.FC<LockMoreModalProps> = (props) => {
    const { nftId, isOpen, onSuccess, onClose, ...rest } = props;
    const { account, chainId } = useWeb3WithDefault();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const { veToken } = useSelector((state: RootState) => state);

    const userVeTokenNfts = veToken.userData?.nftList ?? ([] as VeTokenNft[]);
    const currentTimestamp = veToken.globalData?.currentTimestamp ?? 0;
    const week = 24 * 7 * 60 * 60;
    const NFT = userVeTokenNfts.find((nft) => {
        return nft.nftId === nftId;
    }) ?? {
        lockAmountDecimal: 0,
        veTokenDecimal: 0,
        lockRemainYear: 0,
        endTimestamp: currentTimestamp,
        nftId: '0',
        isStaked: false,
    };

    const locktimes = [
        [1, '1 month'],
        [2, '6 months'],
        [3, '1 year'],
        [4, '2 years'],
        [5, '3 years'],
        [6, '4 years'],
    ];

    const toast = useCustomToast();

    const veTokenAddress = VETOKEN_ADDRESS[chainId];

    const { ARCToken } = useARCToken();
    const veTokenEntity = useVeTokenEntity(veTokenAddress, 'V2');
    const ARCEntity = useTokenEntity(ARCToken, veTokenAddress);

    const currentDate = new Date(currentTimestamp * 1000);
    const nftUnlockDate = new Date(NFT.endTimestamp * 1000);

    const startDate = currentDate;

    const originEndTimestamp = NFT.endTimestamp;
    const originEndDate = new Date(originEndTimestamp * 1000);

    const [endDate, setEndDate] = useState(originEndDate);
    const [endTimestamp, setEndTimestamp] = useState(originEndTimestamp);

    const [inputValueDecimal, setInputValueDecimal] = useState(0);

    const estimateVeTokenDecimal =
        ((inputValueDecimal + NFT.lockAmountDecimal) * (endTimestamp - currentTimestamp)) / Number(veToken.globalData?.seconds4Year) ?? 0;
    const [inputValue, setInputValue] = useState('0');

    const { gasPrice } = useGasPrice();

    console.log('startDate: ', startDate);

    const tryToUpdateEndDate = useCallback(
        (date: Date, nftOriginEndDate: Date, nftOriginEndTimestamp: number, currTimestamp: number) => {
            const seconds4Year = veToken.globalData?.seconds4Year ?? 0;
            const maxMilliseconds = seconds4Year * 1000;
            console.log('maxMilliseconds: ', maxMilliseconds);
            const latestEndDate = new Date(startDate.getTime() + maxMilliseconds);
            console.log('latestEndDate: ', latestEndDate);
            let newDate = date;
            if (date.getTime() > latestEndDate.getTime()) {
                newDate = new Date(latestEndDate.getTime());
            }
            if (newDate.getTime() > nftOriginEndDate.getTime()) {
                const deltaMilliSeconds = newDate.getTime() - startDate.getTime();
                const deltaSeconds = deltaMilliSeconds / 1000;
                if (seconds4Year > 0) {
                    const newEndTimestamp = currTimestamp + Math.min(deltaSeconds, seconds4Year);
                    if (newEndTimestamp > nftOriginEndTimestamp) {
                        setEndDate(newDate);
                        setEndTimestamp(newEndTimestamp);
                    }
                }
            }
        },
        [veToken.globalData, startDate]
    );

    const tokenStat = (img: any, text: any, value: number, symbol: any) => {
        return (
            <HStack w="90%" h="30px" py="10px" pl="20px" borderRadius="4px" justifyContent="space-between">
                <HStack>
                    <Image w="18px" h="18px" src={process.env.PUBLIC_URL + img} />
                    <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.100')}>
                        {text}
                    </Text>
                </HStack>

                <HStack mt="12px">
                    <Text fontSize="16px" className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.50')}>
                        {formatNumber(value, 2, 2)}
                    </Text>

                    <Text fontSize="16px" className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.400')}>
                        {symbol}
                    </Text>
                </HStack>
            </HStack>
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w="788px"
            //h="750px"
            title="Lock More ARC or Extend Lock Time"
            {...rest}
        >
            <Divider mt="-20px !important" />

            <Card variant="base" w="100%" minH="60px" pt="22px" pb="10px" pl="20px" borderRadius="4px" mb="20px !important" mt="20px">
                <HStack w="100%" justifyContent="space-between">
                    <HStack spacing="40px">
                        <NFTId direction="column" id={NFT.nftId as unknown as string} w="75px" link="" />
                    </HStack>

                    <HStack>
                        <Info label="Owner" value={getHiddenAddress(String(account))} w="120px" />
                        <VeTokenAmount balance={NFT.veTokenDecimal} />
                        <LockTime time={getFormatDateDiff(currentDate, nftUnlockDate)} />
                    </HStack>
                </HStack>
            </Card>

            <VStack w="100%" mt="20px">
                <HStack w="100%" spacing="30px">
                    <VStack w="334px" h="230px" alignItems="left">
                        <Text className={i_text_copy_bold} color={colorTheme('tertiary.500', 'tertiary.100')}>
                            Lock ARC Amount
                        </Text>
                        <Card variant="deep" w="100%" h="195px" p="10px">
                            <TokenBalanceBlock token={ARCToken} balance={ARCEntity.tokenBalance()} p="10px" />
                            <Divider my="10px !important" />

                            <Box w="100%" px="10px" mt="20px">
                                <AmountInput
                                    handleSetValue={(v: any) => {
                                        if (isNumeric(Number(v))) {
                                            setInputValueDecimal(Number(v));
                                            const inputValueBN = decimal2Amount(new BigNumber(Number(v)), ARCToken) ?? new BigNumber(0);
                                            setInputValue(inputValueBN.toFixed(0, 3));
                                        }
                                    }}
                                    token={ARCToken}
                                    price={ARCEntity.tokenPrice()}
                                    balance={ARCEntity.tokenBalance()}
                                    errorInfo=""
                                    inputValue={inputValueDecimal}
                                    w="60%"
                                    fontClass={i_h2}
                                />
                            </Box>
                        </Card>
                    </VStack>

                    <VStack w="334px" h="230px" alignItems="left">
                        <Text className={i_text_copy_bold} color={colorTheme('tertiary.500', 'tertiary.100')}>
                            Lock Time
                        </Text>

                        <Card variant="deep" w="100%" h="91px" p="12px 20px 12px 20px">
                            <VStack alignItems="left">
                                <Text fontSize="12px" className={i_text_copy} color={colorTheme('tertiary.500', 'tertiary.100')}>
                                    Until :
                                </Text>
                                <HStack spacing="10px">
                                    <Icon as={CgCalendar} boxSize="24px" />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date: any) =>
                                            tryToUpdateEndDate(date, originEndDate, originEndTimestamp, currentTimestamp)
                                        }
                                        className={colorTheme('light-picker', 'dark-picker')}
                                    />
                                </HStack>
                            </VStack>
                        </Card>
                        <Divider m="0" />

                        <Flex mx="auto" w="100%" flexWrap="wrap">
                            {locktimes.map((t: any, i: any) => {
                                return (
                                    <Flex key={i} w="32%" mr="2px" mt="2px">
                                        <CustomButton
                                            variant="outlinePurple"
                                            text={t[1]}
                                            w="100%"
                                            fontSize="12px"
                                            h="41px"
                                            onClick={() => {
                                                const date = new Date(startDate);
                                                if (t[0] === 1) {
                                                    date.setMonth(startDate.getMonth() + 1);
                                                } else if (t[0] === 2) {
                                                    date.setMonth(startDate.getMonth() + 6);
                                                } else if (t[0] === 3) {
                                                    date.setFullYear(startDate.getFullYear() + 1);
                                                } else if (t[0] === 4) {
                                                    date.setFullYear(startDate.getFullYear() + 2);
                                                } else if (t[0] === 5) {
                                                    date.setFullYear(startDate.getFullYear() + 3);
                                                } else if (t[0] === 6) {
                                                    date.setFullYear(startDate.getFullYear() + 4);
                                                }
                                                tryToUpdateEndDate(date, originEndDate, originEndTimestamp, currentTimestamp);
                                            }}
                                        />
                                    </Flex>
                                );
                            })}
                        </Flex>
                    </VStack>
                </HStack>

                <Card variant="purpleLinear" w="100%" h="111px">
                    <VStack spacing="10px">
                        <HStack w="100%" mt="20px">
                            {tokenStat(
                                '/assets/tokens/veToken.svg',
                                'Estimate New Initial Voting Power :',
                                estimateVeTokenDecimal,
                                'veARC'
                            )}
                        </HStack>
                        <HStack w="100%">
                            <HStack w="90%" h="30px" pl="20px" borderRadius="4px" justifyContent="space-between">
                                <HStack>
                                    <Icon as={BsClock} boxSize="16px" />
                                    <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.100')}>
                                        New Estimate End Lock Time :
                                    </Text>
                                </HStack>

                                <HStack mt="12px">
                                    <Text fontSize="16px" className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.50')}>
                                        {getFormatDateDiff(startDate, endDate)}
                                    </Text>
                                </HStack>
                            </HStack>
                        </HStack>
                    </VStack>
                </Card>

                {inputValue === '0' || ARCEntity.isApproved() ? (
                    <CustomButton
                        variant="purple"
                        mt="20px !important"
                        text={
                            <HStack m="auto">
                                <Image w="17px" h="17px" src={process.env.PUBLIC_URL + '/assets/tokens/arctic.png'} />
                                <Text>Lock ARC</Text>
                            </HStack>
                        }
                        w="304px"
                        h="40px"
                        fontClass={i_text_copy_bold}
                        fontSize="14px"
                        onClick={() => {
                            console.log('ARC amount: ', inputValue);
                            if (inputValue !== '0' || endTimestamp > originEndTimestamp) {
                                // console.log('New End Time Round: ', Math.floor(endTimestamp/week) * week);
                                // console.log('Original End Time: ', originEndTimestamp);
                                // console.log('New End Time Raw: ', endTimestamp);

                                const tokenAmount = inputValue;
                                const endTime = Math.floor(endTimestamp / week) * week > originEndTimestamp ? String(endTimestamp) : '0';
                                veTokenEntity.increaseAmountOrUnlockTime(
                                    NFT.nftId,
                                    tokenAmount,
                                    endTime,
                                    {
                                        onTransactionHash: (e: any) => {
                                            toast('info', 'Lock ARC: ' + e);
                                        },
                                        then: () => {
                                            //console.log(e);
                                            toast('info', 'Lock ARC successfully');
                                            onSuccess?.();
                                            onClose();
                                        },
                                        catch: (e: any) => {
                                            //console.log(e);
                                            toast('error', e.message);
                                        },
                                    } as MiningCallbacks,
                                    gasPrice
                                );
                            }
                        }}
                    />
                ) : (
                    <ApproveTokenButton tokenEntity={ARCEntity} mt="10px !important" w="200px" mx="auto !important" />
                )}
            </VStack>
        </Modal>
    );
};
