import { Flex, SimpleGrid } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { getAllFarmStat, RequestAllFarmStat, ResponseAllFarmStat } from '../../../net/iZUMi-endpoints/src/restful/izumiFarmStat';
import { RootDispatch, RootState } from '../../../state/store';
import { Loading } from '../../components/Loading';
import PoolListEntry from './PoolListEntry/PoolListEntry';
export interface AllFarmData {
    type: number;
    data: ResponseAllFarmStat[];
}
const PoolList: React.FC = () => {
    const { chainId, account } = useWeb3WithDefault();
    const { farmGallery: farm } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        const queryParams: RequestAllFarmStat = {
            status: farm.farmControl.status,
        };
        if (farm.farmControl.chainId) {
            queryParams.chainId = farm.farmControl.chainId;
        }
        if (farm.farmControl.protocol === 0 || farm.farmControl.protocol) {
            queryParams.protocol = farm.farmControl.protocol;
        }
        getAllFarmStat(queryParams).then((r) => {
            setLoading(false);
            if (r.data.data.data) {
                dispatch.farmGallery.setPoolEntryList([...r.data.data.data]);
            }
        });
    }, [farm.farmControl.status, farm.farmControl.protocol, farm.farmControl.chainId, chainId]);

    const farmFiltered = useMemo(() => {
        const control = farm.farmControl;
        let ff = [...farm.poolEntryList];
        if (control.searchKey) {
            ff = ff.filter((pool) => {
                return (
                    pool.tokenX_symbol.toLocaleLowerCase().includes(control.searchKey.toLocaleLowerCase()) ||
                    pool.tokenY_symbol.toLocaleLowerCase().includes(control.searchKey.toLocaleLowerCase())
                );
            });
        }
        if (control.sortBy === 'APR') {
            ff = ff.sort((a, b) => {
                const av = a.apr.length > 1 ? a.apr[1] : a.apr[0];
                const bv = b.apr.length > 1 ? b.apr[1] : b.apr[0];
                return bv - av;
            });
        }
        return ff;
    }, [farm, account]);
    return (
        <Flex w="100%" direction="column" mb={{ base: '70px !important', sm: '50px !important' }}>
            {loading ? (
                <Loading />
            ) : (
                <SimpleGrid
                    columns={{ base: farm.farmControl.showedRow, sm: 1, xxl: 2 }}
                    spacingX={{ base: '11px', sm: '20px' }}
                    spacingY={{ base: '11px', sm: '20px' }}
                >
                    {farmFiltered &&
                        farmFiltered.length > 0 &&
                        farmFiltered.map((item, index) => {
                            return <PoolListEntry entry={item} key={index}></PoolListEntry>;
                        })}
                </SimpleGrid>
            )}
        </Flex>
    );
};
export default PoolList;
