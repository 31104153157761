import React from 'react';
import { Image, Center, VStack, useColorMode, Text } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import { getColorThemeSelector } from '../../utils/funcs';
import { a_h1, a_h2 } from '../../iZUMi-UI-toolkit/src/style';

const ComingSoon: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <PageLayout
            header={<></>}
            body={
                <Center w="100%" h="100%" alignItems="center">
                    <VStack>
                        <Image
                            src={process.env.PUBLIC_URL + '/assets/404.png'}
                            fallbackSrc={process.env.PUBLIC_URL + '/assets/404.png'}
                            w={{ base: '150px', sm: '273px' }}
                            h={{ base: '150px', sm: '276px' }}
                        />
                        <Text className={a_h2} color={colorTheme('tertiary.700', 'tertiary.300')} mt="100px !important">
                            Coming Soon
                        </Text>
                    </VStack>
                </Center>
            }
        />
    );
};

export default ComingSoon;
