import { useBreakpointValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LayoutStateEnum } from '../state/models/pageLayout/pageLayout';
import { RootState } from '../state/store';

export const fixedHeaderRoute = '/pro';

export function useIsFixedHeader(): boolean {
    const location = useLocation();
    const currentPath = location.pathname;
    if (currentPath.includes(fixedHeaderRoute)) {
        return true;
    } else {
        return false;
    }
}

function useLayoutType(): LayoutStateEnum {
    const isXlp = useBreakpointValue({ base: false, xxl: false, xlp1: true, '2xl': true });
    const { pageLayout } = useSelector((state: RootState) => state);
    const isFixedHeader = useIsFixedHeader();
    if (isFixedHeader) {
        return LayoutStateEnum.header;
    } else {
        if (pageLayout.currentLayout === LayoutStateEnum.sidebar || (pageLayout.currentLayout === LayoutStateEnum.init && isXlp)) {
            return LayoutStateEnum.sidebar;
        }
        return LayoutStateEnum.header;
    }
}

export default useLayoutType;
