import { Box, Flex, Image, Text, useColorMode } from '@chakra-ui/react';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { useEffect, useState } from 'react';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { i_h3, i_h4, i_text_16_bold, i_text_copy_bold, i_text_piece3 } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import WinnerList from './WinnerList';
import { GameEventsTypeEnum, GameRule, GameWinner, getGameWinner } from '../../../net/iZUMi-endpoints/src/restful/izumiGame';
import PrizeTabs, { tabList } from '../components/PrizeTabs';
import { useTranslation } from 'react-i18next';

type WinnerFormProps = {
    status: number;
    winnerRules: GameRule[];
    eventId: number;
};

const WinnerForm: React.FC<WinnerFormProps> = (pros) => {
    const { status, winnerRules, eventId } = pros;
    const { t } = useTranslation();
    const { account } = useWeb3WithDefault();
    const [tabValue, setTabValue] = useState('');
    const [winnerData, setWinnerData] = useState<GameWinner[]>();
    const [netWorkLoading, setNetWorkLoading] = useState(true);
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const handleChangeTab = (value: string) => {
        if (winnerData && winnerData.length > 0) {
            winnerData?.map((item) => {
                if (value === item.tabName) {
                    setTabValue(item.tabName);
                }
            });
        }
    };

    const [tab, setTab] = useState<tabList[]>([]);

    useEffect(() => {
        setNetWorkLoading(true);
        getGameWinner({
            id: eventId,
        }).then((r) => {
            setNetWorkLoading(false);
            setWinnerData(r.data.data.data);
            if (r.data.data.data && r.data.data.data.length > 0) {
                setTabValue(r.data.data.data[0].tabName);
            }
            const tabs: tabList[] = [];
            if (r.data.data.data && r.data.data.data.length > 0) {
                r.data.data.data.map((item) => {
                    return tabs.push({
                        label: item.tabName,
                        value: item.tabName,
                        component: <WinnerList tabData={item.tabData} />,
                    } as tabList);
                });
            }

            setTab(tabs);
        });
    }, [eventId]);
    return (
        <Card variant="tabTopCard" w={{ base: '374px', md: '675px', lg: '792px' }} h="669px" mt="20px">
            {netWorkLoading && (
                <Flex alignItems="center" justifyContent="center" mt="50px">
                    <Text className={i_h3} color={colorTheme('tertiary.500', 'tertiary.400')}>
                        {t('Loading Data')} ...
                    </Text>
                </Flex>
            )}
            {!netWorkLoading &&
                (status === GameEventsTypeEnum.LIVE || status === GameEventsTypeEnum.ENDED ? (
                    winnerData && winnerData.length === 0 ? (
                        <>
                            <Flex w="100%" h="125px" direction="column" alignItems="center">
                                <Image
                                    w="97px"
                                    h="47px"
                                    mt="19px"
                                    src={process.env.PUBLIC_URL + '/assets/Prize/winner.svg'}
                                    fallbackSrc={process.env.PUBLIC_URL + '/assets/Prize/winner.svg'}
                                ></Image>
                                <Text className={i_text_16_bold} mt="8px">
                                    {t('Winner List')}
                                </Text>
                            </Flex>
                            <Flex flex="1" direction="column" alignItems="center" mt="11px">
                                <Image
                                    w="100px"
                                    h="83px"
                                    src={process.env.PUBLIC_URL + '/assets/Prize/notStart.svg'}
                                    fallbackSrc={process.env.PUBLIC_URL + '/assets/Prize/notStart.svg'}
                                ></Image>
                                <Text className={i_h4} mt="12px">
                                    {t('Counting event data')} ...
                                </Text>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex w="100%" h={account ? '80px' : '125px'} direction="column" alignItems="center">
                                <Image
                                    w="97px"
                                    h="47px"
                                    mt="19px"
                                    src={process.env.PUBLIC_URL + '/assets/Prize/winner.svg'}
                                    fallbackSrc={process.env.PUBLIC_URL + '/assets/Prize/winner.svg'}
                                ></Image>
                                <Text className={i_text_16_bold} mt="8px">
                                    {t('Winner List')}
                                </Text>
                            </Flex>
                            <Flex w="100%" flex="1" direction="column" mt="15px">
                                <Flex w="100%" h="100%" direction="column">
                                    <PrizeTabs
                                        variant="simple"
                                        notShowDivider={true}
                                        list={tab}
                                        value={tabValue}
                                        handleClick={handleChangeTab}
                                    ></PrizeTabs>
                                    <Flex
                                        flex="1"
                                        px="32px"
                                        bg={colorTheme('#ffffff', '#211834')}
                                        direction="column"
                                        justifyContent="center"
                                    >
                                        {winnerRules &&
                                            winnerRules.map((item, index) => {
                                                return (
                                                    <Flex direction="column" key={index}>
                                                        <Text className={i_text_copy_bold}>
                                                            {<Box dangerouslySetInnerHTML={{ __html: item.title }}></Box>}
                                                        </Text>
                                                        <Text
                                                            className={i_text_piece3}
                                                            color={colorTheme('tertiary.400', 'tertiary.300')}
                                                            lineHeight="16px"
                                                            mt="13px"
                                                        >
                                                            {<Box dangerouslySetInnerHTML={{ __html: item.detail }}></Box>}
                                                        </Text>
                                                    </Flex>
                                                );
                                            })}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </>
                    )
                ) : (
                    <>
                        <Flex w="100%" h="125px" direction="column" alignItems="center" opacity="0.3">
                            <Image
                                w="97px"
                                h="47px"
                                mt="19px"
                                src={process.env.PUBLIC_URL + '/assets/Prize/winner.svg'}
                                fallbackSrc={process.env.PUBLIC_URL + '/assets/Prize/winner.svg'}
                            ></Image>
                            <Text className={i_text_16_bold} mt="8px">
                                {t('Winner List')}
                            </Text>
                        </Flex>
                        <Flex flex="1" direction="column" alignItems="center" mt="11px">
                            <Image
                                w="100px"
                                h="83px"
                                src={process.env.PUBLIC_URL + '/assets/Prize/notStart.svg'}
                                fallbackSrc={process.env.PUBLIC_URL + '/assets/Prize/notStart.svg'}
                            ></Image>
                            <Text className={i_h4} mt="12px">
                                Event hasn&apos;t started yet
                            </Text>
                            <Text className={i_text_piece3} color="tertiary.400" mt="8px">
                                Winner List will be available after the event is started
                            </Text>
                        </Flex>
                    </>
                ))}
        </Card>
    );
};

export default WinnerForm;
