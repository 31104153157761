import { BoxProps, Flex, Modal, ModalCloseButton, ModalContent, ModalOverlay, Text, Divider, Image } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTokenListFormatted } from '../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { Loading } from '../../../iZUMi-UI-toolkit/src/components/Loading/Loading';
import { i_text_copy_bold } from '../../../iZUMi-UI-toolkit/src/style';
import { getClaimHistory, ResponseClaimHistory } from '../../../net/iZUMi-endpoints/src/restful/izumiGame';
import { i_h4, i_text_copy } from '../../../style';
type WarningModalProps = {
    onClose: () => void;
    isOpen: boolean;
} & BoxProps;
const HistoryModal: React.FC<WarningModalProps> = (props) => {
    const { onClose, isOpen } = props;
    const { account } = useWeb3WithDefault();
    const [netWorkLoading, setNetWorkLoading] = useState(true);
    const [historyData, setHistoryData] = useState([] as ResponseClaimHistory[]);

    const { loading, tokenList } = useTokenListFormatted();

    const getIcon = (symbol: string) => {
        const tempToken = {
            ...tokenList.find((e) => e.symbol === symbol),
        } as unknown as any;
        return tempToken.icon ?? '/assets/tokens/default.svg';
    };
    const tabs = [
        {
            title: 'Reward',
            width: { base: 'auto' },
        },

        {
            title: 'Time',
            width: { base: '150px' },
        },
    ];

    useEffect(() => {
        if (account) {
            setNetWorkLoading(true);

            getClaimHistory({
                address: account,
            })
                .then((r) => {
                    setNetWorkLoading(false);
                    setHistoryData(r.data.data);
                })
                .catch(() => {});
        }
    }, [account]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
            <ModalOverlay />
            <ModalContent
                w={{ base: '90%', lg: '550px' }}
                minW={{ base: '90%', lg: '550px' }}
                h={{ base: 'auto', lg: '345px' }}
                my={{ base: 0, md: 'auto' }}
                borderRadius="6px"
                boxShadow="0px 0px 27px 5px rgba(218, 206, 230, 0.25)"
            >
                <ModalCloseButton mt="16px" />
                <Flex direction="column" h="100%" my="28px" mx="28px">
                    <Text className={i_h4}>Claim History</Text>
                    <Flex mt="27px" justifyContent="space-between">
                        {tabs.map((item, index) => {
                            return (
                                <Text w={item.width} key={index} className={i_text_copy} color="#9B8FAE">
                                    {item.title}
                                </Text>
                            );
                        })}
                    </Flex>
                    <Divider mt="5px"></Divider>
                    <Flex direction="column" h={{ base: '300px', sm: '219px' }} overflowY="auto">
                        {netWorkLoading || loading ? (
                            <Loading></Loading>
                        ) : (
                            historyData.length > 0 &&
                            historyData.map((item, index) => {
                                return (
                                    <Flex className={i_text_copy} key={index} mt="19px" justifyContent="space-between">
                                        <Flex w={tabs[0].width}>
                                            <Image boxSize="16px" src={getIcon(item.symbol)}></Image>

                                            <Text className={i_text_copy_bold} ml="6px">
                                                {item.amount} {item.symbol}
                                            </Text>
                                        </Flex>

                                        <Text w={tabs[1].width} className={i_text_copy}>
                                            {moment(item.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}
                                        </Text>
                                    </Flex>
                                );
                            })
                        )}
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    );
};
export default HistoryModal;
