import React, { useEffect } from 'react';
import { Stack } from '@chakra-ui/react';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PageLayout from '../../../components/layout/PageLayout';
import { NFTEntry } from './components/NFTEntry';
import { useLocation } from 'react-router';
//import { CgNpm } from 'react-icons/cg';
import { NftIdParams } from '../../../state/models/veToken/types';
import ValidBlockWrapper from '../../components/ChainNotValidBlock';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import BackButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/BackButton/BackButton';

export const VeNFTManage: React.FC = () => {
    useEffect(() => {
        document.title = 'veARC | Arctic';
    }, []);

    const location = useLocation();

    const stateParam = location.state as NftIdParams;

    const nftId = stateParam.nftId;
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            body={
                <ValidBlockWrapper
                    content={
                        <Stack w="100%" pb="60px">
                            <BackButton></BackButton>
                            <NFTEntry nftId={nftId} />
                        </Stack>
                    }
                    app="veToken"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={<PageHeader headerTitle="Manage veARC NFT" subtitle="" variant="secondary" />}
            body={
                <ValidBlockWrapper
                    content={
                        <Stack w="100%" pb="60px">
                            <NFTEntry nftId={nftId} />
                        </Stack>
                    }
                    app="veToken"
                />
            }
            internalWidth={{ base: '100%', sm: '1100px', xlp1: '1150px' }}
            mobileAdapt={true}
            px="10px"
        />
    );
};

export default VeNFTManage;
