import { Flex, SimpleGrid, Text, Image } from '@chakra-ui/react';
import { a_display } from '../../../../iZUMi-UI-toolkit/src/style';
import { PartnerCard } from './component/PartnerCard';

const HomePiece3: React.FC = () => {
    return (
        <Flex
            direction="column"
            alignItems="center"
            px={{ base: '27px', sm: '72px' }}
            mt={{ base: '60px', sm: '100px' }}
            pt={{ base: '60px', sm: '114px' }}
            bgImage="/assets/home/flow.png"
            bgRepeat="no-repeat"
        >
            <Flex w={{ base: '100%', xl: '1152px' }} direction={{ base: 'column' }}>
                <Image w={{ base: '240px', sm: '273px' }} h="53px" src="/assets/home/homePiece3/Ecosystems.svg"></Image>
                <Text w={{ base: '312px', sm: '100%', xl: '791px' }} className={a_display} color="tertiary.50" mt="19px">
                    Provide deeper on-chain liquidity, and maximise capital efficiency to the Aurora ecosystem with all the eco-friends.
                </Text>
                <SimpleGrid columns={{ base: 2, lg: 3, xl: 4 }} spacing={'18px'} mt="85px">
                    <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/Aurora.png"
                        title="Aurora"
                        content="Aurora is an Ethereum Virtual Machine created by the team at the NEAR Protocol,
                         delivering a turn-key solution for developers to operate their apps on an Ethereum-compatible,
                          high-throughput, scalable and future-safe platform, with low transaction costs for their users."
                        link="https://aurora.dev/"
                    ></PartnerCard>
                    <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/Proximity.png"
                        title="Proximity"
                        content="Proximity is a research and development firm supporting projects building decentralized finance 
                        (DeFi) applications on NEAR and Aurora through grants, advisory services, and developer support. "
                        link="https://www.proximity.dev/"
                    ></PartnerCard>
                    <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/Near.png"
                        title="NEAR"
                        content="Through simple, secure, and scalable technology,
                         NEAR empowers millions to invent and explore new experiences. Business, creativity,
                          and community are being reimagined for a more sustainable and inclusive future.
                    "
                        link="https://near.org/"
                    ></PartnerCard>
                    <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/izumi.png"
                        title="iZUMi"
                        content="iZUMi Finance is a multi-chain DeFi protocol providing One-Stop Liquidity as a Service (LaaS).
                         Its philosophy is that every token deserves better on-chain liquidity in an efficient and lasting way.
                    "
                        link="https://izumi.finance/home"
                    ></PartnerCard>

                    {/* <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/Bastion.png"
                        title="Bastion"
                        content="The Liquidity Foundation of Aurora. Stableswap and Lending
                    "
                        link="https://bastionprotocol.com/"
                    ></PartnerCard> */}
                    <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/Aurigami.png"
                        title="Aurigami"
                        content="Aurora's native money market

                    "
                        link="https://www.aurigami.finance/"
                    ></PartnerCard>
                    <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/Trisolaris.png"
                        title="Trisolaris"
                        content="Trisolaris is the first AMM exchange on Aurora.
                    "
                        link="https://www.trisolaris.io/#/swap"
                    ></PartnerCard>
                    <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/ref.png"
                        title="Ref Finance"
                        content="Ref Finance is a community-led, multi-purpose DeFi platform built on the NEAR Protocol."
                        link="https://www.ref.finance/"
                    ></PartnerCard>
                    <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/Bastion.png"
                        title="Bastion"
                        content="The Liquidity Foundation of Aurora. Stableswap and Lending"
                        link="https://bastionprotocol.com/"
                    ></PartnerCard>
                    <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/DecentralBank.png"
                        title="Decentral Bank"
                        content="Decentral Bank is the DAO developing and supporting $USN, the NEAR-native stablecoins."
                        link="https://decentral-bank.finance/"
                    ></PartnerCard>
                    <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/Atomic.png"
                        title="Atomic Green"
                        content="Atomic Green is the platform for leveraged trading on DEXes with it's own lending protocol"
                        link="https://atomic.green/"
                    ></PartnerCard>
                    <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/nex.png"
                        title="NEX"
                        content="NEX is a perpetual and spot DEX that supports zero price impact trades and low slippage."
                        link="https://nex.market/"
                    ></PartnerCard>
                    <PartnerCard
                        titleImgSrc="/assets/home/homePiece3/Synapse.png"
                        title="Synapse"
                        content="Synapse is a cross-chain layer protocol powering frictionless interoperability between blockchains."
                        link="https://synapseprotocol.com/"
                    ></PartnerCard>
                </SimpleGrid>
            </Flex>
        </Flex>
    );
};

export default HomePiece3;
