import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { getLibrary } from '../utils/connectors';
import { QueryClient, QueryClientProvider } from 'react-query';
import LanguageContext, { languageStore } from './LanguageProvider';
import ToastProvider from './ToastProvider';
import theme from '../theme/theme';
import ConnectProvider from './ConnectProvider';
import { store as modelStore, persistor } from '../state/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ToastWithNetworkProvider from './NetworkToastProvider';

const queryClient = new QueryClient();

const Providers: React.FC = ({ children }) => {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Provider store={modelStore}>
                <PersistGate loading={null} persistor={persistor}>
                    <QueryClientProvider client={queryClient}>
                        <LanguageContext.Provider value={languageStore}>
                            <ChakraProvider theme={theme}>
                                <ToastProvider>
                                    <ToastWithNetworkProvider>
                                        <ConnectProvider>
                                            <Router>{children}</Router>
                                        </ConnectProvider>
                                    </ToastWithNetworkProvider>
                                </ToastProvider>
                            </ChakraProvider>
                        </LanguageContext.Provider>
                    </QueryClientProvider>
                </PersistGate>
            </Provider>
        </Web3ReactProvider>
    );
};

export default Providers;
