import { links } from '../links';

export const config = {
    pages: [
        // {
        //     name: 'home',
        //     img: 'Home.svg',
        //     address: '/home',
        //     children: [],
        // },

        {
            name: 'Trade',
            img: 'Trade.png',
            address: '/trade/swap',
            marker: 'new',
            children: [
                {
                    name: 'Swap',
                    address: '/trade/swap',
                    icon: '/assets/header/lightSwap.svg',
                },
                {
                    name: 'Limit Order',
                    address: '/trade/limit',
                    icon: '/assets/header/lightLimit.svg',
                },
                {
                    name: 'Pro',
                    img: 'Pro.svg',
                    address: '/pro',
                    icon: '/assets/header/lightPro.svg',
                },
                {
                    name: 'Liquidity',
                    address: '/trade/liquidity',
                    icon: '/assets/header/lightLiquidity.svg',
                },
                {
                    name: 'Analytics',
                    address: links.analyticLink,
                    icon: '/assets/header/lightAnalysis.svg',
                },
            ],
        },
        {
            name: 'Farm',
            img: 'Farm.svg',
            address: '/farm/dynamic',
            children: [],
        },
        {
            name: 'veARC',
            img: 'VeiZi.svg',
            address: '/veARC',
            children: [],
        },
        {
            name: 'DAOs',
            img: 'More.svg',
            address: '/DAOs/Vote',
            children: [
                {
                    name: 'Vote',
                    address: '/DAOs/Vote',
                    icon: '/assets/DAOs/Vote.svg',
                },
                {
                    name: 'Bribe',
                    address: '/DAOs/Bribe',
                    icon: '/assets/DAOs/Bribe.svg',
                },
            ],
        },

        {
            name: 'Docs',
            img: 'More.svg',
            address: links.docs,
            children: [],
        },
    ],
} as { pages: PageConfig[] };
