import { Flex, useDisclosure, Box, Image, Text, BoxProps, useBreakpointValue, useInterval } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DropDownMenu } from '../../../components/DropDownMenu/DropDownMenu';
import Identicon from '../../../components/Identicon';
import WalletModal from '../../../components/Modal/WalletModal/WalletModal';
import useIsMobile from '../../../hooks/useIsMobile';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import ConnectButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/ConnectButton/ConnectButton';
import { useConnectModal } from '../../../providers/ConnectProvider';
import { RootDispatch } from '../../../state/store';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';

const Header: React.FC<BoxProps> = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { onOpenModal: onConnect } = useConnectModal();
    const { chainId, chainName, web3, account } = useWeb3WithDefault();
    const history = useHistory();
    const isPC = useBreakpointValue({ base: false, xl: true });
    const isMobile = useIsMobile();

    const disConnect = () => {
        onOpen();
    };

    const onCopyAddress = () => {
        if (account) {
            navigator.clipboard.writeText(account);
        }
    };

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));

    useInterval(() => {
        if (account && web3 && chainId) {
            dispatch.account.fetchEthBalanceAndUSDIfMissing({
                account,
                web3,
                chainId,
            });
        }
    }, 5000);

    return (
        <>
            <Flex
                alignItems="center"
                flexShrink={0}
                flexGrow={0}
                h="60px"
                zIndex="4"
                bg={'#330197'}
                position={'sticky'}
                top="0px"
                {...props}
            >
                <Flex w="100%" m="0 auto" align="center" flexDirection="row">
                    <Flex w={{ base: 'unset', sm: '20%' }}>
                        <Image
                            w={{ base: '133px', sm: '150px' }}
                            h={isMobile ? '20px' : '30px'}
                            src="/assets/header/ArcticLogo.svg"
                            cursor="pointer"
                            onClick={() => {
                                history.push('/home');
                            }}
                            ml={{ base: '20px', sm: '72px' }}
                        ></Image>
                    </Flex>
                    {isPC && <DropDownMenu w="60%" justifyContent="center"></DropDownMenu>}
                    {account ? (
                        <>
                            <Flex w={{ base: 'unset', xl: '20%' }} alignItems="center" onClick={!isPC ? undefined : disConnect} ml="auto">
                                <Flex
                                    px="10px"
                                    py="2px"
                                    alignItems="center"
                                    _hover={{
                                        boxShadow: '0px 2px 12px 0px rgba(164, 165, 180, 0.3), 0px 2px 9px 0px rgba(219, 219, 219, 0.32)',
                                    }}
                                >
                                    <Flex alignItems="center" justifyContent="end" direction={{ base: 'row', xl: 'column' }}>
                                        {isPC && (
                                            <Text
                                                lineHeight="12px"
                                                fontSize="12px"
                                                fontFamily="Avenir"
                                                fontWeight="600"
                                                color="tertiary.50"
                                            >
                                                {truncateWalletAddress(account || '')}
                                            </Text>
                                        )}
                                        <Image
                                            src={process.env.PUBLIC_URL + '/assets/wallet/connected.svg'}
                                            width="90px"
                                            height="20px"
                                            mr={{ base: '14px', sm: '4px' }}
                                            mt={{ base: '0px', xl: '5px' }}
                                            onClick={disConnect}
                                        />
                                        {!isPC && <DropDownMenu variant="mobile"></DropDownMenu>}
                                    </Flex>
                                    {isPC && (
                                        <Box boxSize="44px" ml="14px">
                                            <Identicon size={44} />
                                        </Box>
                                    )}
                                </Flex>
                            </Flex>
                        </>
                    ) : (
                        <Flex w={{ base: 'unset', xl: '20%' }} ml="auto" alignItems="center">
                            <ConnectButton variant="black" mr="10px" Identicon={Identicon} onClick={onConnect} />
                            {!isPC && <DropDownMenu variant="mobile"></DropDownMenu>}
                        </Flex>
                    )}
                </Flex>
            </Flex>
            <WalletModal
                account={account as unknown as string}
                chainId={chainId!}
                isOpen={isOpen}
                onClose={onClose}
                onCopyAddress={onCopyAddress}
                scanName={chainName}
            />
        </>
    );
};

export default Header;
