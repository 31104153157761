import { Box, Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import HomePiece1 from './components/HomePiece1/HomePiece1';
import HomePiece2 from './components/HomePiece2/HomePiece2';
import HomePiece3 from './components/HomePiece3/HomePiece3';
import HomePiece4 from './components/HomePiece4/HomePiece4';

const Home: React.FC = () => {
    useEffect(() => {
        document.title = 'Home | Arctic';
    }, []);

    return (
        <Box
            id="home"
            w="100%"
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none !important',
                },
            }}
            boxSizing="border-box"
            position="relative"
        >
            <HomePiece1 />
            <Flex direction="column" alignItems="center" px={{ base: '27px', sm: '72px' }}>
                <HomePiece2 />
            </Flex>

            <HomePiece3 />
            <HomePiece4 />
        </Box>
    );
};

export default Home;
