import { Box, Button, Grid, Stack, useColorMode, HStack, chakra, Image } from '@chakra-ui/react';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { ToastLink, useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import CountDownBlock from '../../../../../iZUMi-UI-toolkit/src/components/CountDownBlock/CountDownBlock';
import Info from '../../../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { NFTId } from '../../../../../iZUMi-UI-toolkit/src/components/NFTId/NFTId';
import { buildSendingParams, getMiningDynamicRangeiZiBoostContract } from '../../../../../utils/contractHelpers';
import { createContext, useContext, useState } from 'react';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Text } from '../../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import CurrentPriceBlock from '../../../../components/CurrentPriceBlock';
import { formatNumber } from '../../../../../utils/tokenMath';
import { Earned } from '../../../components/Earned';
import { i_text_copy_bold } from '../../../../../style';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { RootDispatch } from '../../../../../state/store';
import { PositionDetailModal } from './PositionDetailModal';
import { PoolEntryState } from '../../../../../state/models/farm/iZiSwap/dynamicRange/types';
import AddLiquidityModal from './AddLiquidityModal';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { BoostModal } from './BoostModal';
import { getChain, getScanUrlPrefix, getTxLink } from '../../../../../config/chains';
import { getNftUrl, links } from '../../../../../config/links';
import { LIQUIDITY_MANAGER_ADDRESS } from '../../../../../config/trade/tradeContracts';
import { FarmRefreshHandle } from './PoolList';
import BigNumber from 'bignumber.js';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import { FARM_CONFIG } from '../../../../../config/bizConfig';
import { VeNFTBlock } from '../../../components/VeNFTBlock';
import useIsMobile from '../../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { miningPoolConfigList } from '../../../../../config/farm/iZiSwap/dynamicRange/miningPoolMetaConst';

const PositionListContext = createContext<PoolEntryState | null>(null);
export const PositionListProvider = PositionListContext.Provider;

export function usePositionList(): PoolEntryState {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(PositionListContext)!;
}

export const PositionList: React.FC<FarmRefreshHandle> = ({ refreshPosition, refreshPoolListDataAndPosition, type }) => {
    const entry = usePositionList();
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [detailNftIdx, setDetailNftIdx] = useState(0);
    const [showBoostModal, setShowBoostModal] = useState(false);
    const [showAddLiquidityModal, setShowAddLiquidityModal] = useState(false);

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const oneLineMode = useBreakpointValue({ base: false, xxl: true, '2xl': true })!;
    const { chainId, account, web3 } = useWeb3WithDefault();

    const toast = useCustomToast();
    const isMobile = useIsMobile();
    const { gasPrice } = useGasPrice();

    const iZiSwapNftAddr = LIQUIDITY_MANAGER_ADDRESS[chainId];
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const isLoggedIn = account;
    const positionList = [...(entry.positionList ?? []), ...(entry.stakedPositionList ?? [])];
    //console.log(positionList);

    const isNeedApprove = false;

    const noPosition = positionList.length === 0 && !entry.data.isEnded;

    const theme = colorTheme('1px solid #E9E9E9', '1px solid #52466A');

    const AddLiquidity = () => {
        dispatch.farmDynamicRangeiZiAddLiquidity.refreshMintForm({
            positionPoolKey: entry.meta.positionPoolKey,
            chainId,
        });
        dispatch.veiZiFarmDynamicRangeiZiAddLiquidity.refreshMintForm({
            positionPoolKey: entry.meta.positionPoolKey,
            chainId,
        });

        setShowAddLiquidityModal(true);
    };

    const miningContract = getMiningDynamicRangeiZiBoostContract(entry.meta.miningContract, web3, entry.meta.contractVersion);

    const onHarvest = (index: number) => {
        miningContract?.methods
            .collect(positionList[index].nftId)
            .estimateGas({ from: account })
            .then((gas: number) => {
                const chain = getChain(chainId);
                const toastLink = {} as ToastLink;
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                miningContract?.methods
                    .collect(positionList[index].nftId)
                    .send(
                        buildSendingParams(
                            chainId,
                            {
                                from: account,
                                gas: gasLimit,
                                maxFeePerGas: gasPrice,
                            },
                            gasPrice
                        )
                    )
                    .on('transactionHash', (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        toast('info', 'Collecting rewards...', undefined, toastLink);
                    })
                    .then((e: any) => {
                        toast('success', 'Collect rewards successfully.', undefined, toastLink);
                        console.log(e);
                        refreshPosition?.();
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            });
    };

    const BoostButton = (index: any) => (
        <CustomButton
            variant="purple"
            text={t('Boost')}
            className={i_text_copy_bold}
            gridRow="span 3"
            mb="2px"
            w="64px"
            h={oneLineMode ? '30px' : '24px'}
            ml={{ base: '0px !important', sm: '10px !important' }}
            onClick={() => {
                setDetailNftIdx(index);
                setShowBoostModal(true);
            }}
        />
    );

    const UnstakeButton = (index: number) => {
        return (
            <CustomButton
                variant={isMobile ? 'mobileWhite' : 'orange'}
                text={t('Unstake')}
                className={i_text_copy_bold}
                gridRow={{ base: 'span 2', sm: 'span 3' }}
                mb="2px"
                w={{ base: '100px', xlp1: '80px' }}
                h={{ base: '30px', sm: '40px' }}
                marginLeft={{ base: 'auto', sm: '30px' }}
                mr={{ base: '15px', sm: '0px' }}
                onClick={() => {
                    console.log('nft: ', positionList[index].nftId);
                    miningContract?.methods
                        .withdraw(positionList[index].nftId, false)
                        .estimateGas({ from: account })
                        .then((gas: number) => {
                            console.log('gas: ', gas);
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            const gasLimit = new BigNumber(gas * 1.1).toFixed(0);
                            miningContract?.methods
                                .withdraw(positionList[index].nftId, false)
                                .send(
                                    buildSendingParams(
                                        chainId,
                                        {
                                            from: account,
                                            gas: gasLimit,
                                            maxFeePerGas: gasPrice,
                                        },
                                        gasPrice
                                    )
                                )
                                .on('transactionHash', (hash: string) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(hash, chain);
                                    }
                                    toast('info', 'Unstaking from mining contract...', undefined, toastLink);
                                })
                                .then((e: any) => {
                                    toast('success', 'Unstake successfully.', undefined, toastLink);
                                    refreshPoolListDataAndPosition?.();
                                    console.log(e);
                                })
                                .catch((e: any) => {
                                    console.log(e);
                                });
                        });
                }}
            />
        );
    };

    const DetailsButton = (index: number) => {
        return isMobile ? (
            <CustomButton
                variant="tertiary"
                text={t('Details')}
                className={i_text_copy_bold}
                gridRow="span 3"
                mb="2px"
                w="64px"
                h={oneLineMode ? '30px' : '24px'}
                bg={colorTheme('#C1CFFF', 'rgba(84, 103, 170, 0.4)')}
                color={colorTheme('#6171C2', '#919DDD')}
                fontFamily="Montserrat-Medium"
                fontWeight="600"
                marginLeft="30px"
                onClick={() => {
                    setDetailNftIdx(index);
                    setShowModal(true);
                }}
            />
        ) : (
            <CustomButton
                variant="tertiary"
                text={t('Details')}
                className={i_text_copy_bold}
                gridRow="span 3"
                mb="2px"
                w="64px"
                h={oneLineMode ? '30px' : '24px'}
                marginLeft="30px"
                onClick={() => {
                    setDetailNftIdx(index);
                    setShowModal(true);
                }}
            />
        );
    };

    const viewMiningContract = () => window.open(getScanUrlPrefix(chainId) + entry.meta.miningContract, '_blank');
    const viewMiningContractInAnalytics = () => {
        const findPool = miningPoolConfigList[chainId]?.find((item) => {
            return item.miningContract === entry.meta.miningContract;
        });
        window.open(links.analyticLink + '/pool?chainId=' + chainId + '&poolAddress=' + findPool?.iZiSwapAddress);
    };
    return type === 'mobile' ? (
        <>
            <Stack direction={oneLineMode ? 'row' : 'column'} spacing={oneLineMode ? '50px' : '10px'} flexGrow={1}>
                <Stack
                    alignItems="center"
                    justifyContent="start"
                    pl={oneLineMode ? '50px' : 'unset'}
                    pt={oneLineMode && isLoggedIn && positionList.length > 0 ? '20px' : 'unset'}
                    spacing="6px"
                    direction={(oneLineMode && isLoggedIn && positionList.length > 0) || isMobile ? 'column' : 'row'}
                >
                    <CurrentPriceBlock
                        tokenA={entry.meta.tokenA}
                        tokenB={entry.meta.tokenB}
                        price={entry.data.priceAByBDecimal}
                        toggle={entry.meta.initialToggle}
                        handleToggle={() => {
                            dispatch.farmDynamicRangeiZi.togglePoolMetaInitialToggle(entry.meta.positionPoolKey);
                        }}
                        type="mobile"
                    />
                    <CountDownBlock initialSeconds={entry.data.secondsLeft} type="mobile" />

                    <Stack
                        direction={oneLineMode && isLoggedIn && positionList.length > 0 ? 'column' : 'row'}
                        spacing="21px"
                        mt="17px !important"
                    >
                        {!entry.data.isEnded && (
                            <Button
                                w={oneLineMode || isMobile ? '140px' : '100px'}
                                h="33px"
                                flexShrink={0}
                                onClick={AddLiquidity}
                                bg={colorTheme('#F1F5F9', '#363457')}
                            >
                                <Text variant="caption-bold" color={colorTheme('#687692', '#ADA7D3')}>
                                    {t('Add Liquidity')}
                                </Text>
                            </Button>
                        )}
                        <Button
                            w={oneLineMode || isMobile ? '140px' : '100px'}
                            h="33px"
                            flexShrink={0}
                            onClick={viewMiningContract}
                            bg={colorTheme('#F1F5F9', '#363457')}
                        >
                            <Text variant="caption-bold" color={colorTheme('#687692', '#ADA7D3')}>
                                {t('View Contract')}
                            </Text>
                        </Button>
                    </Stack>
                    <Button w="100%" h="33px" onClick={viewMiningContractInAnalytics} bg={colorTheme('#F7F4FF', '#4A3B6B')}>
                        <Image boxSize="13px" src="/assets/farm/dashboard.svg"></Image>
                        <Text variant="caption-bold" color={colorTheme('#8E5DF5', '#8E63EA')} ml="6px">
                            {t('Dashboard')}
                        </Text>
                    </Button>
                    {oneLineMode && (
                        <Text
                            variant="caption"
                            color="tertiary.500"
                            w={oneLineMode && isLoggedIn && positionList.length > 0 ? '140px' : 'unset'}
                        >
                            {noPosition ? t("You don't have any iZiSwap  position for now, click ' Add Liquidity ' to start farming.") : ''}
                        </Text>
                    )}
                </Stack>

                {!oneLineMode && noPosition && (
                    <Text
                        variant="caption"
                        color="tertiary.500"
                        w={oneLineMode && isLoggedIn && positionList.length > 0 ? '140px' : 'unset'}
                    >
                        {noPosition ? t("You don't have any iZiSwap  position for now, click ' Add Liquidity ' to start farming.") : ''}
                    </Text>
                )}

                <Box flexGrow={1}>
                    {positionList.map((item, index) => (
                        <Card
                            pt="10px"
                            mt="13px"
                            key={index}
                            alignItems="start"
                            bg={colorTheme(
                                'linear-gradient(129.24deg, rgba(183, 203, 255, 0.7) -3.64%, rgba(255, 255, 255, 0) 15.01%), linear-gradient(180deg, rgba(169, 188, 255, 0.2) 0%, rgba(188, 200, 232, 0.09) 70.11%), linear-gradient(180deg, #FFFFFF 0%, #FEFEFF 100%)',
                                '#201B36'
                            )}
                            border={colorTheme('0.6px solid #F0F0F0', '1px solid #4F4991')}
                            borderRadius="6px"
                            _notLast={{
                                borderBottom: theme,
                            }}
                        >
                            <HStack spacing="10px" w={oneLineMode ? '190px' : '247px'} pl="22px">
                                <NFTId
                                    direction={oneLineMode ? 'column' : 'row'}
                                    id={item.nftId}
                                    w={oneLineMode ? '75px' : '130px'}
                                    link={getNftUrl(chainId, iZiSwapNftAddr, item.nftId)}
                                />
                                {DetailsButton(index)}
                            </HStack>

                            <Info
                                direction={oneLineMode ? 'column' : 'row'}
                                label={t('TVL')}
                                value={item.tvl === undefined ? '...' : `$${formatNumber(item.tvl)}`}
                                w={oneLineMode ? '110px' : '121px'}
                                mt="7px"
                                valueStyle={{ fontFamily: 'Montserrat-Medium', fontSize: '12px' }}
                                pl="22px"
                                labelColor="#A398BD"
                            />

                            {entry.meta.iZiBoost && item.isStaked && (
                                <HStack
                                    spacing="10px"
                                    w={oneLineMode ? '160px' : '247px'}
                                    h={oneLineMode ? 'unset' : '35px'}
                                    mt="7px"
                                    pl="22px"
                                    pb="12px"
                                >
                                    <Info
                                        direction={oneLineMode ? 'column' : 'row'}
                                        label={t('My APR')}
                                        value={
                                            item.apr <= 99.99
                                                ? formatNumber(item.apr * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) + '%'
                                                : '9999+%'
                                        }
                                        w={oneLineMode ? '70px' : '143px'}
                                        bg={colorTheme(
                                            'linear-gradient(90.7deg, #D8C8FF 0.27%, rgba(236, 228, 255, 0.49) 124.53%)',
                                            'linear-gradient(90deg, rgba(99, 31, 245, 0.3) 0%, rgba(97, 60, 143, 0.12) 113.29%);'
                                        )}
                                        h="20px"
                                        borderRadius="2px"
                                        pl="11px"
                                        color={colorTheme('secondary.600', '#F7EEFF')}
                                        valueStyle={{ fontSize: '12px' }}
                                    />
                                    {!entry.data.isEnded && (
                                        <HStack w="100%" bg={colorTheme('inherit', 'rgba(50, 46, 91, 0.3)')}>
                                            {BoostButton(index)}
                                        </HStack>
                                    )}
                                </HStack>
                            )}

                            {!isNeedApprove ? (
                                !entry.meta.veiZiBoost ? (
                                    <Earned
                                        gridRow="span 3"
                                        entry={entry}
                                        tokenId={item.nftId}
                                        onHarvest={() => {
                                            onHarvest(index);
                                        }}
                                        earned={
                                            (item as any).earned &&
                                            (item as any).earned.map((item: any) => {
                                                return parseFloat(item);
                                            })
                                        }
                                        pl="22px"
                                        bg={colorTheme('#FFFFFF', 'rgba(50, 46, 91, 0.3)')}
                                        havestBtStyle={{ bg: colorTheme('#E2EAF2', '#363457'), color: colorTheme('#687692', '#B6C6E7') }}
                                    />
                                ) : (
                                    <HStack gridRow="span 3" w="190px" />
                                )
                            ) : (
                                <Text variant="caption" color="tertiary.500" ml="140px">
                                    Waiting for approve...
                                </Text>
                            )}
                            <HStack w="100%" h="40px" bg={colorTheme('#FFFFFF', 'rgba(50, 46, 91, 0.3)')} pb="9px">
                                {!isNeedApprove && item.isStaked && UnstakeButton(index)}
                            </HStack>
                        </Card>
                    ))}
                    {positionList.length > 0 && entry.meta.veiZiBoost && (
                        <Grid
                            mt="20px"
                            alignItems="center"
                            templateRows={{ base: 'repeat(1, 1fr)', sm: `repeat(${oneLineMode ? 1 : 3}, 1fr)` }}
                            templateColumns={`repeat(${oneLineMode ? 4 : 2}, max-content) auto`}
                            autoFlow={oneLineMode ? 'row' : 'column'}
                            _notLast={{
                                borderBottom: theme,
                            }}
                            _last={{
                                marginBottom: { base: '0px', sm: '-26px' },
                            }}
                        >
                            <Stack direction={oneLineMode ? 'row' : 'column'}>
                                <Card variant="grey" p="10px 40px 20px 40px" h="60px" w="130px">
                                    <Info
                                        direction="column"
                                        label={t('My APR')}
                                        value={
                                            entry.userData.apr <= 99.99
                                                ? formatNumber(entry.userData.apr * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) + '%'
                                                : '9999+%'
                                        }
                                        w="60px"
                                    />
                                </Card>
                                <VeNFTBlock
                                    w={{ base: '100%', sm: 'unset' }}
                                    h={{ base: 'unset', sm: '60px' }}
                                    p={{ base: '10px 20px 20px 20px', sm: '10px 40px 20px 40px' }}
                                    entry={
                                        entry.userData.veiZiNftId === '0'
                                            ? undefined
                                            : {
                                                  nftId: entry.userData.veiZiNftId,
                                                  veiZiDecimal: entry.userData.veiZiDecimal,
                                              }
                                    }
                                />
                            </Stack>
                        </Grid>
                    )}
                </Box>
            </Stack>

            {showModal && (
                <PositionDetailModal
                    isOpen={showModal}
                    entry={entry}
                    index={detailNftIdx}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
            )}

            {showBoostModal && (
                <BoostModal
                    isOpen={showBoostModal}
                    entry={entry}
                    index={detailNftIdx}
                    onClose={() => {
                        setShowBoostModal(false);
                    }}
                    onSuccess={refreshPoolListDataAndPosition}
                />
            )}

            {showAddLiquidityModal && (
                <AddLiquidityModal
                    isOpen={showAddLiquidityModal}
                    onClose={() => {
                        setShowAddLiquidityModal(false);
                    }}
                    onSuccess={refreshPosition}
                    entry={entry}
                />
            )}
        </>
    ) : (
        <>
            <Stack direction={oneLineMode ? 'row' : 'column'} spacing={oneLineMode ? '50px' : '38px'} flexGrow={1}>
                <Stack
                    alignItems="center"
                    justifyContent="start"
                    pl={oneLineMode ? (positionList.length > 0 ? { base: '50px', xxl: '15px', xlp1: '50px' } : '50px') : 'unset'}
                    pt={oneLineMode && isLoggedIn && positionList.length > 0 ? '20px' : 'unset'}
                    spacing={{ base: '8px', xl: '21px' }}
                    direction={(oneLineMode && isLoggedIn && positionList.length > 0) || isMobile ? 'column' : 'row'}
                >
                    <CurrentPriceBlock
                        flexShrink={0}
                        w="170px"
                        tokenA={entry.meta.tokenA}
                        tokenB={entry.meta.tokenB}
                        price={entry.data.priceAByBDecimal}
                        toggle={entry.meta.initialToggle}
                        handleToggle={() => {
                            dispatch.farmDynamicRangeiZi.togglePoolMetaInitialToggle(entry.meta.positionPoolKey);
                        }}
                    />
                    <Stack
                        direction={oneLineMode && isLoggedIn && positionList.length > 0 ? 'column' : 'row'}
                        spacing={{ base: '8px', xl: '21px' }}
                        alignItems="center"
                    >
                        {!entry.data.isEnded && (
                            <Button w={oneLineMode || isMobile ? '140px' : '100px'} flexShrink={0} onClick={AddLiquidity}>
                                <Text variant="caption-bold">{t('Add Liquidity')}</Text>
                            </Button>
                        )}
                        <Button w={oneLineMode || isMobile ? '140px' : '100px'} flexShrink={0} onClick={viewMiningContract}>
                            <Text variant="caption-bold">{t('View Contract')}</Text>
                        </Button>

                        {!noPosition && (
                            <Button
                                w={oneLineMode || isMobile ? '140px' : '100px'}
                                flexShrink={0}
                                bg={colorTheme('#F7F4FF', '#4A3B6B')}
                                onClick={viewMiningContractInAnalytics}
                            >
                                <Image boxSize="13px" src="/assets/farm/dashboard.svg"></Image>
                                <Text variant="caption-bold" color={colorTheme('#8E5DF5', '#8E63EA')} ml="6px">
                                    {t('Dashboard')}
                                </Text>
                            </Button>
                        )}
                    </Stack>

                    <CountDownBlock initialSeconds={entry.data.secondsLeft} flexShrink={0} w="170px" />

                    {oneLineMode && (
                        <HStack>
                            {noPosition && (
                                <Image
                                    boxSize="35px"
                                    src={colorTheme('/assets/trade/limitOrderDashboard.svg', '/assets/trade/darklimitOrderDashboard.svg')}
                                    cursor="pointer"
                                    onClick={viewMiningContractInAnalytics}
                                ></Image>
                            )}
                            <Text
                                variant="caption"
                                color="tertiary.500"
                                w={oneLineMode && isLoggedIn && positionList.length > 0 ? '140px' : 'unset'}
                                ml="10px !important"
                            >
                                {noPosition
                                    ? t("You don't have any iZiSwap  position for now, click ' Add Liquidity ' to start farming.")
                                    : ''}
                            </Text>
                        </HStack>
                    )}
                </Stack>

                {!oneLineMode && noPosition && (
                    <HStack>
                        {noPosition && (
                            <Image
                                boxSize="35px"
                                src={colorTheme('/assets/trade/limitOrderDashboard.svg', '/assets/trade/darklimitOrderDashboard.svg')}
                                cursor="pointer"
                                onClick={viewMiningContractInAnalytics}
                            ></Image>
                        )}
                        <Text
                            variant="caption"
                            color="tertiary.500"
                            w={oneLineMode && isLoggedIn && positionList.length > 0 ? '140px' : 'unset'}
                        >
                            {noPosition ? t("You don't have any iZiSwap  position for now, click ' Add Liquidity ' to start farming.") : ''}
                        </Text>
                    </HStack>
                )}

                <Box flexGrow={1} pl="0px">
                    {positionList.map((item, index) => (
                        <Grid
                            py="10px"
                            key={index}
                            alignItems="center"
                            justifyContent={{ base: 'center', sm: 'unset' }}
                            templateRows={{ base: 'repeat(8, 1fr)', sm: `repeat(${oneLineMode ? 1 : 3}, 1fr)` }}
                            templateColumns={`repeat(${oneLineMode ? 4 : 2}, max-content) auto`}
                            autoFlow={oneLineMode ? 'row' : 'column'}
                            _notLast={{
                                borderBottom: theme,
                            }}
                            _last={{
                                marginBottom: '-26px',
                            }}
                        >
                            <HStack spacing="10px" w={oneLineMode ? '190px' : '247px'}>
                                <NFTId
                                    direction={oneLineMode ? 'column' : 'row'}
                                    id={item.nftId}
                                    w={oneLineMode ? '75px' : '130px'}
                                    link={getNftUrl(chainId, iZiSwapNftAddr, item.nftId)}
                                />
                                {DetailsButton(index)}
                            </HStack>

                            <Info
                                direction={oneLineMode ? 'column' : 'row'}
                                label={t('TVL')}
                                value={item.tvl === undefined ? '...' : `$${formatNumber(item.tvl)}`}
                                w={oneLineMode ? '110px' : '121px'}
                            />

                            {entry.meta.iZiBoost && item.isStaked && (
                                <HStack spacing="10px" w={oneLineMode ? '160px' : '247px'} h={oneLineMode ? 'unset' : '32px'}>
                                    <Info
                                        direction={oneLineMode ? 'column' : 'row'}
                                        label={t('My APR')}
                                        value={
                                            item.apr <= 99.99
                                                ? formatNumber(item.apr * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) + '%'
                                                : '9999+%'
                                        }
                                        w={oneLineMode ? '70px' : '130px'}
                                    />
                                    {!entry.data.isEnded && BoostButton(index)}
                                </HStack>
                            )}

                            {!isNeedApprove ? (
                                !entry.meta.veiZiBoost ? (
                                    <Earned
                                        gridRow="span 3"
                                        entry={entry}
                                        tokenId={item.nftId}
                                        onHarvest={() => {
                                            onHarvest(index);
                                        }}
                                        earned={
                                            (item as any).earned &&
                                            (item as any).earned.map((item: any) => {
                                                return parseFloat(item);
                                            })
                                        }
                                    />
                                ) : (
                                    <HStack gridRow="span 3" w="190px" />
                                )
                            ) : (
                                <Text variant="caption" color="tertiary.500" ml="140px">
                                    Waiting for approve...
                                </Text>
                            )}
                            {!isNeedApprove && item.isStaked && UnstakeButton(index)}
                        </Grid>
                    ))}
                    {positionList.length > 0 && entry.meta.veiZiBoost && (
                        <Grid
                            mt="20px"
                            alignItems="center"
                            templateRows={{ base: 'repeat(1, 1fr)', sm: `repeat(${oneLineMode ? 1 : 3}, 1fr)` }}
                            templateColumns={`repeat(${oneLineMode ? 4 : 2}, max-content) auto`}
                            autoFlow={oneLineMode ? 'row' : 'column'}
                            _notLast={{
                                borderBottom: theme,
                            }}
                            _last={{
                                marginBottom: { base: '0px', sm: '-26px' },
                            }}
                        >
                            <Stack direction={oneLineMode ? 'row' : 'column'}>
                                <Card variant="grey" p="10px 40px 20px 40px" h="60px" w="130px">
                                    <Info
                                        direction="column"
                                        label={t('My APR')}
                                        value={
                                            entry.userData.apr <= 99.99
                                                ? formatNumber(entry.userData.apr * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) + '%'
                                                : '9999+%'
                                        }
                                        w="60px"
                                    />
                                </Card>
                                <VeNFTBlock
                                    w={{ base: '100%', sm: 'unset' }}
                                    h={{ base: 'unset', sm: '60px' }}
                                    p={{ base: '10px 20px 20px 20px', sm: '10px 40px 20px 40px' }}
                                    entry={
                                        entry.userData.veiZiNftId === '0'
                                            ? undefined
                                            : {
                                                  nftId: entry.userData.veiZiNftId,
                                                  veiZiDecimal: entry.userData.veiZiDecimal,
                                              }
                                    }
                                />
                            </Stack>
                        </Grid>
                    )}
                </Box>
            </Stack>

            {showModal && (
                <PositionDetailModal
                    isOpen={showModal}
                    entry={entry}
                    index={detailNftIdx}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
            )}

            {showBoostModal && (
                <BoostModal
                    isOpen={showBoostModal}
                    entry={entry}
                    index={detailNftIdx}
                    onClose={() => {
                        setShowBoostModal(false);
                    }}
                    onSuccess={refreshPoolListDataAndPosition}
                />
            )}

            {showAddLiquidityModal && (
                <AddLiquidityModal
                    isOpen={showAddLiquidityModal}
                    onClose={() => {
                        setShowAddLiquidityModal(false);
                    }}
                    onSuccess={refreshPosition}
                    entry={entry}
                />
            )}
        </>
    );
};

const Wrapper = chakra(({ entry, ...rest }: { entry: PoolEntryState } & FarmRefreshHandle) => {
    return (
        <PositionListProvider value={entry}>
            <PositionList {...rest} />
        </PositionListProvider>
    );
});

export default Wrapper;
