import { Box, Flex, Text, useColorMode, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { tabData } from '../../../net/iZUMi-endpoints/src/restful/izumiGame';
import { i_text_cardTitle } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';

type WinnerListProps = {
    tabData: tabData[];
};

const WinnerList: React.FC<WinnerListProps> = (pros) => {
    const { tabData } = pros;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const tabs = [
        {
            title: t('Rank'),
            width: { base: '18%' },
        },
        {
            title: t('Address'),
            width: { base: '32%' },
        },
        {
            title: t('Score'),
            width: { base: '25%' },
        },
        {
            title: t('Reward'),
            width: { base: '25%' },
        },
    ];
    return (
        <Flex w="100%" h="295px" direction="column" bg={colorTheme('#F9F9F9', '#211834')}>
            <Box pl={{ base: '26px', lg: '61px' }} overflow="auto" mt="21px" pb="21px">
                <Flex
                    className={i_text_cardTitle}
                    w="100%"
                    color={colorTheme('tertiary.500', 'tertiary.300')}
                    position="sticky"
                    top="0px"
                    bg={colorTheme('#F9F9F9', '#211834')}
                    pb="5px"
                >
                    {tabs.map((item, index) => {
                        return (
                            <Text w={item.width} key={index}>
                                {item.title}
                            </Text>
                        );
                    })}
                </Flex>
                {tabData.map((item, index) => {
                    return (
                        <Flex className={i_text_cardTitle} key={index} mt="20px" pl="1px">
                            <Text w={tabs[0].width} fontWeight="700 !important">
                                {item.rank}
                            </Text>
                            <Text w={tabs[1].width} isTruncated={true} pr="5px">
                                {truncateWalletAddress(item.address, 4, 4)}
                            </Text>
                            <Text w={tabs[2].width} fontWeight="700 !important">
                                {item.score}
                            </Text>
                            <Flex w={tabs[3].width}>
                                <Image boxSize="16px" src={process.env.PUBLIC_URL + '/assets/Prize/izi.svg'}></Image>

                                <Text fontWeight="700 !important" ml="9px">
                                    {item.reward}
                                </Text>
                            </Flex>
                        </Flex>
                    );
                })}
            </Box>
        </Flex>
    );
};
export default WinnerList;
