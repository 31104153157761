export const announceConfig =
    process.env.REACT_APP_ENV === 'production'
        ? [
              {
                  text: 'Reminder: \xa0\xa0  Feel free if you find the estimated gas is too high since about 50%~90% gas used will be refunded automatically. ',
                  icon: '',
                  type: 'link',
                  linkText: 'Check more.',
                  onClick: null,
                  index: 0,
              },
          ]
        : [
              {
                  text: 'Testnet only, last update time: 2023.01.31',
                  icon: '',
                  type: 'link',
                  linkText: 'Check more.',
                  onClick: null,
                  index: 0,
              },
          ];
