import { BoxProps, useColorMode, HStack, VStack, Text, Box, Image, Divider } from '@chakra-ui/react';
import { getColorThemeSelector, getFormatDateDiff, getHiddenAddress } from '../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy, i_h4 } from '../../../../style';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import Info from '../../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { NFTId } from '../../../../iZUMi-UI-toolkit/src/components/NFTId/NFTId';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
import { VeTokenAmount } from '../../components/VeTokenAmount';
import { LockTime } from '../../components/LockTime';
import { VeTokenNft } from '../../../../state/models/veToken/types';
import { RootState } from '../../../../state/store';
import { useSelector } from 'react-redux';
import { VETOKEN_ADDRESS } from '../../../../config/veToken/veTokenContracts';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { MiningCallbacks } from '../../../../state/models/hooks/farm/common/callbacks';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import useVeTokenEntity from '../../../../hooks/useVeTokenEntity';

type TransferModalProps = {
    isOpen: boolean | any;
    onClose: () => void;
    entry: VeTokenNft;
    toAddress: string;
    onSuccess?: () => void;
} & BoxProps;

export const TransferModal: React.FC<TransferModalProps> = (props) => {
    const { isOpen, onClose, entry, toAddress, onSuccess, ...rest } = props;

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const { chainId } = useWeb3WithDefault();

    const { veToken } = useSelector((state: RootState) => state);
    const toast = useCustomToast();
    const currentTimestamp = veToken.globalData?.currentTimestamp ?? 0;
    const currentDate = new Date(currentTimestamp * 1000);
    const unlockDate = new Date(entry.endTimestamp * 1000);
    const veTokenAddress = VETOKEN_ADDRESS[chainId];
    const veTokenEntity = useVeTokenEntity(veTokenAddress, 'V1');
    const { gasPrice } = useGasPrice();

    return (
        <Modal isOpen={isOpen} onClose={onClose} w="818px" title="Confirming NFT Transfer" {...rest}>
            <Divider mt="-20px !important" />

            <Box bg={colorTheme('#FFF0F3', 'rgba(94, 42, 52, 0.46)')} w="100%" h="100px" px="20px" pt="20px">
                <VStack alignItems="left">
                    <Text className={i_text_copy} fontWeight="bold" fontSize="14px" color={colorTheme('#FA4D6C', '#C54158')}>
                        Please confirm receipt address
                    </Text>

                    <Text fontSize="12px" className={i_text_copy} color={colorTheme('#FA3155', '#B44358')}>
                        To avoid permanent losses, please make sure the recipient address is correct before initiated the transfer.
                    </Text>
                </VStack>
            </Box>

            <Card variant="base" w="100%" minH="60px" pt="22px" pb="10px" pl="20px" borderRadius="4px" mb="20px !important" mt="20px">
                <HStack w="100%" justifyContent="space-between">
                    <HStack spacing="40px">
                        <NFTId direction="column" id={entry.nftId as unknown as string} w="75px" link="" />
                    </HStack>

                    <HStack>
                        <Info label="Owner" value={getHiddenAddress(entry.owner)} w="120px" />
                        <VeTokenAmount balance={entry.veTokenDecimal} />
                        <LockTime time={getFormatDateDiff(currentDate, unlockDate)} />
                    </HStack>
                </HStack>
            </Card>

            <HStack spacing="80px" mt="30px !important">
                <Text fontSize="14px" className={i_text_copy} color={colorTheme('tertiary.500', 'tertiary.500')}>
                    To address:
                </Text>
                <Text fontSize="18px" className={i_h4} color={colorTheme('tertiary.800', 'tertiary.100')} textAlign="center">
                    {toAddress}
                </Text>
            </HStack>

            <VStack w="100%" mt="20px">
                <CustomButton
                    variant="purple"
                    mt="20px !important"
                    text={
                        <HStack w="100%" position="relative">
                            <HStack mx="auto !important">
                                <Image w="17px" h="17px" src={process.env.PUBLIC_URL + '/assets/tokens/arctic.png'} />
                                <Text>Confirm Transfer</Text>
                            </HStack>
                        </HStack>
                    }
                    w="304px"
                    h="40px"
                    fontClass={i_text_copy_bold}
                    fontSize="14px"
                    onClick={() => {
                        veTokenEntity.safeTransfer(
                            toAddress,
                            entry.nftId,
                            {
                                onTransactionHash: (e: any) => {
                                    toast('info', 'Transfer veARC NFT: ' + e);
                                },
                                then: (e: any) => {
                                    console.log(e);
                                    toast('info', 'Transfer veARC NFT successfully');
                                    onSuccess?.();
                                },
                                catch: (e: any) => {
                                    console.log(e);
                                    toast('error', e.message);
                                },
                            } as MiningCallbacks,
                            gasPrice
                        );
                    }}
                />
            </VStack>
        </Modal>
    );
};
