import { BoxProps, useColorMode, HStack, VStack, Text, Box, Image, Divider, Icon, Flex, Stack } from '@chakra-ui/react';
import { getColorThemeSelector, getFormatDateDiff } from '../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy, i_h2 } from '../../../../style';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { Modal } from '../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { BsClock } from 'react-icons/bs';
import { BiCoinStack } from 'react-icons/bi';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
import DatePicker from 'react-datepicker';
import { useCallback, useState } from 'react';
import { CgCalendar } from 'react-icons/cg';
import { TokenBalanceBlock } from '../../../Trade/components/TokenBalanceBlock';
import { AmountInput } from '../../../Trade/components/AmountInput';
import { decimal2Amount, formatNumber } from '../../../../utils/tokenMath';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import useTokenEntity from '../../../../state/models/hooks/useTokenEntity';
import { VETOKEN_ADDRESS } from '../../../../config/veToken/veTokenContracts';
import { isNumeric } from '../../../../utils/valid';
import BigNumber from 'bignumber.js';
import { RootState } from '../../../../state/store';
import { useSelector } from 'react-redux';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import { MiningCallbacks } from '../../../../state/models/hooks/farm/common/callbacks';
import ApproveTokenButton from '../../../components/ApproveTokenButton';
import { getStakeAPR } from '../../../../state/models/veToken/funcs';
import { useARCToken } from '../../../../hooks/useARCToken';
import useVeTokenEntity from '../../../../hooks/useVeTokenEntity';

type LockModalProps = {
    isOpen: boolean | any;
    onClose: () => void;
    refreshAllData: () => void;
    onSuccess?: () => void;
} & BoxProps;

export const LockModal: React.FC<LockModalProps> = (props) => {
    const { isOpen, onClose, refreshAllData, ...rest } = props;
    const { chainId } = useWeb3WithDefault();
    const { veToken } = useSelector((state: RootState) => state);

    const toast = useCustomToast();

    const veTokenAddress = VETOKEN_ADDRESS[chainId];

    const { ARCToken } = useARCToken();
    const veTokenEntity = useVeTokenEntity(veTokenAddress, 'V2');
    const ARCEntity = useTokenEntity(ARCToken, veTokenAddress);

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const currentTimestamp = veToken.globalData?.currentTimestamp ?? 0;
    const currentDate = new Date(currentTimestamp * 1000);
    const startDate = currentDate;
    const [endDate, setEndDate] = useState(currentDate);
    const [lockSeconds, setLockSeconds] = useState(0);

    const { gasPrice } = useGasPrice();

    console.log('startDate: ', startDate);

    function estimateVeARC(year: number) {
        const date = new Date(startDate);
        date.setFullYear(startDate.getFullYear() + year);
        const seconds4Year = veToken.globalData?.seconds4Year ?? 0;
        const maxMilliseconds = seconds4Year * 1000;
        const latestEndDate = new Date(startDate.getTime() + maxMilliseconds);

        let newDate = date;
        if (date.getTime() > latestEndDate.getTime()) {
            newDate = new Date(latestEndDate.getTime());
        }
        if (newDate.getTime() > startDate.getTime()) {
            const deltaMilliSeconds = newDate.getTime() - startDate.getTime();
            const deltaSeconds = deltaMilliSeconds / 1000;
            if (seconds4Year > 0) {
                const deltaTime = Math.min(deltaSeconds, seconds4Year);
                return ((1 * deltaTime) / Number(veToken.globalData?.seconds4Year)).toFixed(2) ?? 0;
            }
        }
    }

    const tryToUpdateEndDate = useCallback(
        (date: Date) => {
            const seconds4Year = veToken.globalData?.seconds4Year ?? 0;
            const maxMilliseconds = seconds4Year * 1000;
            const latestEndDate = new Date(startDate.getTime() + maxMilliseconds);

            let newDate = date;
            if (date.getTime() > latestEndDate.getTime()) {
                newDate = new Date(latestEndDate.getTime());
            }
            if (newDate.getTime() > startDate.getTime()) {
                setEndDate(newDate);
                const deltaMilliSeconds = newDate.getTime() - startDate.getTime();
                const deltaSeconds = deltaMilliSeconds / 1000;
                if (seconds4Year > 0) {
                    const deltaTime = Math.min(deltaSeconds, seconds4Year);
                    setLockSeconds(deltaTime);
                }
            }
        },
        [startDate, veToken.globalData]
    );

    const [inputValueDecimal, setInputValueDecimal] = useState(0);

    const veTokenDecimal = (inputValueDecimal * lockSeconds) / Number(veToken.globalData?.seconds4Year) ?? 0;
    const veTokenDecimal1Year = estimateVeARC(1);
    const veTokenDecimal2Year = estimateVeARC(2);
    const veTokenDecimal3Year = estimateVeARC(3);
    const veTokenDecimal4Year = estimateVeARC(4);

    const [inputValue, setInputValue] = useState('0');
    const veTokenAmount = decimal2Amount(new BigNumber(veTokenDecimal), ARCToken)?.toFixed(0, 3) ?? '0';
    const stakeAPR = getStakeAPR(
        veToken.globalData?.rewardPerBlock ?? '0',
        veToken.globalData?.stakeTokenAmount ?? '0',
        inputValue,
        veTokenAmount,
        chainId
    );
    //console.log('inputvalue(no decial): ', inputValue);
    const locktimes = [
        [1, '1 month'],
        [2, '6 months'],
        [3, '1 year'],
        [4, '2 years'],
        [5, '3 years'],
        [6, '4 years'],
    ];

    const tokenStat = (img: any, text: any, value: number, symbol: any) => {
        return (
            <Stack
                w="90%"
                h="70px"
                py="10px"
                pl="20px"
                borderRadius="4px"
                justifyContent="space-between"
                direction={{ base: 'column', sm: 'row' }}
            >
                <HStack>
                    <Image w="18px" h="18px" src={process.env.PUBLIC_URL + img} />
                    <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.100')}>
                        {text}
                    </Text>
                </HStack>

                <HStack mt="12px">
                    <Text fontSize="16px" className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.50')}>
                        {formatNumber(value, 2, 2)}
                    </Text>

                    <Text fontSize="16px" className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.400')}>
                        {symbol}
                    </Text>
                </HStack>
            </Stack>
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '338px', sm: '788px' }}
            //h="750px"
            title="Lock ARC to get veARC"
            {...rest}
        >
            <Divider mt="-20px !important" />
            <VStack w="100%" mt="20px">
                <Stack w="100%" spacing="30px" direction={{ base: 'column', sm: 'row' }}>
                    <VStack w={{ base: '100%', sm: '334px' }} h="230px" alignItems="left">
                        <Text className={i_text_copy_bold} color={colorTheme('tertiary.500', 'tertiary.100')}>
                            Lock ARC Amount
                        </Text>
                        <Card variant="deep" w="100%" h="195px" p="10px">
                            <TokenBalanceBlock token={ARCToken} balance={ARCEntity.tokenBalance()} p="10px" />
                            <Divider my="10px !important" />

                            <Box w="100%" px="10px" mt="20px">
                                <AmountInput
                                    handleSetValue={(v: any) => {
                                        if (isNumeric(Number(v))) {
                                            setInputValueDecimal(Number(v));
                                            const inputValueBN = decimal2Amount(new BigNumber(Number(v)), ARCToken) ?? new BigNumber(0);
                                            setInputValue(inputValueBN.toFixed(0, 3));
                                        }
                                    }}
                                    token={ARCToken}
                                    price={ARCEntity.tokenPrice()}
                                    balance={ARCEntity.tokenBalance()}
                                    errorInfo=""
                                    inputValue={inputValueDecimal}
                                    w="60%"
                                    fontClass={i_h2}
                                />
                            </Box>
                        </Card>
                    </VStack>

                    <VStack w={{ base: '100%', sm: '334px' }} h="230px" alignItems="left">
                        <Text className={i_text_copy_bold} color={colorTheme('tertiary.500', 'tertiary.100')}>
                            Lock Time
                        </Text>

                        <Card variant="deep" w="100%" h="91px" p="12px 20px 12px 20px">
                            <VStack alignItems="left">
                                <Text fontSize="12px" className={i_text_copy} color={colorTheme('tertiary.500', 'tertiary.100')}>
                                    Until :
                                </Text>
                                <HStack spacing="10px">
                                    <Icon as={CgCalendar} boxSize="24px" />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date: Date) => {
                                            tryToUpdateEndDate(date);
                                        }}
                                        className={colorTheme('light-picker', 'dark-picker')}
                                    />
                                </HStack>
                            </VStack>
                        </Card>
                        <Divider m="0" />

                        <Flex mx="auto" w="100%" flexWrap="wrap">
                            {locktimes.map((t: any, i: any) => {
                                return (
                                    <Flex key={i} w="32%" mr="2px" mt="2px">
                                        <CustomButton
                                            variant="outlinePurple"
                                            text={t[1]}
                                            w="100%"
                                            fontSize="12px"
                                            h="41px"
                                            onClick={() => {
                                                const date = new Date(startDate);
                                                if (t[0] === 1) {
                                                    date.setMonth(startDate.getMonth() + 1);
                                                } else if (t[0] === 2) {
                                                    date.setMonth(startDate.getMonth() + 6);
                                                } else if (t[0] === 3) {
                                                    date.setFullYear(startDate.getFullYear() + 1);
                                                } else if (t[0] === 4) {
                                                    date.setFullYear(startDate.getFullYear() + 2);
                                                } else if (t[0] === 5) {
                                                    date.setFullYear(startDate.getFullYear() + 3);
                                                } else if (t[0] === 6) {
                                                    date.setFullYear(startDate.getFullYear() + 4);
                                                }
                                                tryToUpdateEndDate(date);
                                            }}
                                        />
                                    </Flex>
                                );
                            })}
                        </Flex>
                    </VStack>
                </Stack>

                <Card variant="purpleLinear" w="100%" h={{ base: '118px', sm: '120px' }}>
                    <HStack w="100%">
                        {tokenStat('/assets/tokens/veToken.svg', 'Estimate Initial Voting Power:', veTokenDecimal, 'veARC')}
                    </HStack>

                    <HStack w="100%">
                        <Stack
                            w="90%"
                            h={{ base: 'unset', sm: '30px' }}
                            pl="20px"
                            borderRadius="4px"
                            justifyContent="space-between"
                            direction={{ base: 'column', sm: 'row' }}
                        >
                            <HStack>
                                <Icon as={BsClock} boxSize="16px" />
                                <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.100')}>
                                    Estimate End Lock Time :
                                </Text>
                            </HStack>

                            <HStack mt="12px">
                                <Text fontSize="16px" className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.50')}>
                                    {getFormatDateDiff(startDate, endDate)}
                                </Text>
                            </HStack>
                        </Stack>
                    </HStack>

                    {/* <HStack w="100%">
                        <Stack
                            w="90%"
                            h={{ base: 'unset', sm: '30px' }}
                            my="14px"
                            pl="20px"
                            borderRadius="4px"
                            justifyContent="space-between"
                            direction={{ base: 'column', sm: 'row' }}
                        >
                            <HStack>
                                <Icon as={BiCoinStack} boxSize="16px" />
                                <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.100')}>
                                    Staking APR:
                                </Text>
                            </HStack>

                            <HStack mt="12px">
                                <Text fontSize="16px" className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.50')}>
                                    {formatNumber(stakeAPR * 100, 2, 6)}%
                                </Text>
                            </HStack>
                        </Stack>
                    </HStack> */}
                </Card>
                <Stack w="100%" className={i_text_copy}>
                    <Text color="gray.400">
                        1 ARC locked for 4 years =<Text as="span">{veTokenDecimal4Year}</Text>
                        veARC
                    </Text>
                    <Text color="gray.400">
                        1 ARC locked for 3 years =<Text as="span">{veTokenDecimal3Year}</Text>
                        veARC
                    </Text>
                    <Text color="gray.400">
                        1 ARC locked for 2 years =<Text as="span">{veTokenDecimal2Year}</Text>
                        veARC
                    </Text>
                    <Text color="gray.400">
                        1 ARC locked for 1 years =<Text as="span">{veTokenDecimal1Year}</Text>
                        veARC
                    </Text>
                </Stack>
                {inputValue === '0' || ARCEntity.isApproved() ? (
                    <CustomButton
                        variant="purple"
                        mt="20px !important"
                        text={
                            <HStack m="auto">
                                <Image w="17px" h="17px" src={process.env.PUBLIC_URL + '/assets/tokens/arctic.png'} />
                                <Text>Lock ARC</Text>
                            </HStack>
                        }
                        w="304px"
                        h="40px"
                        fontClass={i_text_copy_bold}
                        fontSize="14px"
                        onClick={() => {
                            console.log('ARC amount ', inputValue);
                            console.log('lock seconds ', lockSeconds);
                            console.log('end lock num: ', (Number(veToken.globalData?.currentTimestamp) ?? 0) + lockSeconds);
                            const endBlockNum = new BigNumber((Number(veToken.globalData?.currentTimestamp) ?? 0) + lockSeconds).toFixed(0);
                            veTokenEntity.createLock(
                                inputValue,
                                endBlockNum,
                                {
                                    onTransactionHash: (e: any) => {
                                        toast('info', 'Lock ARC: ' + e);
                                    },
                                    then: (e: any) => {
                                        console.log(e);
                                        toast('info', 'Lock ARC successfully');
                                        refreshAllData();
                                    },
                                    catch: (e: any) => {
                                        console.log(e);
                                        toast('error', e.message);
                                    },
                                } as MiningCallbacks,
                                gasPrice
                            );
                        }}
                    />
                ) : (
                    <ApproveTokenButton tokenEntity={ARCEntity} mt="10px !important" w="200px" mx="auto !important" />
                )}
            </VStack>
        </Modal>
    );
};
