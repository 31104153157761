import { createModel } from '@rematch/core';
import { RootModel } from '../../../../index';
import produce from 'immer';

import { ChainId, TokenSymbol } from '../../../../../../types/mod';
import { findPoolEntryByPoolKey, symbol2Decimal, calcAmountDesiredFrom0, calcAmountDesiredFrom1, veiZiNoBoostNewNftAPR, veiZiBoostNewNftAPR, veiZiBoostForNewNftDesiredBoost } from '../funcs';
import { getLiquidityIZIBoostAPR } from '../funcs';
import BigNumber from 'bignumber.js';
import { amount2Decimal } from '../../../../../../utils/tokenMath';
import { tokenSymbol2token } from '../../../../../../config/tokens';

export interface MintForm {

    tokenADecimal: number,
    tokenBDecimal: number,

    positionPoolKey: string,

    uniLiquidity: number,
    vLiquidity: number,

    tokenAAmount: number,
    tokenAAmountDecimal: number,

    tokenBAmount: number,
    tokenBAmountDecimal: number,

    tickLeft: number,
    tickRight: number,

    iZiBoost: boolean,
    iZiAmount: number,
    iZiAmountDecimal: number,

    veiZiBoost: boolean,
    veiZi: number,
    validVeiZi: number,
    userVLiquidity: number,
    userCapital: number,

    apr: number,
    aprBoostIZI: number,
    aprBoostVeiZi: number,
    veiZiForFullBoostDecimal: number,
}

export interface farmDynamicRangeAddLiquidityState {
    mintForm: MintForm;
}


export const farmDynamicRangeiZiAddLiquidity = createModel<RootModel>()({
    state: {
        mintForm: {} as MintForm
    } as farmDynamicRangeAddLiquidityState,

    reducers: {
        setMintForm: (state: farmDynamicRangeAddLiquidityState, mintForm: MintForm) => produce(state, draft => {
            draft.mintForm = mintForm;
        }),
    },

    effects: (dispatch) => ({
        
        async refreshMintForm(params: {positionPoolKey: string, chainId: ChainId}, rootState): Promise<any> {
            const { positionPoolKey, chainId } = params;
            const poolEntry = findPoolEntryByPoolKey(rootState.farmDynamicRangeiZi.poolEntryList, positionPoolKey);
            const tokenADecimal = Number(symbol2Decimal(chainId, poolEntry.meta.tokenA.symbol));
            const tokenBDecimal = Number(symbol2Decimal(chainId, poolEntry.meta.tokenB.symbol));
            const mintForm = {

                tokenADecimal: tokenADecimal,
                tokenBDecimal: tokenBDecimal,

                positionPoolKey: poolEntry.meta.positionPoolKey,
                uniLiquidity: 0,
                vLiquidity: 0,
                tokenAAmount:0,
                tokenAAmountDecimal:0,
                tokenBAmount:0,
                tokenBAmountDecimal:0,

                tickLeft: poolEntry.data.tickLeft,
                tickRight: poolEntry.data.tickRight,

                iZiAmountDecimal: 0,
                iZiAmount: 0,
                apr: 0,
                aprBoostIZI: 0,
            } as MintForm;
            dispatch.farmDynamicRangeiZiAddLiquidity.setMintForm(mintForm);
        },
        updateAmountADecimal(params: { amountDecimal: number, positionPoolKey: string, chainId: number }, rootState): void {
            const {positionPoolKey, chainId} = params;
            const amountDecimal = Number(params.amountDecimal);
            const poolEntry = findPoolEntryByPoolKey(rootState.farmDynamicRangeiZi.poolEntryList, positionPoolKey);

            const mintFormOld = rootState.farmDynamicRangeiZiAddLiquidity.mintForm;
            
            const tickLeft = mintFormOld.tickLeft;
            const tickRight = mintFormOld.tickRight;
            const tokenAAmountInput = amountDecimal * (10 ** mintFormOld.tokenADecimal);
            const [tokenAAmount, tokenBAmount, uniLiquidity] = calcAmountDesiredFrom0(tokenAAmountInput, tickLeft, tickRight, poolEntry.data.currentTick);
            const tokenAAmountDecimal = tokenAAmount / (10 ** mintFormOld.tokenADecimal);
            const tokenBAmountDecimal = tokenBAmount / (10 ** mintFormOld.tokenBDecimal);

            const iZiAmount = mintFormOld.iZiAmount;
            const capital = tokenAAmountDecimal * poolEntry.data.tokenAPriceDecimal + tokenBAmountDecimal * poolEntry.data.tokenBPriceDecimal;
            const vLiquidity = uniLiquidity / 1e6;
            const apr = getLiquidityIZIBoostAPR(poolEntry, chainId, vLiquidity, capital, 0);
            const aprBoostIZI = getLiquidityIZIBoostAPR(poolEntry, chainId, vLiquidity, capital, iZiAmount);

            const mintFormNew = {...rootState.farmDynamicRangeiZiAddLiquidity.mintForm};
            mintFormNew.tokenAAmount = tokenAAmount;
            mintFormNew.tokenBAmount = tokenBAmount;
            mintFormNew.tokenAAmountDecimal = tokenAAmountDecimal;
            mintFormNew.tokenBAmountDecimal = tokenBAmountDecimal;
            mintFormNew.uniLiquidity = uniLiquidity;
            mintFormNew.vLiquidity = vLiquidity;
            mintFormNew.apr = apr;
            mintFormNew.aprBoostIZI = aprBoostIZI;

            dispatch.farmDynamicRangeiZiAddLiquidity.setMintForm(mintFormNew);
        },
        updateAmountBDecimal(params: { amountDecimal: number, positionPoolKey: string, chainId: number }, rootState): void {
            const {positionPoolKey, chainId} = params;
            const amountDecimal = Number(params.amountDecimal);
            const poolEntry = findPoolEntryByPoolKey(rootState.farmDynamicRangeiZi.poolEntryList, positionPoolKey);

            const mintFormOld = rootState.farmDynamicRangeiZiAddLiquidity.mintForm;
            
            const tickLeft = mintFormOld.tickLeft;
            const tickRight = mintFormOld.tickRight;
            const tokenBAmountInput = amountDecimal * (10 ** mintFormOld.tokenBDecimal);
            const [tokenAAmount, tokenBAmount, uniLiquidity] = calcAmountDesiredFrom1(tokenBAmountInput, tickLeft, tickRight, poolEntry.data.currentTick);
            const vLiquidity = uniLiquidity / 1e6;
            const tokenAAmountDecimal = tokenAAmount / (10 ** mintFormOld.tokenADecimal);
            const tokenBAmountDecimal = tokenBAmount / (10 ** mintFormOld.tokenBDecimal);

            const iZiAmount = mintFormOld.iZiAmount;
            const capital = tokenAAmountDecimal * poolEntry.data.tokenAPriceDecimal + tokenBAmountDecimal * poolEntry.data.tokenBPriceDecimal;
            const apr = getLiquidityIZIBoostAPR(poolEntry, chainId, vLiquidity, capital, 0);
            const aprBoostIZI = getLiquidityIZIBoostAPR(poolEntry, chainId, vLiquidity, capital, iZiAmount);

            const mintFormNew = {...rootState.farmDynamicRangeiZiAddLiquidity.mintForm};
            mintFormNew.tokenAAmount = tokenAAmount;
            mintFormNew.tokenBAmount = tokenBAmount;
            mintFormNew.tokenAAmountDecimal = tokenAAmountDecimal;
            mintFormNew.tokenBAmountDecimal = tokenBAmountDecimal;
            mintFormNew.vLiquidity = vLiquidity;
            mintFormNew.apr = apr;
            mintFormNew.aprBoostIZI = aprBoostIZI;

            dispatch.farmDynamicRangeiZiAddLiquidity.setMintForm(mintFormNew);
        },
        updateTokeniZiAmount(params: {amountDecimal: number, positionPoolKey: string, chainId: number}, rootState): void {
            const {positionPoolKey, chainId} = params;
            const amountDecimal = Number(params.amountDecimal);
            const poolEntry = findPoolEntryByPoolKey(rootState.farmDynamicRangeiZi.poolEntryList, positionPoolKey);
            const tokeniZiDecimal = Number(symbol2Decimal(chainId, TokenSymbol.IZI));
            const iZiAmountDecimal = amountDecimal;
            const iZiAmount = iZiAmountDecimal * 10 ** tokeniZiDecimal;
            const mintFormNew = {...rootState.farmDynamicRangeiZiAddLiquidity.mintForm};
            mintFormNew.iZiAmount = iZiAmount;
            mintFormNew.iZiAmountDecimal = iZiAmountDecimal;


            const vLiquidity = mintFormNew.vLiquidity;
            
            const capital = mintFormNew.tokenAAmountDecimal * poolEntry.data.tokenAPriceDecimal + mintFormNew.tokenBAmountDecimal * poolEntry.data.tokenBPriceDecimal;

            mintFormNew.apr = getLiquidityIZIBoostAPR(poolEntry, chainId, vLiquidity, capital, 0);

            mintFormNew.aprBoostIZI = getLiquidityIZIBoostAPR(poolEntry, chainId, vLiquidity, capital, iZiAmount);

            dispatch.farmDynamicRangeiZiAddLiquidity.setMintForm(mintFormNew);
        },
    })
});

export const veiZiFarmDynamicRangeiZiAddLiquidity = createModel<RootModel>()({
    state: {
        mintForm: {} as MintForm
    } as farmDynamicRangeAddLiquidityState,

    reducers: {
        setMintForm: (state: farmDynamicRangeAddLiquidityState, mintForm: MintForm) => produce(state, draft => {
            draft.mintForm = mintForm;
        }),
    },

    effects: (dispatch) => ({

        async refreshMintForm(params: {positionPoolKey: string, chainId: ChainId}, rootState): Promise<any> {
            const { positionPoolKey, chainId } = params;
            const poolEntry = findPoolEntryByPoolKey(rootState.farmDynamicRangeiZi.poolEntryList, positionPoolKey);
            const tokenADecimal = Number(symbol2Decimal(chainId, poolEntry.meta.tokenA.symbol));
            const tokenBDecimal = Number(symbol2Decimal(chainId, poolEntry.meta.tokenB.symbol));
            const mintForm = {
                tokenADecimal: tokenADecimal,
                tokenBDecimal: tokenBDecimal,

                positionPoolKey: poolEntry.meta.positionPoolKey,
                uniLiquidity: 0,
                vLiquidity: 0,
                tokenAAmount:0,
                tokenAAmountDecimal:0,
                tokenBAmount:0,
                tokenBAmountDecimal:0,

                tickLeft: poolEntry.data.tickLeft,
                tickRight: poolEntry.data.tickRight,

                iZiAmountDecimal: 0,
                iZiAmount: 0,
                apr: 0,
                aprBoostVeiZi: 0,

                veiZiBoost: poolEntry.meta.veiZiBoost,
                veiZi: poolEntry.userData.veiZi,
                validVeiZi: poolEntry.userData.validVeiZi,
                userVLiquidity: poolEntry.userData.vLiquidity,
                userCapital: poolEntry.userData.capital,

            } as MintForm;
            dispatch.veiZiFarmDynamicRangeiZiAddLiquidity.setMintForm(mintForm);
        },
        updateAmountADecimal(params: { amountDecimal: number, positionPoolKey: string, chainId: number }, rootState): void {
            const {amountDecimal, positionPoolKey, chainId} = params;
            const poolEntry = findPoolEntryByPoolKey(rootState.farmDynamicRangeiZi.poolEntryList, positionPoolKey);

            const mintFormOld = rootState.veiZiFarmDynamicRangeiZiAddLiquidity.mintForm;
            
            const tickLeft = mintFormOld.tickLeft;
            const tickRight = mintFormOld.tickRight;
            const tokenAAmountInput = amountDecimal * (10 ** mintFormOld.tokenADecimal);
            const [tokenAAmount, tokenBAmount, uniLiquidity] = calcAmountDesiredFrom0(tokenAAmountInput, tickLeft, tickRight, poolEntry.data.currentTick);
            const tokenAAmountDecimal = tokenAAmount / (10 ** mintFormOld.tokenADecimal);
            const tokenBAmountDecimal = tokenBAmount / (10 ** mintFormOld.tokenBDecimal);

            const mintFormNew = {...rootState.veiZiFarmDynamicRangeiZiAddLiquidity.mintForm};
            const capital = tokenAAmountDecimal * poolEntry.data.tokenAPriceDecimal + tokenBAmountDecimal * poolEntry.data.tokenBPriceDecimal;
            const vLiquidity = uniLiquidity / 1e6;
            const apr = veiZiNoBoostNewNftAPR(poolEntry, chainId, vLiquidity, capital);
            const aprBoostVeiZi = veiZiBoostNewNftAPR(poolEntry, chainId, mintFormNew.userVLiquidity, mintFormNew.userCapital, mintFormNew.veiZi, mintFormNew.validVeiZi, vLiquidity, capital);
            const veiZiForFullBoost = veiZiBoostForNewNftDesiredBoost(2.5, poolEntry, mintFormNew.userVLiquidity, mintFormNew.validVeiZi, vLiquidity);

            mintFormNew.tokenAAmount = tokenAAmount;
            mintFormNew.tokenBAmount = tokenBAmount;
            mintFormNew.tokenAAmountDecimal = tokenAAmountDecimal;
            mintFormNew.tokenBAmountDecimal = tokenBAmountDecimal;
            mintFormNew.uniLiquidity = uniLiquidity;
            mintFormNew.vLiquidity = vLiquidity;
            mintFormNew.apr = apr;
            mintFormNew.aprBoostVeiZi = aprBoostVeiZi;
            mintFormNew.veiZiForFullBoostDecimal = Number(amount2Decimal(new BigNumber(veiZiForFullBoost), tokenSymbol2token(TokenSymbol.IZI, chainId)));

            dispatch.veiZiFarmDynamicRangeiZiAddLiquidity.setMintForm(mintFormNew);
        },
        updateAmountBDecimal(params: { amountDecimal: number, positionPoolKey?: any, chainId: number }, rootState): void {
            const {amountDecimal, positionPoolKey, chainId} = params;
            const poolEntry = findPoolEntryByPoolKey(rootState.farmDynamicRangeiZi.poolEntryList, positionPoolKey);

            const mintFormOld = rootState.veiZiFarmDynamicRangeiZiAddLiquidity.mintForm;
            
            const tickLeft = mintFormOld.tickLeft;
            const tickRight = mintFormOld.tickRight;
            const tokenBAmountInput = amountDecimal * (10 ** mintFormOld.tokenBDecimal);
            const [tokenAAmount, tokenBAmount, uniLiquidity] = calcAmountDesiredFrom1(tokenBAmountInput, tickLeft, tickRight, poolEntry.data.currentTick);
            const vLiquidity = uniLiquidity / 1e6;
            const tokenAAmountDecimal = tokenAAmount / (10 ** mintFormOld.tokenADecimal);
            const tokenBAmountDecimal = tokenBAmount / (10 ** mintFormOld.tokenBDecimal);

            const mintFormNew = {...rootState.veiZiFarmDynamicRangeiZiAddLiquidity.mintForm};

            const capital = tokenAAmountDecimal * poolEntry.data.tokenAPriceDecimal + tokenBAmountDecimal * poolEntry.data.tokenBPriceDecimal;
          
            
            const apr = veiZiNoBoostNewNftAPR(poolEntry, chainId, vLiquidity, capital);
            const aprBoostVeiZi = veiZiBoostNewNftAPR(poolEntry, chainId, mintFormNew.userVLiquidity, mintFormNew.userCapital, mintFormNew.veiZi, mintFormNew.validVeiZi, vLiquidity, capital);
            const veiZiForFullBoost = veiZiBoostForNewNftDesiredBoost(2.5, poolEntry, mintFormNew.userVLiquidity, mintFormNew.validVeiZi, vLiquidity);
            
            mintFormNew.tokenAAmount = tokenAAmount;
            mintFormNew.tokenBAmount = tokenBAmount;
            mintFormNew.tokenAAmountDecimal = tokenAAmountDecimal;
            mintFormNew.tokenBAmountDecimal = tokenBAmountDecimal;
            mintFormNew.vLiquidity = vLiquidity;
            mintFormNew.apr = apr;
            mintFormNew.aprBoostVeiZi = aprBoostVeiZi;
            mintFormNew.veiZiForFullBoostDecimal = Number(amount2Decimal(new BigNumber(veiZiForFullBoost), tokenSymbol2token(TokenSymbol.IZI, chainId)));

            dispatch.veiZiFarmDynamicRangeiZiAddLiquidity.setMintForm(mintFormNew);
        },
    })
});