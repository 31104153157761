import { Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import placeholder from '../../../../assets/placeholder.png';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useHistory } from 'react-router';
import { a_display, a_h2 } from '../../../../iZUMi-UI-toolkit/src/style';

const HomePiece1: React.FC = () => {
    const history = useHistory();
    const isPC = useBreakpointValue({ base: false, xl: true });

    const Logo = (
        <Flex
            justifyContent="center"
            bgSize={'100% 100%'}
            bgImage={{ base: '/assets/home/homePiece1/shadow.png', sm: '' }}
            mt={{ base: '0px', sm: '50px', xl: '0px' }}
            direction="column"
            ml={{ base: '0px', xl: '44px' }}
        >
            <Image
                w={{ base: '222px', sm: '280px', xl: '520px' }}
                h={{ base: '189px', sm: '242px', xl: '442px' }}
                mt={{ base: '50px', sm: '0px' }}
                src={process.env.PUBLIC_URL + '/assets/home/homePiece1/rightIcon.gif'}
                fallbackSrc={placeholder}
            />
        </Flex>
    );
    return (
        <Flex
            backgroundSize="cover"
            w="100%"
            h={{ base: 'unset', md: 'unset', xl: '684px' }}
            boxSizing="border-box"
            overflow="hidden"
            pl={{ base: '27px', sm: '72px' }}
            pr={{ base: '27px', sm: '72px', xl: '0px' }}
            bgImage={{ base: '', sm: '/assets/home/homePiece1/shadow.png' }}
            bgSize="100% 100%"
        >
            <Flex
                w={{ base: '100%' }}
                h="100%"
                direction={{ base: 'column', xl: 'row' }}
                margin="auto"
                alignItems="center"
                justifyContent="center"
            >
                {!isPC && Logo}
                <Flex
                    w={{ base: '100%', xl: '1368px', xlp1: '1530px' }}
                    h="100%"
                    alignItems="center"
                    justifyContent="center"
                    bgImage={{ base: '', xl: process.env.PUBLIC_URL + '/assets/home/homePiece1/bottom.png' }}
                    bgRepeat="no-repeat"
                    bgPosition={{ base: '', xl: '349px 262px', xlp1: '427px 260px' }}
                >
                    <Flex w={{ base: '100%', sm: '586px' }} mr="0px" direction="column" mt={{ base: '60px', sm: '60px', xl: '0px' }}>
                        <Flex alignItems="center">
                            <Text
                                className={a_h2}
                                color="tertiary.50"
                                px={{ base: '10px', sm: '0px' }}
                                textAlign={{ base: 'center', sm: 'center', xl: 'unset' }}
                            >
                                The next-generation ecosystem-DEX on Aurora
                            </Text>
                        </Flex>
                        <Text className={a_display} color="tertiary.50" mt="32px" textAlign={{ base: 'center', sm: 'center', xl: 'unset' }}>
                            Arctic is leading the next chapter of Aurora’s development as the core ecosystem-DEX, bringing DeFi innovation
                            to Aurora in the forms of the veNFT and DLAMM (Discretized Liquidity AMM).
                        </Text>

                        <Flex
                            w="100%"
                            direction="row"
                            mt={{ base: '30px', md: '45px' }}
                            justifyContent={{ base: 'center', md: 'center', xl: 'unset' }}
                        >
                            <>
                                <CustomButton
                                    w={{ base: '150px', sm: '183px' }}
                                    h="59px"
                                    variant="dark"
                                    text="Trade Now"
                                    className={a_display}
                                    fontFamily="Gilroy-Bold !important"
                                    _hover={{ background: 'tertiary.700' }}
                                    onClick={() => {
                                        history.push('/trade/swap');
                                    }}
                                    rightIcon={<Image w="15px" h="22px" ml="13px" src="/assets/home/homePiece1/purpleRight.svg"></Image>}
                                ></CustomButton>
                                <CustomButton
                                    w={{ base: '150px', sm: '183px' }}
                                    h="59px"
                                    variant="dark"
                                    text="Farm Now"
                                    fontFamily="Gilroy-Bold !important"
                                    _hover={{ background: 'tertiary.700' }}
                                    ml={{ base: '10px', md: '13px', xl: '16px' }}
                                    className={a_display}
                                    onClick={() => {
                                        history.push('/farm/fix');
                                    }}
                                    rightIcon={<Image w="15px" h="22px" ml="13px" src="/assets/home/homePiece1/purpleRight.svg"></Image>}
                                ></CustomButton>
                            </>
                        </Flex>
                    </Flex>
                    {isPC && Logo}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default HomePiece1;
