import { useEffect, useState } from 'react';
import { useWeb3WithDefault } from './useWeb3WithDefault';
import { TokenInfoFormatted } from './useTokenListFormatted';
import tokens from '../config/tokens';

export const useARCToken = (): { ARCToken: TokenInfoFormatted } => {
    const { chainId } = useWeb3WithDefault();
    const [ARCTokenFormatted, setARCTokenFormatted] = useState(undefined as unknown as any);

    useEffect(() => {
        setARCTokenFormatted({
            chainId,
            name: 'Arctic Token',
            symbol: 'ARC',
            icon: '/assets/tokens/arc.png',
            address: tokens.ARC.contracts[chainId]?.address ?? '',
            decimal: 18,
            custom: false,
        });
    }, [chainId]);

    return {
        ARCToken: ARCTokenFormatted,
    };
};
