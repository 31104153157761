import { useEffect, useState } from 'react';
import { useWeb3WithDefault } from './useWeb3WithDefault';
import { ChainId } from '../types/mod';
import { TokenInfoFormatted } from './useTokenListFormatted';

export const usePrizeToken = (): { prizeToken: TokenInfoFormatted[] } => {
    const { chainId } = useWeb3WithDefault();
    const [prizeTokenList, setPrizeTokenList] = useState([undefined as unknown as any]);

    useEffect(() => {
        setPrizeTokenList([
            {
                chainId,
                name: 'iZUMi Token',
                symbol: 'iZi',
                icon: '/assets/tokens/izumi.svg',
                address:
                    (
                        {
                            [ChainId.EthereumMainnet]: '0x9ad37205d608B8b219e6a2573f922094CEc5c200',
                            [ChainId.Matic]: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
                            [ChainId.Arbitrum]: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
                            [ChainId.BSCTestnet]: '0x551197e6350936976DfFB66B2c3bb15DDB723250',
                            [ChainId.BSC]: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
                        } as any
                    )[chainId] ?? '',
                decimal: 18,
                custom: false,
            },
            process.env.REACT_APP_ENV === 'production'
                ? {
                      chainId,
                      name: 'BNB Token',
                      symbol: 'WBNB',
                      icon: '/assets/tokens/bsc.png',
                      address:
                          (
                              {
                                  [ChainId.BSC]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                              } as any
                          )[chainId] ?? '',
                      decimal: 18,
                      custom: false,
                  }
                : {
                      chainId,
                      name: 'BSC Test Token',
                      symbol: 'WBNB',
                      icon: '/assets/tokens/bsc.png',
                      address:
                          (
                              {
                                  [ChainId.BSCTestnet]: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
                              } as any
                          )[chainId] ?? '',
                      decimal: 18,
                      custom: false,
                  },
        ]);
    }, [chainId]);
    return {
        prizeToken: prizeTokenList,
    };
};
