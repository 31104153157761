import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, TokenSymbol, FarmDynamicRangeiZiContractVersion } from '../../../../types/mod';
import { sortedToken } from '../../../../utils/tokenMath';

export interface MiningPoolMetaConfig {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    miningContract?: any;
    iZiSwapAddress: string;
    initialToggle?: boolean;

    iZiBoost?: boolean;
    veiZiBoost?: boolean;
    contractVersion?: FarmDynamicRangeiZiContractVersion;
    twoRewards?: boolean;
    noFee?: boolean;
    feeCharged?: number;

    priceRangeRatio?: number;

    // if priceRangeRatio is undefined, means
    // leftRangeRatio != rightRangeRatio
    // leftRangeRatio >= 1
    leftRangeRatio?: number;
    // rightRangeRatio >= 1
    rightRangeRatio?: number;
    isEnded?: boolean;

    useTimestamp?: boolean;

    additionalKey?: string;
}

export const miningPoolConfigList: Partial<Record<ChainId, MiningPoolMetaConfig[]>> =
    process.env.REACT_APP_ENV === 'production'
        ? {
            [ChainId.EthereumMainnet]: [],
            [ChainId.Matic]: [],
            [ChainId.BSC]: [
                //   {
                //       ...sortedToken(TokenSymbol.LING, TokenSymbol.USDT, ChainId.BSC),
                //       miningContract: '0x67De350b559E5fe36FC9124131e440FC7EaD920B',
                //       iZiSwapAddress: '0x505ecba1c3dacc65f2e7eb6bb7ee5485840bc333',
                //       feeTier: 0.2,
                //       twoRewards: true,
                //       iZiBoost: true,
                //       contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                //       noFee: false,
                //       feeCharged: 1,
                //       priceRangeRatio: 4,
                //       isEnded: true,
                //   },
                {
                    ...sortedToken(TokenSymbol.WOO, TokenSymbol.BNB, ChainId.BSC),
                    miningContract: '0xeC066c10B79c5f5663E3B224B731e5b390B29ADE',
                    iZiSwapAddress: '0x0c6c93F7DD4F38fb397c6CCCC4a6a74f069b6223',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                },
                //   {
                //       ...sortedToken(TokenSymbol.ERA, TokenSymbol.BUSD, ChainId.BSC),
                //       miningContract: '0x1155001d6Aea041DcB7F3ff643478A1F78245432',
                //       iZiSwapAddress: '0x0Fac07Aa28dDc33C705Dcd4eDCAECD3319560bF0',
                //       feeTier: 1,
                //       twoRewards: true,
                //       iZiBoost: true,
                //       contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                //       noFee: false,
                //       feeCharged: 1,
                //       leftRangeRatio: 2,
                //       rightRangeRatio: 2,
                //       isEnded: true,
                //   },
                //   {
                //       ...sortedToken(TokenSymbol.GOT, TokenSymbol.BUSD, ChainId.BSC),
                //       miningContract: '0x7Aa9E66C4528a9bDeE77D534465593A73061C5De',
                //       iZiSwapAddress: '0x4458086737E35729277E9C4f0F62A04Bf4Ea77Be',
                //       feeTier: 1,
                //       twoRewards: true,
                //       iZiBoost: true,
                //       contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                //       noFee: false,
                //       feeCharged: 1,
                //       leftRangeRatio: 1.0101010101010101,
                //       rightRangeRatio: 5,
                //       isEnded: true,
                //   },
                {
                    ...sortedToken(TokenSymbol.ZBC, TokenSymbol.USDT, ChainId.BSC),
                    miningContract: '0xBba490e2aC03122E23C3c682E71a6E118e30E753',
                    iZiSwapAddress: '0x19a6f9D83F81337ef4b8d7F419e210Cc33939493',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 2,
                    isEnded: false,
                },
                {
                    ...sortedToken(TokenSymbol.GRI, TokenSymbol.BNB, ChainId.BSC),
                    miningContract: '0xc9D1DCa243eACEB62B1e6E354015E53c10f2c59b',
                    iZiSwapAddress: '0x765E152ab2d0C5C41DE733092F5F281C0311695b',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 2,
                    isEnded: true,
                },
                //   {
                //       ...sortedToken(TokenSymbol.FEVR, TokenSymbol.BUSD, ChainId.BSC),
                //       miningContract: '0x277aE32CeDe584784425FCb50710611c13C31521',
                //       iZiSwapAddress: '0x440E5FaC04eB56e4D1d0110Db19a774895596B82',
                //       feeTier: 0.2,
                //       twoRewards: true,
                //       iZiBoost: true,
                //       contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                //       noFee: false,
                //       feeCharged: 1,
                //       leftRangeRatio: 1.01010101010101,
                //       rightRangeRatio: 5,
                //       isEnded: true,
                //   },
                {
                    ...sortedToken(TokenSymbol.GE, TokenSymbol.USDT, ChainId.BSC),
                    miningContract: '0xc5E9eDda9958730e26ff521BA3b6c82B92946700',
                    iZiSwapAddress: '0x2738e0032348e6dccbfef9ffb148e045c0e4d618',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: true,
                },
                // {
                //     ...sortedToken(TokenSymbol.DMT, TokenSymbol.USDT, ChainId.BSC),
                //     miningContract: '0x5DC486C222DAeEC3f6C678Bf3e941fBCEa290ceB',
                //     iZiSwapAddress: '0x85e50099d90d7307c507a05d6e887bf91ac65a38',
                //     feeTier: 0.2,
                //     twoRewards: true,
                //     iZiBoost: true,
                //     contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                //     noFee: false,
                //     feeCharged: 1,
                //     priceRangeRatio: 100,
                //     isEnded: true,
                // },
                //   {
                //       ...sortedToken(TokenSymbol.BURGER, TokenSymbol.USDT, ChainId.BSC),
                //       miningContract: '0x130E0f3B35d7b3CE1658138Be53D103Fd85bF6B6',
                //       iZiSwapAddress: '0x5b9681c93de189577703151b410364070797fccf',
                //       feeTier: 0.2,
                //       twoRewards: true,
                //       iZiBoost: true,
                //       contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                //       noFee: false,
                //       feeCharged: 1,
                //       priceRangeRatio: 2,
                //       isEnded: true,
                //   },

                //   {
                //       ...sortedToken(TokenSymbol.MELT, TokenSymbol.USDT, ChainId.BSC),
                //       miningContract: '0x2df0675e0C60aFDB18cfdff1Eb752E8AC1f0F43C',
                //       iZiSwapAddress: '0xed1e141EADf283b46B47f6C800727b3c63A4936F',
                //       feeTier: 1,
                //       twoRewards: true,
                //       iZiBoost: true,
                //       contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                //       noFee: false,
                //       feeCharged: 1,
                //       priceRangeRatio: 2,
                //       isEnded: true,
                //   },

                {
                    ...sortedToken(TokenSymbol.IZI, TokenSymbol.IUSD, ChainId.BSC),
                    miningContract: '0x29038f8ADFA0325Ae8dF92b3a1491e2eED281d23',
                    iZiSwapAddress: '0x50b4fd0858de0145740ba517dc34f682b9863405',
                    feeTier: 0.2,
                    twoRewards: false,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: true,
                },
                {
                    ...sortedToken(TokenSymbol.BNB, TokenSymbol.USDT, ChainId.BSC),
                    miningContract: '0xA39dD789D77a8D8AE17DdF1b3a8f569a31d6dd41',
                    iZiSwapAddress: '0xF964529721ECD0C9386d922a37CbBD2b67EA6E93',
                    feeTier: 0.2,
                    twoRewards: false,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 1.25,
                    isEnded: true,
                },
                //   {
                //       ...sortedToken(TokenSymbol.MNW, TokenSymbol.USDT, ChainId.BSC),
                //       miningContract: '0xa2337F50091dbfC8e802F811eaD575162FA104e6',
                //       iZiSwapAddress: '0x26b4ce39c1df4a6f8bd702a19d23264f9417430e',
                //       feeTier: 0.2,
                //       twoRewards: true,
                //       iZiBoost: true,
                //       contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                //       noFee: false,
                //       feeCharged: 1,
                //       priceRangeRatio: 2,
                //       isEnded: true,
                //   },
                {
                    ...sortedToken(TokenSymbol.HYT, TokenSymbol.USDT, ChainId.BSC),
                    miningContract: '0xe4C226cdFCBA27bbc283a3050feC65f76BBE8C08',
                    iZiSwapAddress: '0xB28B66cC7e327B2B3Cb49fBc461b6E755Ae56789',
                    feeTier: 1,
                    twoRewards: true,
                    iZiBoost: false,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: true,
                    feeCharged: 0,
                    priceRangeRatio: 2,
                    isEnded: false,
                },
                {
                    ...sortedToken(TokenSymbol.MIT, TokenSymbol.USDT, ChainId.BSC),
                    miningContract: '0x7d93470243cd1c0324276758C45d7d496175A1A9',
                    iZiSwapAddress: '0xC93020EeDeDF6903A7238Dd409f45B74D0ae2071',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: false,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: true,
                    feeCharged: 0,
                    priceRangeRatio: 2,
                    isEnded: false,
                },
                {
                    ...sortedToken(TokenSymbol.slETH, TokenSymbol.IUSD, ChainId.BSC),
                    miningContract: '0x74936Cfb8A326A692Fd78C91A9232DEe5357DFEe',
                    iZiSwapAddress: '0x68d425c8eb08ac83ede6e1d1daa874b073fcb478',
                    feeTier: 0.2,
                    twoRewards: false,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 1.25,
                    isEnded: false,
                },
                {
                    ...sortedToken(TokenSymbol.slETH, TokenSymbol.slUSDT, ChainId.BSC),
                    miningContract: '0x5d6ee7a9adB069685c3FD1c30e56564DE3753A6e',
                    iZiSwapAddress: '0xd341e2c620e4885168431c1d58b4b861c02e5015',
                    feeTier: 0.2,
                    twoRewards: false,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 1.25,
                    isEnded: false,
                },
                {
                    ...sortedToken(TokenSymbol.slETH, TokenSymbol.slUSDC, ChainId.BSC),
                    miningContract: '0x63e68A98219Dd77A30d6078C2247c143c145f182',
                    iZiSwapAddress: '0x9410f80495c3278616034b239bac15a949b5326f',
                    feeTier: 0.2,
                    twoRewards: false,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 1.25,
                    isEnded: false,
                },

                //   {
                //       ...sortedToken(TokenSymbol.KSW, TokenSymbol.BUSD, ChainId.BSC),
                //       miningContract: '0xE2380f4Cc37027B4bF23bBb3b6c092470dB4975f',
                //       iZiSwapAddress: '0x0f17b8991ffB110a57d219181156C7506832250f',
                //       feeTier: 0.2,
                //       twoRewards: true,
                //       iZiBoost: true,
                //       contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                //       noFee: false,
                //       feeCharged: 1,
                //       priceRangeRatio: 2,
                //       isEnded: true,
                //   },
                //   {
                //       ...sortedToken(TokenSymbol.FROYO, TokenSymbol.BNB, ChainId.BSC),
                //       miningContract: '0x414e38CF17a1CCcAc33599D2D88DAf6dbb680b1F',
                //       iZiSwapAddress: '0xE5A2180AEfdf7ADBb8Ed2Fe2F5a5431Db2677577',
                //       feeTier: 0.2,
                //       twoRewards: true,
                //       iZiBoost: true,
                //       contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                //       noFee: false,
                //       feeCharged: 1,
                //       priceRangeRatio: 2,
                //       isEnded: true,
                //   },
            ],
            [ChainId.Arbitrum]: [
                {
                    ...sortedToken(TokenSymbol.slETH, TokenSymbol.IUSD, ChainId.Arbitrum),
                    miningContract: '0xBBC9Fd984699F8a89B53b35bddf77494433Df225',
                    iZiSwapAddress: '0xF40D6E497067Cd246778EEA045751252034257b3',
                    feeTier: 0.2,
                    twoRewards: false,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 1.25,
                    isEnded: false,
                },
                {
                    ...sortedToken(TokenSymbol.slETH, TokenSymbol.slUSDT, ChainId.Arbitrum),
                    miningContract: '0x2df0675e0C60aFDB18cfdff1Eb752E8AC1f0F43C',
                    iZiSwapAddress: '0x6b155C372DCCB076d3Bd90466Ee5f88F2921145e',
                    feeTier: 0.2,
                    twoRewards: false,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 1.25,
                    isEnded: false,
                },
                {
                    ...sortedToken(TokenSymbol.slETH, TokenSymbol.slUSDC, ChainId.Arbitrum),
                    miningContract: '0xfbc1557Ba3A923Fc32333b15E5C7d3d6535eE32B',
                    iZiSwapAddress: '0x5eed1f05Daf0aC107175830eD3255e12Cf532CE4',
                    feeTier: 0.2,
                    twoRewards: false,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 1.25,
                    isEnded: false,
                },
            ],
            [ChainId.ZkSyncEra]: [
                {
                    ...sortedToken(TokenSymbol.BLADE, TokenSymbol.USDC, ChainId.ZkSyncEra),
                    miningContract: '0x8baCe5229771d2909924B055aCd2f109EB4cf8a8',
                    iZiSwapAddress: '0x6fdc8ba4428db4c72b17e6c59ba1ec6de5baeb16',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 2,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.DEXTF, TokenSymbol.USDC, ChainId.ZkSyncEra),
                    miningContract: '0x353a12B0D46618c513bF5313AB7DfFB01227C234',
                    iZiSwapAddress: '0xa9addc3bb68dd601cc9d9ba6b0e5e4651a7c476f',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.ZKSP, TokenSymbol.ETH, ChainId.ZkSyncEra),
                    miningContract: '0x28702B2b58cCb5927b53aF91E5dC63270273d4C3',
                    iZiSwapAddress: '0x3a83b68de90a660e1780df9c84d57de3fa832e66',
                    feeTier: 1,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.RF, TokenSymbol.ETH, ChainId.ZkSyncEra),
                    miningContract: '0x0C4E0F1Ff3B200db601b5A27adBaD288e804A35B',
                    iZiSwapAddress: '0x0f149b3f475d0acbc2ab849b50a0e14593450997',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.OT, TokenSymbol.USDC, ChainId.ZkSyncEra),
                    miningContract: '0xcB27b19c27e316f2deE2f07123716F36368C9e97',
                    iZiSwapAddress: '0x3fc25ee9c05eeddecd04f347b8ed702500993ec3',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },
                {
                    ...sortedToken(TokenSymbol.SPACE, TokenSymbol.ETH, ChainId.ZkSyncEra),
                    miningContract: '0x78e1F126a1BAE67bBC05A92CF2Bc171C69ee250a',
                    iZiSwapAddress: '0x682a471baa1e77d447b4a670925eb7c9ad752324',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                    isEnded: false,
                    useTimestamp: true,
                },

            ]
        }
        : {
            [ChainId.Rinkeby]: [],
            [ChainId.BSCTestnet]: [
                {
                    ...sortedToken(TokenSymbol.USDT, TokenSymbol.BNB, ChainId.BSCTestnet),
                    miningContract: '0x2D509cfbd4503fa3811371f727cFF91075cAddB1',
                    iZiSwapAddress: '0x7b25C7e505244464Fea500109eBa88B82b26954b',
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    priceRangeRatio: 4,
                },
                {
                    ...sortedToken(TokenSymbol.IZI, TokenSymbol.BNB, ChainId.BSCTestnet),
                    miningContract: '0x380AE49a952516579E171CD7F99B0130d0908309',
                    iZiSwapAddress: '0xeAC5db5c8AF54DfC361338083D05dB847708e7A5',
                    useTimestamp: true,
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 0.4,
                    priceRangeRatio: 4,
                },
                {
                    ...sortedToken(TokenSymbol.FeeA, TokenSymbol.FeeB, ChainId.BSCTestnet),
                    miningContract: '0x5C9B644efB689A8D97dAd94D78C953b518cc6593',
                    iZiSwapAddress: '0x01e39eECb26b04ECac644C369C58040025D27727',
                    useTimestamp: false,
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: false,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    leftRangeRatio: 4,
                    rightRangeRatio: 4,
                },
                {
                    ...sortedToken(TokenSymbol.FeeA, TokenSymbol.IUSD, ChainId.BSCTestnet),
                    miningContract: '0xf3656b20355AA315AFE1407269DB2fbc27d959A2',
                    iZiSwapAddress: '0x6d6588F17a14F081f2398174262C89C97d48B5D9',
                    useTimestamp: false,
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: false,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 0.4,
                    leftRangeRatio: 2,
                    rightRangeRatio: 2,
                },
            ],
            [ChainId.AuroraTestnet]: [
                {
                    ...sortedToken(TokenSymbol.IZI, TokenSymbol.ETH, ChainId.AuroraTestnet),
                    miningContract: '0x346255D509d1FCb923077EcC53341F76Ee8182c3',
                    iZiSwapAddress: '0x8F32F1d69E8dd996d4e5D0E9937B388cE67d9551',
                    useTimestamp: false,
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    leftRangeRatio: 2,
                    rightRangeRatio: 4,
                },
            ],
            [ChainId.ZkSyncAlphaTest]: [
                {
                    ...sortedToken(TokenSymbol.MIT, TokenSymbol.USDT, ChainId.ZkSyncAlphaTest),
                    miningContract: '0x254C23Db2E3D309c6025Ff54eb5f6aD6a25fFc43',
                    iZiSwapAddress: '0x6D7607fe4457A82BC7bAf1F22B038accC7044068',
                    useTimestamp: true,
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    leftRangeRatio: 2,
                    rightRangeRatio: 2,
                },
            ]
        };
