import { BoxProps, useColorMode, HStack, VStack, Text, Box, Image, Divider, Center } from '@chakra-ui/react';
import { getColorThemeSelector, getFormatDateDiff, getHiddenAddress } from '../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy } from '../../../../style';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import Info from '../../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { NFTId } from '../../../../iZUMi-UI-toolkit/src/components/NFTId/NFTId';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
import { VeTokenAmount } from '../../components/VeTokenAmount';
import { LockTime } from '../../components/LockTime';
import { VeTokenNft } from '../../../../state/models/veToken/types';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { VETOKEN_ADDRESS } from '../../../../config/veToken/veTokenContracts';
import { MiningCallbacks } from '../../../../state/models/hooks/farm/common/callbacks';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import useVeTokenEntity from '../../../../hooks/useVeTokenEntity';

type MergeModalProps = {
    isOpen: boolean | any;
    onClose: () => void;
    entry: VeTokenNft;
    targetEntry: VeTokenNft;
    onSuccess?: () => void;
} & BoxProps;

export const MergeModal: React.FC<MergeModalProps> = (props) => {
    const { isOpen, onClose, entry, targetEntry, onSuccess, ...rest } = props;

    const mergedEntry = targetEntry;

    const { chainId } = useWeb3WithDefault();
    const veTokenAddress = VETOKEN_ADDRESS[chainId];

    const veTokenEntity = useVeTokenEntity(veTokenAddress, 'V2');
    const currentDate = new Date();

    const { gasPrice } = useGasPrice();

    const toast = useCustomToast();

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <Modal isOpen={isOpen} onClose={onClose} w="788px" title="Marge veNFT" {...rest}>
            <Divider mt="-20px !important" />

            <Box bg={colorTheme('#FFF0F3', 'rgba(94, 42, 52, 0.46)')} w="100%" h="113px">
                <Center w="100%" h="100%" px="10%">
                    <VStack>
                        <Text className={i_text_copy} fontWeight="bold" fontSize="16px" color={colorTheme('#FA4D6C', '#C54158')}>
                            Merge to Existing NFT
                        </Text>

                        <Text fontSize="12px" className={i_text_copy} color={colorTheme('#FA3155', '#B44358')} textAlign="center">
                            To avoid permanent loss, please double check the NFT ID before marging
                        </Text>
                    </VStack>
                </Center>
            </Box>

            <Card variant="base" w="100%" minH="60px" pt="22px" pb="10px" pl="20px" borderRadius="4px" mb="20px !important" mt="20px">
                <HStack w="100%" justifyContent="space-between">
                    <HStack spacing="40px">
                        <NFTId direction="column" id={entry.nftId} w="75px" link="" />
                    </HStack>

                    <HStack>
                        <Info label="Owner" value={getHiddenAddress(entry.owner)} w="120px" />
                        <VeTokenAmount balance={entry.veTokenDecimal} />
                        <LockTime time={getFormatDateDiff(currentDate, new Date(entry.endTimestamp))} />
                    </HStack>
                </HStack>
            </Card>
            <VStack w="100%">
                <Text fontSize="12px" className={i_text_copy_bold} color="secondary.500" textAlign="center">
                    Merge to
                </Text>
                <Image w="16px" h="16px" src={process.env.PUBLIC_URL + '/assets/farm/lightToV.svg'} />
            </VStack>

            <Card variant="base" w="100%" minH="60px" pt="22px" pb="10px" pl="20px" borderRadius="4px" mb="20px !important" mt="20px">
                <HStack w="100%" justifyContent="space-between">
                    <HStack spacing="40px">
                        <NFTId direction="column" id={mergedEntry.nftId} w="75px" link="" />
                    </HStack>

                    <HStack>
                        <Info label="Owner" value={getHiddenAddress(mergedEntry.owner)} w="120px" />
                        <VeTokenAmount balance={mergedEntry.veTokenDecimal} />
                        <LockTime time={getFormatDateDiff(currentDate, new Date(entry.endTimestamp))} />
                    </HStack>
                </HStack>
            </Card>

            <VStack w="100%" mt="20px">
                <CustomButton
                    variant="purple"
                    mt="20px !important"
                    text={
                        <HStack w="100%" position="relative">
                            <HStack mx="auto !important">
                                <Image w="17px" h="17px" src={process.env.PUBLIC_URL + '/assets/tokens/arctic.png'} />
                                <Text>Confirm </Text>
                            </HStack>
                        </HStack>
                    }
                    w="304px"
                    h="40px"
                    fontClass={i_text_copy_bold}
                    fontSize="14px"
                    onClick={() => {
                        veTokenEntity.mergeTo(
                            entry.nftId,
                            mergedEntry.nftId,
                            {
                                onTransactionHash: (e: any) => {
                                    toast('info', 'Merge veARC NFT: ' + e);
                                },
                                then: (e: any) => {
                                    console.log(e);
                                    toast('info', 'Merge veARC NFT successfully');
                                    onSuccess?.();
                                    onClose();
                                },
                                catch: (e: any) => {
                                    console.log(e);
                                    toast('error', e.message);
                                },
                            } as MiningCallbacks,
                            gasPrice
                        );
                    }}
                />
            </VStack>
        </Modal>
    );
};
