import {
    Box,
    Stack,
    HStack,
    VStack,
    useColorMode,
    Center,
    AccordionItem,
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Divider,
    Image,
    Flex,
    Icon,
    IconButton,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';

import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { ToastLink, useCustomToast } from '../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { Text } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import CurrentPriceCard from '../components/CurrentPriceCard';
import { getColorThemeSelector } from '../../../utils/funcs';
import { TokenSelectButton } from '../components/TokenSelectButton';
import { AmountInput } from '../components/AmountInput';
import { i_h2, i_text_copy, i_text_d } from '../../../style';
import { RootDispatch, RootState } from '../../../state/store';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { formatNumber } from '../../../utils/tokenMath';
import { isNumeric } from '../../../utils/valid';
import { useMulticallContract } from '../../../hooks/useContracts';
import { validateSwapForm } from '../../../state/models/trade/swap/funcs';
import { getFieldValidateInfo } from '../../../state/models/trade/liquidity/validationHelper';
import useTokenEntity from '../../../state/models/hooks/useTokenEntity';
import { SwapDetail } from './components/SwapDetail';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../hooks/useTokenListFormatted';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { SwapConfirmModal } from './components/SwapConfirmModal';
import { getGasToken, isGasToken, isWrappedGasToken } from '../../../config/tokens';
import { useTranslation } from 'react-i18next';
import IconTool from '../components/IconTool';
import { getMulticallContract } from '../../../utils/contractHelpers';
import { MULTICALL_ADDRESS } from '../../../config/multicall/multicallContracts';
import { PreQueryParams } from '../../../state/models/trade/aggregator/model';
import { Contract } from 'web3-eth-contract';
import { SwapParams } from '../../../state/models/trade/swap/tradeSwap';
import { useGasPrice } from '../../../hooks/useGasPrice';
import SwapSetting from './components/SwapSetting';
import { AdaptationMode } from '../../../components/layout/PageLayout';
import { AiOutlineSetting } from 'react-icons/ai';
import { ErrorInfo } from '../../../iZUMi-UI-toolkit/src/components/ErrorInfo/ErrorInfo';
import {
    CUSTOM_TOKEN_BLACKLIST,
    IZUMI_SWAP_CONFIG,
    TRADE_DEFAULT_FROM_TOKEN,
    TRADE_DEFAULT_TO_TOKEN,
    TRADE_TOKEN_BLACKLIST,
} from '../../../config/bizConfig';
import { LayoutStateEnum } from '../../../state/models/pageLayout/pageLayout';
import { useHistory } from 'react-router-dom';
import useLayoutType from '../../../hooks/useLayoutType';
import { getChain, getTxLink } from '../../../config/chains';
import { BigNumber } from 'bignumber.js';
import { WarningInfo } from '../../../components/WarningInfo/WarningInfo';
import { useInterval } from 'ahooks';

type SwapFormProps = {
    type?: AdaptationMode;
};

enum SwapButton {
    wrap = 'wrap',
    unwrap = 'unwrap',
    swap = 'swap',
}

const SwapForm: React.FC<SwapFormProps> = (props) => {
    const { type } = props;
    const { t } = useTranslation();
    const { tradeMode, block } = useSelector((state: RootState) => state);
    const { swapPreQueryModel } = useSelector((state: RootState) => state);
    const isSimpleMode = tradeMode.isSimpleMode;
    const toast = useCustomToast();
    const { chainId, web3, account } = useWeb3WithDefault();
    const { gasPrice } = useGasPrice();
    const { tradeSwap } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const layoutType = useLayoutType();
    const history = useHistory();

    const { loading, tokenList } = useTokenListFormatted();

    const { swapForm, isSearchingX, isSearchingY } = tradeSwap;
    const exclusiveLiquidity = swapForm.exclusiveLiquidity;
    const multicallContract = useMulticallContract() as Contract;

    const swapFormTokenX = swapForm.tokenX ?? {};
    const swapFormTokenY = swapForm.tokenY ?? {};

    const tokenXNotWrapToken = !swapFormTokenX.wrapTokenAddress;
    const tokenYNotWrapToken = !swapFormTokenY.wrapTokenAddress;

    const noWrapToken = tokenXNotWrapToken && tokenYNotWrapToken;

    const spenderAddress = swapForm.spenderAddress;
    const depositSpenderAddress = swapForm.depositSpenderAddress;

    const tokenXEntity = useTokenEntity(swapFormTokenX, swapForm.spenderAddress, swapForm.depositSpenderAddress);
    const tokenYEntity = useTokenEntity(swapFormTokenY, undefined, undefined);

    const swapParams = useMemo(() => {
        return {
            account,
            chainId,
            swapForm,
            gasPrice,
        } as SwapParams;
    }, [account, chainId, swapForm]);

    const [isInputFromFocus, setIsInputFromFocus] = useState(false);
    const [isInputToFocus, setIsInputToFocus] = useState(false);
    const [showSwapSettingModal, setShowSwapSettingModal] = useState(false);
    const [showSwapConfirmModal, setShowSwapConfirmModal] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const searchParams = useSearchParams();

    const swapButton = useMemo((): SwapButton => {
        if (isGasToken(swapForm.tokenX, chainId) && isWrappedGasToken(swapForm.tokenY, chainId)) {
            return SwapButton.wrap;
        }
        if (isGasToken(swapForm.tokenY, chainId) && isWrappedGasToken(swapForm.tokenX, chainId)) {
            return SwapButton.unwrap;
        }
        return SwapButton.swap;
    }, [swapForm.tokenX, swapForm.tokenY, chainId]);

    // Auto fill token if has params in URL
    useEffect(() => {
        if (!loading) {
            const chainIdFromUrl = searchParams.get('chainId');

            // chainId not match
            if (chainIdFromUrl && Number(chainIdFromUrl) !== Number(chainId)) {
                //TODO: toast not match chainId
                return;
            }

            const tokenFromAddr = searchParams.get('tokenFrom');

            if (tokenFromAddr && tokenFromAddr !== 'GasToken') {
                const tokenFromFormatted = {
                    ...tokenList.find(
                        (e) =>
                            e.address.toUpperCase() === tokenFromAddr.toUpperCase() ||
                            e.wrapTokenAddress?.toUpperCase() === tokenFromAddr.toUpperCase()
                    ),
                };
                if (
                    !tokenFromFormatted.symbol &&
                    !CUSTOM_TOKEN_BLACKLIST[chainId].find((e: string) => e.toLowerCase() === tokenFromAddr.toLowerCase())
                ) {
                    dispatch.customTokens.fetchAndAddToken({
                        tokenAddr: tokenFromAddr,
                        chainId,
                        web3,
                    });
                }
            }

            const tokenToAddr = searchParams.get('tokenTo');

            if (tokenToAddr && tokenToAddr !== 'GasToken') {
                const tokenToFormatted = {
                    ...tokenList.find(
                        (e) =>
                            e.address.toUpperCase() === tokenToAddr.toUpperCase() ||
                            e.wrapTokenAddress?.toUpperCase() === tokenToAddr.toUpperCase()
                    ),
                };
                if (
                    !tokenToFormatted.symbol &&
                    !CUSTOM_TOKEN_BLACKLIST[chainId].find((e: string) => e.toLowerCase() === tokenToAddr.toLowerCase())
                ) {
                    dispatch.customTokens.fetchAndAddToken({
                        tokenAddr: tokenToAddr,
                        chainId,
                        web3,
                    });
                }
            }
        }
    }, [searchParams, tokenList, loading, dispatch, chainId, web3]);

    useEffect(() => {
        if (!swapFormTokenX.symbol || !swapFormTokenY.symbol || swapFormTokenX.symbol === swapFormTokenY.symbol || !chainId || !web3) {
            return;
        }

        const multicall = getMulticallContract(MULTICALL_ADDRESS[chainId], web3);

        dispatch.swapPreQueryModel
            .preQuery({
                chainId,
                web3,
                tokenIn: swapFormTokenX,
                tokenOut: swapFormTokenY,
                multicall,
            } as PreQueryParams)
            .then(() => console.log('after swap pre query'))
            .catch((e: any) => {
                console.info('error:  ', e.message);
            });
    }, [swapFormTokenX, swapFormTokenY, chainId, web3, dispatch.swapPreQueryModel]);

    useEffect(() => {
        dispatch.tradeSwap.clearSwapForm();
    }, [chainId]);

    useEffect(() => {
        if (!chainId) {
            return;
        }

        const chainIdFromUrl = searchParams.get('chainId');
        const chainMatch = !chainIdFromUrl || (chainIdFromUrl && Number(chainIdFromUrl) === chainId);

        const tokenFromAddr = searchParams.get('tokenFrom');
        const tokenToAddr = searchParams.get('tokenTo');
        let tokenFromFormatted = undefined;
        let tokenToFormatted = undefined;

        if (tokenFromAddr) {
            if (tokenFromAddr !== 'GasToken') {
                tokenFromFormatted = {
                    ...tokenList.find(
                        (e) =>
                            e.address.toUpperCase() === tokenFromAddr.toUpperCase() ||
                            e.wrapTokenAddress?.toUpperCase() === tokenFromAddr.toUpperCase()
                    ),
                };
            } else {
                tokenFromFormatted = getGasToken(chainId);
            }
        }

        if (tokenToAddr) {
            if (tokenToAddr !== 'GasToken') {
                tokenToFormatted = {
                    ...tokenList.find(
                        (e) =>
                            e.address.toUpperCase() === tokenToAddr.toUpperCase() ||
                            e.wrapTokenAddress?.toUpperCase() === tokenToAddr.toUpperCase()
                    ),
                };
            } else {
                tokenToFormatted = getGasToken(chainId);
            }
        }

        if (!loading && tokenList.length && !swapFormTokenY.symbol) {
            if (chainMatch && tokenToFormatted) {
                dispatch.tradeSwap.setSwapFormToken({
                    isUpper: true,
                    chainId,
                    tokenInfo: tokenToFormatted as unknown as TokenInfoFormatted,
                });
            } else if (
                !tokenToAddr && Array.isArray(TRADE_DEFAULT_TO_TOKEN[chainId])
                    ? tokenFromFormatted?.address !== TRADE_DEFAULT_TO_TOKEN[chainId][1]
                    : tokenFromFormatted?.symbol !== (TRADE_DEFAULT_TO_TOKEN[chainId] ?? 'iZi')
            ) {
                dispatch.tradeSwap.setSwapFormToken({
                    isUpper: true,
                    chainId,
                    tokenInfo: tokenList.find((e) =>
                        Array.isArray(TRADE_DEFAULT_TO_TOKEN[chainId])
                            ? e.symbol === TRADE_DEFAULT_TO_TOKEN[chainId][0] &&
                              e.address.toUpperCase() === TRADE_DEFAULT_TO_TOKEN[chainId][1].toUpperCase()
                            : e.symbol === (TRADE_DEFAULT_TO_TOKEN[chainId] ?? 'iZi')
                    ) as unknown as TokenInfoFormatted,
                });
            }
        }

        // if (!loading && tokenList.length && !swapFormTokenX.symbol) {
        //     if (chainMatch && tokenFromFormatted) {
        //         dispatch.tradeSwap.setSwapFormToken({
        //             isUpper: false,
        //             chainId,
        //             tokenInfo: tokenFromFormatted as unknown as TokenInfoFormatted,
        //         });
        //     } else if (!tokenFromAddr && tokenToFormatted?.symbol !== getGasToken(chainId).symbol) {
        //         dispatch.tradeSwap.setSwapFormToken({
        //             isUpper: false,
        //             chainId,
        //             tokenInfo: getGasToken(chainId),
        //         });
        //     }
        // }

        if (!loading && tokenList.length && !swapFormTokenX.symbol) {
            if (chainMatch && tokenFromFormatted) {
                dispatch.tradeSwap.setSwapFormToken({
                    isUpper: false,
                    chainId,
                    tokenInfo: tokenFromFormatted as unknown as TokenInfoFormatted,
                });
            } else if (
                !tokenFromAddr && Array.isArray(TRADE_DEFAULT_FROM_TOKEN[chainId])
                    ? tokenToFormatted?.address !== TRADE_DEFAULT_FROM_TOKEN[chainId][1]
                    : tokenToFormatted?.symbol !== (TRADE_DEFAULT_FROM_TOKEN[chainId] ?? getGasToken(chainId).symbol)
            ) {
                dispatch.tradeSwap.setSwapFormToken({
                    isUpper: false,
                    chainId,
                    tokenInfo:
                        tokenList.find((e) =>
                            Array.isArray(TRADE_DEFAULT_FROM_TOKEN[chainId])
                                ? e.symbol === TRADE_DEFAULT_FROM_TOKEN[chainId][0] &&
                                  e.address.toUpperCase() === TRADE_DEFAULT_FROM_TOKEN[chainId][1].toUpperCase()
                                : e.symbol === TRADE_DEFAULT_FROM_TOKEN[chainId]
                        ) ?? getGasToken(chainId),
                });
            }
        }
    }, [chainId, dispatch.tradeSwap, swapForm, loading, tokenList, searchParams]);

    const validateResult = validateSwapForm(swapForm, tokenXEntity.tokenBalance());
    const isShowError = !isInputFromFocus && validateResult && validateResult?.length > 0;

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const updateSearchFunc = () => {
        if (isSearchingX || isSearchingY) {
            return;
        }
        const sourceTag = searchParams.get('sourceTag') ?? 'default';
        dispatch.swapPreQueryModel
            .preQuery({
                chainId,
                web3,
                tokenIn: swapFormTokenX,
                tokenOut: swapFormTokenY,
                multicall: multicallContract,
            } as PreQueryParams)
            .then((r) => {
                swapForm.desireMode
                    ? dispatch.tradeSwap.calSwapDesire({
                          amountDecimal: swapForm.amountDesireDecimal,
                          tokenX: swapFormTokenX,
                          tokenY: swapFormTokenY,
                          multicall: multicallContract,
                          chainId,
                          web3,
                          swapTag: r?.swapTag,
                          preQueryResult: r?.preQueryResult,
                          exclusiveLiquidity,
                          sourceTag,
                      })
                    : dispatch.tradeSwap.calSwapAmount({
                          amountDecimal: swapForm.amountDecimal,
                          tokenX: swapFormTokenX,
                          tokenY: swapFormTokenY,
                          multicall: multicallContract,
                          chainId,
                          web3,
                          swapTag: r?.swapTag,
                          preQueryResult: r?.preQueryResult,
                          exclusiveLiquidity,
                          sourceTag,
                      });
            });
    };

    const handleSwap = () => {
        const chain = getChain(chainId);
        const toastLink = {} as ToastLink;
        const sourceTag = searchParams.get('sourceTag') ?? 'default';
        dispatch.tradeSwap
            .swap({
                ...swapParams,
                sourceTag,
                onGoingCallback(toastLink: any) {
                    toast('info', 'Ongoing', undefined, toastLink);
                },
            })
            .then((e: any) => {
                console.log(e);
                if (chain) {
                    toastLink.title = 'View on ' + chain.name;
                    toastLink.link = getTxLink(e.transactionHash, chain);
                }
                tokenXEntity.handleApproveSuccess();
                updateSearchFunc();
                toast('success', 'swap successfully', undefined, toastLink);
            })
            .catch((e: any) => {
                console.log(e.message);
                updateSearchFunc();
                toast('info', e.message ? e.message : 'error');
            });
    };

    const handleSelectTokenX = (tokenInfo: TokenInfoFormatted) => {
        dispatch.tradeSwap.setSwapFormToken({
            isUpper: false,
            chainId,
            tokenInfo,
        });
        const sourceTag = searchParams.get('sourceTag') ?? 'default';
        dispatch.swapPreQueryModel
            .preQuery({
                chainId,
                web3,
                tokenIn: tokenInfo,
                tokenOut: swapFormTokenY,
                multicall: multicallContract,
            } as PreQueryParams)
            .then((r) => {
                swapForm.desireMode
                    ? dispatch.tradeSwap.calSwapDesire({
                          amountDecimal: swapForm.amountDesireDecimal,
                          tokenX: tokenInfo,
                          tokenY: swapFormTokenY,
                          multicall: multicallContract,
                          chainId,
                          web3,
                          swapTag: r?.swapTag,
                          preQueryResult: r?.preQueryResult,
                          exclusiveLiquidity,
                          sourceTag,
                      })
                    : dispatch.tradeSwap.calSwapAmount({
                          amountDecimal: swapForm.amountDecimal,
                          tokenX: tokenInfo,
                          tokenY: swapFormTokenY,
                          multicall: multicallContract,
                          chainId,
                          web3,
                          swapTag: r?.swapTag,
                          preQueryResult: r?.preQueryResult,
                          exclusiveLiquidity,
                          sourceTag,
                      });
            });
    };

    const handleSelectTokenY = (tokenInfo: TokenInfoFormatted) => {
        dispatch.tradeSwap.setSwapFormToken({
            isUpper: true,
            chainId,
            tokenInfo,
        });
        const sourceTag = searchParams.get('sourceTag') ?? 'default';
        dispatch.swapPreQueryModel
            .preQuery({
                chainId,
                web3,
                tokenIn: swapFormTokenX,
                tokenOut: tokenInfo,
                multicall: multicallContract,
            } as PreQueryParams)
            .then((r) => {
                swapForm.desireMode
                    ? dispatch.tradeSwap.calSwapDesire({
                          amountDecimal: swapForm.amountDesireDecimal,
                          tokenX: swapFormTokenX,
                          tokenY: tokenInfo,
                          multicall: multicallContract,
                          chainId,
                          web3,
                          swapTag: r?.swapTag,
                          preQueryResult: r?.preQueryResult,
                          exclusiveLiquidity,
                          sourceTag,
                      })
                    : dispatch.tradeSwap.calSwapAmount({
                          amountDecimal: swapForm.amountDecimal,
                          tokenX: swapFormTokenX,
                          tokenY: tokenInfo,
                          multicall: multicallContract,
                          chainId,
                          web3,
                          swapTag: r?.swapTag,
                          preQueryResult: r?.preQueryResult,
                          exclusiveLiquidity,
                          sourceTag,
                      });
            });
    };

    const handleSetValueX = (value: number) => {
        const sourceTag = searchParams.get('sourceTag') ?? 'default';
        if (isNumeric(value)) {
            dispatch.tradeSwap.setSwapFormAmountIn({
                amountDecimal: value,
                chainId,
            });
            dispatch.tradeSwap.calSwapAmount({
                amountDecimal: value,
                tokenX: swapFormTokenX,
                tokenY: swapFormTokenY,
                multicall: multicallContract,
                chainId,
                web3,
                swapTag: swapPreQueryModel.swapTag,
                preQueryResult: swapPreQueryModel.preQueryResult,
                exclusiveLiquidity,
                sourceTag,
            });
        }
    };

    const handleSetValueY = (value: number) => {
        const sourceTag = searchParams.get('sourceTag') ?? 'default';
        if (isNumeric(value)) {
            dispatch.tradeSwap.setSwapFormAmountOut({
                amountDecimal: value,
                chainId,
            });
            dispatch.tradeSwap.calSwapDesire({
                amountDecimal: value,
                tokenX: swapFormTokenX,
                tokenY: swapFormTokenY,
                multicall: multicallContract,
                chainId,
                web3,
                swapTag: swapPreQueryModel.swapTag,
                preQueryResult: swapPreQueryModel.preQueryResult,
                exclusiveLiquidity,
                sourceTag,
            });
        }
    };

    const maxAmountIn = useMemo((): string => {
        if (swapForm.desireMode) {
            const amountIn = swapForm.amount;
            const slippagePercent = swapForm.slippagePercent;
            const maxPayed = new BigNumber(amountIn)
                .times(100 + slippagePercent)
                .div(100)
                .toFixed(0);
            return maxPayed;
        } else {
            return swapForm.amount;
        }
    }, [swapForm]);

    useInterval(() => {
        if (block.remainSeconds === 0) {
            dispatch.block.setRemainSeconds(IZUMI_SWAP_CONFIG.AUTO_REFRESH_SWAP_CALC_INTERVAL / 1000);
            updateSearchFunc();
            return;
        }
        dispatch.block.setRemainSeconds(block.remainSeconds - 1);
    }, 1000);

    return type === 'mobile' ? (
        <>
            <VStack w="100%" h="666px" maxH="764px" pb="34px">
                <Card
                    variant={'tabTopCard'}
                    w="100%"
                    pt="11px"
                    pb="26px"
                    px="19px"
                    bg={colorTheme('#FFFFFF', '#25203B')}
                    boxShadow={colorTheme('0px 0px 26px rgba(166, 135, 198, 0.25)', '0px 0px 24px rgba(23, 15, 31, 0.61)')}
                >
                    <Stack w="100%" direction="column">
                        <HStack w="100%" justifyContent="center" mb="7px">
                            <Text
                                w="100%"
                                mb="3px"
                                textAlign="center"
                                variant="caption"
                                fontSize="15px"
                                fontFamily="Montserrat-Bold"
                                color={colorTheme('#7F4AFE', 'secondary.400')}
                                lineHeight="14px"
                                ml="44px"
                            >
                                {t('Swap')}
                            </Text>
                            <HStack>
                                <IconButton
                                    aria-label="setting"
                                    variant="ghost"
                                    onClick={() => setShowSwapSettingModal(true)}
                                    size="sm"
                                    icon={<Icon as={AiOutlineSetting} boxSize={6} />}
                                />
                                <SwapSetting
                                    isOpen={showSwapSettingModal}
                                    onClose={() => {
                                        setShowSwapSettingModal(false);
                                    }}
                                    exclusiveLiquidity={swapForm.exclusiveLiquidity}
                                    slippage={swapForm.slippagePercent}
                                    maxDelay={swapForm.maxDelay}
                                    quoterType={swapForm.quoterType}
                                    handleSetSlippage={dispatch.tradeSwap.setSwapFormSlippagePercent}
                                    handleSetMaxDelay={dispatch.tradeSwap.setSwapFormMaxDelay}
                                    handleSetQuoterType={dispatch.tradeSwap.setSwapFormQuoterType}
                                    handleSetExclusive={dispatch.tradeSwap.setSwapFormExclusiveLiquidity}
                                />
                                <CustomButton
                                    w="28px"
                                    h="22px"
                                    className={i_text_copy}
                                    bg="linear-gradient(93.67deg, #5685FF -21.64%, #C549FF 138.94%);"
                                    color="#FFFFFF"
                                    borderRadius="3px"
                                    onClick={() => {
                                        if (layoutType === LayoutStateEnum.sidebar) {
                                            dispatch.pageLayout.setIsInAnimation(true);
                                            setTimeout(() => {
                                                dispatch.pageLayout.setIsInAnimation(false);
                                            }, 500);
                                        }
                                        history.push('/pro');
                                    }}
                                    text="Pro"
                                ></CustomButton>
                            </HStack>
                        </HStack>
                        <Divider border="1px solid #EBE6F0;" mt="0px !important"></Divider>
                        <Stack direction="row" spacing="10px" alignItems="center" pt="5px">
                            <TokenSelectButton type="mobile" size="xs" token={swapFormTokenX} handleSelect={handleSelectTokenX} />
                            <IconTool token={swapFormTokenX}></IconTool>
                            <HStack ml="auto !important">
                                <Text variant="caption" color="secondary.400" whiteSpace="nowrap">
                                    {t('Balance')} :<Text variant="caption">{formatNumber(tokenXEntity.tokenBalance())}</Text>
                                </Text>
                            </HStack>
                        </Stack>
                        <VStack spacing="10px" alignItems="flex-start">
                            <Card
                                variant="lightGary"
                                w="100%"
                                h="98px"
                                pt="6px"
                                px="8px"
                                pb="11px"
                                border={
                                    isInputFromFocus
                                        ? '2px solid #A880FF'
                                        : isShowError
                                        ? '2px solid #E05757'
                                        : colorTheme('2px solid #ffffff', '2px solid #211834')
                                }
                            >
                                {!isSearchingX ? (
                                    <AmountInput
                                        type="mobile"
                                        handleSetValue={handleSetValueX}
                                        token={swapFormTokenX}
                                        price={tokenXEntity.tokenPrice()}
                                        balance={tokenXEntity.tokenBalance()}
                                        // errorInfo={getFieldValidateInfo(validateResult, 'amount')}
                                        inputValue={swapForm.amountDecimal && Number(swapForm.amountDecimal).toFixed(5)}
                                        w="100%"
                                        fontClass={i_h2}
                                        setIsInputFocus={setIsInputFromFocus}
                                    />
                                ) : (
                                    <Center h="100%">
                                        <Text variant="caption" mr="10px">
                                            {t('Fetching price')}
                                        </Text>
                                        <img width="20px" height="20px" src="/assets/gallery/loading.gif" />
                                    </Center>
                                )}
                            </Card>
                        </VStack>
                    </Stack>

                    <Stack w="100%" alignItems="center" my="20px">
                        <Flex
                            boxSize="38px"
                            bg={colorTheme('#F8F8FA', '#3A2F53')}
                            borderRadius="30px"
                            boxShadow={colorTheme('0px 0px 9px rgba(119, 84, 147, 0.25)', '0px 0px 9px rgba(40, 9, 64, 0.25)')}
                            alignItems="center"
                            justifyContent="center"
                            onClick={() => {
                                if (!isSearchingX && !isSearchingY && noWrapToken) {
                                    dispatch.tradeSwap.toggleTokenOrder();
                                    setTimeout(() => {
                                        dispatch.tradeSwap.cal({
                                            multicall: multicallContract,
                                            chainId,
                                            web3,
                                            swapTag: swapPreQueryModel.swapTag,
                                            preQueryResult: swapPreQueryModel.preQueryResult,
                                            exclusiveLiquidity,
                                        });
                                    }, 500);
                                }
                            }}
                        >
                            <Image
                                boxSize="16px"
                                src={colorTheme('/assets/limitOrder/Change.svg', '/assets/limitOrder/Change.svg')}
                                fallbackSrc={colorTheme('/assets/limitOrder/Change.svg', '/assets/limitOrder/Change.svg')}
                                cursor="pointer"
                            ></Image>
                        </Flex>
                    </Stack>

                    <Stack w="100%" position="relative">
                        <Stack spacing="10px" alignItems="center" direction="row">
                            <TokenSelectButton type="mobile" size="xs" token={swapFormTokenY} handleSelect={handleSelectTokenY} />
                            <IconTool token={swapFormTokenY}></IconTool>
                            <HStack ml="auto !important">
                                <Text variant="caption" color="secondary.400" whiteSpace="nowrap">
                                    {t('Balance')} :<Text variant="caption">{formatNumber(tokenYEntity.tokenBalance())}</Text>
                                </Text>
                            </HStack>
                        </Stack>
                        <VStack spacing="10px" alignItems="flex-start" pt="1px">
                            <Card
                                variant="lightGary"
                                w="100%"
                                h="73px"
                                pr="15px"
                                pl="18px"
                                pb="10px"
                                border={isInputToFocus ? '2px solid #A880FF' : colorTheme('2px solid #ffffff', '2px solid #211834')}
                            >
                                {!isSearchingY ? (
                                    <AmountInput
                                        type="mobile"
                                        handleSetValue={handleSetValueY}
                                        disabled={!noWrapToken}
                                        token={swapFormTokenY}
                                        price={tokenYEntity.tokenPrice()}
                                        balance={tokenYEntity.tokenBalance()}
                                        inputValue={swapForm.amountDesireDecimal && Number(swapForm.amountDesireDecimal).toFixed(5)}
                                        w="100%"
                                        fontClass={i_h2}
                                        notShowShortcut={true}
                                        setIsInputFocus={setIsInputToFocus}
                                    />
                                ) : (
                                    <Center h="100%">
                                        <Text variant="caption" mr="10px">
                                            {t('Fetching price')}
                                        </Text>
                                        <img width="20px" height="20px" src="/assets/gallery/loading.gif" />
                                    </Center>
                                )}
                            </Card>
                        </VStack>
                        <HStack justifyContent="space-between">
                            <Text variant="caption" color="tertiary.400" fontWeight="600" mt="15px !important">
                                Slippage Tolerance
                            </Text>
                            <Text variant="caption" color="secondary.400" fontFamily="Montserrat-Bold" mt="15px !important">
                                {swapForm.slippagePercent + '%'}
                            </Text>
                        </HStack>
                    </Stack>
                </Card>
                {isShowError && <ErrorInfo content={getFieldValidateInfo(validateResult, 'amount')} mt="13px !important"></ErrorInfo>}
                <WarningInfo isShow={showWarning} onclose={setShowWarning} mt="13px !important"></WarningInfo>

                <Center mt="20px !important" w="100%">
                    <CustomButton
                        hidden={
                            !tokenXEntity.isApproved(maxAmountIn) ||
                            (!tokenXEntity.isDepositApproved() && !tokenXNotWrapToken) ||
                            TRADE_TOKEN_BLACKLIST[chainId].find((item: string) => item === tokenXEntity.token?.address?.toLowerCase()) || //ban blacklist token
                            TRADE_TOKEN_BLACKLIST[chainId].find((item: string) => item === tokenYEntity.token?.address?.toLowerCase())
                        }
                        disabled={
                            !swapForm.swapPath ||
                            swapForm.noSufficientLiquidity ||
                            isSearchingX ||
                            isSearchingY ||
                            showSwapConfirmModal ||
                            showSwapSettingModal
                        }
                        mt="-6px"
                        variant="purple"
                        text={t(String(swapButton))}
                        mr="0px"
                        w="100%"
                        h="50px"
                        fontSize="14px"
                        onClick={() => {
                            Math.abs(swapForm.priceImpact ?? 0) > 0.1 ? setShowSwapConfirmModal(true) : handleSwap();
                        }}
                        _disabled={{
                            opacity: '0.3',
                        }}
                    />

                    <CustomButton
                        hidden={tokenXEntity.isApproved(maxAmountIn) || !account || !swapFormTokenY.symbol || !swapFormTokenX.symbol}
                        mt="-6px"
                        variant="purple"
                        text={t('Approve') + ' ' + swapFormTokenX.symbol}
                        mr="0px"
                        w="100%"
                        h="50px"
                        fontSize="14px"
                        onClick={() => {
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            tokenXEntity
                                .handleApprove()
                                .on('transactionHash', (hash: string) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(hash, chain);
                                    }
                                    toast('info', 'Ongoing', undefined, toastLink);
                                })
                                .then(() => {
                                    toast('success', 'Approve successfully', undefined, toastLink);
                                    tokenXEntity.handleApproveSuccess();
                                })
                                .catch((e: any) => {
                                    console.info('error   :', e.message);
                                });
                        }}
                    />
                    <CustomButton
                        hidden={
                            tokenXNotWrapToken ||
                            tokenXEntity.isDepositApproved() ||
                            !account ||
                            !swapFormTokenY.symbol ||
                            !swapFormTokenX.symbol
                        }
                        mt="-6px"
                        variant="purple"
                        text={t('Deposit Approve') + ' ' + swapFormTokenX.symbol}
                        mr="0px"
                        w="100%"
                        h="50px"
                        fontSize="14px"
                        onClick={() => {
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            tokenXEntity
                                .handleDepositApprove()
                                .on('transactionHash', (hash: string) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(hash, chain);
                                    }
                                    toast('info', 'Ongoing', undefined, toastLink);
                                })
                                .then(() => {
                                    toast('success', 'Deposit approve successfully', undefined, toastLink);
                                    tokenXEntity.handleDepositApproveSuccess();
                                })
                                .catch((e: any) => {
                                    console.info('error   :', e.message);
                                });
                        }}
                    />
                </Center>
            </VStack>

            <SwapConfirmModal
                isOpen={showSwapConfirmModal}
                onClose={() => {
                    setShowSwapConfirmModal(false);
                }}
                handleSwap={handleSwap}
                swapForm={swapForm}
            />
        </>
    ) : (
        <>
            <VStack
                w={isSimpleMode ? { base: '840px', xxl: '881px' } : '370px'}
                h={isSimpleMode ? 'unset' : '666px'}
                maxH="764px"
                px={isSimpleMode ? { base: '25px', xxl: '44px' } : ''}
                pt={isSimpleMode ? (showWarning ? '0px' : '17px') : '0px'}
                pb="34px"
                spacing="0"
                position="relative"
            >
                {isSimpleMode && (
                    <WarningInfo
                        isShow={showWarning}
                        onclose={setShowWarning}
                        mb={isSimpleMode ? '13px' : '0px'}
                        mt={isSimpleMode ? '0px' : '8px'}
                    ></WarningInfo>
                )}
                <Card
                    variant={isSimpleMode ? 'deep' : 'tabTopCard'}
                    w="100%"
                    pt="18px"
                    pb="26px"
                    pl={isSimpleMode ? '65px' : '22px'}
                    pr={isSimpleMode ? '75px' : '15px'}
                >
                    <Stack w="100%" direction={isSimpleMode ? 'row' : 'column'}>
                        <Stack
                            direction={isSimpleMode ? 'column' : 'row'}
                            justifyContent={{ base: 'unset', sm: 'start' }}
                            spacing="10px"
                            alignItems={isSimpleMode ? 'flex-start' : 'center'}
                        >
                            <HStack w={isSimpleMode ? '75px' : '50px'}>
                                <Text
                                    variant="caption"
                                    w={{ base: '40px', sm: '50px' }}
                                    className={i_text_d}
                                    fontWeight="bold"
                                    color={colorTheme('tertiary.600', 'tertiary.200')}
                                >
                                    {t('From')}
                                </Text>
                            </HStack>

                            <TokenSelectButton
                                w={isSimpleMode ? '238px' : '152px'}
                                ml="auto !important"
                                pl="27px"
                                size={isSimpleMode ? 'lg' : 'xs'}
                                token={swapFormTokenX}
                                handleSelect={handleSelectTokenX}
                            />
                            {!isSimpleMode && <IconTool token={swapFormTokenX}></IconTool>}
                        </Stack>
                        <Divider w="100%" display={isSimpleMode ? 'none' : 'block'} pt="16px"></Divider>
                        <VStack
                            spacing={isSimpleMode ? '10px' : '25px'}
                            alignItems="flex-start"
                            pt={isSimpleMode ? 'unset' : '16px'}
                            ml={isSimpleMode ? '46px !important' : '0px'}
                        >
                            <HStack w="100%" justifyContent="space-between">
                                <Text
                                    variant="caption"
                                    className={i_text_d}
                                    fontWeight="bold"
                                    color={colorTheme('tertiary.600', 'tertiary.200')}
                                >
                                    {t('Amount')}
                                </Text>

                                <Text variant="caption" color="tertiary.400" whiteSpace="nowrap">
                                    {t('Balance')} :
                                    <Text variant="caption">
                                        {formatNumber(tokenXEntity.tokenBalance())} {swapFormTokenX.symbol}
                                    </Text>
                                </Text>
                            </HStack>

                            <Card
                                variant="lightGary"
                                w={isSimpleMode ? '386px' : '100%'}
                                h="80px"
                                pt="6px"
                                px="8px"
                                pb="6px"
                                border={
                                    isInputFromFocus
                                        ? '2px solid #A880FF'
                                        : isShowError
                                        ? '2px solid #E05757'
                                        : colorTheme('2px solid #ffffff', '2px solid #211834')
                                }
                            >
                                {!isSearchingX ? (
                                    <AmountInput
                                        handleSetValue={handleSetValueX}
                                        token={swapFormTokenX}
                                        price={tokenXEntity.tokenPrice()}
                                        balance={tokenXEntity.tokenBalance()}
                                        // errorInfo={getFieldValidateInfo(validateResult, 'amount')}
                                        //notShowError={true}
                                        inputValue={swapForm.amountDecimal && Number(swapForm.amountDecimal).toFixed(5)}
                                        w="100%"
                                        fontClass={i_h2}
                                        isSimpleMode={isSimpleMode}
                                        setIsInputFocus={setIsInputFromFocus}
                                    />
                                ) : (
                                    <Center h="100%">
                                        <Text variant="caption" mr="10px">
                                            {t('Fetching price')}
                                        </Text>
                                        <img width="20px" height="20px" src="/assets/gallery/loading.gif" />
                                    </Center>
                                )}
                            </Card>
                        </VStack>
                    </Stack>
                </Card>

                <Box
                    position={isSimpleMode ? 'absolute' : 'static'}
                    mt={isSimpleMode ? '0px' : '10px !important'}
                    borderRadius="50%"
                    border={colorTheme('5px solid #F2EFF7', '#292343')}
                    w={colorTheme('54px', '44px')}
                    h={colorTheme('54px', '44px')}
                    background={colorTheme('#FFFFFF', '#292343')}
                    top={showWarning ? '199px' : '173px'}
                    left={isSimpleMode ? '42%' : '50%'}
                    transform="translateX(-50%) translateY(-50%)"
                    backgroundImage={colorTheme('/assets/swap/transicon.svg', '/assets/swap/transicon_dark.svg')}
                    backgroundSize="20px 24px"
                    backgroundRepeat="no-repeat"
                    backgroundPosition="center"
                    onClick={() => {
                        if (!isSearchingX && !isSearchingY && noWrapToken) {
                            dispatch.tradeSwap.toggleTokenOrder();
                            setTimeout(() => {
                                dispatch.tradeSwap.cal({
                                    multicall: multicallContract,
                                    chainId,
                                    web3,
                                    swapTag: swapPreQueryModel.swapTag,
                                    preQueryResult: swapPreQueryModel.preQueryResult,
                                    exclusiveLiquidity,
                                });
                            }, 500);
                        }
                    }}
                    ml={isSimpleMode ? '0px' : '51px !important'}
                    cursor="pointer"
                    boxShadow={colorTheme('0px 0px 4px rgba(191, 179, 200, 0.25)', '0px 0px 4px 5px #120C1F')}
                />

                <Card
                    variant="tabCard"
                    w="100%"
                    mt={isSimpleMode ? '10px!important' : '-42px !important'}
                    pt="18px"
                    pb="26px"
                    pl={isSimpleMode ? '65px' : '22px'}
                    pr={isSimpleMode ? '75px' : '15px'}
                >
                    <Stack
                        w="100%"
                        position="relative"
                        // justifyContent="space-between"
                        direction={isSimpleMode ? 'row' : 'column'}
                        pt={isSimpleMode ? 'unset' : '10px'}
                    >
                        <Stack
                            spacing="10px"
                            alignItems={isSimpleMode ? 'flex-start' : 'center'}
                            direction={isSimpleMode ? 'column' : 'row'}
                        >
                            <HStack w={{ base: '40px', sm: '50px' }}>
                                <Text
                                    variant="caption"
                                    className={i_text_d}
                                    fontWeight="bold"
                                    mr={{ base: '20px', sm: '36px' }}
                                    color={colorTheme('tertiary.600', 'tertiary.200')}
                                >
                                    {t('To')}
                                </Text>
                            </HStack>
                            <TokenSelectButton
                                w={isSimpleMode ? '238px' : '152px'}
                                ml="auto !important"
                                pl="27px"
                                size={isSimpleMode ? 'lg' : 'xs'}
                                token={swapFormTokenY}
                                handleSelect={handleSelectTokenY}
                            />
                            {!isSimpleMode && <IconTool token={swapFormTokenY}></IconTool>}
                        </Stack>
                        <Divider w="100%" display={isSimpleMode ? 'none' : 'block'} pt="16px"></Divider>
                        <VStack
                            spacing="10px"
                            alignItems="flex-start"
                            pt={isSimpleMode ? 'unset' : '15px'}
                            ml={isSimpleMode ? '46px !important' : 'unset'}
                        >
                            <HStack w="100%" justifyContent="space-between">
                                <Text
                                    variant="caption"
                                    className={i_text_d}
                                    fontWeight="bold"
                                    color={colorTheme('tertiary.600', 'tertiary.200')}
                                >
                                    {t('Amount')}
                                </Text>
                            </HStack>

                            <Card
                                variant="lightGary"
                                w={isSimpleMode ? '386px' : '100%'}
                                h={isSimpleMode ? '80px' : '55px'}
                                pt="3px"
                                pr="15px"
                                pl="18px"
                                border={isInputToFocus ? '2px solid #A880FF' : colorTheme('2px solid #ffffff', '2px solid #211834')}
                            >
                                {!isSearchingY ? (
                                    <AmountInput
                                        handleSetValue={handleSetValueY}
                                        disabled={!noWrapToken}
                                        token={swapFormTokenY}
                                        price={tokenYEntity.tokenPrice()}
                                        balance={tokenYEntity.tokenBalance()}
                                        inputValue={swapForm.amountDesireDecimal && Number(swapForm.amountDesireDecimal).toFixed(5)}
                                        w="100%"
                                        fontClass={i_h2}
                                        notShowShortcut={true}
                                        setIsInputFocus={setIsInputToFocus}
                                    />
                                ) : (
                                    <Center h="100%">
                                        <Text variant="caption" mr="10px">
                                            {t('Fetching price')}
                                        </Text>
                                        <img width="20px" height="20px" src="/assets/gallery/loading.gif" />
                                    </Center>
                                )}
                            </Card>
                        </VStack>
                    </Stack>
                </Card>

                {isSimpleMode ? (
                    <Accordion allowMultiple={true} allowToggle={true} w="100%">
                        <AccordionItem
                            hidden={!swapFormTokenX.symbol || !swapFormTokenY.symbol}
                            tokenA={swapFormTokenX}
                            tokenB={swapFormTokenY}
                            price={swapForm.initPriceDecimal && !isSearchingX && !isSearchingY ? swapForm.initPriceDecimal : undefined}
                            handleToggle={dispatch.tradeSwap.toggleTokenOrder}
                            mt="12px !important"
                            as={CurrentPriceCard}
                            border="0"
                            pl="65px"
                            pr="75px"
                            hideToggle={false}
                            onlyTogglePrice={true}
                        >
                            <HStack position="relative">
                                <AccordionButton
                                    position="absolute"
                                    right="-56px"
                                    top="-45px"
                                    color="secondary.500"
                                    w="unset"
                                    h="unset"
                                    className={i_text_copy}
                                    fontSize="12px !important"
                                >
                                    <AccordionIcon />
                                </AccordionButton>
                            </HStack>

                            <AccordionPanel p="0 0 10px 0">
                                <Divider mb="20px" mt="10px" borderColor={colorTheme('#EBEAEC', '#52466A')} />
                                {swapForm.swapPath && !swapForm.noSufficientLiquidity ? (
                                    <SwapDetail swapForm={swapForm} ready={!isSearchingX && !isSearchingY} />
                                ) : (
                                    <VStack alignItems="center">
                                        <HStack h="80px" textAlign="center">
                                            <Text
                                                variant="caption"
                                                lineHeight="24px"
                                                fontSize="16px"
                                                color={colorTheme('tertiary.600', 'tertiary.200')}
                                            >
                                                {swapFormTokenX.symbol &&
                                                swapFormTokenY.symbol &&
                                                (swapForm.amountDecimal || swapForm.amountDesireDecimal)
                                                    ? t('Oops. There is no sufficient liquidity for this trade') + '.'
                                                    : t('Please select token and input amount to continue') + '.'}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                )}
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                ) : null}
                {isShowError ? (
                    <ErrorInfo
                        content={getFieldValidateInfo(validateResult, 'amount')}
                        mt="13px !important"
                        mb={isSimpleMode ? '0px !important' : '13px !important'}
                    ></ErrorInfo>
                ) : (
                    <Box h="37px"></Box>
                )}

                <Center mt={'0px !important'} w="100%">
                    <CustomButton
                        hidden={
                            (!tokenXEntity.isApproved(maxAmountIn) && !!spenderAddress) ||
                            (!tokenXEntity.isDepositApproved() && !!depositSpenderAddress) ||
                            TRADE_TOKEN_BLACKLIST[chainId].find((item: string) => item === tokenXEntity.token?.address?.toLowerCase()) || //ban blacklist token
                            TRADE_TOKEN_BLACKLIST[chainId].find((item: string) => item === tokenYEntity.token?.address?.toLowerCase())
                        }
                        disabled={!swapForm.swapPath || swapForm.noSufficientLiquidity || isSearchingX || isSearchingY || isShowError}
                        mt={isSimpleMode ? '24px !important' : '-6px'}
                        variant="purple"
                        text={t(String(swapButton))}
                        mr={isSimpleMode ? '30px' : '0px'}
                        w={isSimpleMode ? '204px' : '100%'}
                        h="50px"
                        fontSize="14px"
                        onClick={() => {
                            Math.abs(swapForm.priceImpact ?? 0) > 0.1 ? setShowSwapConfirmModal(true) : handleSwap();
                        }}
                    />

                    <CustomButton
                        hidden={
                            tokenXEntity.isApproved(maxAmountIn) ||
                            !spenderAddress ||
                            !account ||
                            !swapFormTokenY.symbol ||
                            !swapFormTokenX.symbol
                        }
                        mt={isSimpleMode ? '24px !important' : '-6px'}
                        variant="purple"
                        text={t('Approve') + ' ' + swapFormTokenX.symbol}
                        mr={isSimpleMode ? '30px' : '0px'}
                        w={isSimpleMode ? '204px' : '100%'}
                        h="50px"
                        fontSize="14px"
                        onClick={() => {
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            tokenXEntity
                                .handleApprove()
                                .on('transactionHash', (hash: string) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(hash, chain);
                                    }
                                    toast('info', 'Ongoing', undefined, toastLink);
                                })
                                .then(() => {
                                    toast('success', 'Approve successfully', undefined, toastLink);
                                    tokenXEntity.handleApproveSuccess();
                                })
                                .catch((e: any) => {
                                    console.info('error   :', e.message);
                                });
                        }}
                    />
                    <CustomButton
                        hidden={
                            !depositSpenderAddress ||
                            tokenXEntity.isDepositApproved() ||
                            !account ||
                            !swapFormTokenY.symbol ||
                            !swapFormTokenX.symbol
                        }
                        mt={isSimpleMode ? '24px !important' : '-6px'}
                        variant="purple"
                        text={t('Deposit Approve') + ' ' + swapFormTokenX.symbol}
                        mr={isSimpleMode ? '30px' : '0px'}
                        w={isSimpleMode ? '204px' : '100%'}
                        h="50px"
                        fontSize="14px"
                        onClick={() => {
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            tokenXEntity
                                .handleDepositApprove()
                                .on('transactionHash', (hash: string) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(hash, chain);
                                    }
                                    toast('info', 'Ongoing', undefined, toastLink);
                                })
                                .then(() => {
                                    toast('success', 'Deposit approve successfully', undefined, toastLink);
                                    tokenXEntity.handleDepositApproveSuccess();
                                })
                                .catch((e: any) => {
                                    console.info('error   :', e.message);
                                });
                        }}
                    />
                </Center>
            </VStack>

            <SwapConfirmModal
                isOpen={showSwapConfirmModal}
                onClose={() => {
                    setShowSwapConfirmModal(false);
                }}
                handleSwap={handleSwap}
                swapForm={swapForm}
            />
        </>
    );
};

export default SwapForm;
