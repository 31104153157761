import { SearchIcon } from '@chakra-ui/icons';
import {
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    Switch,
    Image,
    InputLeftElement,
    useColorMode,
    InputRightElement,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getChain } from '../../../../config/chains';
import useIsMobile from '../../../../hooks/useIsMobile';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { getColorThemeSelector } from '../../../../iZUMi-UI-toolkit/src/utils/funcs';
import { RootDispatch, RootState } from '../../../../state/store';
import { i_text_copy, i_text_copy_bold } from '../../../../style';
import { Filter } from '../../../Farm/Gallery/components/Filter';

const MyLiquidityControl: React.FC = () => {
    const { t } = useTranslation();
    const { iZiSwapLiquidityList } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const { chainId } = useWeb3WithDefault();
    const chain = getChain(chainId);
    const isMobile = useIsMobile();
    const history = useHistory();

    const onShowByPair = useCallback(
        (e: any) => {
            const control = { ...iZiSwapLiquidityList.control };
            control.showByPair = e.target.checked;
            dispatch.iZiSwapLiquidityList.setControl(control);
        },
        [iZiSwapLiquidityList, dispatch]
    );

    const onSearchKeyChange = useCallback(
        (e: any) => {
            const control = { ...iZiSwapLiquidityList.control };
            control.searchKey = e.target.value;
            dispatch.iZiSwapLiquidityList.setControl(control);
        },
        [iZiSwapLiquidityList, dispatch]
    );

    const onUseWrappedGasToken = useCallback(
        (e: any) => {
            const control = { ...iZiSwapLiquidityList.control };
            control.useWrappedGasToken = e.target.checked;
            dispatch.iZiSwapLiquidityList.setControl(control);
        },
        [iZiSwapLiquidityList, dispatch]
    );

    const UseBlock = () => (
        <FormControl
            display="flex"
            alignItems="end"
            w={{ base: '207px', sm: 'unset' }}
            mr={{ base: '0px', sm: '40px !important' }}
            ml={{ base: 'auto !important', sm: 'auto !important' }}
            flexDirection={{ base: 'column', sm: 'row' }}
        >
            {chain?.wrappedTokenSymbol && (
                <Flex>
                    <FormLabel className={i_text_copy} fontSize="12px" fontWeight="500" ml={{ base: 'unset', lg: '20px' }} pt="6px">
                        {t('Use')} {chain.wrappedTokenSymbol}
                    </FormLabel>
                    <Switch
                        isChecked={iZiSwapLiquidityList.control.useWrappedGasToken}
                        onChange={(e) => onUseWrappedGasToken(e)}
                        size="lg"
                    ></Switch>
                </Flex>
            )}
            <Flex mt={{ base: '10px', sm: '0px' }}>
                <FormLabel className={i_text_copy} fontSize="12px" fontWeight="500" ml={{ base: '10px', lg: '20px' }} pt="6px">
                    {t('Zip')}
                </FormLabel>
                <Switch isChecked={iZiSwapLiquidityList.control.showByPair} onChange={(e) => onShowByPair(e)} size="lg"></Switch>
            </Flex>
        </FormControl>
    );

    return isMobile ? (
        <HStack w="100%" alignItems="start" pt="16px" pb="8px">
            <InputGroup w="274px">
                <InputLeftElement boxSize="20px" mt="4px" ml="10px">
                    <SearchIcon boxSize={5} />
                </InputLeftElement>
                <Input
                    variant="filled"
                    placeholder={t('Search Tokens')}
                    size="lg"
                    h="30px"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e) => onSearchKeyChange(e)}
                    value={iZiSwapLiquidityList.control.searchKey || ''}
                    bg={colorTheme('#FFFFFF', 'rgba(37, 32, 59, 0.5)')}
                    color="tertiary.300"
                    letterSpacing="0.02em"
                    fontFamily="Montserrat"
                />
            </InputGroup>

            <HStack h="100%" spacing="12px" ml="auto !important">
                <Filter
                    h="100%"
                    alignItems="center"
                    filterTitle="Liquidity Filter"
                    filterElement={[<UseBlock key={0}></UseBlock>]}
                ></Filter>
                <Image
                    boxSize="20px"
                    src="/assets/liquidity/addLiquidity.svg"
                    onClick={() => {
                        history.push('/add-liquidity/popular');
                    }}
                ></Image>
            </HStack>
        </HStack>
    ) : (
        <HStack w="100%" alignItems="start" pt={{ base: '12px', sm: '34px' }} pb={{ base: '20px', sm: '34px' }}>
            <InputGroup w={{ base: '275px', sm: '310px' }} h="41px" mt="-4px">
                <Input
                    variant="filled"
                    placeholder={t('Search Tokens')}
                    size="lg"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e) => onSearchKeyChange(e)}
                    value={iZiSwapLiquidityList.control.searchKey || ''}
                />
                <InputRightElement h="100%" mt="4px">
                    <SearchIcon boxSize={5} />
                </InputRightElement>
            </InputGroup>
            {isMobile && (
                <Filter
                    h="100%"
                    ml="auto !important"
                    alignItems="center"
                    filterTitle="Liquidity Filter"
                    filterElement={[<UseBlock key={0}></UseBlock>]}
                ></Filter>
            )}
            {!isMobile && <UseBlock></UseBlock>}
        </HStack>
    );
};

export default MyLiquidityControl;
