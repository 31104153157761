import { Divider, Flex, VStack, useColorMode, BoxProps } from '@chakra-ui/react';
import { ReactElement, useEffect, useState } from 'react';
import { getColorThemeSelector } from '../../utils/funcs';
import { useTokenListFormatted } from '../../hooks/useTokenListFormatted';
import { useLocation } from 'react-router-dom';
import { Loading } from '../../views/components/Loading';
import useIsMobile from '../../hooks/useIsMobile';
import { ScrollTab } from '../ScrollTab/ScrollTab';
import useWindowDimensions from '../../iZUMi-UI-toolkit/src/hooks/useWindowDimension';

export type AdaptationMode = 'mobile' | 'pc';

export type PageLayoutProps = {
    type?: AdaptationMode;
    header?: ReactElement;
    body: ReactElement;
    fixWidth?: boolean;
    fixMargin?: boolean;
    mobileAdapt?: boolean;
    internalWidth?: any;
    isShowScrollTab?: boolean;
} & BoxProps;

const PageLayout: React.FC<PageLayoutProps> = (props) => {
    const { type, header, body, fixWidth, fixMargin, mobileAdapt, internalWidth, isShowScrollTab = true, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const { height } = useWindowDimensions();

    const { loading } = useTokenListFormatted();
    const location = useLocation();
    const notShowDividerUrl = ['/swap', '/limit', '/iPoints', '/pools'];
    const [isShowDivider, setIsShowDivider] = useState(true);

    const showLoading = () => {
        const urls = ['/trade'];
        const url = urls.filter((u) => location.pathname.startsWith(u));
        return url.length;
    };

    useEffect(() => {
        const currentPath = location.pathname;
        setIsShowDivider(!isMobile && !notShowDividerUrl.includes(currentPath));
    }, [location, isMobile]);

    return type === 'mobile' ? (
        <VStack w="100%" spacing="0" {...rest}>
            <Flex
                pr="0px"
                pl="0px"
                h="100%"
                w="100%"
                overflowY="scroll"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none !important',
                    },
                }}
                justifyContent="space-between"
                position="relative"
            >
                <VStack
                    mx="auto"
                    w={'100%'}
                    overflowY="auto"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none !important',
                        },
                    }}
                >
                    {/* {isShowScrollTab && <ScrollTab />} */}

                    {isShowDivider && <Divider m="0 !important" />}

                    <Flex w="100%" direction="column" pr="24px" pl="24px">
                        {loading && showLoading() ? <Loading /> : props.body}
                    </Flex>
                </VStack>
            </Flex>
        </VStack>
    ) : (
        <VStack w="100%" h="100%" spacing="0" {...rest}>
            <Flex
                pr={{ base: '40px', xlp1: '100px' }}
                pl={{ base: '40px', xlp1: '0px' }}
                h="100%"
                w="100%"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none !important',
                    },
                }}
                justifyContent={{ base: 'space-between', '2xl': 'center' }}
                position="relative"
            >
                <VStack
                    mx={props.fixMargin ? 'unset' : { base: 'auto', xl: 'auto' }}
                    w={
                        props.internalWidth
                            ? props.internalWidth
                            : props.fixWidth
                            ? { xl: '1100px', xlp1: '1150px' }
                            : {
                                  base: props.mobileAdapt ? '100%' : '700px',
                                  lg: '840px',
                                  xxl: '1100px',
                                  xlp1: '1150px',
                              }
                    }
                    overflowY={{ base: 'auto', sm: 'unset' }}
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none !important',
                        },
                    }}
                >
                    {props.header}

                    {isShowDivider && <Divider m="0 !important" />}

                    <Flex
                        w="100%"
                        direction="column"
                        pr={{ base: props.mobileAdapt ? '24px' : '40px', sm: '0px' }}
                        pl={{ base: props.mobileAdapt ? '24px' : '40px', sm: '0px' }}
                        flexGrow={1}
                    >
                        {loading && showLoading() ? <Loading /> : props.body}
                    </Flex>
                </VStack>
            </Flex>
        </VStack>
    );
};

export default PageLayout;
