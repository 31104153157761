import {
    BoxProps,
    useColorMode,
    HStack,
    VStack,
    Text,
    Box,
    Image,
    Icon,
    InputGroup,
    Input,
    InputLeftElement,
    Stack,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { TokenSymbol } from '../../../../types/mod';
import { getColorThemeSelector, getFormatDateDiff, getHiddenAddress } from '../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy, i_h4 } from '../../../../style';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import Info from '../../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { NFTId } from '../../../../iZUMi-UI-toolkit/src/components/NFTId/NFTId';

import { useCallback, useEffect, useState } from 'react';
import { decimal2Amount, formatNumber } from '../../../../utils/tokenMath';
import tokens from '../../../../config/tokens';
import { BsClock } from 'react-icons/bs';
import { VeTokenChart } from './VeTokenChart';
import { VeTokenAmount } from '../../components/VeTokenAmount';
import { LockTime } from '../../components/LockTime';
import { LockMoreModal } from './LockMoreModal';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../state/store';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { InitManageDataParams, InitUserDataParams, VeTokenNft } from '../../../../state/models/veToken/types';
import { isNumeric } from '../../../../utils/valid';
import { StakeModal } from './StakeModal';
import { TransferModal } from './TransferModal';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import { VETOKEN_ADDRESS } from '../../../../config/veToken/veTokenContracts';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import { MergeModal } from './MergeModal';
import { FARM_CONFIG } from '../../../../config/bizConfig';
import { getStakeAPR } from '../../../../state/models/veToken/funcs';
import BigNumber from 'bignumber.js';
import { useARCToken } from '../../../../hooks/useARCToken';
import useVeTokenEntity from '../../../../hooks/useVeTokenEntity';

type NFTEntryProps = {
    nftId: string;
} & BoxProps;

export const NFTEntry: React.FC<NFTEntryProps> = (props) => {
    const { nftId, ...rest } = props;
    const { chainId, web3, account } = useWeb3WithDefault();
    const [searchedNFT, setSearchedNFT] = useState(undefined as unknown as VeTokenNft);
    const [searchedNftId, setSearchedNftId] = useState('');
    const [transferAddress, setTransferAddress] = useState('');
    const [showLockMoreModal, setShowLockMoreModal] = useState(false);
    const [showStakeModal, setShowStakeModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [showMergeModal, setShowMergeModal] = useState(false);

    const { ARCToken } = useARCToken();
    const { veToken } = useSelector((state: RootState) => state);
    const veTokenAddress = VETOKEN_ADDRESS[chainId];
    const veTokenEntity = useVeTokenEntity(veTokenAddress, 'V1');
    const { gasPrice } = useGasPrice();

    const userNftList = veToken.userData?.nftList ?? [];

    const currentTimestamp = veToken.globalData?.currentTimestamp ?? 0;

    const NFT =
        userNftList.find((e: VeTokenNft) => {
            return e.nftId === nftId;
        }) ??
        ({
            lockAmountDecimal: 0,
            veTokenDecimal: 0,
            nftId: '0',
            isStaked: false,
            endTimestamp: currentTimestamp,
            owner: '0x0000000000000000000000000000000000000000',
            apr: 0,
        } as VeTokenNft);

    const lockAmount = decimal2Amount(new BigNumber(NFT.lockAmountDecimal), ARCToken)?.toString() ?? '0';
    const veTokenAmount = decimal2Amount(new BigNumber(NFT.veTokenDecimal), ARCToken)?.toString() ?? '0';
    const stakeAPR = getStakeAPR(
        veToken.globalData?.rewardPerBlock ?? '0',
        veToken.globalData?.stakeTokenAmount ?? '0',
        lockAmount,
        veTokenAmount,
        chainId
    );

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const refreshAllData = useCallback(
        () =>
            dispatch.veToken
                .initAll({
                    chainId,
                    web3,
                    account,
                } as InitUserDataParams)
                .catch((e: any) => console.log('refresh global data error: ', e))
                .then(() => {
                    dispatch.veToken.initManageData({
                        chainId,
                        web3,
                        account,
                        manageNftId: nftId,
                    } as InitManageDataParams);
                }),
        [dispatch.veToken, chainId, web3, account, nftId]
    );
    const unlockRemainSeconds = NFT.endTimestamp - currentTimestamp;
    const currentDate = new Date(currentTimestamp * 1000);
    const unlockDate = new Date(currentDate.getTime() + unlockRemainSeconds * 1000);

    const formatDiffDate = getFormatDateDiff(currentDate, unlockDate);

    //console.log('end timestamp: ', NFT.endTimestamp);
    //console.log('current timestamp: ', currentTimestamp);

    useEffect(() => {
        console.info('trigger init manage data', chainId);
        refreshAllData();
    }, [chainId, dispatch.veToken, web3, account, nftId, refreshAllData]);

    const curveTimeStamps = veToken.globalData?.curveTimestamps ?? ([] as number[]);
    const curveValues = [...(veToken.manageData?.manageVeTokenCurve ?? ([] as number[]))];
    const lengthDelta = curveTimeStamps.length - curveValues.length;
    for (let i = 0; i < lengthDelta; i++) {
        curveValues.push(0);
    }

    const toast = useCustomToast();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const tokenStat = (img: any, text: any, value: number, symbol: any) => {
        return (
            <VStack w="90%" h="70px" py="10px" pl="20px" borderRadius="4px" alignItems="left">
                <HStack>
                    <Image w="18px" h="18px" src={process.env.PUBLIC_URL + img} />
                    <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.100')}>
                        {text}
                    </Text>
                </HStack>

                <HStack mt="12px">
                    <Text fontSize="16px" className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.50')}>
                        {formatNumber(value, 2, 2)}
                    </Text>

                    <Text fontSize="16px" className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.400')}>
                        {symbol}
                    </Text>
                </HStack>
            </VStack>
        );
    };

    const searchCard = () => {
        return (
            <Card
                variant="navyBlue"
                w="100%"
                minH="60px"
                pt="22px"
                pb="10px"
                pl={{ base: '0px', sm: '20px' }}
                borderRadius="4px"
                mb="20px !important"
            >
                <Stack w="100%" alignItems={{ base: 'center', sm: 'start' }} spacing="40px" direction={{ base: 'column', sm: 'row' }}>
                    <HStack alignItems="start" spacing={{ base: '20px', sm: '40px' }}>
                        <Text fontSize="12px" className={i_h4} color={colorTheme('tertiary.700', 'tertiary.100')}>
                            Search NFT:
                        </Text>

                        <InputGroup w={{ base: '181px', sm: '810px' }} h="41px" mt="-8px !important">
                            <Input
                                variant="filled"
                                placeholder="Please Enter NFT ID."
                                size="md"
                                className={i_text_copy_bold}
                                fontSize="12px"
                                onChange={(e) => {
                                    //console.log('nftid: ', e.target.value);
                                    if (isNumeric(Number(e.target.value))) {
                                        setSearchedNftId(e.target.value);
                                    }
                                }}
                                //value={''}
                            />
                            <InputLeftElement h="100%" mt="0px">
                                <SearchIcon boxSize={5} />
                            </InputLeftElement>
                        </InputGroup>
                    </HStack>

                    <CustomButton
                        variant="purple"
                        text="Search"
                        w={{ base: '164px', sm: '104px' }}
                        h="40px"
                        disabled={NFT.isStaked}
                        fontClass={i_text_copy_bold}
                        fontSize="12px"
                        mt={{ base: '10px !important', sm: '-8px !important' }}
                        onClick={() => {
                            const findNft = userNftList.find((e) => {
                                return e.nftId === searchedNftId;
                            });
                            if (findNft) {
                                setSearchedNFT({ ...findNft });
                            } else {
                                setSearchedNFT(undefined as unknown as any);
                            }
                        }}
                    />
                </Stack>
                {searchedNFT && (
                    <Card variant="base" w="90%" h="74px" p="17px 40px 17px 47px" mt="20px" ml="3%">
                        <HStack w="100%" justifyContent="space-between">
                            <HStack spacing="40px">
                                <NFTId direction="column" id={searchedNFT.nftId} w="75px" link="" />
                            </HStack>

                            <HStack>
                                <Info label="Owner" value={getHiddenAddress(searchedNFT.owner)} w="120px" />
                                <VeTokenAmount balance={searchedNFT.veTokenDecimal} />
                                <LockTime time={getFormatDateDiff(currentDate, new Date(searchedNFT.endTimestamp * 1000))} />
                                <CustomButton
                                    variant="primary2"
                                    text="Merge To"
                                    disabled={searchedNFT.isStaked || NFT.isStaked || NFT.endTimestamp > searchedNFT.endTimestamp}
                                    w="104px"
                                    h="40px"
                                    fontClass={i_text_copy_bold}
                                    fontSize="12px"
                                    mt="-8px !important"
                                    onClick={() => {
                                        setShowMergeModal(true);
                                    }}
                                />
                            </HStack>
                        </HStack>
                    </Card>
                )}
            </Card>
        );
    };

    return (
        <>
            <VStack w="100%" h="100%" alignItems="left" spacing="20px" {...rest}>
                <Card variant="navyBlue" w={{ base: '100%', sm: '1150px' }} h={{ base: 'unset', sm: '371px' }} mt="20px">
                    <Stack
                        w="100%"
                        h={{ base: 'unset', sm: '85px' }}
                        bg={colorTheme(
                            'linear-gradient(180deg, #F1F1FF 0%, #FAFAFF 100%)',
                            'linear-gradient(356.77deg, #3E3A66 -96.71%, rgba(62, 43, 103, 0.95) 140.6%)'
                        )}
                        p="22px 40px 20px 40px"
                        justifyContent="space-between"
                        direction={{ base: 'column', sm: 'row' }}
                    >
                        <Stack spacing="30px" direction={{ base: 'column', sm: 'row' }}>
                            <NFTId direction="row" id={NFT.nftId} w="100px" link="" />
                            <HStack spacing="5px">
                                <Box w="11px" h="11px" borderRadius="6px" bg="secondary.500" />
                                <Text className={i_text_copy_bold} color="secondary.500">
                                    Locked
                                </Text>
                            </HStack>
                            {NFT.isStaked && (
                                <HStack>
                                    <HStack spacing="5px">
                                        <Box w="11px" h="11px" borderRadius="6px" bg="#60DA00" />
                                        <Text className={i_text_copy_bold} color="#60DA00">
                                            Staked
                                        </Text>
                                    </HStack>
                                    {/* <Info
                                        direction="column"
                                        label="My APR"
                                        value={
                                            (NFT.apr ?? 0) < 99.99
                                                ? formatNumber((NFT.apr ?? 0) * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) + '%'
                                                : '9999+%'
                                        }
                                        w="70px"
                                        ml="30px !important"
                                    /> */}
                                </HStack>
                            )}
                            {/* <Info
                                direction="column"
                                label="Base~Max APR"
                                value={
                                    '0 ~ ' +
                                    (NFT.isStaked
                                        ? (NFT.apr ?? 0) < 99.99
                                            ? formatNumber((NFT.apr ?? 0) * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) + '%'
                                            : '9999+%'
                                        : (stakeAPR ?? 0) < 99.99
                                        ? formatNumber((stakeAPR ?? 0) * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) + '%'
                                        : '9999+%')
                                }
                                w="120px"
                                ml={
                                    NFT.isStaked
                                        ? { base: '0px !important', sm: '20px !important' }
                                        : { base: '0px !important', sm: '50px !important' }
                                }
                            /> */}
                        </Stack>
                        <Stack spacing="20px" direction={{ base: 'column', sm: 'row' }}>
                            <CustomButton
                                variant="purple"
                                text="Lock More/Extend Time"
                                w={{ base: '100%', sm: '234px' }}
                                h="40px"
                                fontClass={i_text_copy_bold}
                                fontSize="14px"
                                onClick={() => {
                                    setShowLockMoreModal(true);
                                }}
                            />

                            <CustomButton
                                variant="primary"
                                text={NFT.isStaked ? 'Unstake' : 'Stake'}
                                disabled={true}
                                w={{ base: '100%', sm: '204px' }}
                                h="40px"
                                fontClass={i_text_copy_bold}
                                fontSize="14px"
                                onClick={() => {
                                    setShowStakeModal(true);
                                }}
                            />
                        </Stack>
                    </Stack>

                    <Stack w="100%" p="0" direction={{ base: 'column', sm: 'row' }} alignItems="center">
                        <VStack w="266px" h={{ base: 'unset', sm: '336px' }} p="0" alignItems="left" spacing="20px" mt="15px">
                            <VStack w="286px" h="247px" borderRadius="14px" pt="13px" px="10px" spacing="16px">
                                {tokenStat(tokens[TokenSymbol.ARC].icon, 'ARC Locked :', NFT.lockAmountDecimal, TokenSymbol.ARC)}

                                {tokenStat('/assets/tokens/veToken.svg', 'veARC Amount :', NFT.veTokenDecimal, 'veARC')}

                                <VStack w="90%" h="70px" py="10px" pl="20px" borderRadius="4px" alignItems="left">
                                    <HStack spacing="10px">
                                        <Icon as={BsClock} boxSize="16px" />
                                        <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.100')}>
                                            Remaining Lock Time :
                                        </Text>
                                    </HStack>
                                    <HStack>
                                        <Text fontSize="16px" className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.50')}>
                                            {formatDiffDate}
                                        </Text>
                                    </HStack>
                                </VStack>
                            </VStack>
                        </VStack>
                        <VeTokenChart
                            ml={{ base: '10px !important', sm: '40px !important' }}
                            mr={{ base: '10px !important', sm: '0px !important' }}
                            mt={{ base: '20px !important', sm: '-10px !important' }}
                            h="300px"
                            curveTimeStamps={curveTimeStamps}
                            curveValues={curveValues}
                            height="250px"
                        />
                    </Stack>
                </Card>
                <Text className={i_text_copy_bold} color={colorTheme('tertiary.700', 'tertiary.300')}>
                    Withdraw
                </Text>
                <Card
                    variant="navyBlue"
                    w={{ base: '100%', sm: '1150px' }}
                    h="71px"
                    pt={{ base: '15px', sm: '22px' }}
                    pb={{ base: '0px', sm: '10px' }}
                    pl="20px"
                    pr={{ base: '20px', sm: '63px' }}
                >
                    <HStack justifyContent="space-between">
                        <Text className={i_text_copy} color={colorTheme('tertiary.700', 'tertiary.300')}>
                            {currentTimestamp >= NFT.endTimestamp ? 'The unlock time has arrived.' : 'The unlock time has not arrived yet.'}
                        </Text>
                        <CustomButton
                            variant="purple"
                            text="Withdraw"
                            w="164px"
                            h="40px"
                            disabled={currentTimestamp < NFT.endTimestamp || NFT.nftId === '0' || NFT.isStaked}
                            fontClass={i_text_copy_bold}
                            fontSize="12px"
                            mt={{ base: '0px !important', sm: '-8px !important' }}
                            onClick={() => {
                                veTokenEntity.withdraw(
                                    NFT.nftId,
                                    {
                                        onTransactionHash: (e: any) => {
                                            toast('info', 'Withdraw ARC: ' + e);
                                        },
                                        then: () => {
                                            //console.log(e);
                                            toast('info', 'Withdraw ARC successfully');
                                        },
                                        catch: (e: any) => {
                                            //console.log(e);
                                            toast('error', e.message);
                                        },
                                    },
                                    gasPrice
                                );
                            }}
                        />
                    </HStack>
                </Card>

                <Text className={i_text_copy_bold} color={colorTheme('tertiary.700', 'tertiary.300')}>
                    Transfer
                </Text>
                <Card
                    variant="navyBlue"
                    w={{ base: '100%', sm: '1150px' }}
                    h={{ base: 'unset', sm: '71px' }}
                    pt="22px"
                    pb="10px"
                    pl={{ base: '0px', sm: '20px' }}
                >
                    <Stack w="100%" alignItems={{ base: 'center', sm: 'start' }} spacing="40px" direction={{ base: 'column', sm: 'row' }}>
                        <HStack>
                            <Text fontSize="12px" className={i_h4} color={colorTheme('tertiary.700', 'tertiary.100')}>
                                To address
                            </Text>

                            <InputGroup
                                w={{ base: 'unset', sm: '750px' }}
                                h="41px"
                                mt="-8px !important"
                                ml={{ base: '20px !important', sm: '50px !important' }}
                            >
                                <Input
                                    variant="filled"
                                    placeholder="Please Enter Address"
                                    size="md"
                                    className={i_text_copy_bold}
                                    fontSize="12px"
                                    onChange={(e) => {
                                        setTransferAddress(e.target.value);
                                    }}
                                    //value={''}
                                />
                            </InputGroup>
                        </HStack>
                        <CustomButton
                            variant="purple"
                            text="Transfer veNFT"
                            w="164px"
                            h="40px"
                            disabled={transferAddress === '' || NFT.isStaked || NFT.nftId === '0'}
                            fontClass={i_text_copy_bold}
                            fontSize="12px"
                            mt={{ base: '10px !important', sm: '-8px !important' }}
                            onClick={() => {
                                setShowTransferModal(true);
                            }}
                        />
                    </Stack>
                </Card>
                <Text className={i_text_copy_bold} color={colorTheme('tertiary.700', 'tertiary.300')}>
                    Merge
                </Text>
                {searchCard()}
            </VStack>
            {showLockMoreModal && (
                <LockMoreModal
                    isOpen={showLockMoreModal}
                    nftId={nftId}
                    onSuccess={refreshAllData}
                    onClose={() => {
                        setShowLockMoreModal(false);
                    }}
                />
            )}
            {showStakeModal && (
                <StakeModal
                    isOpen={showStakeModal}
                    onClose={() => {
                        setShowStakeModal(false);
                    }}
                    entry={NFT}
                    onSuccess={refreshAllData}
                />
            )}
            {showTransferModal && (
                <TransferModal
                    isOpen={showTransferModal}
                    onClose={() => {
                        setShowTransferModal(false);
                    }}
                    toAddress={transferAddress}
                    entry={NFT}
                    onSuccess={refreshAllData}
                />
            )}
            {showMergeModal && (
                <MergeModal
                    isOpen={showMergeModal}
                    onClose={() => {
                        setShowMergeModal(false);
                    }}
                    entry={NFT}
                    targetEntry={searchedNFT}
                />
            )}
        </>
    );
};
