import { BigNumber } from 'bignumber.js';
import { useCallback } from 'react';
import { MiningCallbacks } from '../state/models/hooks/farm/common/callbacks';
import { buildSendingParams } from '../utils/contractHelpers';
import { useVeTokenContractWithVersion } from './useContracts';
import { useWeb3WithDefault } from './useWeb3WithDefault';

export interface VeTokenEntity {
    createLock: (tokenAmount: string, endBlockNum: string, callbacks: MiningCallbacks, gasPrice: number) => void;
    increaseAmountOrUnlockTime: (
        nftId: string,
        tokenAmount: string,
        endBlockNum: string,
        callbacks: MiningCallbacks,
        gasPrice: number
    ) => void;
    stake: (nftId: string, callbacks: MiningCallbacks, gasPrice: number) => void;
    unStake: (callbacks: MiningCallbacks, gasPrice: number) => void;
    unStakeAndStake: (stakeNftId: string, callbacks: MiningCallbacks, gasPrice: number) => void;
    withdraw: (nftId: string, callbacks: MiningCallbacks, gasPrice: number) => void;
    safeTransfer: (toAddress: string, nftId: string, callbacks: MiningCallbacks, gasPrice: number) => void;
    harvest: (callbacks: MiningCallbacks, gasPrice: number) => void;
    mergeTo: (fromNftId: string, toNftId: string, callbacks: MiningCallbacks, gasPrice: number) => void;
}

export interface MetaParams {
    account: string | null | undefined;
    veTokenContract: any;
    gas?: string;
    gasPrice?: number;
}

const _getCreateLockCall = (tokenAmount: string, endBlockNum: string, metaParams: MetaParams): any => {
    const { account, veTokenContract, gas, gasPrice } = metaParams;

    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };

    return [veTokenContract?.methods.createLock(tokenAmount, endBlockNum, '0x0000000000000000000000000000000000000000'), options];
};

const _getMulticall = (callings: string[], metaParams: MetaParams): any => {
    const { account, veTokenContract, gas, gasPrice } = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veTokenContract?.methods.multicall(callings), options];
};

const _getStakeCall = (nftId: string, metaParams: MetaParams): any => {
    const { account, veTokenContract, gas, gasPrice } = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veTokenContract?.methods.stake(nftId), options];
};

const _getWithdrawCall = (nftId: string, metaParams: MetaParams): any => {
    const { account, veTokenContract, gas, gasPrice } = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veTokenContract?.methods.withdraw(nftId), options];
};

const _getUnStakeCall = (metaParams: MetaParams): any => {
    const { account, veTokenContract, gas, gasPrice } = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veTokenContract?.methods.unStake(), options];
};

const _getSafeTransferFromCall = (nftId: string, toAddress: string, metaParams: MetaParams): any => {
    const { account, veTokenContract, gas, gasPrice } = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veTokenContract?.methods.safeTransferFrom(account, toAddress, nftId), options];
};

const _getHarvestCall = (metaParams: MetaParams): any => {
    const { account, veTokenContract, gas, gasPrice } = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veTokenContract?.methods.collect(), options];
};

const _getMergeCall = (fromNftId: string, toNftId: string, metaParams: MetaParams): any => {
    const { account, veTokenContract, gas, gasPrice } = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veTokenContract?.methods.merge(fromNftId, toNftId), options];
};

const useVeTokenEntity = (veTokenAddress: string | null | undefined, version: string): VeTokenEntity => {
    const { chainId, account } = useWeb3WithDefault();
    // const { gasPrice } = useGasPrice();

    const { contract } = useVeTokenContractWithVersion(veTokenAddress, version);

    const createLock = useCallback(
        (tokenAmount: string, endBlockNum: string, callbacks: MiningCallbacks, gasPrice: number) => {
            const getCreateLockCall = _getCreateLockCall;

            if (version === 'V3') {
                // update getCreateLockCall
            }
            const [createLockCall, options] = getCreateLockCall(tokenAmount, endBlockNum, { account, veTokenContract: contract });

            createLockCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);

                const [sendCreateLockCall, sendOptions] = getCreateLockCall(tokenAmount, endBlockNum, {
                    account,
                    veTokenContract: contract,
                    gas: gasLimit,
                    gasPrice,
                });

                sendCreateLockCall
                    .send(buildSendingParams(chainId, sendOptions, gasPrice))
                    .on('transactionHash', (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    })
                    .then((e: any) => {
                        if (callbacks.then !== undefined) {
                            callbacks.then(e);
                        }
                    })
                    .catch((e: any) => {
                        if (callbacks.catch !== undefined) {
                            callbacks.catch(e);
                        }
                    });
            });
        },
        [version, account, contract, chainId]
    );

    const increaseAmountOrUnlockTime = useCallback(
        (nftId: string, tokenAmount: string, endBlockNum: string, callbacks: MiningCallbacks, gasPrice: number) => {
            const callings: string[] = [];
            if (endBlockNum !== '0') {
                const increaseUnlockTimeCalling = contract?.methods.increaseUnlockTime(nftId, endBlockNum).encodeABI();
                callings.push(increaseUnlockTimeCalling);
            }
            if (tokenAmount !== '0') {
                const increaseAmountCalling = contract?.methods.increaseAmount(nftId, tokenAmount).encodeABI();
                callings.push(increaseAmountCalling);
            }
            const [multicall, options] = _getMulticall(callings, { account, veTokenContract: contract });
            multicall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendMulticall, sendOptions] = _getMulticall(callings, {
                    account,
                    veTokenContract: contract,
                    gas: gasLimit,
                    gasPrice,
                });
                sendMulticall
                    .send(buildSendingParams(chainId, sendOptions, gasPrice))
                    .on('transactionHash', (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    })
                    .then((e: any) => {
                        if (callbacks.then !== undefined) {
                            callbacks.then(e);
                        }
                    })
                    .catch((e: any) => {
                        if (callbacks.catch !== undefined) {
                            callbacks.catch(e);
                        }
                    });
            });
        },
        [account, chainId, contract]
    );

    const unStakeAndStake = useCallback(
        (stakeNftId: string, callbacks: MiningCallbacks, gasPrice: number) => {
            const callings: string[] = [];
            const unStakeCalling = contract?.methods.unStake().encodeABI();
            callings.push(unStakeCalling);
            const stakeCalling = contract?.methods.stake(stakeNftId).encodeABI();
            callings.push(stakeCalling);
            const [multicall, options] = _getMulticall(callings, { account, veTokenContract: contract });
            multicall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendMulticall, sendOptions] = _getMulticall(callings, {
                    account,
                    veTokenContract: contract,
                    gas: gasLimit,
                    gasPrice,
                });
                sendMulticall
                    .send(buildSendingParams(chainId, sendOptions, gasPrice))
                    .on('transactionHash', (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    })
                    .then((e: any) => {
                        if (callbacks.then !== undefined) {
                            callbacks.then(e);
                        }
                    })
                    .catch((e: any) => {
                        if (callbacks.catch !== undefined) {
                            callbacks.catch(e);
                        }
                    });
            });
        },
        [account, chainId, contract]
    );

    const stake = useCallback(
        (nftId: string, callbacks: MiningCallbacks, gasPrice: number) => {
            const [stakeCall, options] = _getStakeCall(nftId, { account, veTokenContract: contract });
            stakeCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendStakeCall, sendOptions] = _getStakeCall(nftId, { account, veTokenContract: contract, gas: gasLimit, gasPrice });
                sendStakeCall
                    .send(buildSendingParams(chainId, sendOptions, gasPrice))
                    .on('transactionHash', (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    })
                    .then((e: any) => {
                        if (callbacks.then !== undefined) {
                            callbacks.then(e);
                        }
                    })
                    .catch((e: any) => {
                        if (callbacks.catch !== undefined) {
                            callbacks.catch(e);
                        }
                    });
            });
        },
        [account, chainId, contract]
    );
    const unStake = useCallback(
        (callbacks: MiningCallbacks, gasPrice: number) => {
            const [stakeCall, options] = _getUnStakeCall({ account, veTokenContract: contract });
            stakeCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendStakeCall, sendOptions] = _getUnStakeCall({ account, veTokenContract: contract, gas: gasLimit, gasPrice });
                sendStakeCall
                    .send(buildSendingParams(chainId, sendOptions, gasPrice))
                    .on('transactionHash', (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    })
                    .then((e: any) => {
                        if (callbacks.then !== undefined) {
                            callbacks.then(e);
                        }
                    })
                    .catch((e: any) => {
                        if (callbacks.catch !== undefined) {
                            callbacks.catch(e);
                        }
                    });
            });
        },
        [account, chainId, contract]
    );

    const withdraw = useCallback(
        (nftId: string, callbacks: MiningCallbacks, gasPrice: number) => {
            const [stakeCall, options] = _getWithdrawCall(nftId, { account, veTokenContract: contract });
            stakeCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendStakeCall, sendOptions] = _getWithdrawCall(nftId, {
                    account,
                    veTokenContract: contract,
                    gas: gasLimit,
                    gasPrice,
                });
                sendStakeCall
                    .send(buildSendingParams(chainId, sendOptions, gasPrice))
                    .on('transactionHash', (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    })
                    .then((e: any) => {
                        if (callbacks.then !== undefined) {
                            callbacks.then(e);
                        }
                    })
                    .catch((e: any) => {
                        if (callbacks.catch !== undefined) {
                            callbacks.catch(e);
                        }
                    });
            });
        },
        [account, chainId, contract]
    );

    const safeTransfer = useCallback(
        (toAddress: string, nftId: string, callbacks: MiningCallbacks, gasPrice: number) => {
            // todo
            const [safeTransferFromCall, options] = _getSafeTransferFromCall(nftId, toAddress, { account, veTokenContract: contract });
            safeTransferFromCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendSafeTransferFromCall, sendOptions] = _getSafeTransferFromCall(nftId, toAddress, {
                    account,
                    veTokenContract: contract,
                    gas: gasLimit,
                    gasPrice,
                });
                sendSafeTransferFromCall
                    .send(buildSendingParams(chainId, sendOptions, gasPrice))
                    .on('transactionHash', (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    })
                    .then((e: any) => {
                        if (callbacks.then !== undefined) {
                            callbacks.then(e);
                        }
                    })
                    .catch((e: any) => {
                        if (callbacks.catch !== undefined) {
                            callbacks.catch(e);
                        }
                    });
            });
        },
        [account, chainId, contract]
    );

    const harvest = useCallback(
        (callbacks: MiningCallbacks, gasPrice: number) => {
            const [harvestCall, options] = _getHarvestCall({ account, veTokenContract: contract });
            harvestCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendHarvestCall, sendOptions] = _getHarvestCall({ account, veTokenContract: contract, gas: gasLimit, gasPrice });
                sendHarvestCall
                    .send(buildSendingParams(chainId, sendOptions, gasPrice))
                    .on('transactionHash', (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    })
                    .then((e: any) => {
                        if (callbacks.then !== undefined) {
                            callbacks.then(e);
                        }
                    })
                    .catch((e: any) => {
                        if (callbacks.catch !== undefined) {
                            callbacks.catch(e);
                        }
                    });
            });
        },
        [account, chainId, contract]
    );

    const mergeTo = useCallback(
        (fromNftId: string, toNftId: string, callbacks: MiningCallbacks, gasPrice: number) => {
            const [mergeToCall, options] = _getMergeCall(fromNftId, toNftId, { account, veTokenContract: contract });
            mergeToCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendMergeToCall, sendOptions] = _getMergeCall(fromNftId, toNftId, {
                    account,
                    veTokenContract: contract,
                    gas: gasLimit,
                    gasPrice,
                });
                sendMergeToCall
                    .send(buildSendingParams(chainId, sendOptions, gasPrice))
                    .on('transactionHash', (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    })
                    .then((e: any) => {
                        if (callbacks.then !== undefined) {
                            callbacks.then(e);
                        }
                    })
                    .catch((e: any) => {
                        if (callbacks.catch !== undefined) {
                            callbacks.catch(e);
                        }
                    });
            });
        },
        [account, chainId, contract]
    );
    return {
        createLock,
        increaseAmountOrUnlockTime,
        stake,
        unStake,
        unStakeAndStake,
        withdraw,
        safeTransfer,
        harvest,
        mergeTo,
    };
};

export default useVeTokenEntity;
