import { Box, BoxProps, Divider, Flex, HStack, useColorMode, VStack, Text } from '@chakra-ui/react';
import { default as React, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../../hooks/useWindowDimension';
import useIsMobile from '../../../hooks/useIsMobile';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { RootState } from '../../../state/store';
import { i_text_d } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import * as styles from './style';
import useLayoutType from '../../../hooks/useLayoutType';
import { LayoutStateEnum } from '../../../state/models/pageLayout/pageLayout';
type Value = string | number;
type DividerStyle = {
    w: string;
    top: string;
};
type InternalStyle = {} & BoxProps;
type Props = {
    list: { label: string; value: Value; component: ReactElement }[];
    endList?: { label: string; value: Value; component: ReactElement | undefined }[];
    value: Value;
    handleClick: OnClickFunction;
    isAdvanceMode?: boolean;
    variant?: 'simple' | 'mobile' | 'none';
    dividerStyle?: DividerStyle;
    notShowDivider?: boolean;
    internalStyle?: InternalStyle;
} & BoxProps;

const Tabs: React.FC<Props> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { list, endList, value, handleClick, variant, dividerStyle, notShowDivider, ...rest } = props;
    const { tradeMode } = useSelector((state: RootState) => state);
    const isSimpleMode = tradeMode.isSimpleMode;
    const layoutType = useLayoutType();
    const isHeader = layoutType === LayoutStateEnum.header;
    const isMobile = useIsMobile();

    return (
        <VStack borderRadius="4px" alignItems="flex-start" spacing="0" w={isMobile ? '100%' : 'unset'} {...rest}>
            <HStack
                h={isSimpleMode ? '66px' : '56px'}
                spacing="0"
                cursor="pointer"
                w="100%"
                bg={variant ? 'inherit' : colorTheme('#ffffff', '#140E28')}
                px={isMobile ? 0 : variant ? '44px' : 'unset'}
            >
                <HStack w="100%" justifyContent="space-between">
                    <HStack spacing="0px">
                        {list.map((item, key) => (
                            <Flex
                                className={variant ? i_text_d : styles.tabsBtnStyle}
                                key={key}
                                bg={
                                    variant
                                        ? 'inherit'
                                        : value !== item.value
                                        ? colorTheme('#EBEAF9', '#24193C')
                                        : colorTheme('#fff', '#292343')
                                }
                                w={isSimpleMode ? '120px' : '185px'}
                                textAlign="center"
                                h={isSimpleMode ? '66px' : '56px'}
                                alignItems="center"
                                justify="center"
                                onClick={() => handleClick(item.value)}
                                borderRadius="3px 3px 0 0"
                                direction="column"
                                color={variant ? colorTheme('#52466A', '#DDDAE1') : ''}
                                _hover={
                                    isSimpleMode
                                        ? {
                                              color: '#53E08F',
                                              fontWeight: '700 !important',
                                              zIndex: 1,
                                          }
                                        : {}
                                }
                                boxShadow={isSimpleMode ? '' : colorTheme('', 'unset !important')}
                            >
                                {variant ? (
                                    <Text color={value === item.value ? '#53E08F' : ''} fontWeight={value === item.value ? '700' : '500'}>
                                        {item.label}
                                    </Text>
                                ) : (
                                    <Text
                                        className={i_text_d}
                                        fontSize="14px"
                                        color={colorTheme('tertiary.700', 'tertiary.50')}
                                        fontWeight={value === item.value ? '700 !important' : '500 !important'}
                                    >
                                        {item.label}
                                    </Text>
                                )}

                                {variant && (
                                    <Divider
                                        mt="11px"
                                        w="114px"
                                        borderWidth={value === item.value ? '3px ' : '0px'}
                                        borderColor={
                                            value === item.value ? colorTheme('#53E08F', '#53E08F') : colorTheme('#EBE6F0', '#52466A')
                                        }
                                        zIndex={value === item.value ? 1 : 0}
                                        borderRadius="28px"
                                    ></Divider>
                                )}
                            </Flex>
                        ))}
                    </HStack>

                    <HStack pb="12px">
                        {endList &&
                            endList.map(
                                (item, key) =>
                                    item.component && (
                                        <Flex
                                            className={variant ? i_text_d : styles.tabsBtnStyle}
                                            key={key}
                                            bg={
                                                variant
                                                    ? 'inherit'
                                                    : value !== item.value
                                                    ? colorTheme('#EBEAF9', '#24193C')
                                                    : colorTheme('#fff', '#292343')
                                            }
                                            w={isSimpleMode ? 'auto' : '185px'}
                                            textAlign="center"
                                            h={isSimpleMode ? '66px' : '56px'}
                                            alignItems="center"
                                            justify="center"
                                            onClick={() => handleClick(item.value)}
                                            borderRadius="3px 3px 0 0"
                                            direction="column"
                                            color={variant ? colorTheme('#52466A', '#DDDAE1') : ''}
                                            _hover={
                                                isSimpleMode
                                                    ? {
                                                          color: '#53E08F',
                                                          fontWeight: '700 !important',
                                                          zIndex: 1,
                                                      }
                                                    : {}
                                            }
                                        >
                                            {variant ? (
                                                <Text
                                                    color={value === item.value ? '#53E08F' : ''}
                                                    fontWeight={value === item.value ? '700' : '500'}
                                                >
                                                    {item.component}
                                                </Text>
                                            ) : (
                                                <Text
                                                    className={i_text_d}
                                                    fontSize="14px"
                                                    color={colorTheme('tertiary.700', 'tertiary.50')}
                                                    fontWeight={value === item.value ? '700 !important' : '500 !important'}
                                                >
                                                    {item.label}
                                                </Text>
                                            )}
                                        </Flex>
                                    )
                            )}
                    </HStack>
                </HStack>
                {variant && !notShowDivider && (
                    <Divider
                        position="absolute"
                        w={dividerStyle?.w || '793px'}
                        top={dividerStyle?.top || (layoutType == LayoutStateEnum.sidebar ? '115px' : '115px')}
                        borderWidth="1px"
                    ></Divider>
                )}

                <Flex flexGrow={2} h="100%"></Flex>
            </HStack>

            <Box
                w="100%"
                boxShadow={variant ? 'inherit' : isSimpleMode ? colorTheme('', 'undefined') : ''}
                bg={
                    variant
                        ? 'inherit'
                        : isSimpleMode
                        ? colorTheme(
                              'linear-gradient(180deg, #EAEAF9 15.93%, rgba(246, 245, 253, 0.72) 45.7%)',
                              'linear-gradient(179.48deg, #40325C 3.65%, #2C1E42 42.65%, #221739 92.15%)'
                          )
                        : 'inherit'
                }
            >
                {list.find((item) => item.value === value)?.component}
            </Box>
        </VStack>
    );
};

export default Tabs;
