import { Box, HStack, useColorMode, Text, Flex } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PageLayout from '../../components/layout/PageLayout';
import PageHeader from '../../components/PageHeader/PageHeader';
import { GameEvents, getGameEvents } from '../../net/iZUMi-endpoints/src/restful/izumiGame';
import { RootDispatch, RootState } from '../../state/store';
import { getColorThemeSelector } from '../../utils/funcs';
// import HomePiece7 from '../Home/components/HomePiece7/HomePiece7';
import { EventEntry } from './components/EventEntry';
import HeaderTabs from './components/HeaderTabs';
import NetworkSelect from '../../components/Select/NetworkSelect/NetworkSelect';
import { useRematchDispatch } from '../../hooks/useRematchDispatch';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

const PrizeEvent: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const { prize } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));

    const [tabValue, setTabValue] = useState('');
    const [eventData, setEventData] = useState<GameEvents[]>([]);

    const bgColor = colorTheme('#FBFBFB', '#100C1E');

    const handleChangeTab = (value: string) => {
        if (value === 'Finished') {
            setTabValue('Finished');
        }
        if (eventData && eventData.length > 0) {
            eventData?.map((item) => {
                if (value === item.name) {
                    setTabValue(item.name);
                }
            });
        }
    };

    const formatTime = (type: string, time: number) => {
        const t = new Date().valueOf() / 1000;
        if (type === 'start') {
            return Math.max(time - t, 0);
        }
        if (type === 'end') {
            return Math.max(time - t, 0);
        }
        return Math.max(time - t, 0);
    };

    useEffect(() => {
        getGameEvents().then((r) => {
            setEventData(r.data.data.data);
        });
    }, [prize.eventStateChange]);

    const endedEventList = useMemo(() => {
        const t = new Date().valueOf() / 1000 - 14 * 24 * 60 * 60;
        dispatch.prize.setEndListState(eventData.filter((item) => (item.endTime as unknown as number) < t));
        return eventData.filter((item) => (item.endTime as unknown as number) < t);
    }, [dispatch.prize, eventData]);
    const activeEventList = useMemo(() => {
        const t = new Date().valueOf() / 1000 - 14 * 24 * 60 * 60;
        return eventData.filter((item) => (item.endTime as unknown as number) >= t);
    }, [eventData]);
    useEffect(() => {
        dispatch.prize.setEndListState(endedEventList);
    }, [dispatch.prize, endedEventList]);

    const EventList = useMemo(() => {
        const tabs: any[] = [];
        if (activeEventList && activeEventList.length > 0) {
            if (!tabValue) {
                setTabValue(activeEventList[0].name);
            }
            activeEventList?.map((item) => {
                return tabs.push({
                    label: item.name,
                    value: item.name,
                    status: item.status,
                    component: (
                        <EventEntry
                            status={item.status}
                            startTime={formatTime('start', item.startTime as unknown as number)}
                            endTIme={formatTime('end', item.endTime as unknown as number)}
                            eventId={item.id}
                            banner={item.banner}
                            leftTime={item.leftTime as unknown as number}
                        />
                    ),
                });
            });
        }

        return tabs;
    }, [activeEventList, tabValue]);

    return isMobile ? (
        <PageLayout
            body={
                <Box
                    w="100%"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none !important',
                        },
                    }}
                    boxSizing="border-box"
                    position="relative"
                >
                    <HeaderTabs list={EventList} value={tabValue} handleClick={handleChangeTab} mt="34px"></HeaderTabs>
                    {/* <HomePiece7 bg="inherit"></HomePiece7> */}
                </Box>
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={t('Campaign')}
                    subtitle={t('Join the Campaigns to win rewards') + '.'}
                    element={
                        <HStack spacing="10px">
                            <NetworkSelect />
                        </HStack>
                    }
                />
            }
            body={
                <Box
                    w="100%"
                    bgColor={bgColor}
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none !important',
                        },
                    }}
                    boxSizing="border-box"
                    position="relative"
                >
                    <HeaderTabs list={EventList} value={tabValue} handleClick={handleChangeTab} mt="34px"></HeaderTabs>
                    {/* <HomePiece7 bg="inherit"></HomePiece7> */}
                </Box>
            }
        />
    );
};
export default PrizeEvent;
