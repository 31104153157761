import { Flex, Image, Text } from '@chakra-ui/react';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { a_display, a_p } from '../../../../../iZUMi-UI-toolkit/src/style';
import placeholder from '../../../../../assets/placeholder.png';

type PartnerCardProps = {
    titleImgSrc?: string;
    title?: string;
    content?: string;
    link?: string;
};
export const PartnerCard: React.FC<PartnerCardProps> = (props) => {
    const { titleImgSrc, title, content, link } = props;
    return (
        <Card
            w={'unset'}
            h="247px"
            bg="linear-gradient(180deg, #20094B 0%, rgba(30, 7, 73, 0.94) 100%);"
            px="21px"
            cursor="pointer"
            onClick={() => {
                window.open(link);
            }}
            boxShadow="unset"
        >
            <Flex alignItems="center" mt="24px">
                <Image boxSize="53px" src={titleImgSrc ? titleImgSrc : placeholder}></Image>
                <Text w="149px" className={a_display} fontFamily="Gilroy-Bold" color="tertiary.50" ml="17px">
                    {title}
                </Text>
            </Flex>
            <Text className={a_p} noOfLines={3} lineHeight="19px !important" color="#9C95BE" pr="15px" mt="28px">
                {content}
            </Text>
            <Image mt="auto" ml="auto" mb="17px" boxSize="15px" src="/assets/home/homePiece3/ArrowRight.svg"></Image>
        </Card>
    );
};
