import { BoxProps, Circle, Image } from '@chakra-ui/react';
import placeholder from '../../../../../assets/placeholder.png';

type MediaCircleProps = {
    imgSrc: string;
    link?: string;
} & BoxProps;
export const MediaCircle: React.FC<MediaCircleProps> = (props) => {
    const { imgSrc, link, ...rest } = props;
    return (
        <Circle
            boxSize="62px"
            bg="rgba(64, 54, 167, 0.35);"
            _hover={{ background: '#7D68E6' }}
            cursor="pointer"
            onClick={() => {
                window.open(link);
            }}
            {...rest}
        >
            <Image boxSize="24px" src={imgSrc ?? placeholder}></Image>
        </Circle>
    );
};
