import React, { useEffect } from 'react';
import {
    Text,
    Flex,
    Icon,
    IconButton,
    Button,
    Box,
    Image,
    useColorMode,
    Divider,
    VStack,
    HStack,
    useInterval,
    Stack,
} from '@chakra-ui/react';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { Modal } from '../../../iZUMi-UI-toolkit/src/components/Modal/Modal';

import useAuth from '../../../hooks/useAuth';
import { BiLinkExternal } from 'react-icons/bi';
import { getScanUrlPrefix } from '../../../config/chains';
import Identicon from '../../Identicon';
import { getColorThemeSelector } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../state/store';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { i_text_copy_bold, i_text_d, i_h4, i_h2, i_text_copy } from '../../../style';
import { setCurrentConnector, useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { AccountTokenParams } from '../../../state/models/account/account';
import { ACCOUNT_CONFIG } from '../../../config/bizConfig';
import useWindowDimensions from '../../../hooks/useWindowDimension';
import { useHistory } from 'react-router-dom';
import useIsMobile from '../../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { logoutWithParticle, particle } from '../../../utils/particle';
import { ConnectorNames } from '../../../utils/connectors';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onCopyAddress: () => void;
    account: string;
    scanName: string;
    chainId: number;
}

const WalletModal: React.FC<Props> = ({ isOpen, onClose, account, scanName, chainId, onCopyAddress }) => {
    const { logout } = useAuth();
    const isMobile = useIsMobile();

    const viewAccountSelf = () => window.open(getScanUrlPrefix(chainId) + account, '_blank');

    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { account: accountModel } = useSelector((state: RootState) => state);
    const addressBgColor = colorTheme('#F5F5F5', '#18102D9E');
    const { web3 } = useWeb3WithDefault();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    useEffect(() => {
        if (account && web3 && chainId) {
            dispatch.account.changeAccount({ address: account, chainId });
            dispatch.account.fetchEthBalanceAndUSDIfMissing({
                account,
                web3,
                chainId,
            });
        }
    }, [account, chainId, web3, dispatch.account]);
    useInterval(() => {
        if (account && web3 && chainId) {
            dispatch.account.refreshTokenBalance({
                account,
                web3,
                chainId,
            } as AccountTokenParams);
        }
    }, ACCOUNT_CONFIG.AUTO_REFRESH_TOKEN_BALANCE_INTERVAL);

    const { width } = useWindowDimensions();
    const changeLineWidth = 480;
    const isChangeLine = changeLineWidth > width ? true : false;
    const history = useHistory();

    return (
        <Modal isOpen={isOpen} onClose={onClose} w={{ base: '80%', lg: '640px' }} h="unset" title={t('Your Wallet')} top="40%">
            <Stack
                direction={isChangeLine ? 'column' : 'row'}
                w="100%"
                h={isChangeLine ? 'unset' : '50px'}
                alignItems={isChangeLine ? 'left' : 'unset'}
            >
                <Flex w={isChangeLine ? 'unset' : '50%'} h="100%" justifyContent={isChangeLine ? 'center' : 'unset'}>
                    <Identicon />
                    <VStack ml={isChangeLine ? '26px' : '20px'} spacing="6px" alignItems="left">
                        <Text className={i_text_d} color={colorTheme('tertiary.800', 'tertiary.100')}>
                            {t('Account')}
                        </Text>
                        <Text w="120px" h="15px" color={colorTheme('tertiary.300', 'tertiary.300')} className={i_text_copy}>
                            {truncateWalletAddress(account || '', 8)}
                        </Text>
                    </VStack>
                </Flex>
                <Box w={isChangeLine ? 'unset' : '50%'} h="100%">
                    <Flex
                        h="100%"
                        float={isChangeLine ? 'unset' : 'right'}
                        alignItems="center"
                        mr={isChangeLine ? 'unset' : '6px'}
                        ml={isChangeLine ? '60px' : 'unset'}
                    >
                        <VStack ml="6px" textAlign="right" alignItems="end" mb="10px">
                            <HStack spacing="10px" mr={isChangeLine ? 'auto' : 'unset'} justifyItems={isChangeLine ? 'unset' : 'center'}>
                                <Image
                                    src={
                                        process.env.PUBLIC_URL +
                                        colorTheme('/assets/wallet/balanceIcon.png', '/assets/wallet/balanceIconDark.png')
                                    }
                                    w="6px"
                                    h="16px"
                                />
                                <Text className={i_h2} fontWeight="bold" color={colorTheme('tertiary.800', 'tertiary.100')}>
                                    {formatNumber(accountModel.ethBalance ?? 0, 2, 2)}
                                </Text>
                            </HStack>
                            <Text className={i_text_d} fontSize="12px" color="tertiary.300" mt="0px !important">
                                ≈{formatNumber(accountModel.ethBalanceUSD ?? 0, 2, 2)} USD
                            </Text>
                        </VStack>
                    </Flex>
                </Box>
            </Stack>

            <Divider w="100%" h="5px" mt="10px"></Divider>
            <Box mt="15px">
                <Text className={i_text_d} color={colorTheme('tertiary.500', 'tertiary.400')} fontSize="12px">
                    {t('Address')}
                </Text>
                <HStack h="53px" w="100%" mb="24px" mt="10px">
                    <Flex
                        h={width > changeLineWidth ? '53px' : '54px'}
                        w={width > changeLineWidth ? '86%' : '77%'}
                        textAlign="center"
                        border={`${colorTheme('1px solid #ECECEC', 'unset')}`}
                        boxShadow="0px 0px 10px 0px rgba(63, 63, 63, 0.05)"
                        borderRadius="6px"
                        align="center"
                        justify="center"
                        bgColor={addressBgColor}
                        p="15px"
                        mr="auto"
                    >
                        <Text
                            className={i_h4}
                            color={colorTheme('tertiary.800', 'tertiary.200')}
                            fontSize={{ base: '12px', lg: '15px' }}
                            w="100%"
                        >
                            {truncateWalletAddress(account || '', 10, 8)}
                        </Text>
                    </Flex>
                    <Button
                        w={{ base: '30px', lg: '46px' }}
                        h={width > changeLineWidth ? '53px' : '37px'}
                        ml="auto"
                        mr="7px !important"
                        borderRadius="4px"
                        bgColor={addressBgColor}
                        onClick={onCopyAddress}
                    >
                        <Image src={process.env.PUBLIC_URL + '/assets/wallet/copy.svg'}></Image>
                    </Button>
                </HStack>
            </Box>

            <HStack alignItems="center" my="20px !important">
                <Text className={i_text_copy_bold} fontSize={isMobile ? '12px' : '14px'} color={colorTheme('primary.500', 'primary.500')}>
                    {t('View on')} {scanName}
                </Text>
                <IconButton
                    ml="2px"
                    aria-label="explore"
                    variant="ghost"
                    onClick={viewAccountSelf}
                    size="sm"
                    icon={<Icon as={BiLinkExternal} boxSize={5} />}
                    color={colorTheme('primary.500', 'primary.500')}
                />
            </HStack>

            <Flex w="100%" position="relative">
                <CustomButton
                    mx="auto"
                    w={isMobile ? '100px' : '206px'}
                    h="40px"
                    variant="orange"
                    text={isMobile ? t('Disconnect') : t('Disconnect Wallet')}
                    fontClass={i_text_copy_bold}
                    onClick={() => {
                        onClose();
                        if (particle.auth.isLogin()) {
                            logoutWithParticle();
                            setCurrentConnector(ConnectorNames.Injected);
                            dispatch.loginConfig.setLoginConfig(ConnectorNames.Injected);
                        } else {
                            logout();
                            setCurrentConnector(ConnectorNames.Injected);
                            dispatch.loginConfig.setLoginConfig(ConnectorNames.Injected);
                        }
                    }
                }
                />
                <CustomButton
                    mx="auto"
                    w={isMobile ? '100px' : '206px'}
                    h="40px"
                    variant="primary2"
                    text={isMobile ? t('History') : t('Order History')}
                    fontClass={i_text_copy_bold}
                    onClick={() => {
                        onClose();
                        history.push('/order-history');
                    }}
                />
            </Flex>
        </Modal>
    );
};

export default WalletModal;
