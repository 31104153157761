import React from 'react';
import { Text, useColorMode, Image, BoxProps, HStack, StackDirection } from '@chakra-ui/react';
import Info from '../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';

type VeTokenAmountProps = {
    balance: number;
    direction?: StackDirection;
} & BoxProps;

export const VeTokenAmount: React.FC<VeTokenAmountProps> = ({ balance, direction, ...rest }) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    return (
        <Info
            direction={direction || 'column'}
            label={
                <HStack spacing="10px">
                    <Image h="17px" w="17px" src={process.env.PUBLIC_URL + '/assets/tokens/veToken.svg'} />
                    <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.300')}>
                        veARC balance
                    </Text>
                </HStack>
            }
            value={
                <Text className={i_text_copy} fontSize="14px" color={colorTheme('tertiary.800', 'tertiary.100')}>
                    {formatNumber(balance, 2, 2)}
                </Text>
            }
            w="170px"
            {...rest}
        />
    );
};
