import { Box, BoxProps, Divider, Flex, HStack, useColorMode, VStack, Text } from '@chakra-ui/react';
import { default as React, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { i_text_d } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { LayoutStateEnum } from '../../../state/models/pageLayout/pageLayout';
import useLayoutType from '../../../hooks/useLayoutType';
type Value = string | number;
type DividerStyle = {
    w: string;
    top: string;
};
export type tabList = {
    label: string;
    value: Value;
    component: ReactElement;
};
type Props = {
    list: tabList[];
    value: Value;
    handleClick: OnClickFunction;
    isAdvanceMode?: boolean;
    variant?: 'simple' | 'none';
    dividerStyle?: DividerStyle;
    notShowDivider?: boolean;
} & BoxProps;

const PrizeTabs: React.FC<Props> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { list, value, handleClick, variant, dividerStyle, notShowDivider, ...rest } = props;
    const { tradeMode } = useSelector((state: RootState) => state);
    const isSimpleMode = tradeMode.isSimpleMode;
    const layoutType = useLayoutType();

    return (
        <VStack w="100%" borderRadius="4px" mx="auto" alignItems="flex-start" {...rest} spacing="0">
            <HStack
                h={isSimpleMode ? '46px' : '36px'}
                spacing="0"
                cursor="pointer"
                w="100%"
                bg={variant ? 'inherit' : colorTheme('#ffffff', '#140E28')}
                alignItems="end"
            >
                {list.map((item, key) => (
                    <Flex
                        className={i_text_d}
                        key={key}
                        bg={variant ? 'inherit' : value !== item.value ? colorTheme('#EBEAF9', '#24193C') : colorTheme('#fff', '#292343')}
                        w={100 / list.length + '%'}
                        textAlign="center"
                        h={isSimpleMode ? '36px' : '26px'}
                        alignItems="center"
                        justifyContent="end"
                        onClick={() => handleClick(item.value)}
                        borderRadius="3px 3px 0 0"
                        direction="column"
                        color={variant ? colorTheme('#52466A', '#DDDAE1') : ''}
                        _hover={
                            isSimpleMode
                                ? {
                                      color: '#7F4AFE',
                                      fontWeight: '700 !important',
                                      zIndex: 1,
                                  }
                                : {}
                        }
                        boxShadow={
                            isSimpleMode
                                ? ''
                                : colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', '0px 0px 27px 5px rgba(29, 6, 51, 0.25)')
                        }
                    >
                        {variant ? (
                            <Text color={value === item.value ? '#7F4AFE' : ''} fontWeight={value === item.value ? '700' : '500'}>
                                {item.label}
                            </Text>
                        ) : (
                            <Text
                                className={i_text_d}
                                fontSize="14px"
                                color={colorTheme('tertiary.700', 'tertiary.50')}
                                fontWeight={value === item.value ? '700 !important' : '500 !important'}
                            >
                                {item.label}
                            </Text>
                        )}

                        {variant && (
                            <Divider
                                mt="11px"
                                w="100%"
                                borderWidth={value === item.value ? '3px ' : '0px'}
                                borderColor={value === item.value ? colorTheme('#7F4AFE', '#8B62FF') : colorTheme('#EBE6F0', '#52466A')}
                                zIndex={value === item.value ? 1 : 0}
                                borderRadius="28px"
                            ></Divider>
                        )}
                    </Flex>
                ))}
                {variant && !notShowDivider && (
                    <Divider
                        position="absolute"
                        w={dividerStyle?.w || '793px'}
                        top={dividerStyle?.top || (layoutType == LayoutStateEnum.sidebar ? '239px' : '199px')}
                        borderWidth="1px"
                    ></Divider>
                )}
            </HStack>

            <Box
                w="100%"
                boxShadow={variant ? 'inherit' : isSimpleMode ? colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', 'undefined') : ''}
                bg={
                    variant
                        ? 'inherit'
                        : isSimpleMode
                        ? colorTheme(
                              'linear-gradient(180deg, #EAEAF9 15.93%, rgba(246, 245, 253, 0.72) 45.7%)',
                              'linear-gradient(179.48deg, #40325C 3.65%, #2C1E42 42.65%, #221739 92.15%)'
                          )
                        : colorTheme('linear-gradient( #ffffff 15%, #fbfbfb 50%)', 'linear-gradient( #292343 15%, #140E28 50%)')
                }
            >
                {list.find((item) => item.value === value)?.component}
            </Box>
        </VStack>
    );
};

export default PrizeTabs;
