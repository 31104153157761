import { HStack, Stack, Text, useColorMode } from '@chakra-ui/react';
import { BigNumber } from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES } from '../../../../../config/trade/tradeContracts';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { ToastLink, useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import useTokenEntity from '../../../../../state/models/hooks/useTokenEntity';
import { RootDispatch, RootState } from '../../../../../state/store';
import { i_text_copy_bold, noto_t3, noto_t3_bold } from '../../../../../style';
import { LimitOrderWithSwapManagerContract } from '../../../../../types/abis/iZiSwap/LimitOrderWithSwapManager';
import { QuoterContract } from '../../../../../types/abis/iZiSwap/Quoter';
import { getLimitOrderWithSwapManagerContract, getQuoterContract } from '../../../../../utils/contractFactory';
import { getColorThemeSelector, toFeeNumber } from '../../../../../utils/funcs';
import { amount2Decimal, decimal2Amount, formatNumber } from '../../../../../utils/tokenMath';
import OrderSlider from './OrderSlider/OrderSlider';

import { useGasOrWrappedToken } from '../../../../../state/models/pro/utils/useGasOrWrappedToken';
import OrderNumberInput from './OrderInput/OrderNumberInput';
import { getChain, getTxLink } from '../../../../../config/chains';

type LimitProps = {
    actionType?: any;
    setActionType?: any;
};
const Limit: React.FC<LimitProps> = (props) => {
    const { actionType, setActionType } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [quantity, setQuantity] = useState(0);
    // const [sliderValue, setSliderValue] = useState(0);
    const [orderValue, setOrderValue] = useState(0);

    const { proOrderFormState } = useSelector((state: RootState) => state);

    const priceDecimalBaseByQuote = proOrderFormState.priceDecimalBaseByQuote;

    const [inputPrice, setInputPrice] = useState(0);

    useEffect(() => {
        setInputPrice(proOrderFormState.priceDecimalBaseByQuote);
    }, [proOrderFormState, setInputPrice]);

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { pro, proControlState } = useSelector((state: RootState) => state);
    const tradeFlashMode = proControlState.tradeFlashMode;
    const useGasToken = proControlState.useGasToken;

    const { account, chainId, web3 } = useWeb3WithDefault();
    const { gasPrice } = useGasPrice();
    const toast = useCustomToast();

    useEffect(() => {
        if (chainId !== proOrderFormState.chainId) {
            // newly start (initial chainId is -1) or change chainId
            // todo: select pool by chainId
            const tokenA = pro.tokenX;
            const tokenB = pro.tokenY;
            const fee = toFeeNumber(pro.currentFee) as FeeTier;

            if (pro.isReverseToken) {
                dispatch.proOrderFormState.setTokenPair({
                    tokenB,
                    tokenA,
                    fee,
                    chainId,
                    web3,
                });
            } else {
                dispatch.proOrderFormState.setTokenPair({
                    tokenA,
                    tokenB,
                    fee,
                    chainId,
                    web3,
                });
            }
        }
    }, [chainId, dispatch.proOrderFormState, pro.currentFee, pro.isReverseToken, pro.tokenX, pro.tokenY, proOrderFormState.chainId, web3]);

    const limitOrderWithSwapManagerAddress = LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES[chainId];

    const baseToken = useGasOrWrappedToken(proOrderFormState.baseToken, useGasToken);
    const quoteToken = useGasOrWrappedToken(proOrderFormState.quoteToken, useGasToken);

    const payToken = actionType === 'buy' ? quoteToken : baseToken;

    const baseTokenEntity = useTokenEntity(baseToken, limitOrderWithSwapManagerAddress);
    const quoteTokenEntity = useTokenEntity(quoteToken, limitOrderWithSwapManagerAddress);

    const payTokenEntity = actionType === 'buy' ? quoteTokenEntity : baseTokenEntity;

    const quoteAmountDecimalBalance = quoteTokenEntity.tokenBalance();
    const baseAmountDecimalBalance = baseTokenEntity.tokenBalance();

    const baseAmountBalance = decimal2Amount(new BigNumber(baseAmountDecimalBalance), baseToken) ?? new BigNumber(0);
    const quoteAmountBalance = decimal2Amount(new BigNumber(quoteAmountDecimalBalance), quoteToken) ?? new BigNumber(0);

    const baseAmountDecimal = amount2Decimal(proOrderFormState.baseTokenAmount, baseToken) ?? 0;
    const quoteAmountDecimal = amount2Decimal(proOrderFormState.quoteTokenAmount, quoteToken) ?? 0;
    const formatPriceDecimalBaseByQuote = Number(formatNumber(priceDecimalBaseByQuote, 2, 2, true).replace(/,/g, ''));
    const formatbaseAmountDecimal = Number(formatNumber(baseAmountDecimal, 2, 2, true).replace(/,/g, ''));

    let sliderValue = 0;

    if (actionType === 'buy') {
        if (quoteAmountBalance.gt(0)) {
            sliderValue = Math.round(proOrderFormState.quoteTokenAmount.div(quoteAmountBalance).toNumber() * 100);
        }
    } else {
        if (baseAmountBalance.gt(0)) {
            sliderValue = Math.round(proOrderFormState.baseTokenAmount.div(baseAmountBalance).toNumber() * 100);
        }
    }
    sliderValue = Math.max(0, Math.min(100, sliderValue));

    const quoteValue = quoteAmountDecimal * quoteTokenEntity.tokenPrice();

    const payAmount = useMemo((): string => {
        return actionType === 'buy' ? proOrderFormState.quoteTokenAmount.toFixed(0) : proOrderFormState.baseTokenAmount.toFixed(0);
    }, [proOrderFormState, actionType]);

    const approved = payTokenEntity.isApproved(payAmount);
    const quoterContract = getQuoterContract(chainId, web3, false) as QuoterContract;
    return (
        <Stack w="100%">
            <HStack spacing="0px">
                <CustomButton
                    w="50%"
                    h="24px"
                    className={noto_t3}
                    text="Buy"
                    color={actionType === 'buy' ? '#FFFFFF' : '#B3B3B3'}
                    bg={colorTheme(actionType === 'buy' ? '#54AB6B' : '#EEEEEE', actionType === 'buy' ? '#54AB6B' : '#251B39')}
                    borderRadius="2px"
                    _hover={{
                        bg: colorTheme(actionType === 'buy' ? '' : '#E6F2E8', actionType === 'buy' ? '' : '#243334'),
                        color: actionType === 'buy' ? '' : '#576359',
                    }}
                    onClick={() => {
                        dispatch.proOrderFormState.setIsBuy(true);
                        setActionType('buy');
                    }}
                ></CustomButton>
                <CustomButton
                    w="50%"
                    h="24px"
                    className={noto_t3}
                    text="Sell"
                    color={actionType === 'sell' ? '#FFFFFF' : '#B3B3B3'}
                    bg={colorTheme(actionType === 'sell' ? '#D34D4D' : '#EEEEEE', actionType === 'sell' ? '#D34D4D' : '#251B39')}
                    borderRadius="2px"
                    _hover={{
                        bg: colorTheme(actionType === 'sell' ? '' : '#F8EBEB', actionType === 'sell' ? '' : '#382433'),
                        color: actionType === 'sell' ? '' : '#92686B',
                    }}
                    onClick={() => {
                        dispatch.proOrderFormState.setIsBuy(false);
                        setActionType('sell');
                    }}
                ></CustomButton>
            </HStack>
            <Stack mt="25px !important">
                <HStack justifyContent="space-between">
                    <Text className={noto_t3} color={colorTheme('#B3B3B3', '#6A598C')}>
                        Available Balance
                    </Text>
                    <HStack>
                        <Text className={noto_t3_bold} color={colorTheme('#767676', '#C6B8CF')}>
                            {formatNumber(payTokenEntity.tokenBalance(), 2, 4)}
                        </Text>
                        <Text className={noto_t3} color={colorTheme('#B3B3B3', '#6A598C')}>
                            {payToken.symbol}
                        </Text>
                    </HStack>
                </HStack>
            </Stack>

            <OrderNumberInput
                variant={actionType === 'buy' ? 'green' : 'red'}
                leftContent="Order Price"
                rightContent={quoteToken.symbol}
                setValue={(priceDecimalBaseByQuote: number) => {
                    setInputPrice(priceDecimalBaseByQuote);
                    if (priceDecimalBaseByQuote > 0) {
                        dispatch.proOrderFormState.setPrice({
                            priceDecimalBaseByQuote,
                            baseAmountBalance,
                            quoteAmountBalance,
                            quoterContract,
                            flashMode: tradeFlashMode,
                        });
                    }
                }}
                value={formatPriceDecimalBaseByQuote}
            ></OrderNumberInput>
            <OrderNumberInput
                variant={actionType === 'buy' ? 'green' : 'red'}
                leftContent="Quantity"
                rightContent={baseToken.symbol}
                setValue={(newBaseAmountDecimal: number) => {
                    dispatch.proOrderFormState.setBaseTokenAmount({
                        amountDecimal: newBaseAmountDecimal,
                        baseAmountBalance,
                        quoteAmountBalance,
                        quoterContract,
                        flashMode: tradeFlashMode,
                    });
                }}
                value={formatbaseAmountDecimal}
                output={true}
            ></OrderNumberInput>
            <Stack alignItems="center">
                <OrderSlider
                    variant={actionType === 'buy' ? 'green' : 'red'}
                    sliderValue={sliderValue}
                    // setValue={(newSliderValue: number) => {
                    //     if (actionType === 'buy') {
                    //         const newQuoteAmountDecimal = (quoteAmountDecimalBalance * newSliderValue) / 100;
                    //         dispatch.proOrderFormState.setTempTokenAmount({
                    //             tempIsLockBaseToken: false,
                    //             tempTokenAmountDecimal: newQuoteAmountDecimal
                    //         });
                    //     } else {
                    //         const newBaseAmountDecimal = (baseAmountDecimalBalance * newSliderValue) / 100;
                    //         dispatch.proOrderFormState.setTempTokenAmount({
                    //             tempIsLockBaseToken: true,
                    //             tempTokenAmountDecimal: newBaseAmountDecimal
                    //         });
                    //     }
                    // }}
                    setEndValue={(newSliderValue: number) => {
                        if (actionType === 'buy') {
                            const newQuoteAmountDecimal = (quoteAmountDecimalBalance * newSliderValue) / 100;
                            dispatch.proOrderFormState.setQuoteTokenAmount({
                                amountDecimal: newQuoteAmountDecimal,
                                baseAmountBalance,
                                quoteAmountBalance,
                                quoterContract,
                                flashMode: tradeFlashMode,
                            });
                        } else {
                            const newBaseAmountDecimal = (baseAmountDecimalBalance * newSliderValue) / 100;
                            dispatch.proOrderFormState.setBaseTokenAmount({
                                amountDecimal: newBaseAmountDecimal,
                                baseAmountBalance,
                                quoteAmountBalance,
                                quoterContract,
                                flashMode: tradeFlashMode,
                            });
                        }
                    }}
                ></OrderSlider>
            </Stack>
            <OrderNumberInput
                variant={actionType === 'buy' ? 'green' : 'red'}
                leftContent="Order Value"
                rightContent={quoteToken.symbol}
                setValue={(newQuoteAmountDecimal: number) => {
                    dispatch.proOrderFormState.setQuoteTokenAmount({
                        amountDecimal: newQuoteAmountDecimal,
                        baseAmountBalance,
                        quoteAmountBalance,
                        quoterContract,
                        flashMode: tradeFlashMode,
                    });
                }}
                value={quoteAmountDecimal}
                mt="10px !important"
            ></OrderNumberInput>
            <HStack ml="auto !important">
                <Text className={noto_t3_bold} color={colorTheme('#767676', '#C6B8CF')}>
                    ≈ {formatNumber(quoteValue, 2, 4)}
                </Text>
                <Text className={noto_t3} color={colorTheme('#B3B3B3', '#6A598C')}>
                    USD
                </Text>
            </HStack>
            <CustomButton
                hidden={!approved}
                disabled={priceDecimalBaseByQuote === 0 || baseAmountDecimal === 0 || quoteAmountDecimal === 0}
                w="100%"
                h="36px"
                className={i_text_copy_bold}
                borderRadius="3px"
                color="#FFFFFF"
                bg={actionType === 'buy' ? '#54AB6B' : '#D34D4D'}
                text={actionType === 'buy' ? 'Buy ' + baseToken.symbol : 'Sell ' + baseToken.symbol}
                mt="20px !important"
                _hover={{
                    bg:
                        priceDecimalBaseByQuote === 0 || baseAmountDecimal === 0 || quoteAmountDecimal === 0
                            ? ''
                            : actionType === 'buy'
                            ? '#47C87A'
                            : '#F95858',
                }}
                onClick={() => {
                    const limitOrderWithSwapContract = getLimitOrderWithSwapManagerContract(
                        chainId,
                        web3
                    ) as LimitOrderWithSwapManagerContract;

                    const limitOrderWithSwapAddress = LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES[chainId];

                    dispatch.proOrderFormState
                        .newLimitOrder({
                            limitOrderWithSwapContract,
                            limitOrderWithSwapAddress,
                            account: account as string,
                            web3,
                            chainId,
                            gasPrice,
                            maxDelayMinutes: 5,
                            flashMode: tradeFlashMode,
                            realBaseToken: baseToken,
                            realQuoteToken: quoteToken,
                            onGoingCallback: (toastLink?: ToastLink) => {
                                toast('info', 'Ongoing ...', undefined, toastLink);
                            },
                        })
                        .then((e: any) => {
                            payTokenEntity.handleApproveSuccess().catch((e) => {
                                console.log('update allowance error: ', e);
                            });                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            if (chain) {
                                toastLink.title = 'View on ' + chain.name;
                                toastLink.link = getTxLink(e.transactionHash, chain);
                            }
                            toast('success', 'Successfully', undefined, toastLink);
                        })
                        .catch((e) => {
                            toast('info', e.message);
                        });
                }}
            ></CustomButton>

            <CustomButton
                hidden={approved}
                w="100%"
                h="36px"
                className={i_text_copy_bold}
                borderRadius="3px"
                color="#FFFFFF"
                bg={actionType === 'buy' ? '#54AB6B' : '#D34D4D'}
                text={'Approve ' + payToken.symbol}
                mt="20px !important"
                _hover={{ bg: actionType === 'buy' ? '#47C87A' : '#F95858' }}
                onClick={() => {
                    const chain = getChain(chainId);
                    const toastLink = {} as ToastLink;
                    payTokenEntity
                        .handleApprove()
                        .on('transactionHash', (hash: string) => {
                            if (chain) {
                                toastLink.title = 'View on ' + chain.name;
                                toastLink.link = getTxLink(hash, chain);
                            }
                            toast('info', 'Ongoing ', undefined, toastLink);
                        })
                        .then(() => {
                            toast('success', 'Approve ' + payToken.symbol + ' successfully', undefined, toastLink);
                            payTokenEntity.handleApproveSuccess();
                        })
                        .catch((e: any) => {
                            console.info('error   :', e.message);
                        });
                }}
            ></CustomButton>
        </Stack>
    );
};

export default Limit;
