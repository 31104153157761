import {
    Box,
    Flex,
    Divider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    useColorMode,
    Image,
    Text,
} from '@chakra-ui/react';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { useEffect, useState } from 'react';
import { getGameRules, ResponseGameRules, GameRule } from '../../../net/iZUMi-endpoints/src/restful/izumiGame';
import { i_h3, i_text_copy, i_h4, i_text_16_bold, i_h5, i_text_piece3 } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { UserBlock } from '../UserBlock/UserBlock';
import WinnerForm from '../WinnerBlock/WinnerForm';
import { gameParentEndpoint } from '../../../net/iZUMi-endpoints/src/restful/apiEndpoints';
import { useTranslation } from 'react-i18next';
type EventEntryProps = {
    status: number;
    startTime: number;
    endTIme: number;
    eventId: number;
    banner: string;
    leftTime: number;
};

export const EventEntry: React.FC<EventEntryProps> = (props) => {
    const { status, startTime, endTIme, eventId, banner, leftTime } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [netWorkLoading, setNetWorkLoading] = useState(true);

    const [GameRules, setGameRules] = useState({
        winnerRules: [] as GameRule[],
        gameRules: [] as GameRule[],
        description: [] as GameRule[],
    } as ResponseGameRules);
    useEffect(() => {
        setNetWorkLoading(true);
        getGameRules({
            id: eventId,
        }).then((r) => {
            setNetWorkLoading(false);
            setGameRules(r.data.data);
        });
    }, [eventId]);

    const FixedTerms = [
        '- In the event of a disagreement concerning the final results, iZiSwap will have the final say.',
        '- iZiSwap reserves the right to disqualify any trades or specific addresses that are proven to have taken malicious action or attempt to cheat.',
        '- Trading volume includes both buys and sells with eligible trading tokens by using the "Swap" function during the activity.',
        '- If your trade goes through the route which includes eligible trading tokens then such trades are also added in the trading volume of the activity.',
        '- All the rewards will be distributed within 7 days and credited to your prize balance after the end of the activity.',
    ];
    return (
        <Box>
            <Flex direction="column" mt="17px" alignItems="center">
                <Image
                    w={{ base: '373px', md: '675px', lg: '1000px' }}
                    h={{ base: '190px', md: '220px', lg: '350px' }}
                    src={gameParentEndpoint + '/game/media/' + banner}
                    fallbackSrc={gameParentEndpoint + '/game/media/' + banner}
                ></Image>
                <UserBlock status={status} startTime={startTime} endTIme={endTIme} eventId={eventId} leftTime={leftTime}></UserBlock>
                <WinnerForm status={status} winnerRules={GameRules.winnerRules} eventId={eventId}></WinnerForm>
                <Card variant="tabTopCard" w={{ base: '373px', md: '675px', lg: '794px' }} mt="23px" pb="51px" zIndex="1">
                    <Flex direction="column" mx="40px" mt="36px">
                        <Flex mb="25px" alignItems="center">
                            <Image boxSize="36px" src={process.env.PUBLIC_URL + '/assets/Prize/rules.svg'}></Image>
                            <Text className={i_h3} fontWeight="700 !important" ml="11px">
                                {t('Rules')}
                            </Text>
                        </Flex>
                        {netWorkLoading ? (
                            <Text className={i_text_piece3}>{t('Loading Rules')}</Text>
                        ) : (
                            GameRules &&
                            GameRules.gameRules &&
                            GameRules.gameRules.map((item, index) => {
                                return (
                                    <Flex key={index} mt="25px">
                                        <Text className={i_h4} w="15px" color="secondary.500">
                                            {index + 1}
                                        </Text>
                                        <Text w="202px" ml="25px" className={i_text_16_bold}>
                                            {<Box dangerouslySetInnerHTML={{ __html: item.title }}></Box>}
                                        </Text>
                                        <Text
                                            w="394px"
                                            ml="auto"
                                            className={i_text_piece3}
                                            color={colorTheme('tertiary.400', 'tertiary.300')}
                                        >
                                            {<Box dangerouslySetInnerHTML={{ __html: item.detail }}></Box>}
                                        </Text>
                                    </Flex>
                                );
                            })
                        )}
                    </Flex>
                </Card>
                <Card
                    w={{ base: '374px', md: '675px', lg: '792px' }}
                    mx="40px"
                    px="40px"
                    mb="30px"
                    bgColor={colorTheme('#ffffff', '#211834')}
                >
                    {netWorkLoading ? (
                        <Text className={i_text_piece3} my="26px">
                            Loading Rules
                        </Text>
                    ) : (
                        <Accordion defaultIndex={[0]} allowMultiple={true}>
                            {GameRules.description.map((item, index) => {
                                return (
                                    <AccordionItem key={index} border="0px" my="26px">
                                        <AccordionButton h="41px" _hover={{}}>
                                            <Flex className={i_h5} flex="1" textAlign="left">
                                                {<Box dangerouslySetInnerHTML={{ __html: item.title }}></Box>}
                                            </Flex>
                                            <Flex direction="column" mr="10px">
                                                <Text className={i_text_piece3} color="secondary.500">
                                                    {t('Details')}
                                                </Text>
                                            </Flex>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pb={4}>
                                            <Text className={i_text_piece3} color={colorTheme('tertiary.400', 'tertiary.300')}>
                                                {<Box dangerouslySetInnerHTML={{ __html: item.detail }}></Box>}
                                            </Text>
                                        </AccordionPanel>
                                    </AccordionItem>
                                );
                            })}
                        </Accordion>
                    )}
                </Card>
                <Divider w={{ base: '359px', md: '622px', lg: '716px' }} mt="36px" mb="32px"></Divider>
                <Flex w={{ base: '359px', md: '622px', lg: '716px' }} direction="column" mx="40px">
                    <Flex direction="column" alignItems="center">
                        <Text className={i_h5} color={colorTheme('tertiary.700', 'tertiary.50')}>
                            {'Terms & Conditions'}
                        </Text>
                    </Flex>
                    <Flex direction="column" className={i_text_copy} fontWeight="700" color={'tertiary.400'} mt="15px">
                        {FixedTerms.map((item, index) => {
                            return (
                                <Text className={i_text_piece3} key={index} mt="20px">
                                    {item}
                                </Text>
                            );
                        })}
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};
