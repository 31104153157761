import { HStack, Stack } from '@chakra-ui/react';
import PageLayout from '../../../components/layout/PageLayout';
import PageHeader from '../../../components/PageHeader/PageHeader';
import GalleryControls from './GalleryControls';
import PoolList from './PoolList';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

const Gallery: React.FC = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            body={
                <Stack w="100%">
                    <GalleryControls />
                    <PoolList />;
                </Stack>
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={t('Gallery')}
                    subtitle={t('Stake your liquidity NFTs to earn rewards') + ' !'}
                    element={<HStack spacing="10px"></HStack>}
                />
            }
            body={
                <Stack w="100%">
                    <GalleryControls />
                    <PoolList />;
                </Stack>
            }
            internalWidth={{ base: '700px', xl: '840px', xxl: '1100px', xlp1: '1150px' }}
        />
    );
};
export default Gallery;
