import { Flex, HStack, Text, Image, BoxProps, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getChain } from '../../../../config/chains';
import tokens, { tokenSymbol2token } from '../../../../config/tokens';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { ResponseAllFarmStat } from '../../../../net/iZUMi-endpoints/src/restful/izumiFarmStat';
import { RootState } from '../../../../state/store';
import { i_h5, i_text_copy } from '../../../../style';
import { TokenSymbol } from '../../../../types/mod';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { switchNetwork } from '../../../../utils/metamaskWallet';
import { FeeRate } from '../../../Trade/components/FeeRate';
import { TokenIcons } from '../../../Trade/components/TokenIcons';
import { APR } from '../../components/APR';

type PoolListEntryProps = {
    entry: ResponseAllFarmStat;
} & BoxProps;

const PoolListEntry: React.FC<PoolListEntryProps> = (props) => {
    const { entry } = props;
    const { chainId } = useWeb3WithDefault();
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { farmGallery: farm } = useSelector((state: RootState) => state);

    const history = useHistory();
    const showedRow = farm.farmControl.showedRow;
    const isMobile = useIsMobile();

    const getIcon = (symbol: string) => {
        const token = tokens[symbol as TokenSymbol];
        if (!token) {
            return '/assets/tokens/default.svg';
        } else {
            return tokenSymbol2token(symbol, chainId).icon;
        }
    };
    const getSymbol = (chainId: number) => {
        return getChain(chainId)?.icon;
    };
    const judgeType = (type: number) => {
        if (type === 0) {
            return 'Fix Range';
        } else if (type === 1) {
            return 'One Side';
        } else if (type === 2) {
            return 'Dynamic';
        }
        return '';
    };
    const judgeProtocol = (protocol: number) => {
        if (protocol === 0) {
            return process.env.PUBLIC_URL + '/assets/gallery/uniswap.svg';
        } else if (protocol === 1) {
            return process.env.PUBLIC_URL + '/assets/home/iziLogo/logo.svg';
        }

        return '';
    };
    return isMobile && showedRow === 2 ? (
        <Card w="164px" h="55px" pl={{ base: '0px', sm: '40px', lg: '30px' }} pr={{ base: '0px', sm: '22px' }}>
            <Flex
                h="100%"
                direction="column"
                alignItems="center"
                onClick={() => {
                    if (entry.type === 0) {
                        history.push(`/farm/${entry.protocol === 0 ? 'uni' : 'iZi'}/fix`);
                    } else if (entry.type === 1) {
                        history.push(`/farm/${entry.protocol === 0 ? 'uni' : 'iZi'}/one-side`);
                    } else if (entry.type === 2) {
                        history.push(`/farm/${entry.protocol === 0 ? 'uni' : 'iZi'}/dynamic`);
                    }
                    switchNetwork(entry.chain_id);
                }}
                cursor="pointer"
            >
                <HStack w="100%" spacing="5px" flexShrink={0} my="8px">
                    <TokenIcons
                        type="small"
                        tokenA={{
                            chainId: entry.chain_id,
                            name: entry.tokenX_symbol,
                            symbol: entry.tokenX_symbol,
                            icon: getIcon(entry.tokenX_symbol),

                            address: '',
                            decimal: 0,
                            custom: false,
                        }}
                        tokenB={{
                            chainId: entry.chain_id,
                            name: entry.tokenY_symbol,
                            symbol: entry.tokenY_symbol,
                            icon: getIcon(entry.tokenY_symbol),
                            address: '',
                            decimal: 0,
                            custom: false,
                        }}
                        initialToggle={false}
                        chainIcon={getSymbol(entry.chain_id)}
                    />
                    <FeeRate
                        type="gallery"
                        tokenA={{
                            chainId: entry.chain_id,
                            name: entry.tokenX_symbol,
                            symbol: entry.tokenX_symbol,
                            icon: getIcon(entry.tokenX_symbol),

                            address: '',
                            decimal: 0,
                            custom: false,
                        }}
                        tokenB={{
                            chainId: entry.chain_id,
                            name: entry.tokenY_symbol,
                            symbol: entry.tokenY_symbol,
                            icon: getIcon(entry.tokenY_symbol),
                            address: '',
                            decimal: 0,
                            custom: false,
                        }}
                        feeTier={(entry.fee / 10000) as FeeTier}
                        initialToggle={false}
                        protocolElement={
                            <Flex ml="auto">
                                <Image boxSize="14px" src={judgeProtocol(entry.protocol)}></Image>
                            </Flex>
                        }
                    />
                </HStack>
            </Flex>
        </Card>
    ) : (
        <Card
            w={{ base: '100%', sm: '653px', xxl: '540px', xlp1: '560px' }}
            h={{ base: '145px', sm: '90px' }}
            pl={{ base: '0px', sm: '40px', lg: '30px' }}
            pr={{ base: '0px', sm: '22px' }}
        >
            <Flex
                h="100%"
                direction={{ base: 'column', sm: 'row' }}
                alignItems="center"
                onClick={() => {
                    if (entry.type === 0) {
                        history.push(`/farm/${entry.protocol === 0 ? 'uni' : 'iZi'}/fix`);
                    } else if (entry.type === 1) {
                        history.push(`/farm/${entry.protocol === 0 ? 'uni' : 'iZi'}/one-side`);
                    } else if (entry.type === 2) {
                        history.push(`/farm/${entry.protocol === 0 ? 'uni' : 'iZi'}/dynamic`);
                    }
                    switchNetwork(entry.chain_id);
                }}
                cursor="pointer"
            >
                <HStack spacing="28px" flexShrink={0} mt={{ base: '21px', sm: '0px' }}>
                    <TokenIcons
                        tokenA={{
                            chainId: entry.chain_id,
                            name: entry.tokenX_symbol,
                            symbol: entry.tokenX_symbol,
                            icon: getIcon(entry.tokenX_symbol),

                            address: '',
                            decimal: 0,
                            custom: false,
                        }}
                        tokenB={{
                            chainId: entry.chain_id,
                            name: entry.tokenY_symbol,
                            symbol: entry.tokenY_symbol,
                            icon: getIcon(entry.tokenY_symbol),
                            address: '',
                            decimal: 0,
                            custom: false,
                        }}
                        initialToggle={false}
                        chainIcon={getSymbol(entry.chain_id)}
                    />
                    <FeeRate
                        tokenA={{
                            chainId: entry.chain_id,
                            name: entry.tokenX_symbol,
                            symbol: entry.tokenX_symbol,
                            icon: getIcon(entry.tokenX_symbol),

                            address: '',
                            decimal: 0,
                            custom: false,
                        }}
                        tokenB={{
                            chainId: entry.chain_id,
                            name: entry.tokenY_symbol,
                            symbol: entry.tokenY_symbol,
                            icon: getIcon(entry.tokenY_symbol),
                            address: '',
                            decimal: 0,
                            custom: false,
                        }}
                        feeTier={(entry.fee / 10000) as FeeTier}
                        initialToggle={false}
                    />
                </HStack>
                <Flex
                    w="100%"
                    h={{ base: '60px', sm: 'unset' }}
                    mt={{ base: '24px', sm: '0px' }}
                    px={{ base: '25px', sm: '0px' }}
                    alignItems="center"
                    bgColor={{ base: colorTheme('#F8F8FA', 'tertiary.500'), sm: 'inherit' }}
                >
                    <Flex w="100px" direction="column">
                        <Text className={i_text_copy} color={colorTheme('tertiary.600', 'tertiary.100')}>
                            {t('Type')}
                        </Text>
                        <Text className={i_h5} mt="8px">
                            {t(judgeType(entry.type))}
                        </Text>
                    </Flex>
                    <Flex direction="column">
                        <APR w="140px" apr={entry.apr} variant={'known'} />
                    </Flex>
                    <Flex ml="auto">
                        <Image boxSize="40px" src={judgeProtocol(entry.protocol)}></Image>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    );
};
export default PoolListEntry;
