import { HStack, Stack, Text, useColorMode } from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IZUMI_SWAP_CONFIG } from '../../../../../config/bizConfig';
import { getChain, getTxLink } from '../../../../../config/chains';
import { LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES } from '../../../../../config/trade/tradeContracts';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { ToastLink, useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import useTokenEntity from '../../../../../state/models/hooks/useTokenEntity';
import { buySingleDesire, sellSingleAmount } from '../../../../../state/models/pro/proSwap/controllers';
import { swapAmountAcquired, swapDesireCost } from '../../../../../state/models/pro/quoter/controllers';
import { useGasOrWrappedToken } from '../../../../../state/models/pro/utils/useGasOrWrappedToken';
import { getSafeTokenPrice } from '../../../../../state/models/token/funcs';
import { RootDispatch, RootState } from '../../../../../state/store';
import { i_text_copy_bold, noto_t3, noto_t3_bold } from '../../../../../style';
import { LimitOrderWithSwapManagerContract } from '../../../../../types/abis/iZiSwap/LimitOrderWithSwapManager';
import { QuoterContract } from '../../../../../types/abis/iZiSwap/Quoter';
import { getLimitOrderWithSwapManagerContract, getQuoterContract } from '../../../../../utils/contractFactory';
import { getColorThemeSelector, toFeeNumber } from '../../../../../utils/funcs';
import { decimal2Amount, formatNumber } from '../../../../../utils/tokenMath';
import OrderInput from './OrderInput/OrderInput';

type MarketProps = {
    actionType?: any;
    setActionType?: any;
};

interface Quantity {
    baseAmount: string;
    baseAmountDecimal: number;
    acquireOrCost?: string;
}

const Market: React.FC<MarketProps> = (props) => {
    const { actionType, setActionType } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [quantity, setQuantity] = useState(undefined as unknown as Quantity);
    const [computing, setComputing] = useState(false);
    const baseAmount = quantity?.baseAmount ?? '0';

    const cantSwap = computing || baseAmount === '0';

    const { proOrderFormState, proControlState } = useSelector((state: RootState) => state);
    const { token } = useSelector((state: RootState) => state);
    const flashMode = proControlState.tradeFlashMode;
    const useGasToken = proControlState.useGasToken;

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { pro } = useSelector((state: RootState) => state);

    const { account, chainId, web3 } = useWeb3WithDefault();
    const { gasPrice } = useGasPrice();
    const toast = useCustomToast();
    const { t } = useTranslation();

    useEffect(() => {
        if (chainId !== proOrderFormState.chainId) {
            // newly start (initial chainId is -1) or change chainId
            // todo: select pool by chainId
            const tokenA = pro.tokenX;
            const tokenB = pro.tokenY;
            const fee = toFeeNumber(pro.currentFee) as FeeTier;

            dispatch.proOrderFormState.setTokenPair({
                tokenA,
                tokenB,
                fee,
                chainId,
                web3,
            });
        }
    }, [chainId, dispatch.proOrderFormState, pro.currentFee, pro.tokenX, pro.tokenY, proOrderFormState.chainId, web3]);

    const limitOrderWithSwapAddress = LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES[chainId];
    const limitOrderWithSwapContract = getLimitOrderWithSwapManagerContract(chainId, web3) as LimitOrderWithSwapManagerContract;

    const baseToken = useGasOrWrappedToken(proOrderFormState.baseToken, useGasToken);
    const quoteToken = useGasOrWrappedToken(proOrderFormState.quoteToken, useGasToken);

    const fee = proOrderFormState.fee;

    const baseTokenEntity = useTokenEntity(baseToken, limitOrderWithSwapAddress);
    const quoteTokenEntity = useTokenEntity(quoteToken, limitOrderWithSwapAddress);

    const payToken = actionType === 'buy' ? quoteToken : baseToken;
    const payTokenEntity = actionType === 'buy' ? quoteTokenEntity : baseTokenEntity;

    const payAmount = useMemo((): string => {
        let amount = actionType === 'buy' ? quantity?.acquireOrCost : quantity?.baseAmount
        amount = amount ?? '0'
        if (actionType === 'buy') {
            amount = new BigNumber(amount).times(1.1).toFixed(0)
        }
        return amount
    }, [quantity, actionType]);

    const needApprove = !payTokenEntity.isApproved(payAmount);

    const baseAmountDecimal = quantity?.baseAmountDecimal ?? 0;
    const acquireOrCost = quantity?.acquireOrCost ?? '0';

    //console.log('need approve: ', needApprove);

    return (
        <Stack w="100%">
            <HStack spacing="0px">
                <CustomButton
                    w="50%"
                    h="24px"
                    className={noto_t3}
                    text="Buy"
                    color={actionType === 'buy' ? '#FFFFFF' : '#B3B3B3'}
                    bg={colorTheme(actionType === 'buy' ? '#54AB6B' : '#EEEEEE', actionType === 'buy' ? '#54AB6B' : '#251B39')}
                    borderRadius="2px"
                    _hover={{
                        bg: colorTheme(actionType === 'buy' ? '' : '#E6F2E8', actionType === 'buy' ? '' : '#243334'),
                        color: actionType === 'buy' ? '' : '#576359',
                    }}
                    onClick={() => {
                        setComputing(false);
                        setQuantity(undefined as unknown as Quantity);
                        setActionType('buy');
                    }}
                ></CustomButton>
                <CustomButton
                    w="50%"
                    h="24px"
                    className={noto_t3}
                    text="Sell"
                    color={actionType === 'sell' ? '#FFFFFF' : '#B3B3B3'}
                    bg={colorTheme(actionType === 'sell' ? '#D34D4D' : '#EEEEEE', actionType === 'sell' ? '#D34D4D' : '#251B39')}
                    borderRadius="2px"
                    _hover={{
                        bg: colorTheme(actionType === 'sell' ? '' : '#F8EBEB', actionType === 'sell' ? '' : '#382433'),
                        color: actionType === 'sell' ? '' : '#92686B',
                    }}
                    onClick={() => {
                        setComputing(false);
                        setQuantity(undefined as unknown as Quantity);
                        setActionType('sell');
                    }}
                ></CustomButton>
            </HStack>
            <Stack mt="25px !important">
                <HStack justifyContent="space-between">
                    <Text className={noto_t3} color={colorTheme('#B3B3B3', '#6A598C')}>
                        Available Balance
                    </Text>
                    <HStack>
                        <Text className={noto_t3_bold} color={colorTheme('#767676', '#C6B8CF')}>
                            {formatNumber(payTokenEntity.tokenBalance(), 2, 4)}
                        </Text>
                        <Text className={noto_t3} color={colorTheme('#B3B3B3', '#6A598C')}>
                            {payToken.symbol}
                        </Text>
                    </HStack>
                </HStack>
            </Stack>

            <OrderInput
                variant={actionType === 'buy' ? 'green' : 'red'}
                leftContent="Quantity"
                rightContent={baseToken.symbol}
                value={baseAmountDecimal}
                setValue={(value: number) => {
                    const quoterContract = getQuoterContract(chainId, web3, false) as QuoterContract;

                    setComputing(true);
                    if (actionType === 'buy') {
                        const amount = decimal2Amount(new BigNumber(value), baseToken)?.toFixed(0) ?? '0';
                        swapDesireCost(quoteToken, baseToken, fee, amount, quoterContract)
                            .then((e) => {
                                const newQuantity = {
                                    baseAmount: amount,
                                    baseAmountDecimal: value,
                                    acquireOrCost: e?.cost ?? '0',
                                }
                                setQuantity(newQuantity);
                                setComputing(false);
                            })
                            .catch((e) => {
                                setQuantity({
                                    baseAmount: amount,
                                    baseAmountDecimal: value,
                                    acquireOrCost: '0',
                                });
                                setComputing(false);
                                console.log('[error]: swapDesireCost error: ', e);
                            });
                    } else {
                        const amount = decimal2Amount(new BigNumber(value), baseToken)?.toFixed(0) ?? '0';
                        if (!flashMode) {
                            swapAmountAcquired(baseToken, quoteToken, fee, amount, quoterContract)
                                .then((e) => {
                                    const newQuantity = {
                                        baseAmount: amount,
                                        baseAmountDecimal: value,
                                        acquireOrCost: e?.acquire ?? '0',
                                    }
                                    setQuantity(newQuantity);
                                    setComputing(false);
                                })
                                .catch((e) => {
                                    setQuantity({
                                        baseAmount: amount,
                                        baseAmountDecimal: value,
                                        acquireOrCost: '0',
                                    });
                                    setComputing(false);
                                    console.log('[error]: swapAmountAcquired error: ', e);
                                });
                        } else {
                            setQuantity({
                                baseAmount: amount,
                                baseAmountDecimal: value,
                                acquireOrCost: '0',
                            });
                        }
                    }
                }}
            ></OrderInput>
            <HStack ml="auto !important">
                <Text className={noto_t3_bold} color="#767676">
                    ≈{baseAmountDecimal * getSafeTokenPrice(token, baseToken.symbol)}
                </Text>
                <Text className={noto_t3} color="#B3B3B3">
                    USD
                </Text>
            </HStack>
            {/* <Stack alignItems="center">
                <OrderSlider
                    variant={actionType === 'buy' ? 'green' : 'red'}
                    sliderValue={sliderValue}
                    setValue={setSliderValue}
                ></OrderSlider>
            </Stack> */}

            <CustomButton
                w="100%"
                h="36px"
                hidden={!needApprove}
                className={i_text_copy_bold}
                disabled={!account || !chainId || !web3}
                borderRadius="3px"
                color="#FFFFFF"
                bg={actionType === 'buy' ? '#54AB6B' : '#D34D4D'}
                text={'approve ' + payToken.symbol}
                mt="20px !important"
                _hover={{ bg: actionType === 'buy' ? '#47C87A' : '#F95858' }}
                onClick={() => {
                    const chain = getChain(chainId);
                    const toastLink = {} as ToastLink;
                    payTokenEntity
                        .handleApprove()
                        .on('transactionHash', (hash: string) => {
                            if (chain) {
                                (toastLink.title = 'View on ' + chain.name), (toastLink.link = getTxLink(hash, chain));
                            }
                            toast('info', t('Approve') + ' ' + payTokenEntity.token?.symbol, undefined, toastLink);
                        })
                        .then(() => {
                            payTokenEntity.handleApproveSuccess();
                            toast('success', t('Approve') + ' ' + payTokenEntity.token?.symbol + ' successfully', undefined, toastLink);
                        })
                        .catch((e: any) => {
                            console.info('error   :', e.message);
                        });
                }}
            ></CustomButton>
            <CustomButton
                w="100%"
                h="36px"
                hidden={needApprove}
                className={i_text_copy_bold}
                disabled={!account || !chainId || !web3 || cantSwap || (acquireOrCost === '0' && !flashMode)}
                borderRadius="3px"
                color="#FFFFFF"
                bg={actionType === 'buy' ? '#54AB6B' : '#D34D4D'}
                text={actionType === 'buy' ? 'Buy ' + baseToken.symbol : 'Sell ' + baseToken.symbol}
                mt="20px !important"
                _hover={{
                    bg:
                        !account || !chainId || !web3 || cantSwap || (acquireOrCost === '0' && !flashMode)
                            ? ''
                            : actionType === 'buy'
                            ? '#47C87A'
                            : '#F95858',
                }}
                onClick={() => {
                    const acquireOrCost = quantity.acquireOrCost ?? '0';
                    if (actionType === 'buy') {
                        const maxPayed = !flashMode
                            ? new BigNumber(acquireOrCost).times(1 + IZUMI_SWAP_CONFIG.SWAP_DEFAULT_SLIPPAGE_PERCENT).toFixed(0, 2)
                            : '0xffffffffffffffffffffffffffffffff';
                        buySingleDesire(
                            baseToken,
                            quoteToken,
                            fee,
                            baseAmount,
                            maxPayed,
                            chainId,
                            account as string,
                            IZUMI_SWAP_CONFIG.SWAP_DEFAULT_MAXIMUM_DELAY,
                            limitOrderWithSwapContract,
                            limitOrderWithSwapAddress,
                            gasPrice,
                            (toastLink?: ToastLink) => {
                                toast('info', 'Ongoing ...', undefined, toastLink);
                            }
                        ).then((e: any) => {
                            payTokenEntity.handleApproveSuccess().catch((e) => {
                                console.log('update allowance error: ', e);
                            });
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            if (chain) {
                                toastLink.title = 'View on ' + chain.name;
                                toastLink.link = getTxLink(e.transactionHash, chain);
                            }
                            toast('success', 'Success', undefined, toastLink);
                        });
                    } else {
                        const minAcquired = !flashMode
                            ? new BigNumber(acquireOrCost).times(IZUMI_SWAP_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR).toFixed(0, 1)
                            : '1';
                        sellSingleAmount(
                            baseToken,
                            quoteToken,
                            fee,
                            baseAmount,
                            minAcquired,
                            chainId,
                            account as string,
                            IZUMI_SWAP_CONFIG.SWAP_DEFAULT_MAXIMUM_DELAY,
                            limitOrderWithSwapContract,
                            limitOrderWithSwapAddress,
                            gasPrice,
                            (toastLink?: ToastLink) => {
                                toast('info', 'Ongoing ...', undefined, toastLink);
                            }
                        ).then((e: any) => {
                            payTokenEntity.handleApproveSuccess().catch((e) => {
                                console.log('update allowance error: ', e);
                            });
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            if (chain) {
                                toastLink.title = 'View on ' + chain.name;
                                toastLink.link = getTxLink(e.transactionHash, chain);
                            }
                            toast('success', 'Success', undefined, toastLink);
                        });
                    }
                }}
            ></CustomButton>
        </Stack>
    );
};

export default Market;
