import React from 'react';
import { Text, useColorMode, BoxProps, HStack, StackDirection, IconButton, Icon } from '@chakra-ui/react';
import Info from '../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { BsClock } from 'react-icons/bs';

type LockTimeProps = {
    time: any;
    direction?: StackDirection;
} & BoxProps;

export const LockTime: React.FC<LockTimeProps> = ({ time, direction, ...rest }) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    return (
        <Info
            direction={direction || 'column'}
            label={
                <HStack spacing="4px">
                    <IconButton size="sm" aria-label="" variant="ghost" isRound={true} icon={<Icon as={BsClock} boxSize="16px" />} />
                    <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.300')}>
                        Remaining Lock Time
                    </Text>
                </HStack>
            }
            value={
                <Text className={i_text_copy} fontSize="14px" color={colorTheme('tertiary.800', 'tertiary.100')} pl="8px">
                    {time}
                </Text>
            }
            w="220px"
            {...rest}
        />
    );
};
