import { HStack, VStack } from '@chakra-ui/layout';
import {
    Icon,
    IconButton,
    Popover,
    PopoverContent,
    PopoverTrigger,
    useColorMode,
    BoxProps,
    Flex,
    useOutsideClick,
    Switch,
    Tooltip,
    Box,
} from '@chakra-ui/react';
import { i_h3, i_text_d } from '../../../../style';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { CustomNumberInput } from '../../../../iZUMi-UI-toolkit/src/components/Inputs/NumberInput/NumberInput';
import { AiOutlineInfoCircle, AiOutlineSetting } from 'react-icons/ai';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { Text } from '../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { isNumeric } from '../../../../utils/valid';
import { useState } from 'react';
import { IZUMI_SWAP_CONFIG } from '../../../../config/bizConfig';
import React from 'react';
import { useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { useTranslation } from 'react-i18next';
import { QUOTER_TYPE } from '../../../../config/trade/tradeContracts';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { Modal } from '../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';

export type SwapSettingProps = {
    slippage: number;
    maxDelay: number;
    quoterType: QUOTER_TYPE;
    exclusiveLiquidity: boolean;
    handleSetSlippage: (slippagePercentage: number) => void;
    handleSetMaxDelay: (maxDelay: number) => void;
    handleSetQuoterType: (quoterType: QUOTER_TYPE) => void;
    handleSetExclusive: (exclusive: boolean) => void;
    isOpen?: boolean | any;
    onClose?: () => void;
} & BoxProps;

export const SwapSetting: React.FC<SwapSettingProps> = (props) => {
    const {
        isOpen,
        onClose,
        slippage,
        maxDelay,
        quoterType,
        exclusiveLiquidity,
        handleSetSlippage,
        handleSetMaxDelay,
        handleSetQuoterType,
        handleSetExclusive,
        ...rest
    } = props;
    const toast = useCustomToast();
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [slippageLocal, setSlippage] = useState<number>(slippage);
    const [maxDelayLocal, setMaxDelay] = useState<number>(maxDelay);
    const [showPopOver, setShowPopOver] = useState(false);
    const clearForm = (): void => {
        setSlippage(slippage);
        setMaxDelay(maxDelay);
    };
    const inputFocusRef = React.useRef<any | null>(null);
    useOutsideClick({
        ref: inputFocusRef,
        handler: () => {
            setShowPopOver(false);
        },
    });

    const slippageBlock = (
        <Card
            w={isMobile ? '100%' : '260px'}
            h="80px"
            bg={colorTheme('#F9F9FB', '#18102D')}
            px="22px"
            py="5px"
            mt="19px !important"
            border={colorTheme('0.5px solid #EEEAFE', 'none')}
            boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', 'none')}
        >
            <Text variant="caption-bold" color="#938CA4" textAlign="start">
                {t('Slippage tolerance')}
            </Text>
            <HStack mt="9px">
                <CustomNumberInput
                    textAlign="center"
                    w="140px"
                    h="30px"
                    fontFamily={i_h3}
                    placeholder="%"
                    color={colorTheme('tertiary.500', 'tertiary.200')}
                    bg={colorTheme('#FFF', '#211834')}
                    inputValue={slippageLocal}
                    onBlur={(value: any) => isNumeric(value) && setSlippage(Math.min(100, Math.max(0, value)))}
                    borderRadius="6px"
                    boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', '')}
                    unit={'%'}
                />
                <CustomButton
                    variant="tertiary"
                    text={t('default')}
                    w="70px"
                    h="30px"
                    ml="15px !important"
                    fontSize="13px"
                    fontClass={i_text_d}
                    onClick={() => setSlippage(IZUMI_SWAP_CONFIG.SWAP_DEFAULT_SLIPPAGE_PERCENT)}
                ></CustomButton>
            </HStack>
        </Card>
    );

    const delayBlock = (
        <Card
            w={isMobile ? '100%' : '260px'}
            h="80px"
            bg={colorTheme('#F9F9FB', '#18102D')}
            px="22px"
            py="5px"
            mt="19px !important"
            border={colorTheme('0.5px solid #EEEAFE', 'none')}
            boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', 'none')}
        >
            <Text variant="caption-bold" color="#938CA4" textAlign="start">
                {t('Maximum delay')}{' '}
            </Text>
            <HStack mt="9px">
                <CustomNumberInput
                    textAlign="center"
                    w="140px"
                    h="30px"
                    fontFamily={i_h3}
                    placeholder={t('minutes')}
                    color={colorTheme('tertiary.500', 'tertiary.200')}
                    bg={colorTheme('#FFF', '#211834')}
                    inputValue={maxDelayLocal}
                    onBlur={(value: any) => isNumeric(value) && setMaxDelay(Math.max(0, value))}
                    borderRadius="6px"
                    boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', '')}
                    whiteSpace="nowrap"
                    unit={t('minutes')}
                />
                <CustomButton
                    variant="tertiary"
                    text={t('default')}
                    w="70px"
                    h="30px"
                    ml="15px !important"
                    fontSize="13px"
                    fontClass={i_text_d}
                    onClick={() => setMaxDelay(IZUMI_SWAP_CONFIG.SWAP_DEFAULT_MAXIMUM_DELAY)}
                ></CustomButton>
            </HStack>
        </Card>
    );

    const exportRouterBlock = (
        <Card
            w={isMobile ? '100%' : '260px'}
            h="40px"
            bg={colorTheme('#F9F9FB', '#18102D')}
            px="22px"
            py="5px"
            mt="19px !important"
            border={colorTheme('0.5px solid #EEEAFE', 'none')}
            boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', 'none')}
        >
            <HStack spacing="60px">
                <Text variant="caption-bold" color="#938CA4">
                    {t('Expert Mode')}
                </Text>

                <HStack>
                    <Switch
                        isChecked={quoterType == QUOTER_TYPE.full ? true : false}
                        onChange={(e) => {
                            handleSetQuoterType(e.target.checked ? QUOTER_TYPE.full : QUOTER_TYPE.limit);
                        }}
                        size="md"
                    />
                    <Tooltip label="If switch on, iZiSwap will use a more powerful quoter.">
                        <IconButton
                            size="sm"
                            aria-label=""
                            variant="ghost"
                            isRound={true}
                            icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                        />
                    </Tooltip>
                </HStack>
            </HStack>
        </Card>
    );

    const exclusiveLiquidityBlock = (
        <Card
            w={isMobile ? '100%' : '260px'}
            h="40px"
            bg={colorTheme('#F9F9FB', '#18102D')}
            px="22px"
            py="5px"
            mt="19px !important"
            border={colorTheme('0.5px solid #EEEAFE', 'none')}
            boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', 'none')}
        >
            <HStack spacing="60px">
                <Text variant="caption-bold" color="#938CA4">
                    {t('Exclusive Liq')}
                </Text>

                <HStack>
                    <Switch
                        isChecked={exclusiveLiquidity}
                        onChange={(e) => {
                            handleSetExclusive(e.target.checked);
                        }}
                        size="md"
                    />
                    <Tooltip label="If switch off, iZiSwap may use other DEXs' liquidity when under insufficient condition.">
                        <IconButton
                            size="sm"
                            aria-label=""
                            variant="ghost"
                            isRound={true}
                            icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                        />
                    </Tooltip>
                </HStack>
            </HStack>
        </Card>
    );

    const confirmButton = (
        <CustomButton
            variant="purple"
            text={t('Save')}
            w={isMobile ? '100%' : '70px'}
            h="30px"
            mt="30px !important"
            fontSize="14px"
            fontClass={i_text_d}
            onClick={() => {
                handleSetSlippage(slippageLocal);
                handleSetMaxDelay(maxDelayLocal);
                toast('info', 'Saved successfully');
            }}
        />
    );

    return isMobile ? (
        <Modal isOpen={isOpen} onClose={onClose} w={'360px'} title={'Transaction Settings'} mx="0px !important" {...rest}>
            <Box w="100%" position="relative">
                <VStack>
                    {slippageBlock}
                    {delayBlock}
                    {exportRouterBlock}
                    {exclusiveLiquidityBlock}
                    {confirmButton}
                </VStack>
            </Box>
        </Modal>
    ) : (
        <Flex {...rest}>
            <Popover initialFocusRef={inputFocusRef} onOpen={clearForm}>
                <HStack position="relative" display="inline-block">
                    <PopoverTrigger>
                        <IconButton
                            aria-label="setting"
                            variant="ghost"
                            onClick={() => console.log('click')}
                            size="sm"
                            icon={<Icon as={AiOutlineSetting} boxSize={6} />}
                        />
                    </PopoverTrigger>
                </HStack>
                <PopoverContent
                    hidden={showPopOver}
                    p="30px 12px 17px 12px "
                    color={colorTheme('tertiary.400', 'tertiary.300')}
                    w="300px"
                    autoFocus={false}
                    bg={colorTheme('#FFFFFF', '#24193C')}
                    _focus={{
                        boxShadow: colorTheme('0px 0px 27px 5px rgba(29, 6, 51, 0.25)', 'none'),
                    }}
                    border="none"
                >
                    <VStack>
                        <HStack alignItems="left" w="100%">
                            <Text
                                variant="caption-bold"
                                color={colorTheme('#2B1166', '#FFFFFF')}
                                fontSize="12px"
                                letterSpacing="0.02em"
                                ml="10px"
                            >
                                {t('Transaction Settings')}
                            </Text>
                        </HStack>

                        {slippageBlock}
                        {delayBlock}
                        {exportRouterBlock}
                        {exclusiveLiquidityBlock}
                        {confirmButton}
                    </VStack>
                </PopoverContent>
            </Popover>
        </Flex>
    );
};

export default SwapSetting;
