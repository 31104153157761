import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { blocksPerDay } from '../../../config/chains';
import { tokenSymbol2token } from '../../../config/tokens';
import { VETOKEN_ADDRESS } from '../../../config/veToken/veTokenContracts';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { getVeTokenContract } from '../../../utils/contractHelpers';
import { amount2Decimal } from '../../../utils/tokenMath';
import { VeTokenGlobalData, VeTokenNft } from './types';

export const getVeTokenGlobalData = async (veTokenAddress: string| null | undefined, chainId?: ChainId, web3?: Web3): Promise<VeTokenGlobalData> => {
    const ret = {
        stakeTokenAmount: '0',
        rewardPerBlock: '0',
        totalTokenLocked: '0',
        totalTokenLockedDecimal: 0,
        totalVeToken: '0',
        totalVeTokenDecimal: 0,
        currentTimestamp: 0,
        curveTimestamps: [],
        totalVeTokenCurve: [],
        seconds4Year: (4 * 365 + 1) * 24 * 3600,
        avgLockYear: 0,
    } as VeTokenGlobalData;
    if (!chainId || !web3) {
        return ret;
    }
    const veTokenContract = getVeTokenContract(veTokenAddress, web3);
    ret.currentTimestamp = Math.round((new Date()).getTime() / 1000);
    ret.totalTokenLocked = await veTokenContract?.methods.supply().call() ?? '0';
    ret.totalTokenLockedDecimal = amount2Decimal(new BigNumber(ret.totalTokenLocked), tokenSymbol2token(TokenSymbol.ARC, chainId)) ?? 0;
    ret.totalVeToken = await veTokenContract?.methods.totalVeTokenAt(ret.currentTimestamp).call() ?? '0';
    ret.stakeTokenAmount = await veTokenContract?.methods.stakeTokenAmount().call() ?? '0';
    const rewardInfo = await veTokenContract?.methods.rewardInfo().call();
    ret.rewardPerBlock = rewardInfo?.rewardPerBlock ?? '0';

    ret.totalVeTokenDecimal = amount2Decimal(new BigNumber(ret.totalVeToken), tokenSymbol2token(TokenSymbol.ARC, chainId)) ?? 0;
  
    return ret;
};

export const getVeTokenNft = async (
    nftId: string, 
    currentTimestamp: number, 
    seconds4Year: number,
    chainId: ChainId,
    web3: Web3
) : Promise<VeTokenNft> => {
    if (!chainId || !web3) {
        return undefined as unknown as VeTokenNft;
    }
    const veTokenAddress = VETOKEN_ADDRESS[chainId];
    const veTokenContract = getVeTokenContract(veTokenAddress, web3);
    const nftLocked = await veTokenContract?.methods.nftLocked(nftId).call();
    if (nftLocked.amount === '0') {
        return undefined as unknown as VeTokenNft;
    }
    const lockAmountDecimal = amount2Decimal(new BigNumber(nftLocked.amount), tokenSymbol2token(TokenSymbol.ARC, chainId)) ?? 0;
    const endTimestamp = Number(nftLocked.end);
    const veTokenDecimal = lockAmountDecimal * Math.max(endTimestamp - currentTimestamp, 0) / seconds4Year;
    let owner = await veTokenContract?.methods.ownerOf(nftId).call();
    let isStaked = false;
    if (owner && veTokenAddress && owner.toLowerCase() === veTokenAddress.toLowerCase()) {
        isStaked = true;
        owner = await veTokenContract?.methods.stakedNftOwners(nftId).call();
    }
    return {
        lockAmountDecimal,
        veTokenDecimal,
        owner,
        endTimestamp,
        nftId,
        isStaked,
    } as VeTokenNft;
};

export const getChartTimeStamps = (
    currentDate: Date,
): number[] => {
    const lastMonth = new Date(currentDate);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const timestamp = [] as number[];

    timestamp.push(Math.round(lastMonth.getTime() / 1000));
    timestamp.push(Math.round(currentDate.getTime() / 1000));

    const date = new Date(currentDate);
    for (let i = 0; i < 48; i ++) {
        date.setMonth(date.getMonth() + 1);
        timestamp.push(Math.round(date.getTime() / 1000));
    }

    return timestamp;
};

export const getTotalVeTokenCurve = async (veTokenAddress: string | null | undefined, timestamp: number[], chainId?: ChainId, web3?: Web3): Promise<number[]> => {
    const ret = [] as number[];
    if (!chainId || !web3) {
        for (let i = 0; i < timestamp.length; i ++) {
            ret.push(0);
        }
        return ret;
    }
    if (timestamp.length > 0) {
        const veTokenContract = getVeTokenContract(veTokenAddress, web3);
        const callings = [] as string[];
        for (let i = 0; i < timestamp.length; i ++) {
            callings.push(veTokenContract?.methods.totalVeTokenAt(timestamp[i]).encodeABI());
        }
        const multicallRes = await veTokenContract?.methods.multicall(callings).call();
        for (let i = 0; i < multicallRes.length; i ++) {
            const totalVeTokenDecimal = amount2Decimal(new BigNumber(multicallRes[i]), tokenSymbol2token(TokenSymbol.ARC, chainId)) ?? 0;
            ret.push(totalVeTokenDecimal);
        }
    }
    return ret;
};

export const getVeTokenNftCurve = async (veTokenAddress: string | null | undefined, nftId: string, timestamp: number[], chainId?: ChainId, web3?: Web3): Promise<number[]> => {
    const ret = [] as number[];
    if (!chainId || !web3 || !veTokenAddress) {
        for (let i = 0; i < timestamp.length; i ++) {
            ret.push(0);
        }
        return ret;
    }
    if (timestamp.length > 0) {
        const veTokenContract = getVeTokenContract(veTokenAddress, web3);
        const callings = [] as string[];
        for (let i = 0; i < timestamp.length; i ++) {
            callings.push(veTokenContract?.methods.nftVeTokenAt(nftId, timestamp[i]).encodeABI());
        }
        const multicallRes = await veTokenContract?.methods.multicall(callings).call();
        for (let i = 0; i < multicallRes.length; i ++) {
            const veTokenDecimal = amount2Decimal(new BigNumber(multicallRes[i]), tokenSymbol2token(TokenSymbol.ARC, chainId)) ?? 0;
            ret.push(veTokenDecimal);
        }
    }
    return ret;
};

export const getStakeAPR = (rewardPerBlock: string, stakeAmount:string, lockAmount: string, veTokenAmount: string, chainId: ChainId): number => {
    if (lockAmount === '0') {
        return 0;
    }
    const blocksPerYear = blocksPerDay(chainId) * 365;
    const totalAmount = new BigNumber(stakeAmount).plus(lockAmount);
    const apr = Number(new BigNumber(rewardPerBlock).times(blocksPerYear).div(totalAmount).times(veTokenAmount).div(lockAmount));
    return apr;
};