import {
    Flex,
    Image,
    Text,
    useColorMode,
    HStack,
    Tooltip,
    useBreakpointValue,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Divider,
    BoxProps,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { i_h5, i_text_cardTitle } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { links } from '../../../../config/links';
import { a_bold, a_p, a_small } from '../../../../iZUMi-UI-toolkit/src/style';
import { MediaCircle } from './component/MediaCircle';

type OneColumnProps = {
    breakPoint: number[];
    footerList: any;
};

export const OneColumn: React.FC<OneColumnProps> = ({ breakPoint, footerList }) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [isOpenList, setOpenList] = useState(false);

    return footerList.slice(breakPoint[0], breakPoint[1]).map((section: any, key: number) => (
        <Flex
            w={{ base: '120px', sm: '180px', xl: '230px' }}
            direction="column"
            key={key}
            mt={{ base: '40px', md: '0px' }}
            alignItems="center"
        >
            <Text h="20px" mb="19px" color="#4DB78D" className={a_bold} letterSpacing="0.1em" fontWeight="700">
                {section.title}
            </Text>

            {section.items.map((item: any, index: number) =>
                item.type === 'link' ? (
                    <HStack
                        key={index}
                        mb="13px"
                        cursor="pointer"
                        onClick={
                            item.link
                                ? () => {
                                      window.open(item.link);
                                  }
                                : () => {}
                        }
                    >
                        {item.icon && (
                            <Image
                                w="14px"
                                h="14px"
                                src={process.env.PUBLIC_URL + item.icon}
                                fallbackSrc={process.env.PUBLIC_URL + item.icon}
                            />
                        )}

                        <Text color="tertiary.300" className={i_text_cardTitle} fontWeight="600">
                            {item.text}
                        </Text>
                    </HStack>
                ) : item.type === 'list' ? (
                    <Flex
                        key={index}
                        onMouseOver={() => {
                            setOpenList(true);
                        }}
                        onMouseLeave={() => {
                            setOpenList(false);
                        }}
                        mt="-8px"
                        pt="8px"
                        zIndex="1"
                    >
                        <Menu isOpen={isOpenList}>
                            <MenuButton>
                                <HStack key={index} mb="13px" cursor="pointer">
                                    {item.icon && (
                                        <Image
                                            w="14px"
                                            h="14px"
                                            src={process.env.PUBLIC_URL + item.icon}
                                            fallbackSrc={process.env.PUBLIC_URL + item.icon}
                                        />
                                    )}

                                    <Text color="tertiary.300" className={i_text_cardTitle} fontWeight="600">
                                        {item.text}
                                    </Text>
                                </HStack>
                            </MenuButton>
                            <MenuList
                                minW="0px"
                                w={{ base: '100px', lg: '130px' }}
                                className={a_p}
                                bg={colorTheme('#FBFBFB', '#100C1E')}
                                position="absolute"
                                bottom="40px"
                            >
                                <MenuItem
                                    justifyContent="center"
                                    onClick={() => {
                                        window.open(links.telegramChannel);
                                    }}
                                >
                                    Channel
                                </MenuItem>
                                <Divider></Divider>
                                <MenuItem
                                    justifyContent="center"
                                    onClick={() => {
                                        window.open(links.telegramCN);
                                    }}
                                >
                                    中文
                                </MenuItem>
                                <MenuItem
                                    justifyContent="center"
                                    onClick={() => {
                                        window.open(links.telegramEN);
                                    }}
                                >
                                    English
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                ) : (
                    <Tooltip label={item.link} key={index}>
                        <Text color="tertiary.300" className={i_text_cardTitle} fontWeight="600" mb="13px">
                            {item.text}
                        </Text>
                    </Tooltip>
                )
            )}
            {section.subtitle && (
                <Text color="tertiary.200" mt="-3px" className={i_h5}>
                    {section.subtitle}
                </Text>
            )}
        </Flex>
    ));
};

const HomePiece4: React.FC<BoxProps> = (pros) => {
    const { ...rest } = pros;
    const oneLineMode = useBreakpointValue({ base: false, md: true });
    const breakPoint = oneLineMode ? 1 : 2;
    const footerList = [
        {
            width: '230px',
            title: 'ABOUT',
            items: [
                {
                    text: 'Contact',
                    icon: '',
                    //link: 'mailto:contact@izumi.finance',
                    link: 'contact@arctic.trade',
                    onClick: null,
                    type: 'tooltip',
                },
                {
                    text: 'Github',
                    icon: '',
                    link: links.github,
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'Arc Token',
                    icon: '',
                    link: 'https://aurorascan.dev/address/0xBCD4e5E349Cc2810830E94f7fFEa4BD6b04c5978',
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'Media Kit',
                    icon: '',
                    link: links.mediaKit,
                    onClick: null,
                    type: 'link',
                },
            ],
            //subtitle: 'Online Store',
            subtitle: '',
        },
        {
            width: '258px',
            title: 'COMMUNITY',
            items: [
                {
                    text: 'Twitter',
                    link: links.twitter,
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'Medium',
                    link: links.medium,
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'Discord',
                    link: links.discord,
                    onClick: null,
                    type: 'link',
                },
                // {
                //     text: 'Telegram',
                //     link: links.telegram,
                //     onClick: null,
                //     type: 'list',
                // },
            ],
        },
        {
            width: '230px',
            title: 'HELP',
            items: [
                {
                    text: 'Troubleshooting',
                    icon: '',
                    link: 'contact@arctic.trade',
                    onClick: null,
                    type: 'tooltip',
                },
                {
                    text: 'Documentation',
                    icon: '',
                    link: links.docs,
                    onClick: null,
                    type: 'link',
                },
                {
                    text: 'Disclaimer',
                    icon: '',
                    link: links.disclaimer,
                    onClick: null,
                    type: 'link',
                },
            ],
        },
    ];

    return (
        <Flex
            w="100%"
            h={{ base: '468px' }}
            justifyContent="center"
            alignItems="center"
            bgImage={'/assets/home/homePiece7/footerBg.png'}
            bgSize="cover"
            mt="100px"
            {...rest}
        >
            <Flex w={{ base: '100%' }} direction={{ base: 'column' }} mx="auto" alignItems="center">
                <Image w="125px" h="92px" src={'/assets/home/homePiece7/ArcticLogo.svg'}></Image>
                <HStack spacing={{ base: '20px', sm: '50px' }} mt="50px">
                    <MediaCircle imgSrc={'/assets/home/homePiece7/TwitterWhite.svg'} link={links.twitter}></MediaCircle>
                    <MediaCircle imgSrc={'/assets/home/homePiece7/MediumWhite.svg'} link={links.medium}></MediaCircle>
                    <MediaCircle imgSrc={'/assets/home/homePiece7/DiscordWhite.svg'} link={links.discord}></MediaCircle>
                    {/* <MediaCircle imgSrc={'/assets/home/homePiece7/TelegramWhite.svg'} ></MediaCircle> */}
                </HStack>
                <Flex direction={{ base: 'row' }} mt="30px">
                    <>
                        <Flex>
                            <OneColumn breakPoint={[0, breakPoint]} footerList={footerList}></OneColumn>
                        </Flex>
                        <Flex>
                            <OneColumn breakPoint={[breakPoint, footerList.length]} footerList={footerList}></OneColumn>
                        </Flex>
                    </>
                </Flex>
                <Text className={a_small} color="tertiary.400" mt="30px" mb="50px">
                    Copyright 2022 Arctic Inc. All Rights Reserved.
                </Text>
            </Flex>
        </Flex>
    );
};

export default HomePiece4;
