import { BoxProps, useColorMode, HStack, VStack, Text, Box, Image, Divider, Center } from '@chakra-ui/react';
import { getColorThemeSelector, getFormatDateDiff, getHiddenAddress } from '../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy, i_h2 } from '../../../../style';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import Info from '../../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { NFTId } from '../../../../iZUMi-UI-toolkit/src/components/NFTId/NFTId';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
import { useState } from 'react';
import { TokenBalanceBlock } from '../../../Trade/components/TokenBalanceBlock';
import { AmountInput } from '../../../Trade/components/AmountInput';
import { decimal2Amount, formatNumber } from '../../../../utils/tokenMath';
import { VeTokenAmount } from '../../components/VeTokenAmount';
import { LockTime } from '../../components/LockTime';
import { VeTokenNft } from '../../../../state/models/veToken/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import useTokenEntity from '../../../../state/models/hooks/useTokenEntity';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { VETOKEN_ADDRESS } from '../../../../config/veToken/veTokenContracts';
import { isNumeric } from '../../../../utils/valid';
import BigNumber from 'bignumber.js';
import ApproveTokenButton from '../../../components/ApproveTokenButton';
import { MiningCallbacks } from '../../../../state/models/hooks/farm/common/callbacks';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import { useARCToken } from '../../../../hooks/useARCToken';
import useVeTokenEntity from '../../../../hooks/useVeTokenEntity';

type LockOtherModalProps = {
    isOpen: boolean | any;
    nft: VeTokenNft;
    onClose: () => void;
    onSuccess?: () => void;
} & BoxProps;

export const LockOtherModal: React.FC<LockOtherModalProps> = (props) => {
    const { isOpen, nft, onClose, onSuccess, ...rest } = props;

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { ARCToken } = useARCToken();

    const { veToken } = useSelector((state: RootState) => state);
    const toast = useCustomToast();

    const currentTimestamp = veToken.globalData?.currentTimestamp ?? 0;
    const seconds4Year = veToken.globalData?.seconds4Year ?? 0;
    const currentDate = new Date(currentTimestamp * 1000);
    const unlockDate = new Date(nft.endTimestamp * 1000);

    const { chainId } = useWeb3WithDefault();

    const veTokenAddress = VETOKEN_ADDRESS[chainId];
    const ARCEntity = useTokenEntity(ARCToken, veTokenAddress);
    const veTokenEntity = useVeTokenEntity(veTokenAddress, 'V1');

    const [inputValueDecimal, setInputValueDecimal] = useState(0);
    const [inputValue, setInputValue] = useState('0');

    const { gasPrice } = useGasPrice();

    const tokenStat = (img: any, text: any, value: number, variant: any) => {
        return (
            <VStack w="90%" h="70px" py="10px" pl="20px" borderRadius="4px" alignItems="left">
                <HStack>
                    <Image w="18px" h="18px" src={process.env.PUBLIC_URL + img} />
                    <Text
                        className={i_text_copy}
                        color={variant === 'purple' ? 'secondary.500' : colorTheme('tertiary.800', 'tertiary.100')}
                    >
                        {text}
                    </Text>
                </HStack>

                <HStack mt="8px !important">
                    <Text
                        fontSize="16px"
                        className={i_text_copy}
                        color={variant === 'purple' ? 'secondary.500' : colorTheme('tertiary.800', 'tertiary.50')}
                    >
                        {formatNumber(value, 2, 2)}
                    </Text>
                </HStack>
            </VStack>
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w="788px"
            //h="750px"
            title="Lock More VeARC NFT"
            {...rest}
        >
            <Divider mt="-20px !important" />
            <VStack w="100%" mt="20px">
                <Box bg={colorTheme('#FFF0F3', 'rgba(94, 42, 52, 0.46)')} w="100%" h="113px">
                    <Center w="100%" h="100%" px="25%">
                        <VStack>
                            <Text fontSize="16px" className={i_text_copy} color={colorTheme('#FA4D6C', '#C54158')}>
                                The NFT may not in your account.
                            </Text>

                            <Text fontSize="12px" className={i_text_copy} color={colorTheme('#FA3155', '#B44358')} textAlign="center">
                                To avoid permanent loss, please double check the NFT ID before locking more
                            </Text>
                        </VStack>
                    </Center>
                </Box>

                <Card variant="base" w="100%" minH="60px" pt="22px" pb="10px" pl="20px" borderRadius="4px" mb="20px !important" mt="20px">
                    <HStack w="100%" justifyContent="space-between">
                        <HStack spacing="40px">
                            <NFTId direction="column" id={nft.nftId} w="75px" link="" />
                        </HStack>

                        <HStack>
                            <Info label="Owner" value={getHiddenAddress(nft.owner)} w="120px" />
                            <VeTokenAmount balance={nft.veTokenDecimal} />
                            <LockTime time={getFormatDateDiff(currentDate, unlockDate)} />
                        </HStack>
                    </HStack>
                </Card>

                <HStack w="100%" spacing="30px">
                    <VStack w="334px" h="230px" alignItems="left">
                        <Text className={i_text_copy_bold} color={colorTheme('tertiary.500', 'tertiary.100')}>
                            Lock ARC Amount
                        </Text>
                        <Card variant="deep" w="100%" h="195px" p="10px">
                            <TokenBalanceBlock token={ARCToken} balance={ARCEntity.tokenBalance()} p="10px" />
                            <Divider my="10px !important" />

                            <Box w="100%" px="10px" mt="20px">
                                <AmountInput
                                    handleSetValue={(v: any) => {
                                        if (isNumeric(Number(v))) {
                                            setInputValueDecimal(Number(v));
                                            const inputValueBN = decimal2Amount(new BigNumber(Number(v)), ARCToken) ?? new BigNumber(0);
                                            setInputValue(inputValueBN.toFixed(0, 3));
                                        }
                                    }}
                                    token={ARCToken}
                                    price={ARCEntity.tokenPrice()}
                                    balance={ARCEntity.tokenBalance()}
                                    errorInfo=""
                                    inputValue={inputValueDecimal}
                                    w="60%"
                                    fontClass={i_h2}
                                />
                            </Box>
                        </Card>
                    </VStack>

                    <VStack w="334px" h="230px" alignItems="left">
                        <Box h="14px"></Box>

                        <Card variant="deep" w="100%" h="195px" p="12px 20px 12px 20px">
                            <VStack spacing="10px" alignItems="left">
                                {tokenStat('/assets/tokens/veToken.svg', 'Current veARC balance', nft.veTokenDecimal, 'base')}
                                <Image w="16px" h="16px" src={process.env.PUBLIC_URL + '/assets/farm/lightToV.svg'} ml="18px !important" />
                                {tokenStat(
                                    '/assets/tokens/veToken.svg',
                                    'New veARC balance after lock',
                                    ((nft.lockAmountDecimal + inputValueDecimal) * (nft.endTimestamp - currentTimestamp)) / seconds4Year,
                                    'purple'
                                )}
                            </VStack>
                        </Card>
                    </VStack>
                </HStack>
                {inputValue === '0' || ARCEntity.isApproved() ? (
                    <CustomButton
                        variant="purple"
                        mt="20px !important"
                        text={
                            <HStack m="auto">
                                <Image w="17px" h="17px" src={process.env.PUBLIC_URL + '/assets/tokens/arctic.png'} />
                                <Text>Lock ARC</Text>
                            </HStack>
                        }
                        disabled={inputValue === '0'}
                        w="304px"
                        h="40px"
                        fontClass={i_text_copy_bold}
                        fontSize="14px"
                        onClick={() => {
                            veTokenEntity.increaseAmountOrUnlockTime(
                                nft.nftId,
                                inputValue,
                                '0',
                                {
                                    onTransactionHash: (e: any) => {
                                        toast('info', 'Lock ARC: ' + e);
                                    },
                                    then: (e: any) => {
                                        console.log(e);
                                        toast('info', 'Lock ARC successfully');
                                        onSuccess?.();
                                        onClose();
                                    },
                                    catch: (e: any) => {
                                        console.log(e);
                                        toast('error', e.message);
                                    },
                                } as MiningCallbacks,
                                gasPrice
                            );
                        }}
                    />
                ) : (
                    <ApproveTokenButton tokenEntity={ARCEntity} mt="10px !important" w="200px" mx="auto !important" />
                )}
            </VStack>
        </Modal>
    );
};
