import { Box, BoxProps, Divider, Flex, HStack, useColorMode, VStack, Text } from '@chakra-ui/react';
import { default as React, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GameEventsTypeEnum } from '../../../net/iZUMi-endpoints/src/restful/izumiGame';
import { i_text_cardTitle, i_text_d } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { FinishedBlock } from '../FinishedBlock/FinishedBlock';

type Props = {
    list: { label: string; value: string; status: number; component: ReactElement }[];
    value: string;
    handleClick: OnClickFunction;
} & BoxProps;

const HeaderTabs: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { list, value, handleClick, ...rest } = props;
    const [selectId, setSelectId] = useState(-1);
    const judgeStatus = (status: number) => {
        if (status === GameEventsTypeEnum.LIVE) {
            return (
                <Flex bgColor={'secondary.50'} color="secondary.500" textAlign="center" ml="10px" borderRadius="3px">
                    <Text className={i_text_cardTitle} mx="6px" my="2px">
                        {t('Live')}
                    </Text>
                </Flex>
            );
        } else if (status === GameEventsTypeEnum.UPCOMING) {
            return (
                <Flex bgColor="tertiary.100" color="tertiary.500" textAlign="center" ml="10px" borderRadius="3px">
                    <Text className={i_text_cardTitle} mx="6px" my="2px">
                        {t('Upcoming')}
                    </Text>
                </Flex>
            );
        } else if (status === GameEventsTypeEnum.ENDED) {
            return (
                <Flex bgColor="tertiary.100" color="tertiary.500" textAlign="center" ml="10px" borderRadius="3px">
                    <Text className={i_text_cardTitle} mx="6px" my="2px">
                        {t('Ended')}
                    </Text>
                </Flex>
            );
        }
    };
    return (
        <VStack w={{ base: 'auto', md: '1000px' }} borderRadius="4px" mx="auto" alignItems="center" {...rest} spacing="0">
            <HStack
                h={'42px'}
                spacing="0"
                cursor="pointer"
                w={{ base: '100%', md: '1000px' }}
                alignItems="end"
                overflowX="auto"
                whiteSpace="nowrap"
            >
                {list.map((item, key) => (
                    <Flex
                        className={i_text_d}
                        key={key}
                        textAlign="center"
                        h={'36px'}
                        alignItems="center"
                        justifyContent="end"
                        onClick={() => handleClick(item.value)}
                        borderRadius="3px 3px 0 0"
                        direction="column"
                        color={colorTheme('#52466A', '#DDDAE1')}
                        px="10px"
                    >
                        <Flex alignItems="center" px="20px">
                            <Text color={value === item.value ? '#7F4AFE' : ''} fontWeight={value === item.value ? '700' : '500'}>
                                {item.label}
                            </Text>

                            {judgeStatus(item.status)}
                        </Flex>

                        <Divider
                            mt="13px"
                            w="100%"
                            borderWidth={value === item.value ? '3px ' : '0px'}
                            borderColor={value === item.value ? colorTheme('#7F4AFE', '#8B62FF') : colorTheme('#EBE6F0', '#52466A')}
                            zIndex={value === item.value ? 1 : 0}
                            borderRadius="28px"
                        ></Divider>
                    </Flex>
                ))}
                <Flex
                    className={i_text_d}
                    w="180px"
                    textAlign="center"
                    h={'36px'}
                    alignItems="center"
                    justifyContent="end"
                    onClick={() => {
                        setSelectId(-1);
                        handleClick('Finished');
                    }}
                    borderRadius="3px 3px 0 0"
                    direction="column"
                    color={colorTheme('#52466A', '#DDDAE1')}
                    px="5px"
                    ml="auto !important"
                >
                    <Flex alignItems="center">
                        <Text color={value === 'Finished' ? '#7F4AFE' : ''} fontWeight={value === 'Finished' ? '700' : '500'}>
                            {t('Finished')}
                        </Text>
                    </Flex>

                    <Divider
                        mt="13px"
                        w="100%"
                        borderWidth={value === 'Finished' ? '3px ' : '0px'}
                        borderColor={value === 'Finished' ? colorTheme('#7F4AFE', '#8B62FF') : colorTheme('#EBE6F0', '#52466A')}
                        zIndex={value === 'Finished' ? 1 : 0}
                        borderRadius="28px"
                    ></Divider>
                </Flex>
            </HStack>
            <Divider
                w="100%"
                borderWidth="1px"
                borderColor={colorTheme('#EBE6F0', '#52466A')}
                borderRadius="28px"
                mt="-4px !important"
            ></Divider>
            <Box w={{ base: '100%', sm: 'auto' }}>
                {value === 'Finished' ? <FinishedBlock selectId={selectId} setSelectId={setSelectId}></FinishedBlock> : ''}
            </Box>

            <Box w={{ base: '100%', sm: 'auto' }}>{list.find((item) => item.value === value)?.component}</Box>
        </VStack>
    );
};

export default HeaderTabs;
