import { InputGroup, InputLeftElement, Input, InputRightElement, Text, BoxProps, useColorMode } from '@chakra-ui/react';
import { useState } from 'react';
import { noto_t3, noto_t3_bold } from '../../../../../../style';
import { getColorThemeSelector } from '../../../../../../utils/funcs';

type OrderInputProps = {
    variant?: string;
    leftContent?: string;
    rightContent?: string;
    setValue?: any;
    value?: number;
} & BoxProps;

const OrderInput: React.FC<OrderInputProps> = (props) => {
    const { variant, leftContent, rightContent, setValue, value: valueRaw, ...rest } = props;
    const value = valueRaw ?? 0;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [isFocus, setIsInput] = useState(false);

    return variant === 'green' ? (
        <InputGroup
            h="60px"
            bg={colorTheme('#EEF0F3', '#160F29')}
            borderRadius="4px"
            border={colorTheme(isFocus ? '1px solid #54AB6B' : '1px solid #ffffff', isFocus ? '1px solid #54AB6B' : '1px solid #160F29')}
            _active={{ border: '1px solid #54AB6B' }}
            {...rest}
        >
            <InputLeftElement w="80px" h="100%" pl="8px" justifyContent="start">
                <Text className={noto_t3} color={colorTheme('#767676', '#C6B8CF')}>
                    {leftContent}
                </Text>
            </InputLeftElement>
            <Input
                variant="unstyled"
                w={{ base: '100%', sm: '120px' }}
                className={noto_t3_bold}
                color={colorTheme('#576359', '#EEF0F3')}
                textAlign="end"
                ml="80px"
                pr="5px"
                pl="5px"
                fontSize="14px"
                border="unset"
                type="number"
                value={value}
                onChange={(e: any) => {
                    setValue(e.target.value);
                }}
                onBlur={() => {
                    setIsInput(false);
                }}
                onFocus={() => {
                    setIsInput(true);
                }}
                // onKeyPress={(event) => {
                //     if (event.key === '-' || event.key === '+') {
                //         event.preventDefault(); // Cancel the native operation
                //     }
                // }}
            />
            <InputRightElement w="unset" h="100%" pr="8px" justifyContent="end" position={{ base: 'unset', sm: 'absolute' }}>
                <Text className={noto_t3} color={colorTheme('#767676', '#C6B8CF')} whiteSpace="nowrap">
                    {rightContent}
                </Text>
            </InputRightElement>
        </InputGroup>
    ) : (
        //variant === red
        <InputGroup
            h="60px"
            bg={colorTheme('#F7F3F2', '#160F29')}
            borderRadius="4px"
            border={colorTheme(isFocus ? '1px solid #D34D4D' : '1px solid #ffffff', isFocus ? '1px solid #D34D4D' : '1px solid #160F29')}
            _active={{ border: '1px solid #D34D4D' }}
            {...rest}
        >
            <InputLeftElement w="80px" h="100%" pl="8px" justifyContent="start">
                <Text className={noto_t3} color={colorTheme('#767676', '#C6B8CF')}>
                    {leftContent}
                </Text>
            </InputLeftElement>
            <Input
                variant="unstyled"
                w={{ base: '100%', sm: '120px' }}
                className={noto_t3_bold}
                color={colorTheme('#603A3A', '#F8EBEB')}
                textAlign="end"
                ml="80px"
                pr="5px"
                pl="5px"
                fontSize="14px"
                border="unset"
                type="number"
                value={value}
                onChange={(e: any) => {
                    setValue(e.target.value);
                }}
                onBlur={() => {
                    setIsInput(false);
                }}
                onFocus={() => {
                    setIsInput(true);
                }}
                // onKeyPress={(event) => {
                //     if (event.key === '-' || event.key === '+') {
                //         event.preventDefault(); // Cancel the native operation
                //     }
                // }}
            />
            <InputRightElement w="unset" h="100%" pr="8px" justifyContent="end" position={{ base: 'unset', sm: 'absolute' }}>
                <Text className={noto_t3} color={colorTheme('#603A3A', '#C6B8CF')} whiteSpace="nowrap">
                    {rightContent}
                </Text>
            </InputRightElement>
        </InputGroup>
    );
};

export default OrderInput;
