import { ChainId } from '../../types/mod';
import chains from '../chains';

export const LIQUIDITY_MANAGER_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.BSCTestnet]: '0xDE02C26c46AC441951951C97c8462cD85b3A124c', // 2022.09.26
        [ChainId.BSC]: '0x93C22Fbeff4448F2fb6e432579b0638838Ff9581', // 2022.05.10
        [ChainId.AuroraTestnet]: '0xEa0f268a066dF3329283b0AF7560e19B89c35511',
        [ChainId.Aurora]: '0xE78e7447223aaED59301b44513D1d3A892ECF212', //2022.09.23
        [ChainId.Arbitrum]: '0x611575eE1fbd4F7915D0eABCC518eD396fF78F0c', // 2022.09.23
        [ChainId.Matic]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF', // 2022.09.28
        [ChainId.Cronos]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF', // 2022.09.23
        [ChainId.ETC]: '0x1D377311b342633A970e71a787C50F83858BFC1B', // 2022.08.11
        [ChainId.ZkSyncAlphaTest]: '0x25727b360604E1e6B440c3B25aF368F54fc580B6', //2023.4.10
        [ChainId.ZkSyncEra]: '0x936c9A1B8f88BFDbd5066ad08e5d773BC82EB15F', //2023.03.27
        [ChainId.MantleTest]: '0xaDd9336AB4a795a66E4E1820f5d347c4294dd76a', //2023.03.08
        [ChainId.ScrollTestL2]: '0x6953DEe956eEe39973F1e1090368Ba434CCa0d94', //2023.01.30
        [ChainId.Icplaza]: '0x5bD3E57915D8136d5118Fb08C838542A3DE817DC',
        [ChainId.ConfluxESpace]: '0x1502d025BfA624469892289D45C0352997251728',
        [ChainId.Meter]: '0x07aBf894D5C25E626bb30f75eFC728a1d86BEeDC',
        [ChainId.Telos]: '0x110dE362cc436D7f54210f96b8C7652C2617887D',
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const LIMIT_ORDER_MANAGER_ADDRESSES: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.BSCTestnet]: '0x5C97187B2CEb1494078b70623c1E59edA79983A3', // 2022.09.26
        [ChainId.BSC]: '0x9Bf8399c9f5b777cbA2052F83E213ff59e51612B', // 2022.05.10
        [ChainId.AuroraTestnet]: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6',
        [ChainId.Aurora]: '0x01fDea353849cA29F778B2663BcaCA1D191bED0e', //2022.09.23
        [ChainId.Arbitrum]: '0xcA7e21764CD8f7c1Ec40e651E25Da68AeD096037', //2022.09.23
        [ChainId.Matic]: '0x34bc1b87f60e0a30c0e24FD7Abada70436c71406', // 2022.09.28
        [ChainId.Cronos]: '0x34bc1b87f60e0a30c0e24FD7Abada70436c71406', //2022.09.23
        [ChainId.ETC]: '0x032b241De86a8660f1Ae0691a4760B426EA246d7', // 2022.08.11
        [ChainId.ZkSyncAlphaTest]: '0x430972C4AF4703F7ce7B95C03735ae1504dD0Dd6', //2023.4.10
        [ChainId.ZkSyncEra]: '0x8b9D7D609a83B2f69D2135786a7D230043AF7283', //2023.03.27
        [ChainId.MantleTest]: '0xfc88C03eea05aCd91e394d418B73978D6AF10E94', //2023.03.08
        [ChainId.ScrollTestL2]: '0xcFf0318C36147deaB07Cf751a00b21F83d13412d', //2023.01.30
        [ChainId.Icplaza]: '0xF2a57CA885a082782764CC357fCD79466481C80D',
        [ChainId.ConfluxESpace]: '0x19b683A2F45012318d9B2aE1280d68d3eC54D663',
        [ChainId.Meter]: '0xB62315104a49C26521695600Be1f8c5C7F6DaCC4',
        [ChainId.Telos]: '0x1502d025BfA624469892289D45C0352997251728',
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.BSCTestnet]: '0x14C91A5b3b130e2342cCe549c0f4072Fc7604B6B',
        [ChainId.BSC]: '0xE65DB7179d70DAea99A0547eE732087eab0ffFBC',
        [ChainId.Arbitrum]: '0x3a2932a74e511C9Dc4CaD60e06eE6D3690Ce2492', //2023.03.23
        [ChainId.MantleTest]: '0x63fEF8791bdbe25A77337Cc265Ad732f200450AF', //2023.03.08
        [ChainId.ZkSyncEra]: '0xC70daeFbEf10089231543CFaf9699Ff10124Ec22', //2023.03.28
        [ChainId.ZkSyncAlphaTest]: '0x51082D700FB9cD0E4AC9F7D98AE5Ec2e9B97879C', // 2023.04.10
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const SWAP_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.BSCTestnet]: '0x4bD007912911f3Ee4b4555352b556B08601cE7Ce', // 2022.09.26
        [ChainId.BSC]: '0xBd3bd95529e0784aD973FD14928eEDF3678cfad8', // 2022.05.10
        [ChainId.AuroraTestnet]: '0x77132b63429718Db2B6ad8D942eE13A198f6Ab49',
        [ChainId.Aurora]: '0x96539F87cA176c9f6180d65Bc4c10fca264aE4A5', //2022.09.23
        [ChainId.Arbitrum]: '0x1CB60033F61e4fc171c963f0d2d3F63Ece24319c', //2022.09.23
        [ChainId.Matic]: '0x3F559139C2Fc7B97Ad6FE9B4d1f75149F551DB18', // 2022.09.28
        [ChainId.Cronos]: '0x04830cfCED9772b8ACbAF76Cfc7A630Ad82c9148', //2022.09.23
        [ChainId.ETC]: '0xe6805638db944eA605e774e72c6F0D15Fb6a1347', // 2022.08.11
        [ChainId.ZkSyncAlphaTest]: '0x3040EE148D09e5B92956a64CDC78b49f48C0cDdc', //2023.4.10
        [ChainId.ZkSyncEra]: '0x9606eC131EeC0F84c95D82c9a63959F2331cF2aC', //2023.03.27
        [ChainId.MantleTest]: '0x3A507CB35fcDe6445EF6e3D44D836f81d24374B8', //2023.03.08
        [ChainId.ScrollTestL2]: '0xaDd9336AB4a795a66E4E1820f5d347c4294dd76a', //2023.01.30
        [ChainId.Icplaza]: '0xE0c7b10009fC582beFE3cAFE777eC7E2753368aF',
        [ChainId.ConfluxESpace]: '0x2db0AFD0045F3518c77eC6591a542e326Befd3D7',
        [ChainId.Meter]: '0x6960A7151d3310804aFA9CaD2c7415f9564BE03e',
        [ChainId.Telos]: '0x2db0AFD0045F3518c77eC6591a542e326Befd3D7',
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const QUOTER_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.BSCTestnet]: '0x34bfB7478Eb5d13968CACeD116023D215A7505Dc', // 2022.11.24
        [ChainId.BSC]: '0x64b005eD986ed5D6aeD7125F49e61083c46b8e02', // 2022.06.06
        [ChainId.AuroraTestnet]: '0xa9754f0D9055d14EB0D2d196E4C51d8B2Ee6f4d3',
        [ChainId.Aurora]: '0x64b005eD986ed5D6aeD7125F49e61083c46b8e02', //2022.09.23
        [ChainId.Arbitrum]: '0x25C030116Feb2E7BbA054b9de0915E5F51b03e31',
        [ChainId.Matic]: '0x04830cfCED9772b8ACbAF76Cfc7A630Ad82c9148', // 2022.09.28
        [ChainId.Cronos]: '0x2C6Df0fDbCE9D2Ded2B52A117126F2Dc991f770f', //2022.09.23
        [ChainId.ETC]: '0xe4A0b241D8345d86FB140D40c87C5fbDd685B9dd', // 2022.08.11
        [ChainId.ZkSyncAlphaTest]: '0xE93D1d35a63f7C6b51ef46a27434375761a7Db28', //2023.4.10
        [ChainId.ZkSyncEra]: '0x377EC7c9ae5a0787F384668788a1654249059dD6', // 2023.03.27
        [ChainId.MantleTest]: '0xa847ffbeB5fD9F302ec6f5C38Cfd18E3b58c020f', //2023.03.08
        [ChainId.ScrollTestL2]: '0xfc88C03eea05aCd91e394d418B73978D6AF10E94', //2023.01.30
        [ChainId.Icplaza]: '0x95308cD647F6f2a44c891BC685d6117b3d27e461',
        [ChainId.ConfluxESpace]: '0x3EF68D3f7664b2805D4E88381b64868a56f88bC4',
        [ChainId.Meter]: '0x2C34664e5cD0981d44d237F1D7D3A09039D77E34',
        [ChainId.Telos]: '0x3EF68D3f7664b2805D4E88381b64868a56f88bC4',
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const QUOTER_ADDRESS_LIMIT: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.BSCTestnet]: '0x4bCACcF9A0FC3246449AC8A42A8918F2349Ed543', // 2022.09.26
        [ChainId.BSC]: '0xF3409631f87B0bC9bD6e9D9FD26D31BaDAA21880', // 2022.08.11
        [ChainId.AuroraTestnet]: null,
        [ChainId.Aurora]: '0x50E024bd504f066fd7f343d6D048F4BD97B1064E', //2022.09.23
        [ChainId.Arbitrum]: '0x1D377311b342633A970e71a787C50F83858BFC1B', // 2022.09.23
        [ChainId.Matic]: '0x2C6Df0fDbCE9D2Ded2B52A117126F2Dc991f770f', // 2022.09.28
        [ChainId.Cronos]: '0x88867BF3bB3321d8c7Da71a8eAb70680037068E4', // 2022.09.23
        [ChainId.ZkSyncAlphaTest]: '0xA8101060508f3A7fB9a98425a7fb765DB14ae224', //2023.4.10
        [ChainId.ZkSyncEra]: '0x3EC82C07981D6D213DA9bd35A0ba4cd324feA438', //2023.03.27
        [ChainId.ETC]: null,
        [ChainId.MantleTest]: '0xffCE438BB30D468f78F12BB9d9e237B7D3Eb8bd8', //2023.03.08
        [ChainId.ScrollTestL2]: '0x3A507CB35fcDe6445EF6e3D44D836f81d24374B8', //2023.01.30
        [ChainId.Icplaza]: '0x4FB0eacF72Bb49666f2D9Cf08ac4A31e1b68937b',
        [ChainId.ConfluxESpace]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF',
        [ChainId.Meter]: '0xFCA4de999fed6Fa40F32Ba014d15Be2e9373F370',
        [ChainId.Telos]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF',
    } as any;
    memo[chain.id] = contracts[chain.id] ?? null;
    return memo;
}, {});

export const BOX_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.BSCTestnet]: '0x8fbA4265785c56110B1277d6B23dD43B3797b3A8', // 2022.10.26
        [ChainId.BSC]: '0x05dCaF89dFA5A09d832aC4e7FE01f75e82643F60', // 2022.10.27
        [ChainId.AuroraTestnet]: null,
        [ChainId.Aurora]: '0xC4987497DEddd529e689809Ad213cc7645170c22', // 2022.09.23
        [ChainId.Arbitrum]: '0x032b241De86a8660f1Ae0691a4760B426EA246d7', //2022.09.23
        [ChainId.Matic]: '0x14323AfbC2b82fE58F0D9c203830EE969B4d1bE2', // 2022.09.28
        [ChainId.Cronos]: '0xB8dDAfE7385A962a4515821248368823E93fa61F', //2022.09.23
        [ChainId.ETC]: '0x3a2932a74e511C9Dc4CaD60e06eE6D3690Ce2492', // 2022.08.11
        [ChainId.ZkSyncAlphaTest]: '0x4130b96c97406EdfCe493088A008D80a3F48F2Ae', //2023.4.10
        [ChainId.ZkSyncEra]: '0x7dEe7de9814ed6C1e20B3E4E2fA9b1B96E15FDe1', //2023.03.27
        [ChainId.MantleTest]: '0xd1867201AEED11f3b6799Bb3bbB74569e0843d7B', //2023.03.08
        [ChainId.ScrollTestL2]: '0xa847ffbeB5fD9F302ec6f5C38Cfd18E3b58c020f', //2023.01.30
        [ChainId.Icplaza]: '0x1B76dC1494c38a4314d001a2f86AAAe45B48dFe0',
        [ChainId.ConfluxESpace]: '0x34bc1b87f60e0a30c0e24FD7Abada70436c71406',
        [ChainId.Meter]: '0xbF83D435c7859d6A7bF529AD9720abD21F8F4025',
        [ChainId.Telos]: '0x34bc1b87f60e0a30c0e24FD7Abada70436c71406',
    } as any;
    memo[chain.id] = contracts[chain.id] ?? null;
    return memo;
}, {});

export enum QUOTER_TYPE {
    limit,
    full,
}

export const SWAP_ADDRESS_WITH_SOURCE = chains.all.reduce<Record<number, any>>((memo, chain) => {
    const contracts = {
        [ChainId.ZkSyncEra]: {
            'K61R3U7BM4': '0x7Fb9b1492e4D69Ee8840c00c1baBc0E988DeeE9D',
            '6xT2HD3hit': '0xeDd6fd68e3283D9855791bFb9B16C4D7E7591D07',
            '3EGeGLgkWg': '0x2ddb0F3B573e366642d0c6f66EB360B22f9F9E07',
            '18y5vp2zJh': '0xEbF9775748B7e25c3BEE5A673bb8785F324cF0D8',
            'tiIhNsj6J1': '0x253f388Fc2130fa7c7ea7e918e68B8DE3F03C970',
            'xsxRLZOiMV': '0xCBceb4549D1f6C19F8FFbdF567d3Cc812152F318',
            '9s3oueG5fc': '0x55ac279884Df044D450C9c5D872b5dA68C74E7A6',
            'qwSKXzLaA3': '0xd512AadF3D3a55E9E7F2D4A7989CD534E676bD47',
        },
        [ChainId.ZkSyncAlphaTest]: {
            'test_token': '0xdFA454b8BdA6B0b315852d178F00DE83D7739b2e',
        },
    } as any;
    memo[chain.id] = contracts[chain.id] ?? {};
    return memo;
}, {});
