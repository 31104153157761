import { BoxProps, useColorMode, Text, Box, Flex, Button, VStack, HStack } from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { useEffect, useState, useMemo } from 'react';
import { getColorThemeSelector } from '../../../../utils/funcs';
import _ from 'lodash';
import { DataSeries, TimeScope } from '../../../Trade/Swap/components/types';
import { a_display, a_h3, a_h5 } from '../../../../iZUMi-UI-toolkit/src/style';

type TradingChartProps = {
    height?: string;
    variant?: string;
    series: DataSeries;

    timeScope: TimeScope;
    setTimeScope: (timeScope: TimeScope) => void;
} & BoxProps;

export const TradingChart: React.FC<TradingChartProps> = (props) => {
    const { variant, series: seriesRaw, timeScope, setTimeScope, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [value, setValue] = useState([0, 0]);
    const [maxTime, setMaxTime] = useState<number>(0);
    const [minTime, setMinTime] = useState<number>(0);
    const [toggle, setToggle] = useState(false);

    const series = useMemo(() => {
        const res = _.cloneDeep(seriesRaw);
        if (toggle) {
            for (let i = 0; i < res[0].data.length; i++) {
                res[0].data[i][1] = 1 / (res[0].data[i][1] ?? 1e-8);
            }
        }
        return res;
    }, [seriesRaw, toggle]);

    useEffect(() => {
        const data = series[0].data;
        const seriesMaxIdx = data.length - 1;
        if (series && seriesMaxIdx >= 0) {
            setValue(data[seriesMaxIdx]);
        }
    }, [series]);

    useEffect(() => {
        const data = series[0].data;
        const seriesMaxIdx = data.length - 1;
        if (data && seriesMaxIdx >= 0) {
            setMaxTime(data[seriesMaxIdx][0]);
            setMinTime(Math.max(maxTime - timeScope, data[0][0]));
        }
    }, [timeScope, series]);

    const options: ApexOptions = {
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        yaxis: {
            show: false,
            title: {
                text: 'TVL',
                style: {
                    color: colorTheme('#3A2F53', '#9B8FAE'),
                },
            },
            labels: {
                style: {
                    colors: colorTheme('#3A2F53', '#9B8FAE'),
                },
            },
        },
        colors: ['#B15AFF'],
        xaxis: {
            min: minTime,
            max: maxTime,
            type: 'datetime',
            axisBorder: {
                show: false,
                color: colorTheme('#52466A', '#8F84A0'),
            },
            labels: {
                style: {
                    colors: colorTheme('#3A2F53', '#9B8FAE'),
                },
                datetimeUTC: false,
            },
        },
        markers: {
            size: 0.000001,
        },
        tooltip: {
            x: {
                show: false,
                format: 'yyyy-MM-dd HH:mm',
            },
            custom: () => {
                return '';
            },
            theme: colorTheme('light', 'dark'),
        },
        grid: {
            show: false,
            borderColor: colorTheme('#B7B1BE', '#52466A'),
        },
        dataLabels: {
            enabled: false,
        },
        chart: {
            toolbar: {
                show: false,
            },
            events: {
                mouseMove: (e, d, c) => {
                    // TODO or useLatest
                    const series = c.config.series;
                    const seriesMaxIdx = series[0].data.length - 1;
                    if (seriesMaxIdx === -1) {
                        setValue([0, 0]);
                    } else if (c.dataPointIndex === -1) {
                        setValue(series[0].data[seriesMaxIdx]);
                    } else {
                        setValue(series[0].data[Math.min(c.dataPointIndex, seriesMaxIdx)]);
                    }
                },
            },
        },
    };

    return (
        <Card variant={variant ?? 'base'} w="100%" position="relative" bg="#260B5A" {...rest}>
            <Flex alignItems="center" my="30px" mx="23px">
                <VStack alignItems="start" w="100%">
                    <HStack justifyContent="space-between" w="100%">
                        <Flex direction="column">
                            <Text className={a_h5} color="customGreen" top="30px" letterSpacing="0.189em">
                                {'TRADING VOLUME'}
                            </Text>
                        </Flex>

                        <Flex className={a_display} ml="auto">
                            <Button
                                mx="5px"
                                bg="inherit"
                                color={timeScope === TimeScope.day ? 'customGreen' : 'tertiary.50'}
                                onClick={() => {
                                    setTimeScope(TimeScope.day);
                                }}
                            >
                                24H
                            </Button>
                            <Button
                                mx="5px"
                                bg="inherit"
                                color={timeScope === TimeScope.week ? 'customGreen' : 'tertiary.50'}
                                onClick={() => {
                                    setTimeScope(TimeScope.week);
                                }}
                            >
                                1W
                            </Button>
                            <Button
                                mx="5px"
                                bg="inherit"
                                color={timeScope === TimeScope.month ? 'customGreen' : 'tertiary.50'}
                                onClick={() => {
                                    setTimeScope(TimeScope.month);
                                }}
                            >
                                1M
                            </Button>
                            <Button
                                mx="5px"
                                bg="inherit"
                                color={timeScope === TimeScope.year ? 'customGreen' : 'tertiary.50'}
                                onClick={() => {
                                    setTimeScope(TimeScope.year);
                                }}
                            >
                                1Y
                            </Button>
                        </Flex>
                    </HStack>
                    <HStack>
                        <Text className={a_h3} color="tertiary.50">
                            {'$1.21m'}
                        </Text>
                    </HStack>
                </VStack>
            </Flex>
            <Box w="100%" h="72%" mt="14px !important">
                <ReactApexChart width="100%" height="100%" options={options} series={series} type="area" />
            </Box>
        </Card>
    );
};
