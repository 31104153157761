import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Button,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Box,
    useColorMode,
    Flex,
    Text,
    useBreakpointValue,
    InputLeftElement,
    Image,
} from '@chakra-ui/react';
import { i_text_copy, i_text_copy_bold, i_text_d } from '../../../style';
import { useCallback } from 'react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { RootState, RootDispatch } from '../../../state/store';
import NetworkSelect from './components/NetworkSelect';
import { Filter } from './components/Filter';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

const GalleryControls: React.FC = () => {
    const { farmGallery: farm } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isXl = useBreakpointValue({ base: false, xxl: true, '2xl': true });
    const isMobile = useIsMobile();

    const showedRow = farm.farmControl.showedRow;

    const onProtocolChange = useCallback(
        (e: any) => {
            const farmControl = { ...farm.farmControl };
            farmControl.protocol = e;
            dispatch.farmGallery.setFarmControl(farmControl);
        },
        [farm, dispatch]
    );

    const onSortByChange = useCallback(
        (e: any) => {
            const farmControl = { ...farm.farmControl };
            farmControl.sortBy = e;
            dispatch.farmGallery.setFarmControl(farmControl);
        },
        [farm, dispatch]
    );

    const onSearchKeyChange = useCallback(
        (e: any) => {
            const farmControl = { ...farm.farmControl };
            farmControl.searchKey = e.target.value;
            dispatch.farmGallery.setFarmControl(farmControl);
        },
        [farm, dispatch]
    );

    const onStatusChange = useCallback(
        (e: any) => {
            const farmControl = { ...farm.farmControl };
            farmControl.status = e;
            dispatch.farmGallery.setFarmControl(farmControl);
        },
        [farm, dispatch]
    );
    const judgePlatForm = (type: number | string | undefined) => {
        if (type === undefined || type === '') {
            return 'Show All';
        } else if (type === 0) {
            return 'UniSwap';
        } else if (type === 1) {
            return 'iZiSwap';
        }

        return 'Show All';
    };

    const LiveEndBlock = (isFilter: boolean) => (
        <Stack direction="row" flexGrow={{ base: 0, xl: 1 }} alignItems="center" ml={isFilter ? 'auto !important' : '20px !important'}>
            <CustomButton
                variant="none"
                text={t('Live')}
                pt="0px"
                className={i_text_copy_bold}
                fontSize="16px"
                selected={farm.farmControl.status === 'LIVE'}
                onClick={() => {
                    onStatusChange('LIVE');
                }}
            />

            <Box h="20px" w="1px" bg={colorTheme('tertiary.200', 'tertiary.400')} />

            <CustomButton
                variant="none"
                ml="0 !important"
                pt="0px"
                pl="10px"
                text={t('Ended')}
                className={i_text_copy_bold}
                fontSize="16px"
                selected={farm.farmControl.status === 'ENDED'}
                onClick={() => {
                    onStatusChange('ENDED');
                }}
            />
        </Stack>
    );
    const PlatFormBlock = () => (
        <FormControl display="flex" alignItems="center" w="unset">
            <FormLabel
                className={i_text_copy}
                w={{ base: '55px', xxl: '38px' }}
                textAlign={{ base: 'end', xxl: 'start' }}
                fontSize="12px"
                fontWeight="500"
                pt="6px"
            >
                {t('DEX')}
            </FormLabel>
            <Menu>
                {() => (
                    <>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} className={i_text_d} fontSize="13px" w="140px">
                            {t(judgePlatForm(farm.farmControl.protocol)) || t('Show All')}
                        </MenuButton>
                        <MenuList className={i_text_copy}>
                            <MenuItem onClick={() => onProtocolChange('')}>{t('Show All')} </MenuItem>
                            <MenuItem onClick={() => onProtocolChange(0)}> UniSwap </MenuItem>
                            <MenuItem onClick={() => onProtocolChange(1)}> iZiSwap </MenuItem>
                        </MenuList>
                    </>
                )}
            </Menu>
        </FormControl>
    );
    const NetworkBlock = () => (
        <Flex className={i_text_copy} alignItems="center">
            <Text w="55px" textAlign="end" mr="12px">
                {t('Network')}
            </Text>
            <NetworkSelect menuButtonWidth={!isXl ? '140px' : '178px'} />
        </Flex>
    );

    const SortByBlock = () => (
        <FormControl display="flex" w="206px" alignItems="center" ml="0px !important">
            <FormLabel className={i_text_copy} w="55px" textAlign="end" fontSize="12px" fontWeight="500" pt="6px">
                {t('Sort by')}
            </FormLabel>
            <Menu>
                {() => (
                    <>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} className={i_text_d} fontSize="13px" w="140px">
                            {farm.farmControl.sortBy || t('Hot')}
                        </MenuButton>
                        <MenuList className={i_text_copy}>
                            <MenuItem onClick={() => onSortByChange('Hot')}> {t('Hot')}</MenuItem>
                            <MenuItem onClick={() => onSortByChange('APR')}>{t('APR')} </MenuItem>
                        </MenuList>
                    </>
                )}
            </Menu>
        </FormControl>
    );
    return isMobile ? (
        <Stack w="100%" direction="column" alignItems="start" pt="16px" pb="12px" justifyContent={{ base: 'space-between' }} spacing="20px">
            <HStack w="100%" mr="20px">
                <InputGroup h="35px" flex="1">
                    <InputLeftElement boxSize="20px" mt="5px" ml="10px">
                        <SearchIcon boxSize={5} />
                    </InputLeftElement>
                    <Input
                        variant="filled"
                        h="30px"
                        placeholder={t('Search Tokens or Pools')}
                        size="lg"
                        className={i_text_d}
                        fontSize="12px"
                        onChange={(e: any) => onSearchKeyChange(e)}
                        value={farm.farmControl.searchKey || ''}
                        bg={colorTheme('#FFFFFF', 'rgba(37, 32, 59, 0.5)')}
                        color="tertiary.300"
                        letterSpacing="0.02em"
                        fontFamily="Montserrat"
                    />
                </InputGroup>

                <HStack spacing="13px" ml="17px !important">
                    <Filter
                        filterTitle={t('Gallery Filter')}
                        filterElement={[
                            LiveEndBlock(true),
                            <PlatFormBlock key={1}></PlatFormBlock>,
                            <NetworkBlock key={2}></NetworkBlock>,
                            <SortByBlock key={3}></SortByBlock>,
                        ]}
                    ></Filter>
                    <Image
                        src={
                            showedRow === 2
                                ? '/assets/filter/selectedTwoRow.svg'
                                : colorTheme('/assets/filter/twoRow.svg', '/assets/filter/twoRowDark.svg')
                        }
                        fallbackSrc={'/assets/filter/twoRow.svg'}
                        onClick={() => {
                            dispatch.farmGallery.setFarmShowedRow(2);
                        }}
                    ></Image>
                    <Image
                        src={
                            showedRow === 1
                                ? '/assets/filter/selectedOneRow.svg'
                                : colorTheme('/assets/filter/oneRow.svg', '/assets/filter/oneRowDark.svg')
                        }
                        fallbackSrc={'/assets/filter/oneRow.svg'}
                        onClick={() => {
                            dispatch.farmGallery.setFarmShowedRow(1);
                        }}
                    ></Image>
                </HStack>
            </HStack>
            {isXl && (
                <Stack w="100%" direction={{ base: 'row', xl: 'row' }} ml="0px !important" spacing="10px">
                    <PlatFormBlock />
                    <NetworkBlock />
                    <SortByBlock />
                </Stack>
            )}
        </Stack>
    ) : (
        <Stack
            w="100%"
            direction={{ base: 'column', xl: 'row' }}
            alignItems="start"
            pt={{ base: '10px', sm: '34px' }}
            pb="34px"
            justifyContent={{ base: 'space-between' }}
            spacing="20px"
        >
            <HStack w="100%" mr="20px">
                <InputGroup w="275px" h="41px" mt="-4px">
                    <Input
                        variant="filled"
                        placeholder={t('Search Tokens or Pools')}
                        size="lg"
                        className={i_text_d}
                        fontSize="12px"
                        onChange={(e: any) => onSearchKeyChange(e)}
                        value={farm.farmControl.searchKey || ''}
                    />
                    <InputRightElement h="100%" mt="4px">
                        <SearchIcon boxSize={5} />
                    </InputRightElement>
                </InputGroup>

                {isXl && LiveEndBlock(false)}
                {!isXl && (
                    <Filter
                        filterTitle={t('Gallery Filter')}
                        filterElement={[
                            LiveEndBlock(true),
                            <PlatFormBlock key={1}></PlatFormBlock>,
                            <NetworkBlock key={2}></NetworkBlock>,
                            <SortByBlock key={3}></SortByBlock>,
                        ]}
                        ml="auto !important"
                    ></Filter>
                )}
            </HStack>
            {isXl && (
                <Stack w="100%" direction={{ base: 'row', xl: 'row' }} ml="0px !important" spacing="10px">
                    <PlatFormBlock />
                    <NetworkBlock />
                    <SortByBlock />
                </Stack>
            )}
        </Stack>
    );
};

export default GalleryControls;
