import { ChevronDownIcon } from '@chakra-ui/icons';
import { useColorMode, Box, Menu, MenuButton, Button, MenuList, MenuItem, Image, Text, BoxProps } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import chains, { BaseChain } from '../../../../config/chains';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import { RootDispatch, RootState } from '../../../../state/store';
import { i_text_d } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import placeholder from '../../../../assets/placeholder.png';
type NetworkSelectProps = {
    menuButtonWidth?: string;
} & BoxProps;
const NetworkSelect: React.FC<NetworkSelectProps> = (props) => {
    const { menuButtonWidth } = props;
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const bg2 = colorTheme('#F5F5F5', 'tertiary.800');

    const location = useLocation();
    const { farmGallery: farm } = useSelector((state: RootState) => state);

    const chainsFilter = useMemo(() => {
        let res = chains.farm.concat(chains.farm);
        const resSet = new Set(res);
        console.log(resSet);
        res = Array.from(resSet);
        return res;
    }, [location]);

    const findChainById = (chainId: number) => {
        const chain = chains.all.find((e) => {
            return e.id === chainId;
        }) as BaseChain;
        return chain;
    };

    const onChainChange = useCallback(
        (e: any) => {
            const farmControl = { ...farm.farmControl };
            farmControl.chainId = e;
            dispatch.farmGallery.setFarmControl(farmControl);
        },
        [dispatch.farm, farm.farmControl]
    );

    return (
        <Box>
            <Menu>
                <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    leftIcon={
                        !farm.farmControl.chainId ? (
                            <></>
                        ) : (
                            <Image
                                src={farm.farmControl.chainId ? findChainById(farm.farmControl.chainId as number).icon : placeholder}
                                boxSize={'24px'}
                                fallbackSrc={placeholder}
                            />
                        )
                    }
                    style={{
                        height: '40px',
                        width: menuButtonWidth || '178px',
                        background: { bg2 } as unknown as string,
                        borderRadius: '6px',
                    }}
                >
                    <Text className={i_text_d} fontSize="13px" fontWeight="bold">
                        {farm.farmControl.chainId ? findChainById(farm.farmControl.chainId as number).name : 'Show All'}
                    </Text>
                </MenuButton>

                <MenuList>
                    {
                        <MenuItem
                            as={Button}
                            variant="light"
                            text={'Show All'}
                            size="md"
                            isActive={!farm.farmControl.chainId}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                borderRadius: '4px',
                                overflow: 'hidden',
                                fontSize: '12px',
                            }}
                            onClick={() => {
                                onChainChange('');
                            }}
                            leftIcon={<Image src={placeholder} boxSize="20px" mr="10px" fallbackSrc={placeholder} />}
                        >
                            {'Show All'}
                        </MenuItem>
                    }
                    {chainsFilter.map((chain) => (
                        <MenuItem
                            as={Button}
                            variant="light"
                            text={chain.name}
                            size="md"
                            key={chain.id}
                            isActive={chain.id === farm.farmControl.chainId}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                borderRadius: '4px',
                                overflow: 'hidden',
                                fontSize: '12px',
                            }}
                            onClick={() => {
                                onChainChange(chain.id);
                            }}
                            leftIcon={
                                <Image src={process.env.PUBLIC_URL + chain.icon} boxSize="20px" mr="10px" fallbackSrc={placeholder} />
                            }
                        >
                            {chain.name}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    );
};

export default NetworkSelect;
