import { ChainId } from '../types/mod';
import { getScanUrl } from './chains';
export const links = {
    docs: 'https://docs.arctic.trade/',
    developerDocs: 'https://developer.izumi.finance',
    github: 'https://github.com/ArcticTrade',
    miningProtocol:
        'https://izumi-finance.medium.com/announcement-of-izi-eth-and-usdc-usdt-liquidity-mining-program-on-uniswap-v3-397705303e08',
    dswapProtocol: 'https://izumi.finance/paper/dswap.pdf',
    telegram: 'https://t.me/joinchat/e7idO_QU_21iY2E9',
    telegramCN: 'https://t.me/iZUMi_Finance_CN',
    telegramEN: 'https://t.me/izumifinance',
    telegramChannel: 'https://t.me/iZUMi_Finance_Channel',
    twitter: 'https://twitter.com/Arctic_trade',
    medium: 'https://medium.com/@Arctic_trade',
    discord: 'https://dsc.gg/arctictrade',
    reddit: 'https://www.reddit.com/r/izumiFinance/',
    defipulse: 'https://www.defipulse.com',
    research: 'https://docs.izumi.finance/research',
    iZiToken: 'https://etherscan.io/address/0x9ad37205d608b8b219e6a2573f922094cec5c200#readContract',
    mediaKit: 'https://drive.google.com/drive/folders/1JVfol9vynhf8d8wsvr5VliIB2WRt25Za?usp=sharing',
    liquidBoxDoc: 'https://docs.izumi.finance/product/liquidbox/why-do-protocols-need-liquidbox-to-gain-liquidity',
    iZiBuyLink: {
        [ChainId.Aurora]: 'https://izumi.finance/trade/swap?tokenTo=0x60d01ec2d5e98ac51c8b4cf84dfcce98d527c747&chainId=56',
    } as any,
    disclaimer: 'https://docs.arctic.trade/other/disclaimer',
    gateiZiLink: 'https://www.gateio.ch/cn/trade/IZI_USDT',
    bybitiZiLink: 'https://www.bybit.com/en-US/trade/spot/IZI/USDT',
    mexciZiLink: 'https://www.mexc.com/zh-CN/exchange/IZI_USDT',
    analyticLink: process.env.REACT_APP_ENV === 'development' ? 'https://analytics-alpha.arctic.trade' : 'https://analytics.arctic.trade',
    veTokenlink: 'https://etherscan.io/address/0xb56a454d8dac2ad4cb82337887717a2a427fcd00',
    cbridgLink: 'https://cbridge.celer.network/#/transfer',
    veiZilink: 'https://etherscan.io/address/0xb56a454d8dac2ad4cb82337887717a2a427fcd00',
};

export const getNftUrl = (chainId: ChainId, contractAddress: string | null | undefined, nftId: string): string => {
    return getScanUrl(chainId) + 'token/' + contractAddress + '?a=' + nftId;
};
