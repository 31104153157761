import { BoxProps, useColorMode, Box } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../../utils/funcs';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './apexcharts.css';
import BigNumber from 'bignumber.js';

type VeTokenChartProps = {
    height?: string;
    curveTimeStamps: number[];
    curveValues: number[];
} & BoxProps;

export const VeTokenChart: React.FC<VeTokenChartProps> = (props) => {
    const { height, curveTimeStamps, curveValues, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const options: ApexOptions = {
        stroke: {
            curve: 'smooth',
        },
        yaxis: {
            title: {
                text: 'VeToken Amount',
                style: {
                    color: colorTheme('#3A2F53', '#9B8FAE'),
                },
            },
            labels: {
                style: {
                    colors: colorTheme('#3A2F53', '#9B8FAE'),
                },
            },
        },
        colors: [colorTheme('#6A20B3', '#8B62FF')],
        xaxis: {
            type: 'datetime',
            axisBorder: {
                color: colorTheme('#52466A', '#8F84A0'),
            },
            labels: {
                style: {
                    colors: colorTheme('#3A2F53', '#9B8FAE'),
                },
            },
        },
        tooltip: {
            x: {
                show: false,
                format: 'dd MMM yyyy',
            },
            theme: colorTheme('light', 'dark'),
        },
        grid: {
            borderColor: colorTheme('#B7B1BE', '#52466A'),
        },
        chart: {
            toolbar: {
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: false,
                    customIcons: [],
                },
            },
        },
    };

    const series = [
        {
            name: 'veARC amount',
            data: [
                // [1643794437000, 100],
                // [1649894437000, 200],
                // [1653994437000, 300],
                // [1659094437000, 700],
                // [1664194437000, 400],
                // [1670594437000, 200],
                // [1674694437000, 100],
                // [1678994437000, 50],
                // [1684994437000, 20],
            ] as any[][],
        },
    ];

    for (let i = 0; i < curveTimeStamps.length; i++) {
        series[0].data.push([curveTimeStamps[i] * 1000, new BigNumber(curveValues[i]).toFixed(2)]);
    }

    return (
        <Box w={{ base: 'unset', sm: '745px' }} h="355px" {...rest}>
            <ReactApexChart options={options} series={series} height={height ?? '300px'} />
        </Box>
    );
};
