import { ChainId } from '../../types/mod';
import chains from '../chains';

export const VETOKEN_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    memo[chain.id] = '';
    if (process.env.REACT_APP_ENV === 'production') {
        if (chain.id === ChainId.Aurora) {
            memo[chain.id] = '0x8F8aB669CF784db9ec147BDb9FDa5F80D0617D5f';
        }
    } else {
        if (chain.id === ChainId.AuroraTestnet) {
            memo[chain.id] = '0xF7713d221418e098a788C4DaDd52F74b55B379E5';
        }
    }
    return memo;
}, {});
