import React,{useState} from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { HStack, Tooltip, IconButton, Icon, VStack, Text, useColorMode, Image, Flex } from '@chakra-ui/react';

import { i_text_copy } from '../../../../style';
import { formatNumber } from '../../../../utils/tokenMath';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { SwapForm } from '../../../../state/models/trade/swap/tradeSwap';
import { feeRateToFeeNumber, getColorThemeSelector, toFeeNumber } from '../../../../utils/funcs';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { SwapTag } from '../../../../state/models/trade/aggregator/config';

export type SwapDetailProps = {
    swapForm: SwapForm;
    ready: boolean;
    isSimpleMode?: boolean;
};

const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/dex/';

export const SwapDetail: React.FC<SwapDetailProps> = (props) => {
    const { swapForm, isSimpleMode, ready } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [togglePrice, setTogglePrice] = useState(false);

    const swapInfoRow = (title: string, value: string, type?: string) => {
        return (
            <HStack w="100%" justifyContent="space-between" marginTop="3px !important">
                <Text variant="caption" letterSpacing="0.02em" color={colorTheme('tertiary.400', 'tertiary.300')}>
                    {title}
                </Text>
                {type === 'price' ? (
                    <HStack spacing="10px">
                        <Text variant="caption" letterSpacing="0.02em" color={colorTheme('tertiary.700', 'tertiary.50')}>
                            {value}
                        </Text>
                        <Image
                            w="18px"
                            h="14px"
                            src={colorTheme('/assets/trade/limitOrderChange.svg', '/assets/trade/darklimitOrderChange.svg')}
                            fallbackSrc="/assets/trade/limitOrderChange.svg"
                            cursor="pointer"
                            onClick={() => setTogglePrice(!togglePrice)}
                        ></Image>
                    </HStack>
                ) : (
                    <Text variant="caption" letterSpacing="0.02em" color={colorTheme('tertiary.700', 'tertiary.50')}>
                        {value}
                    </Text>
                )}
            </HStack>
        );
    };

    const poolBlock = (tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, fee: FeeTier) => {
        return (
            <Card
                w="65px"
                h="28px"
                ml="0px !important"
                variant="grey"
                bg={colorTheme('#FFFFFF', 'tertiary.700')}
                border={colorTheme('1px solid #DDDAE1', 'none')}
                alignItems="center"
            >
                <HStack px="3px">
                    <Flex h="16px" w="16px" my="6px" mx="4px" position="relative" flexShrink={0}>
                        <Image h="9px" w="9px" position="absolute" top="0" left="0" borderRadius="2px" flexShrink={0} src={tokenA.icon} />
                        <Image
                            h="12px"
                            w="12px"
                            position="absolute"
                            bottom="0"
                            right="0"
                            borderRadius="2px"
                            flexShrink={0}
                            src={tokenB.icon}
                        />
                    </Flex>
                    <Text className={i_text_copy} ml="0 !important" variant="caption">
                        {fee}%
                    </Text>
                </HStack>
            </Card>
        );
    };

    const isWeth9 = swapForm.swapTag === SwapTag.weth9;

    return ready ? (
        <HStack justifyContent="space-between" className={i_text_copy}>
            <Card minW="300px" h={isSimpleMode ? '80px' : '105px'} variant="gray" px="13px" pt="8px">
                <HStack justifyContent="space-between">
                    <HStack spacing="10px">
                        {isWeth9 && (
                            <Text variant="caption-bold" ml="5px">
                                {t('Wrap/unwrap')}
                            </Text>
                        )}
                        {!isWeth9 && (
                            <Text variant="caption-bold" ml="5px">
                                {t('Auto Router')}
                            </Text>
                        )}
                        {!isWeth9 && process.env.NODE_ENV === 'development' && (
                            <Image boxSize="18px" src={baseURL + swapForm.swapTag + '.png'} />
                        )}
                    </HStack>
                    <Tooltip
                        label={t('Currently the router is chosen by maximum the target token acquired without gas fee consideration') + '.'}
                        placement="top"
                    >
                        <IconButton
                            size="16px"
                            aria-label=""
                            variant="ghost"
                            isRound={true}
                            icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                        />
                    </Tooltip>
                </HStack>
                <HStack mt="22px" justifyContent="center" px="10px !important">
                    <Image h="19px" w="19px" mx="0px !important" borderRadius="2px" src={swapForm.tokenX.icon} />
                    <Image mx="3px !important" src={process.env.PUBLIC_URL + '/assets/trade/dashArrowShort.svg'} />
                    {swapForm.swapPath.feeRate.length >= 1 && (
                        <>
                            {swapForm.swapPath.feeRate.map((f: number, i: number) => (
                                <React.Fragment key={i}>
                                    {poolBlock(
                                        swapForm.swapPath.tokenChain[i],
                                        swapForm.swapPath.tokenChain[i + 1],
                                        feeRateToFeeNumber(swapForm.swapPath.feeRate[i])
                                    )}
                                    <Image mx="5px !important" src={process.env.PUBLIC_URL + '/assets/trade/dashArrowShort.svg'} />
                                </React.Fragment>
                            ))}
                        </>
                    )}
                    <Image h="19px" w="19px" mx="0px !important" borderRadius="2px" src={swapForm.tokenY.icon} />
                </HStack>
            </Card>
            <Card w="300px" h="125px" variant="grey" bg="transparent">
                <VStack w="100%" alignItems="left" padding="5px">
                    <Text
                        variant="caption-bold"
                        letterSpacing="0.02em"
                        marginBottom="6px"
                        color={colorTheme('tertiary.900', 'tertiary.50')}
                    >
                        {t('Transaction Details')}
                    </Text>
                    {swapInfoRow(
                        t('Liquidity Provider Fee'),
                        formatNumber(swapForm.feePayedDecimal, 4, 8, true) + ' ' + swapForm.tokenX.symbol
                    )}
                    {swapInfoRow(t('Price Impact'), formatNumber((swapForm.priceImpact ?? 0) * 100, 2, 2) + '%')}
                    {swapInfoRow(
                        t('Average Price'),
                        formatNumber(
                            swapForm.amountDecimal
                                ? togglePrice
                                    ? swapForm.amountDesireDecimal / swapForm.amountDecimal
                                    : 1 / (swapForm.amountDesireDecimal / swapForm.amountDecimal)
                                : 0,
                            2,
                            2,
                            true
                        ),
                        'price'
                    )}
                    {swapInfoRow(t('Allowed Slippage'), `${swapForm.slippagePercent} %`)}

                    {!swapForm.desireMode &&
                        swapInfoRow(
                            t('Minimum Receive'),
                            formatNumber(swapForm.amountDesireDecimal * (1 - swapForm.slippagePercent / 100), 2, 2, true) +
                                ' ' +
                                swapForm.tokenY.symbol
                        )}

                    {swapForm.desireMode &&
                        swapInfoRow(
                            t('Maximum Pay'),
                            formatNumber(swapForm.amountDecimal / (1 - swapForm.slippagePercent / 100), 2, 2, true) +
                                ' ' +
                                swapForm.tokenX.symbol
                        )}
                </VStack>
            </Card>
        </HStack>
    ) : (
        <></>
    );
};
