import {
    HStack,
    Stack,
    Text,
    Image,
    useColorMode,
    useInterval,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Center,
} from '@chakra-ui/react';
import { TransactionReceipt } from 'ethereum-abi-types-generator';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { A_LONG_FUTURE_TIME, IZUMI_SWAP_CONFIG } from '../../../../../config/bizConfig';
import { isGasOrWrappedGasToken } from '../../../../../config/tokens';
import { LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES } from '../../../../../config/trade/tradeContracts';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { useGasToken } from '../../../../../state/models/hooks/useGasToken';
import { getBaseQuoteOrder } from '../../../../../state/models/pro/proOrderFormState/funcs';
import { FetchLimitOrderParams, ProLimitOrder } from '../../../../../state/models/pro/proOrderList/types';
import { RootDispatch, RootState } from '../../../../../state/store';
import { noto_h3, noto_t3_bold } from '../../../../../style';
import { getLimitOrderWithSwapManagerContract } from '../../../../../utils/contractFactory';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { formatNumber } from '../../../../../utils/tokenMath';
import { getChain, getTxLink } from '../../../../../config/chains';
import { ToastLink, useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import { buildSendingParams } from '../../../../../utils/contractHelpers';
type CurrentOrdersProps = {
    themeColor: any;
};
const CurrentOrders: React.FC<CurrentOrdersProps> = (props) => {
    const { themeColor } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    const tabs = [
        { title: 'Pairs', width: { base: '128px', xxl: '128px' } },
        { title: 'Direction', width: { base: '79px', xxl: '107px' } },
        { title: 'Amount', width: { base: '112px', xxl: '118px' } },
        { title: 'Price', width: { base: '114px', xxl: '144px' } },
        { title: 'Filled', width: { base: '95px', xxl: '113px' } },
        { title: 'Pending', width: { base: '173px', xxl: '187px' } },
        { title: 'Time', width: { base: '145px', xxl: '206px' } },
        { title: 'State', width: '86px' },
    ];

    const { chainId, web3, account } = useWeb3WithDefault();
    const { gasPrice } = useGasPrice();
    const toast = useCustomToast();
    const { proOrderList, proControlState } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { tokenList } = useTokenListFormatted();

    const refreshLimitOrder = useCallback(() => {
        const limitOrderWithSwapManager = getLimitOrderWithSwapManagerContract(chainId, web3);
        dispatch.proOrderList
            .fetchLimitOrder({
                chainId,
                web3,
                account,
                orderManagerContract: limitOrderWithSwapManager,
                tokenList,
            } as FetchLimitOrderParams)
            .catch((e) => console.log('refreshLimitOrder: ', e));
    }, [chainId, account, web3, tokenList]);

    useEffect(() => {
        console.info('trigger refreshLimitOrder', account);
        refreshLimitOrder();
    }, [chainId, account, tokenList]);

    useInterval(() => {
        console.log('auto trigger refreshLimitOrder', chainId, account);
        refreshLimitOrder();
    }, IZUMI_SWAP_CONFIG.AUTO_REFRESH_LIMIT_ORDER_DATA_INTERVAL);

    const gasToken = useGasToken();

    const handleCancelOrder = useCallback(
        (orderId: string, tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, isUseGasToken: boolean, gasPrice: number) => {
            const limitOrderWithSwapManager = getLimitOrderWithSwapManagerContract(chainId, web3);
            const limitOrderWithSwapAddress = LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES[chainId];
            if (!limitOrderWithSwapManager || !limitOrderWithSwapAddress || !account) {
                return new Promise<TransactionReceipt>((_, reject) => reject('invalid OrderManagerContract or Account'));
            }
            const tokenAIsChainCoin = isGasOrWrappedGasToken(tokenA, chainId) && isUseGasToken;
            const tokenBIsChainCoin = isGasOrWrappedGasToken(tokenB, chainId) && isUseGasToken;
            const hasChainCoin = tokenAIsChainCoin || tokenBIsChainCoin;
            const recipient = hasChainCoin ? limitOrderWithSwapAddress : account;
            const cancelCall = limitOrderWithSwapManager.methods.cancel(recipient, orderId, String(A_LONG_FUTURE_TIME));
            const chain = getChain(chainId);
            const toastLink = {} as ToastLink;
            if (!hasChainCoin) {
                return cancelCall
                    .send(
                        buildSendingParams(
                            chainId,
                            {
                                from: account!,
                                maxFeePerGas: gasPrice,
                            },
                            gasPrice
                        ) as any
                    )
                    .on('transactionHash', (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        toast('info', 'Ongoing', undefined, toastLink);
                    });
            }
            const callList: string[] = [cancelCall.encodeABI()];

            const unwrapWETH9 = limitOrderWithSwapManager.methods.unwrapWETH9('0', account!).encodeABI();
            callList.push(unwrapWETH9);
            const tokenNotEth = tokenAIsChainCoin ? tokenB.address : tokenA.address;
            const sweepToken = limitOrderWithSwapManager.methods.sweepToken(tokenNotEth, '0', account!).encodeABI();
            callList.push(sweepToken);

            return limitOrderWithSwapManager.methods
                .multicall(callList)
                .send(
                    buildSendingParams(
                        chainId,
                        {
                            from: account!,
                            maxFeePerGas: gasPrice,
                        },
                        gasPrice
                    ) as any
                )
                .on('transactionHash', (hash: string) => {
                    if (chain) {
                        toastLink.title = 'View on ' + chain.name;
                        toastLink.link = getTxLink(hash, chain);
                    }
                    toast('info', 'Ongoing', undefined, toastLink);
                });
        },
        [account, chainId, web3]
    );

    const handleClaimOrder = useCallback(
        (orderId: string, tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, isUseGasToken: boolean, gasPrice: number) => {
            const limitOrderWithSwapManager = getLimitOrderWithSwapManagerContract(chainId, web3);
            const limitOrderWithSwapAddress = LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES[chainId];
            if (!limitOrderWithSwapManager || !limitOrderWithSwapAddress || !account) {
                return new Promise<TransactionReceipt>((_, reject) => reject('invalid OrderManagerContract or account'));
            }
            const tokenAIsChainCoin = isGasOrWrappedGasToken(tokenA, chainId) && isUseGasToken;
            const tokenBIsChainCoin = isGasOrWrappedGasToken(tokenB, chainId) && isUseGasToken;
            const tokenNotEth = tokenAIsChainCoin ? tokenB.address : tokenA.address;
            const chain = getChain(chainId);
            const toastLink = {} as ToastLink;
            if (tokenBIsChainCoin || tokenAIsChainCoin) {
                const callList: string[] = [];
                callList.push(limitOrderWithSwapManager.methods.collect(limitOrderWithSwapAddress, orderId).encodeABI());
                callList.push(limitOrderWithSwapManager.methods.unwrapWETH9('0', account!).encodeABI());

                callList.push(limitOrderWithSwapManager.methods.sweepToken(tokenNotEth, '0', account!).encodeABI());
                return limitOrderWithSwapManager.methods
                    .multicall(callList)
                    .send(
                        buildSendingParams(
                            chainId,
                            {
                                from: account!,
                                maxFeePerGas: gasPrice,
                            },
                            gasPrice
                        ) as any
                    )
                    .on('transactionHash', (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        toast('info', 'Ongoing', undefined, toastLink);
                    });
            } else {
                return limitOrderWithSwapManager.methods
                    .collect(account!, orderId)
                    .send(
                        buildSendingParams(
                            chainId,
                            {
                                from: account!,
                                maxFeePerGas: gasPrice,
                            },
                            gasPrice
                        ) as any
                    )
                    .on('transactionHash', (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        toast('info', 'Ongoing', undefined, toastLink);
                    });
            }
        },
        [account, chainId, web3]
    );

    const proLimitOrder = proOrderList.activeList;

    //TODO: better code
    const parsePair = useCallback(
        (id) => {
            const tokenX = proLimitOrder[id].acquireToken;
            const tokenY = proLimitOrder[id].sellToken;
            const { baseToken, quoteToken } = getBaseQuoteOrder(tokenX, tokenY, chainId);
            const tokenXIsGasToken = isGasOrWrappedGasToken(baseToken, chainId);
            const tokenYIsGasToken = isGasOrWrappedGasToken(quoteToken, chainId);
            const pairs =
                (tokenXIsGasToken ? (proControlState.useGasToken ? gasToken.symbol : 'W' + gasToken.symbol) : baseToken.symbol) +
                '/' +
                (tokenYIsGasToken ? (proControlState.useGasToken ? gasToken.symbol : 'W' + gasToken.symbol) : quoteToken.symbol) +
                '/' +
                proLimitOrder[id].pairs.split('/')[2];
            return pairs;
        },
        [proControlState, gasToken, proLimitOrder, chainId]
    );

    const showList = proLimitOrder.map((o: ProLimitOrder) => {
        return {
            Pairs: o.pairs,
            Direction: o.direction,
            Amount: formatNumber(o.baseAmountDecimal, 2, 4, true),
            Price: formatNumber(o.priceDecimalBaseByQuote, 4, 6, true),
            Filled: formatNumber(o.fillPercent, 2, 4) + '%',
            Pending: formatNumber(o.pendingAmountDecimal, 2, 4, true),
            Time: o.createTime,
            State: o.status,
        };
    });

    return isMobile ? (
        <Stack>
            {showList.length === 0 ? (
                <Center w="100%" h="180px">
                    <Text className={noto_h3} color="#83789B">
                        Oh, there's nothing here !
                    </Text>
                </Center>
            ) : (
                <Accordion allowMultiple={true} allowToggle={true}>
                    {showList.map((item: any, index: number) => {
                        return (
                            <AccordionItem key={index} mb="0px" border={colorTheme('1px solid #EEEEEE', '1px solid #251C4D')}>
                                <AccordionButton
                                    px="10px"
                                    mx="0px"
                                    _expanded={{
                                        bg: colorTheme('#EEF0F9', '#1C1831'),
                                    }}
                                    _hover={{}}
                                >
                                    <HStack spacing="0px">
                                        <Stack w="110px">
                                            <Text className={noto_h3} textAlign="left" color="#6A598C">
                                                Pairs
                                            </Text>
                                            <Text className={noto_t3_bold} textAlign="left" color={colorTheme('#6A598C', '#FFFFFF')}>
                                                {parsePair(index)}
                                            </Text>
                                        </Stack>
                                        <Stack w="71px">
                                            <Text className={noto_h3} textAlign="left" color="#6A598C">
                                                Direction
                                            </Text>
                                            <Text
                                                className={noto_h3}
                                                textAlign="left"
                                                color={item.Direction === 'Buy' ? '#12931F' : '#CB1414'}
                                            >
                                                {item.Direction}
                                            </Text>
                                        </Stack>
                                        <Stack w="75px">
                                            <Text className={noto_h3} textAlign="left" color="#6A598C">
                                                Amount
                                            </Text>
                                            <Text className={noto_h3} textAlign="left" color={colorTheme('#6A598C', '#FFFFFF')}>
                                                {item.Amount}
                                            </Text>
                                        </Stack>
                                        <Stack w="65px">
                                            <Text className={noto_h3} textAlign="left" color="#6A598C">
                                                Price
                                            </Text>
                                            <Text className={noto_h3} textAlign="left" color={colorTheme('#6A598C', '#FFFFFF')}>
                                                {item.Price}
                                            </Text>
                                        </Stack>
                                    </HStack>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4} bg={colorTheme('#EEF0F9', '#1C1831')} px="10px" pt="0px">
                                    <HStack w="100%" spacing="0px">
                                        <Stack w="110px">
                                            <Stack
                                                w="65px"
                                                bg="#7751F5"
                                                textAlign="center"
                                                color={colorTheme('#FFFFFF', '#FFFFFF')}
                                                borderRadius="3px"
                                                py="2px"
                                                hidden={item['Pending'] === '0'}
                                                cursor="pointer"
                                            >
                                                <Text
                                                    className={noto_h3}
                                                    onClick={() => {
                                                        const chain = getChain(chainId);
                                                        const toastLink = {} as ToastLink;
                                                        const orderIdx = proLimitOrder[index].orderIdx;
                                                        handleClaimOrder(
                                                            orderIdx,
                                                            proLimitOrder[index].sellToken,
                                                            proLimitOrder[index].acquireToken,
                                                            proControlState.useGasToken,
                                                            gasPrice
                                                        )
                                                            .then((e: any) => {
                                                                if (chain) {
                                                                    toastLink.title = 'View on ' + chain.name;
                                                                    toastLink.link = getTxLink(e.transactionHash, chain);
                                                                }
                                                                toast('success', 'Claim successfully', undefined, toastLink);
                                                                refreshLimitOrder();
                                                            })
                                                            .catch((e: any) => {
                                                                console.info('error:   ', e.message);
                                                            });
                                                    }}
                                                >
                                                    {'Claim'}
                                                </Text>
                                            </Stack>
                                            <CustomButton
                                                className={noto_h3}
                                                w="65px"
                                                h="18px"
                                                bg={colorTheme('#F8EBEB', '#603A3A')}
                                                text={'Cancel'}
                                                color={colorTheme('#92686B', '#B47E82')}
                                                borderRadius="3px"
                                                py="2px"
                                                onClick={() => {
                                                    const chain = getChain(chainId);
                                                    const toastLink = {} as ToastLink;
                                                    const orderIdx = proLimitOrder[index].orderIdx;
                                                    handleCancelOrder(
                                                        orderIdx,
                                                        proLimitOrder[index].sellToken,
                                                        proLimitOrder[index].acquireToken,
                                                        proControlState.useGasToken,
                                                        gasPrice
                                                    )
                                                        .then((e: any) => {
                                                            if (chain) {
                                                                toastLink.title = 'View on ' + chain.name;
                                                                toastLink.link = getTxLink(e.transactionHash, chain);
                                                            }
                                                            toast('success', 'Cancel successfully', undefined, toastLink);
                                                            refreshLimitOrder();
                                                        })
                                                        .catch((e: any) => {
                                                            console.info('error:   ', e.message);
                                                        });
                                                }}
                                                _hover={{ bg: '#A287F8' }}
                                            ></CustomButton>
                                        </Stack>
                                        <Stack w="71px">
                                            <Text className={noto_h3} textAlign="left" color="#6A598C">
                                                Filled
                                            </Text>
                                            <Text className={noto_h3} textAlign="left" color={colorTheme('#6A598C', '#EEEEEE')}>
                                                {item.Filled}
                                            </Text>
                                        </Stack>
                                        <HStack w="75px">
                                            <Image boxSize="16px" src={proLimitOrder[index].acquireToken.icon}></Image>
                                            <Text className={noto_t3_bold} ml="5px !important">
                                                {item['Pending']}
                                            </Text>
                                        </HStack>
                                        <Stack w="65px">
                                            <Text className={noto_h3} textAlign="left" color="#6A598C">
                                                Time
                                            </Text>
                                            <Text className={noto_h3} textAlign="left" color={colorTheme('#6A598C', '#C6B8CF')}>
                                                {item.Time}
                                            </Text>
                                        </Stack>
                                    </HStack>
                                </AccordionPanel>
                            </AccordionItem>
                        );
                    })}
                </Accordion>
            )}
        </Stack>
    ) : (
        <Stack h="100%" overflow="auto">
            <HStack spacing="0px" position="sticky" top="0px" zIndex="1" bg={themeColor} pt="8px">
                {tabs.map((item, index) => {
                    return (
                        <Text className={noto_h3} key={index} w={item.width} color="#6A598C">
                            {item.title}
                        </Text>
                    );
                })}
            </HStack>
            <Stack h="100%">
                {showList.length === 0 ? (
                    <Center w="100%" h="100%">
                        <Text className={noto_h3} color="#83789B">
                            Oh, there's nothing here !
                        </Text>
                    </Center>
                ) : (
                    showList.map((item: any, index: number) => {
                        return (
                            <HStack key={index} spacing="0px">
                                {Object.keys(item).map((key: any, i) => {
                                    return key === 'Pending' ? (
                                        <HStack w={tabs[i].width} key={i}>
                                            <Image boxSize="16px" src={proLimitOrder[index].acquireToken.icon}></Image>
                                            <Text className={noto_t3_bold} ml="5px !important">
                                                {item[key]}
                                            </Text>
                                            <CustomButton
                                                w="56px"
                                                className={noto_h3}
                                                bg="#7751F5"
                                                textAlign="center"
                                                color={colorTheme('#FFFFFF', '#FFFFFF')}
                                                borderRadius="3px"
                                                py="2px"
                                                hidden={item[key] === '0'}
                                                text="Claim"
                                                onClick={() => {
                                                    const chain = getChain(chainId);
                                                    const toastLink = {} as ToastLink;
                                                    const orderIdx = proLimitOrder[index].orderIdx;
                                                    handleClaimOrder(
                                                        orderIdx,
                                                        proLimitOrder[index].sellToken,
                                                        proLimitOrder[index].acquireToken,
                                                        proControlState.useGasToken,
                                                        gasPrice
                                                    )
                                                        .then((e: any) => {
                                                            if (chain) {
                                                                toastLink.title = 'View on ' + chain.name;
                                                                toastLink.link = getTxLink(e.transactionHash, chain);
                                                            }
                                                            toast('success', 'Claim successfully', undefined, toastLink);
                                                            refreshLimitOrder();
                                                        })
                                                        .catch((e: any) => {
                                                            console.info('error:   ', e.message);
                                                        });
                                                }}
                                                _hover={{ bg: '#A287F8' }}
                                            ></CustomButton>
                                        </HStack>
                                    ) : key === 'Time' ? (
                                        <Text className={noto_h3} key={i} w={tabs[i].width} color={colorTheme('#6A598C', '#C9C3D7')}>
                                            {item[key]}
                                        </Text>
                                    ) : key === 'State' ? (
                                        <CustomButton
                                            className={noto_h3}
                                            key={i}
                                            w={tabs[i].width}
                                            h="18px"
                                            bg={colorTheme('#F8EBEB', '#1C182F')}
                                            text={'Cancel'}
                                            color={colorTheme('#92686B', '#6A598C')}
                                            borderRadius="3px"
                                            py="2px"
                                            onClick={() => {
                                                const chain = getChain(chainId);
                                                const toastLink = {} as ToastLink;
                                                const orderIdx = proLimitOrder[index].orderIdx;
                                                handleCancelOrder(
                                                    orderIdx,
                                                    proLimitOrder[index].sellToken,
                                                    proLimitOrder[index].acquireToken,
                                                    proControlState.useGasToken,
                                                    gasPrice
                                                )
                                                    .then((e: any) => {
                                                        if (chain) {
                                                            toastLink.title = 'View on ' + chain.name;
                                                            toastLink.link = getTxLink(e.transactionHash, chain);
                                                        }
                                                        toast('success', 'Cancel successfully', undefined, toastLink);
                                                        refreshLimitOrder();
                                                    })
                                                    .catch((e: any) => {
                                                        console.info('error:   ', e.message);
                                                    });
                                            }}
                                            _hover={{ bg: colorTheme('#F6D2D2', '#2B2547') }}
                                        ></CustomButton>
                                    ) : key === 'Pairs' ? (
                                        <Text className={noto_h3} key={i} w={tabs[i].width} color={colorTheme('#332D43', '#F8EBEB')}>
                                            {parsePair(index)}
                                        </Text>
                                    ) : key === 'Direction' ? (
                                        <Text
                                            className={noto_h3}
                                            key={i}
                                            w={tabs[i].width}
                                            color={item[key] === 'Buy' ? '#12931F' : '#CB1414'}
                                        >
                                            {item[key]}
                                        </Text>
                                    ) : (
                                        <Text className={noto_h3} key={i} w={tabs[i].width} color={colorTheme('#332D43', '#F8EBEB')}>
                                            {item[key]}
                                        </Text>
                                    );
                                })}
                            </HStack>
                        );
                    })
                )}
            </Stack>
        </Stack>
    );
};

export default CurrentOrders;
